import { Table } from "antd";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { showNotificationWithIcon } from "../../../utils/helpers/helpers";
import { getList } from "../../../utils/http/dataProvider";
import { getUserInfo } from "../../../utils/redux/reducers/User";

import "./QrcodeDetailList.scss";
import { qrcodeDetailListColumns } from "./qrcodeDetailListColumns";
import QrcodeDetailListHeader from "./QrcodeDetailListHeader";
import { useHistory, useLocation } from "react-router-dom";

const QrcodeDetailList = () => {
  const user = useSelector((state) => getUserInfo(state));
  const [keyword, setKeyword] = useState();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const qrcodeId = location?.query?.id;

  if (!qrcodeId) {
    history.goBack();
  }

  const { data, error, isLoading } = useQuery(
    ["lottery-qrcode-list", [user.token, page]],
    () =>
      getList(`lottery-qrcode/${qrcodeId}/qrcode-list`, {
        pagination: {
          perPage: 10,
          page,
        },
        sort: {
          field: "id",
          order: "desc",
        },
        filter: {
          keyword,
        },
        token: user.token,
      })
  );

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  if (error) {
    showNotificationWithIcon(error.data.message);
  }

  return (
    <div className="qrcode-list">
      <QrcodeDetailListHeader keyword={keyword} setKeyword={setKeyword} />
      <Table
        className="list"
        loading={isLoading || loading}
        rowKey="id"
        columns={qrcodeDetailListColumns(user.token, setLoading, history)}
        dataSource={data?.data}
        scroll={{
          y: 320,
        }}
        pagination={{
          onChange: handleChangePage,
          showQuickJumper: true,
          total: data?.total,
        }}
      />
    </div>
  );
};

export default QrcodeDetailList;
