
import { Image } from "antd";
import { Locale } from "../../../utils";
const i18n = Locale.getInstance();

export const qrcodeDetailListColumns = (

) => {
  return [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: i18n.t('qrcode.type'),
      dataIndex: "type_label",
    },
    {
      title: i18n.t('financeAccount.listHeaderStatus'),
      dataIndex: "status_label",
    },
    {
      title: i18n.t("users.avatar"),
      render: (item) => {
        if(!item.avatar) return null;
        return (
          <>
            <img
              src={item.avatar}
              alt={item.nickname}
              width={60}
              height={60}
            />
          </>
        );
      },
    },
    {
      title: i18n.t("qrcode.prize"),
      dataIndex: "prize",
      render: (prize, record) => (
        <div>
          <div>
            <Image width={100} src={i18n.getPropNameByLocale(prize, 'image')} />
          </div>
          <div>
            <a>{i18n.getPropNameByLocale(prize)}</a>
          </div>
        </div>
      ),
    },
    {
      title: i18n.t("qrcode.redPacketAmount"),
      dataIndex: "red_packet_amount"
    },
    {
      title: i18n.t("qrcode.regionName"),
      render: (item) => {
        return <span>{i18n.getPropNameByLocale(item.region, "name")}</span>;
      }
    },
    {
      title: i18n.t("qrcode.scannedAt"),
      render: (item) => {
        return <span>{item.scanned_at}</span>;
      }
    },
    {
      title: i18n.t("qrcode.drawndAt"),
      render: (item) => {
        return <span>{item.drawn_at}</span>;
      }
    },
    {
      title: i18n.t("qrcode.exchangedAt"),
      render: (item) => {
        return <span>{item.exchanged_at}</span>;
      }
    },

  ];
};
