//react
import React, { Component } from "react";
import { QueryClient, QueryClientProvider } from "react-query";

//antd
import { ConfigProvider } from "antd";
import Locale from "./utils/locale/Locale";
import zhCN from "./utils/locale/antd-zh_CN";
import ugCN from "./utils/locale/antd-ug_CN";

//react router
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

//app routes
import Main from "./routes/main/Main";
import Login from "./routes/login/Login";
import ShowsEdit from "./routes/shows/ShowsEdit";
import NotFound from "./routes/404/NotFount";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
    },
  },
});

class App extends Component {
  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  /* render methods */
  render() {
    let antLocale = zhCN;
    const localeIsRtl = Locale.getInstance().isLocaleRTL();
    if (localeIsRtl) {
      antLocale = ugCN;
    }

    return (
      <ConfigProvider
        locale={antLocale}
        direction={localeIsRtl ? "rtl" : "ltr"}
        theme={{
          token: {
            colorPrimary: "#419cb4",
          },
        }}
      >
        <QueryClientProvider client={queryClient}>
          <Router basename={localeIsRtl ? "/ug" : "/"}>
            <Switch>
              <Redirect exact from="/" to="/main" />
              <Route path="/main" component={Main} />
              <Route path="/login" component={Login} />
              <Route path="/show-edit" component={ShowsEdit} />
              <Route component={NotFound} />
            </Switch>
          </Router>
        </QueryClientProvider>
      </ConfigProvider>
    );
  }
}

export default App;
