import axios from "axios";
import { Locale } from "../";

const newRequest = {
  get: (uri, token = "") => {
    if (uri.indexOf("?") !== -1) {
      uri += "&t=" + new Date().getTime();
    } else {
      uri += "?t=" + new Date().getTime();
    }
    return newRequest.fetch("GET", uri, {}, token);
  },
  post: (uri, data, token = "") => {
    return newRequest.fetch("POST", uri, data, token);
  },
  put: (uri, data, token = "") => {
    return newRequest.fetch("PUT", uri, data, token);
  },
  delete: (uri, token = "") => {
    return newRequest.fetch("DELETE", uri, {}, token);
  },
  upload: (uri, files, token, type = "", folderId = "", option) => {
    let data = new FormData();
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      if (!file.file) {
        if (file.type && file.type.indexOf("video") !== -1) {
          data.append(`files[]`, file, file.name);
        } else {
          data.append(`files[]`, file, "images.png");
        }
      } else {
        data.append(`files[]`, file.file, file.file.name);
      }
    }
    data.append("folder_id", folderId);
    data.append("for", type);
    if (type === "licence") {
      data.append("licence", 1);
    }
    let options = {
      method: "POST",
      headers: {
        "Accept-Language": newRequest.getDefaultLocale(),
        Authorization: token,
      },
      data,
      ...option,
    };
    return newRequest.fetchPromise(uri, options);
  },
  fetch: (method, uri, data = {}, token = "") => {
    let options = {
      method,
      headers: {
        "Accept-Language": newRequest.getDefaultLocale(),
        Authorization: token,
      },
      data,
    };
    return newRequest.fetchPromise(uri, options);
  },
  fetchPromise: (uri, options) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios(uri, {
          ...options,
          timeout: 100000,
          timeoutErrorMessage: "response time out",
        });

        if (res.status === 200) {
          if (res.data.result) {
            resolve(res.data);
          } else {
            reject(res.data);
          }
        } else if (res.status === 422) {
          reject(res.data);
        } else {
          reject(res.data);
        }
      } catch (error) {
        if (error.response) {
          reject(error.response);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          reject(error.request);
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error

          console.log("Error", error.message);
        }
        console.log("error", error.config);
      }
    });
  },
  getDefaultLocale: () => {
    return Locale.getLocale();
  },
};
export const buildBaseURL = (version = "v1", prefix = null) => {
  let url = "/";
  const locale = newRequest.getDefaultLocale();
  if (locale === "zh") {
    url += "zh/";
  }
  if (prefix) {
    url += prefix + "/";
  }
  url += "api/" + version + "/";

  return url;
};

export default newRequest;
