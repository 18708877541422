import { Button, Form, Input, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useForm } from "antd/lib/form/Form";
import { useLocation, useHistory } from "react-router-dom";

import { Locale } from "../../../utils";
import {
  handleMutationError,
  showNotificationWithIcon,
} from "../../../utils/helpers/helpers";
import { getUserInfo } from "../../../utils/redux/reducers/User";
import { BreadCrumb } from "../../../components/main/LayoutComponents";

import "./AddOrEditQrcode.scss";
import { create, getOne, update } from "../../../utils/http/dataProvider";
import TranslateBtn from "../../../components/product/TranslateBtn";
import breadCrumb from "./breadCrumb";
const i18n = Locale.getInstance();
let counter = 0;
const AddOrEditQrcode = () => {
  const user = useSelector((state) => getUserInfo(state));
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const [qrcodeForm] = useForm();
  const history = useHistory();
  const location = useLocation();

  const id = location?.state?.id;
  counter += 1;

  const { data, isLoading } = useQuery(
    ["lottery-qrcode-detail", user.token],
    () => getOne("lottery-qrcode", { id, token: user.token }),
    {
      enabled: !!id,
    }
  );

  useEffect(() => {
    if (data && id === data?.id) {
      const values = {
        zh_name: data.zh_name,
        ug_name: data.ug_name,
        batch_number: data.batch_number,
        quantity: data.quantity,
        pallet_capacity: data.pallet_capacity,
        cartoon_capacity: data.cartoon_capacity,
        box_capacity: data.box_capacity,
      };

      qrcodeForm.setFieldsValue(values);
    }
    if (!id) {
      qrcodeForm.setFieldsValue({
        zh_name: "",
        ug_name: "",
        zh_description: [],
        ug_description: [],
        mobile: "",
        gender: "",
        avatar: [],
      });
    }
  }, [data]);

  const addQrcodeMutation = useMutation(create, {
    onSuccess: () => {
      queryClient.invalidateQueries("lottery-qrcode");
      showNotificationWithIcon(i18n.t("media.saveFinished"), "success");
      setLoading(false);
      history.goBack();
    },
    onError: (error) => {
      handleMutationError(error);
      setLoading(false);
    },
  });

  const updateQrcodeMutation = useMutation(update, {
    onSuccess: () => {
      queryClient.invalidateQueries("lottery-qrcode");
      showNotificationWithIcon(i18n.t("media.saveFinished"), "success");
      setLoading(false);
      history.goBack();
    },
    onError: (error) => {
      handleMutationError(error);
      setLoading(false);
    },
  });

  const onFinish = (values) => {
    setLoading(true);
    const params = {
      data: values,
      token: user.token,
    };

    if (id) {
      updateQrcodeMutation.mutate({
        resource: "lottery-qrcode",
        params: { ...params, id },
      });
    } else {
      addQrcodeMutation.mutate({ resource: "lottery-qrcode", params });
    }
  };

  const onFinishFailed = (errorInfo) => {
    setLoading(false);
    showNotificationWithIcon(errorInfo);
  };
  let breadCrumbData = breadCrumb;
  // breadCrumbData.push({
  //   title: id ? i18n.t("qrcode.edit") : i18n.t("qrcode.add"),
  // });

  if (isLoading) {
    return <Spin spinning={isLoading} />;
  }
  return (
    <>
      <BreadCrumb data={breadCrumbData} />
      <Spin spinning={loading}>
        <Form
          form={qrcodeForm}
          className="qrcode-form"
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                shouldUpdate
                name="ug_name"
                label={i18n.t("qrcode.ugName")}
                initialValue={data?.ug_name}
                rules={[
                  {
                    required: true,
                    message: i18n.t("productEdit.standardRequired"),
                  },
                ]}
              >
                <Input
                  addonAfter={
                    <TranslateBtn
                      context={getFieldValue("ug_name")}
                      onFinish={(value) =>
                        setFieldsValue({
                          zh_name: value,
                        })
                      }
                      from="ug"
                      to="zh"
                    />
                  }
                />
              </Form.Item>
            )}
          </Form.Item>

          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                shouldUpdate
                name="zh_name"
                label={i18n.t("qrcode.zhName")}
                initialValue={data?.zh_name}
                rules={[
                  {
                    required: true,
                    message: i18n.t("productEdit.standardRequired"),
                  },
                ]}
              >
                <Input
                  addonAfter={
                    <TranslateBtn
                      context={getFieldValue("zh_name")}
                      onFinish={(value) =>
                        setFieldsValue({
                          ug_name: value,
                        })
                      }
                      from="zh"
                      to="ug"
                    />
                  }
                />
              </Form.Item>
            )}
          </Form.Item>

          <Form.Item
            name="quantity"
            label={i18n.t("qrcode.quantity")}
            initialValue={data?.quantity}
            shouldUpdate
            rules={[
              {
                required: true,
                message: i18n.t("productEdit.standardRequired"),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="batch_number"
            label={i18n.t("qrcode.batch")}
            initialValue={data?.batch_number}
            shouldUpdate
            rules={[
              {
                required: true,
                message: i18n.t("productEdit.standardRequired"),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="pallet_capacity"
            label={i18n.t("qrcode.palletCapacity")}
            initialValue={data?.pallet_capacity}
            shouldUpdate
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="cartoon_capacity"
            label={i18n.t("qrcode.cartoonCapacity")}
            initialValue={data?.cartoon_capacity}
            shouldUpdate
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="box_capacity"
            label={i18n.t("qrcode.boxCapacity")}
            initialValue={data?.box_capacity}
            shouldUpdate
          >
            <Input />
          </Form.Item>

          <Form.Item className="submit">
            <Button type="primary" htmlType="submit">
              {i18n.t("productEdit.saveAndBack")}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};

export default AddOrEditQrcode;
