import { Avatar, Button, Image, Space, Tag } from "antd";
import { Locale } from "../../../utils";
import { CheckCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
const i18n = Locale.getInstance();

export const redeemOrderListColumns = (
  history,
  handleApprove,
  handleReject
) => {
  return [
    {
      title: i18n.t("qrcode.store"),
      render: (item) => {
        return (
          <Tag color="blue">{i18n.getPropNameByLocale(item.store, "name")}</Tag>
        );
      },
    },
    {
      title: i18n.t("qrcode.regionName"),
      render: (item) => {
        return (
          <div color="blue">
            {item?.store?.province?.name.zh} {item?.store?.city?.name.zh}{" "}
            {item?.store?.region?.name.zh}{" "}
          </div>
        );
      },
    },
    {
      title: i18n.t("commissionList.address"),
      render: (item) => {
        return (
          <div className="address">
            <div>{item.receiver}</div>
            <div>{item.tel}</div>
            <div>{item.full_address}</div>
          </div>
        );
      },
    },
    {
      title: i18n.t("link.products"),
      render: (item) => {
        return (
          <div className="details">
            <Avatar.Group
              maxCount={4}
              size="large"
              maxStyle={{
                color: "#f56a00",
                backgroundColor: "#fde3cf",
              }}
            >
              {item?.details.map((detail) => {
                return (
                  <Avatar
                    size="large"
                    shape="circle"
                    src={i18n.getPropNameByLocale(detail?.prize, "image")}
                  />
                );
              })}
            </Avatar.Group>
          </div>
        );
      },
    },
    {
      title: i18n.t("common.statusLabel"),
      render: (item) => {
        let color;
        let icon;
        if (item.status === 1) {
          color = "gray";
          icon = <InfoCircleOutlined />;
        } else {
          color = "green";
          icon = <CheckCircleOutlined />;
        }
        return (
          <>
            <Tag icon={icon} color={color}>
              {item.status_label}
            </Tag>
          </>
        );
      },
    },
    {
      title: i18n.t("applyment.createdDate"),
      dataIndex: "created_at",
    },
    {
      title: i18n.t("applyment.updatedDate"),
      dataIndex: "updated_at",
    },

    {
      title: i18n.t("users.actions"),
      fixed: "right",
      width: 100,
      render: (item) => {
        return (
          <Space direction="vertical">
            {item.status == 0 && (
              <Button
                onClick={() => handleApprove(item.id)}
                type="primary"
                size="small"
              >
                {i18n.t("commissionList.confirm")}
              </Button>
            )}
          </Space>
        );
      },
    },
  ];
};
