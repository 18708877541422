import { Button } from "antd";
import { Locale } from "../../../utils";

const i18n = Locale.getInstance();
const StudentListHeader = ({ setOpenAddStudentModal }) => {
  return (
    <div className="header">
      <div className="actions">
        <Button
          onClick={() => {
            setOpenAddStudentModal(true);
          }}
          type="primary"
        >
          {i18n.t("course.student.add")}
        </Button>
      </div>
    </div>
  );
};
export default StudentListHeader;
