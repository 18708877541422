import React from "react";

import { Layout, Button } from "antd";

import Base from "../Base";

class SelectLang extends Base {
  /* lifecycle methods */

  /* render methods */

  render() {
    return (
      <Layout id="route-setting-lang">
        {this._renderSelected()}
        {this._renderAvailable()}
      </Layout>
    );
  }

  _renderSelected() {
    let currentLocale = this.i18n.getCurrentLocale();
    if (currentLocale) {
      return (
        <div className="list-wrap">
          <div className="list-label">
            {this.i18n.t("selectLang.currentTitle")}
          </div>
          <div className="lang-list">
            <Button type="primary" size="large">
              {this.i18n.t(currentLocale.name)}
            </Button>
          </div>
        </div>
      );
    }

    return null;
  }

  _renderAvailable() {
    let availableLocales = this.i18n.getAvailableLocales();
    if (availableLocales && availableLocales.length > 0) {
      return (
        <div className="list-wrap">
          <div className="list-label">
            {this.i18n.t("selectLang.availableTitle")}
          </div>
          <div className="lang-list">
            {availableLocales.map((locale, index) => {
              return (
                <Button
                  size="large"
                  key={`avai-lang-${index}`}
                  onClick={() => this.onLangItemClick(locale)}
                >
                  {this.i18n.t(locale.name)}
                </Button>
              );
            })}
          </div>
        </div>
      );
    }

    return null;
  }

  /* user methods */

  onHeaderBackClick = () => {
    this.props.history.goBack();
  };

  onLangItemClick = (locale) => {
    if (!locale) return;

    this.showModal(
      this.i18n.t("selectLang.switchConfirm"),
      Base.Modal.confirm,
      () => {
        let pathname = "/";
        if (locale.locale.indexOf("zh") === -1) {
          pathname += `${locale.locale}/`;
        }

        window.location.href = pathname;
      }
    );
  };
}

export default SelectLang;
