import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Button, Card, Col, Form, Image, Input, Radio, Row, Spin } from "antd";
import { useEffect, useRef } from "react";
import AMapLoader from "@amap/amap-jsapi-loader";

import { create, getOne, update } from "../../../utils/http/dataProvider";
import { getUserInfo } from "../../../utils/redux/reducers/User";
import { Locale } from "../../../utils";
import TranslateBtn from "../../../components/product/TranslateBtn";
import { BreadCrumb } from "../../../components/main/LayoutComponents";
import { AMAP_KEY, AMAP_VERSION, AMAP_ZOOM } from "../../../constants";
import { showNotificationWithIcon } from "../../../utils/helpers/helpers";
import SlateRichTextEditor from "../../../components/editor/SlateRichTextEditor";

const i18n = Locale.getInstance();
const { Meta } = Card;

function StoreEdit() {
  const { agreementId } = useParams();
  const user = useSelector((state) => getUserInfo(state));
  const [agreementForm] = Form.useForm();
  let map = useRef(null);
  const queryClient = useQueryClient();
  const history = useHistory();

  const { data, isLoading } = useQuery(
    ["lottery-agreements", user.token, agreementId],
    () =>
      getOne("lottery-agreements", {
        id: agreementId,
        token: user.token,
      }),
    {
      enabled: !!agreementId && agreementId != "add",
    }
  );

  useEffect(() => {
    if (data) {
      agreementForm.setFieldsValue({
        zh_agreement: data.zh_agreement,
        ug_agreement: data.ug_agreement,
        type: data.type,
        status: data.status,
      });
    }
  }, [data]);

  const updateAgreementMutation = useMutation(update, {
    onSuccess: () => {
      queryClient.invalidateQueries("lottery-agreements");
      showNotificationWithIcon(i18n.t("media.saveFinished"), "success");
      history.goBack();
    },
    onError: (error) => {
      handleMutationError(error);
    },
  });
  const addAgreementMutation = useMutation(create, {
    onSuccess: () => {
      queryClient.invalidateQueries("lottery-agreements");
      showNotificationWithIcon(i18n.t("media.saveFinished"), "success");
      history.goBack();
    },
    onError: (error) => {
      handleMutationError(error);
    },
  });
  const handleSubmit = async (values) => {
    console.log(
      "🚀 ~ file: AgreementEdit.jsx:60 ~ handleSubmit ~ values:",
      values
    );
    try {
      if (agreementId !== "add") {
        updateAgreementMutation.mutate({
          resource: "lottery-agreements",
          params: { data: values, token: user.token, id: agreementId },
        });
      } else {
        addAgreementMutation.mutate({
          resource: "lottery-agreements",
          params: { data: values, token: user.token, id: agreementId },
        });
      }
    } catch (error) {
      console.log("🚀 ~ file: StoreEdit.jsx:84 ~ handleSubmit ~ error:", error);
    }
  };

  const cardStyle = {
    display: "flex",
    flexDirection: "column",
  };

  return (
    <>
      <BreadCrumb
        data={[
          {
            href: "/main/lottery-qrcode/agreement",
            title: i18n.t("qrcode.agreement"),
          },
          {
            title: (
              <span>
                {agreementId !== "add"
                  ? i18n.t("sales.activity.edit")
                  : i18n.t("sales.activity.add")}
              </span>
            ),
          },
        ]}
      />
      <div className="qrcode">
        <Spin spinning={isLoading}>
          <Form
            form={agreementForm}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 10 }}
            onFinish={handleSubmit}
          >
            <Form.Item
              name="status"
              label={i18n.t("course.status")}
              initialValue={1}
            >
              <Radio.Group>
                <Radio defaultChecked={true} value={1}>
                  {i18n.t("course.statusActive")}
                </Radio>
                <Radio value={0}>{i18n.t("course.statusInActive")}</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="type"
              label={i18n.t("qrcode.customer")}
              initialValue={1}
            >
              <Radio.Group>
                <Radio defaultChecked={true} value={0}>
                  {i18n.t("qrcode.typeCustomer")}
                </Radio>
                <Radio value={1}>{i18n.t("qrcode.typeStore")}</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {({ setFieldsValue }) => {
                return (
                  <Form.Item
                    name="zh_agreement"
                    label={i18n.t("qrcode.zhAgreement")}
                    initialValue={data?.zh_description}
                  >
                    <SlateRichTextEditor
                      name="zh_agreement"
                      setFieldsValue={(value) =>
                        setFieldsValue({
                          zh_agreement: value,
                        })
                      }
                      initialValue={data?.zh_agreement ?? null}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {({ setFieldsValue }) => {
                return (
                  <Form.Item
                    name="ug_agreement"
                    label={i18n.t("qrcode.ugAgreement")}
                    initialValue={data?.ug_description}
                  >
                    <SlateRichTextEditor
                      name="ug_agreement"
                      setFieldsValue={(value) =>
                        setFieldsValue({
                          ug_agreement: value,
                        })
                      }
                      initialValue={data?.ug_agreement ?? null}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {i18n.t("productEdit.saveAndBack")}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </div>
    </>
  );
}

export default StoreEdit;
