import React from "react";
import { List, Button, Tag, Space, Statistic, Radio, Modal } from "antd";
import { connect } from "react-redux";
import moment from "dayjs";
import storage from "redux-persist/lib/storage";

import { getUserInfo } from "../../utils/redux/reducers/User";
import Base from "../Base";
import SendTicketForm from "./SendTicketForm";
import { FILTER_STATUS } from "../../constants";
import { getMerchantSettings } from "../../utils/redux/reducers/Merchant";

class LotteryList extends Base {
  constructor(props) {
    super(props);
    this.state = {
      data: { data: [] },
      loading: false,
      currentTicket: null,
      filterStatus: FILTER_STATUS.ALL.value,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.onGetDataList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.filterStatus !== this.state.filterStatus) {
      this.onGetDataList();
    }
  }

  onGetDataList = async (page = 1) => {
    const api = this.api.lottery.list;
    try {
      const token = this.getUserToken();
      if (token) {
        const { filterStatus } = this.state;
        const apiConfig = {
          token,
          query: {
            page,
            count: 10,
            "filter[status]": filterStatus,
          },
        };
        this.setState({ loading: true });
        const res = await api.run(apiConfig);
        if (res.result && this.mounted) {
          this.setState({
            data: res.data,
          });
        } else {
          this.showMessage(res.message);
        }
      }
    } catch (error) {
      this.handleApiError(api, error);
    } finally {
      if (this.mounted) {
        this.setState({ loading: false });
      }
    }
  };

  onDeleteItem(id) {
    this.showModal(
      this.i18n.t("sales.deleteWarning"),
      Base.Modal.confirm,
      async () => {
        const api = this.api.lottery.delete;
        if (this.mounted) {
          try {
            const token = this.getUserToken();
            if (token) {
              const apiConfig = {
                token,
                path: {
                  id,
                },
              };
              const res = await api.run(apiConfig);
              if (res.result) {
                this.onGetDataList();
              } else {
                this.showMessage(res.message, Base.Message.warning);
              }
            }
          } catch (error) {
            this.handleApiError(api, error);
          } finally {
            this.setState({ loading: false });
          }
        }
      }
    );
  }

  onCopyItem(data) {
    this.props.history.push({
      pathname: "/main/lottery-edit",
      state: {
        data,
        action: "copy",
      },
    });
  }

  onHideSendForm = () => this.setState({ currentTicket: null });

  onCreateNew = (data) => {
    this.props.history.push({
      pathname: "/main/lottery-edit",
      state: {
        data,
      },
    });
  };

  onGoToEdit(data = null) {
    if (data) {
      this.onCreateNew(data);
    } else {
      storage.getItem("lotteryData").then((localData) => {
        if (localData) {
          Modal.confirm({
            title: this.i18n.t("sales.localWarning"),
            okText: this.i18n.t("sales.localOk"),
            cancelText: this.i18n.t("sales.localNew"),
            onOk: () => {
              this.props.history.push({
                pathname: "/main/lottery-edit",
                state: {
                  action: "local",
                },
              });
            },
            onCancel: () => {
              storage.removeItem("lotteryData");
              this.onCreateNew(data);
            },
          });
        } else {
          this.onCreateNew(data);
        }
      });
    }
  }

  getFormattedDateString(date) {
    if (moment.isDayjs(date)) {
      return date.format("YYYY-MM-DD HH:mm:ss");
    }
    if (typeof date === "string") {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    }
    return null;
  }

  render() {
    const { data, loading, currentTicket, filterStatus } = this.state;
    const { settings } = this.props;
    return (
      <>
        <List
          bordered
          size="large"
          style={{ backgroundColor: "#fff" }}
          header={
            <Space direction="horizontal" wrap>
              <Button type="primary" onClick={() => this.onGoToEdit()}>
                {this.i18n.t("sales.activity.add")}
              </Button>
              <Radio.Group
                style={{ marginRight: 20, marginLeft: 20 }}
                onChange={(e) => {
                  this.setState({ filterStatus: e.target.value });
                }}
                value={filterStatus}
              >
                <Radio value={FILTER_STATUS.ALL.value}>
                  {FILTER_STATUS.ALL.name}
                </Radio>
                <Radio value={FILTER_STATUS.OPENED.value}>
                  {FILTER_STATUS.OPENED.name}
                </Radio>
                <Radio value={FILTER_STATUS.CLOSED.value}>
                  {FILTER_STATUS.CLOSED.name}
                </Radio>
              </Radio.Group>
            </Space>
          }
          itemLayout={window.innerWidth < 800 ? "vertical" : "horizontal"}
          dataSource={data.data}
          loading={loading}
          pagination={{
            current: data.current_page,
            pageSize: 10,
            total: data.total,
            showQuickJumper: true,
            onChange: (page) => this.onGetDataList(page),
          }}
          renderItem={(item) => (
            <List.Item
              style={{
                backgroundColor: item.status === 1 ? "#ffffff" : "#f1f1f1",
              }}
              actions={[
                <Space direction="vertical">
                  <Space wrap>
                    {!this.isLotteryQrcodeEnabled() && (
                      <>
                        <Button
                          type="link"
                          key="list-loadmore-send"
                          onClick={() => this.setState({ currentTicket: item })}
                        >
                          {this.i18n.t("sales.coupons.send")}
                        </Button>
                        <Button
                          type="link"
                          key="list-managers"
                          onClick={() => {
                            this.props.history.push({
                              pathname: "/main/lottery-managers-list",
                              state: { lotteryId: item.id },
                            });
                          }}
                        >
                          {this.i18n.t("lottery.managers")}
                        </Button>
                      </>
                    )}
                    <Button
                      type="link"
                      key="list-prize"
                      onClick={() => {
                        this.props.history.push({
                          pathname: "/main/lottery-prize-list",
                          state: {
                            lotteryId: item.id,
                            name: { zh: item.zh_name, ug: item.ug_name },
                          },
                        });
                      }}
                    >
                      {this.i18n.t("lottery.prize")}
                    </Button>
                    <Button
                          type="link"
                          key="list-loadmore-send-list"
                          onClick={() => {
                            this.props.history.push({
                              pathname: "/main/lottery-recommenders",
                              state: {
                                id: item.id,
                                title: this.i18n.t("lottery.recommendList"),
                              },
                            });
                          }}
                        >
                          {this.i18n.t("lottery.recommendList")}
                        </Button>
                  </Space>
                  <Space wrap>
                    {!this.isLotteryQrcodeEnabled() && (
                      <>
                        <Button
                          type="link"
                          key="list-loadmore-send-list"
                          onClick={() => {
                            this.props.history.push({
                              pathname: "/main/lottery-detail",
                              state: {
                                id: item.id,
                                title: this.i18n.t("lottery.currentCount"),
                              },
                            });
                          }}
                        >
                          {this.i18n.t("lottery.currentCount")}
                        </Button>
                        

                        <Button
                          type="link"
                          key="list-loadmore-buy-list"
                          onClick={() => {
                            this.props.history.push({
                              pathname: "/main/lottery-detail",
                              state: {
                                id: item.id,
                                title: this.i18n.t("lottery.buyHistory"),
                                type: "buy_ticket",
                              },
                            });
                          }}
                        >
                          {this.i18n.t("lottery.buyHistory")}
                        </Button>
                        <Button
                          type="link"
                          key="list-loadmore-trans-list"
                          onClick={() => {
                            this.props.history.push({
                              pathname: "/main/lottery-detail",
                              state: {
                                id: item.id,
                                title: this.i18n.t("lottery.transCount"),
                                type: "transaction",
                              },
                            });
                          }}
                        >
                          {this.i18n.t("lottery.transCount")}
                        </Button>
                      </>
                    )}
                    <Button
                      type="link"
                      key="list-loadmore-prize-list"
                      onClick={() => {
                        this.props.history.push({
                          pathname: "/main/lottery-detail",
                          state: {
                            id: item.id,
                            title: this.i18n.t("lottery.prizeCount"),
                            type: "prize",
                          },
                        });
                      }}
                    >
                      {this.i18n.t("lottery.prizeCount")}
                    </Button>
                  </Space>
                  <Space wrap>
                    <Button
                      key="list-loadmore-edit"
                      type="link"
                      onClick={() => this.onGoToEdit(item)}
                    >
                      {this.i18n.t("sales.activity.edit")}
                    </Button>
                    <Button
                      key="list-copy-more"
                      type="link"
                      onClick={() => this.onCopyItem(item)}
                    >
                      {this.i18n.t("product.productItem.actionClone")}
                    </Button>
                    <Button
                      key="list-loadmore-more"
                      type="link"
                      onClick={() => this.onDeleteItem(item.id)}
                      danger
                    >
                      {this.i18n.t("sales.activity.delete")}
                    </Button>
                  </Space>
                </Space>,
              ]}
            >
              <List.Item.Meta
                title={
                  <Space style={{ minWidth: 120 }} wrap>
                    <div>{this.i18n.getPropNameByLocale(item, "name")}</div>
                    <div>
                      {this.i18n.t("sales.activity.start_date")}
                      {this.getFormattedDateString(item.start_time)} -
                      {this.i18n.t("sales.activity.end_date")}
                      {this.getFormattedDateString(item.end_time)}
                    </div>
                  </Space>
                }
                avatar={
                  <Tag color={item.type === "fruit" ? "blue" : "cyan"}>
                    {item.type === 0
                      ? this.i18n.t("lottery.wheel")
                      : item.type === 1
                      ? this.i18n.t("lottery.nine")
                      : this.i18n.t("lottery.fruit")}{" "}
                    {item.id}
                  </Tag>
                }
                description={
                  <Space
                    direction="horizontal"
                    style={{ minWidth: 100 }}
                    size={30}
                    wrap
                  >
                    <Statistic
                      title={this.i18n.t("lottery.draw")}
                      value={item.statistics.draw_count}
                      valueStyle={{ color: "#999", fontSize: 14 }}
                    />
                    <Statistic
                      title={this.i18n.t("lottery.exchange")}
                      value={item.statistics.exchanged_count}
                      valueStyle={{ color: "#999", fontSize: 14 }}
                    />
                    {!this.isLotteryQrcodeEnabled() && (
                      <>
                        <Statistic
                          title={this.i18n.t("lottery.revert")}
                          value={item.statistics.organ_revert_sub_count}
                          valueStyle={{ color: "#999", fontSize: 14 }}
                        />
                        <Statistic
                          title={this.i18n.t("lottery.organSend")}
                          value={item.statistics.organ_send_count}
                          valueStyle={{ color: "#999", fontSize: 14 }}
                        />
                        <Statistic
                          title={this.i18n.t("lottery.selfBuyCount")}
                          value={item.statistics.self_buy}
                          valueStyle={{ color: "#999", fontSize: 14 }}
                        />

                        <Statistic
                          title={this.i18n.t("lottery.userTickets")}
                          value={item.statistics.user_tickets_count}
                          valueStyle={{ color: "#999", fontSize: 14 }}
                        />
                      </>
                    )}
                  </Space>
                }
              />
            </List.Item>
          )}
        />
        <SendTicketForm
          selectedCoupon={currentTicket}
          onCancel={this.onHideSendForm}
          onGetDataList={this.onGetDataList}
        />
      </>
    );
  }
}

export default connect((state) => {
  return {
    user: getUserInfo(state),
    settings: getMerchantSettings(state),
  };
})(LotteryList);
