import { Breadcrumb } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import { Locale } from "../../utils";
import "./LayoutComponents.scss";

const i18n = Locale.getInstance();

export const BreadCrumb = ({ data }) => {
  const arr = [
    {
      title: i18n.t("common.home"),
      icon: <HomeOutlined />,
    },
  ]
    .concat(data)
    .map((item) => {
      if (item) {
        if (item.href && item.icon) {
          item.title = (
            <Link to={item.href}>
              {item.icon}
              <span>{item.title}</span>
            </Link>
          );
          delete item.href;
          return item;
        } else if (item.icon) {
          item.title = (
            <span>
              {item.icon}
              <span>{item.title}</span>
            </span>
          );
          delete item.icon;
          return item;
        } else {
          return item;
        }
      }
      return {};
    });
  return <Breadcrumb className="bread-crumb" items={arr} separator=">" />;
};
