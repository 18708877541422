import { Button, Image, Space, Tag } from "antd";
import { Locale } from "../../../utils";
import {
  CheckCircleOutlined,
  InfoCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
const i18n = Locale.getInstance();

export const recommenderListColumns = (history, handleApprove, handleReject) => {
  return [
    {
      title: i18n.t("qrcode.name"),
      render: (item) => {
        return <span>{item.name}</span>;
      },
    },
    {
      title: i18n.t("users.avatar"),
      render: (item) => {
        return (
          <>
            <img
              src={`${item.avatar}`}
              alt={item.name}
              width={60}
              height={60}
            />
          </>
        );
      },
    },
    {
      title: i18n.t("common.mobile"),
      render: (item) => {
        return <span>{item.mobile}</span>;
      },
    },
    {
      title: i18n.t("productEdit.commission"),
      render: (item) => {
        return <span>{item.commission_rate}</span>;
      },
    },

    {
      title: i18n.t("qrcode.applyDate"),
      dataIndex: "applied_at",
    },

    {
      title: i18n.t("qrcode.approveDate"),
      dataIndex: "approved_at",
    },
    {
      title: i18n.t("common.statusLabel"),
      render: (item) => {
        let color;
        let icon;
        if (item.status === 1) {
          color = "purple";
          icon = <InfoCircleOutlined />;
        } else if (item.status === 3) {
          color = "red";
          icon = <QuestionCircleOutlined />;
        } else {
          color = "green";
          icon = <CheckCircleOutlined />;
        }
        return (
          <>
            <Tag icon={icon} color={color}>
              {item.status_label}
            </Tag>
            <span>
              {item.zh_refuse_reason ? i18n.getPropNameByLocale(item, 'refuse_reason') : ''}
            </span>
          </>
        );
      },
    },
    {
      title: i18n.t("recommender.qrcode"),
      render: (item) => {
        return <Image minwidth={100} maxwidth={200} src={item.qrcode} />;
      },
    },
    {
      title: i18n.t("users.actions"),
      fixed: "right",
      width: 100,
      render: (item) => {
        return (
          <Space direction="vertical">
            <Button
              onClick={() => {
                history.push({
                  pathname: `/main/lottery/recommender/${item.id}`,
                });
              }}
              type="link"
              size="small"
            >
              {i18n.t("product.productItem.actionEdit")}
            </Button>
            {item.status == 0 && (
              <Button
                onClick={() => {
                  history.push({
                    pathname: `/main/lottery/recommender/${item.id}`,
                  });
                }}
                type="primary"
                size="small"
              >
                {i18n.t("commissionList.confirm")}
              </Button>
            )}
            {/* {item.status != 2 && (
              <Button
                onClick={() => handleReject(item.id)}
                type="primary"
                size="small"
                danger
              >
                {i18n.t("commissionList.reject")}
              </Button>
            )} */}
          </Space>
        );
      },
    },
  ];
};
