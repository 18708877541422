import { Button } from "antd";
import { Locale } from "../../../utils";

const i18n = Locale.getInstance();
const LessonListHeader = ({ selectLesson }) => {
  return (
    <div className="header">
      <div className="actions">
        <Button
          onClick={() => {
            selectLesson();
          }}
          type="primary"
        >
          {i18n.t("course.lesson.add")}
        </Button>
      </div>
    </div>
  );
};
export default LessonListHeader;
