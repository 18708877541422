import { Button, Cascader, DatePicker, Form, Input, InputNumber, Radio, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useForm } from "antd/lib/form/Form";
import { useLocation, useHistory } from "react-router-dom";
import moment from "dayjs";
import { Locale } from "../../utils";
import {
  handleMutationError,
  showNotificationWithIcon,
} from "../../utils/helpers/helpers";
import { getUserInfo } from "../../utils/redux/reducers/User";
import { BreadCrumb } from "../../components/main/LayoutComponents";
import { regionData, codeToText } from "element-china-area-data";
import "./redPacketEdit.scss";
import { create, getList, getOne, update } from "../../utils/http/dataProvider";
import ImageUpload from "../../components/media/ImageUpload";
import TranslateBtn from "../../components/product/TranslateBtn";
import SlateRichTextEditor from "../../components/editor/SlateRichTextEditor";
import UserSelector from "../../components/main/UserSelector";
import { useParams } from "react-router-dom";
import breadCrumb from "./breadCrumb";
const i18n = Locale.getInstance();
let counter = 0;
const AddOrEditRedPacket = () => {
  const user = useSelector((state) => getUserInfo(state));
  const [loading, setLoading] = useState(false);
  const [lotteryQrcode, setLotteryQrcode] = useState(null);
  const queryClient = useQueryClient();
  const [redPacketForm] = useForm();
  const history = useHistory();
  
  const { id } = useParams();
  counter += 1;

  const { data, isLoading } = useQuery(
    ["red-packet-show", user.token],
    () => getOne("red-packet", { id, token: user.token }),
    {
      enabled: !!id,
    }
  );
  const { data: qrcodes } = useQuery(
    ["lottery-qrcode-activity", user.token],
    () =>
      getList("lottery-qrcode-activity", {
        pagination: {
          perPage: 100,
          page: 1,
        },
        sort: {
          field: "id",
          order: "desc",
        },
        token: user.token,
      })
  );
  useEffect(() => {
    if (data?.activity) {
      setLotteryQrcode(data.activity);
    }
    if (data && id == data?.id) {
    
      const range= [data.start_time?moment(data.start_time):moment(), data.end_time?moment(data.end_time):moment()];
      const values = {
        ...data,
        range:[range[0], range[1]]
      };
    
      redPacketForm.setFieldsValue(values);
    }
    if (!id) {
      redPacketForm.resetFields();
    }
  }, [data]);

  const addRedPacketMutation = useMutation(create, {
    onSuccess: () => {
      queryClient.invalidateQueries("red-packet");
      showNotificationWithIcon(i18n.t("media.saveFinished"), "success");
      setLoading(false);
      history.goBack();
    },
    onError: (error) => {
      handleMutationError(error);
      setLoading(false);
    },
  });

  const updateRedPacketMutation = useMutation(update, {
    onSuccess: () => {
      queryClient.invalidateQueries("red-packet");
      showNotificationWithIcon(i18n.t("media.saveFinished"), "success");
      setLoading(false);
      history.goBack();
    },
    onError: (error) => {
      handleMutationError(error);
      setLoading(false);
    },
  });
  const getFormattedDateString = (date) => {
    if (moment.isDayjs(date)) {
      return date.format("YYYY-MM-DD HH:mm:ss");
    }
    if (typeof date === "string") {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    }
    return null;
  };
  const onFinish = (values) => {
    const {
      region_id,
    } = values;
    setLoading(true);
    values['status'] = 0;
    values["start_time"]= getFormattedDateString(values.range[0]);
    values["end_time"]= getFormattedDateString(values.range[1]);
    values["region_id"] = region_id ? region_id[region_id.length - 1] : null;
    const params = {
      data: values,
      token: user.token,
    };
    
    if (id) {
      updateRedPacketMutation.mutate({
        resource: "red-packet",
        params: { ...params, id },
      });
    } else {
      addRedPacketMutation.mutate({ resource: "red-packet", params });
    }
    
  };

  const onFinishFailed = (errorInfo) => {
    setLoading(false);
    showNotificationWithIcon(errorInfo);
  };

  
  if (isLoading) {
    return <Spin spinning={isLoading} />;
  }
  return (
    <>
      <BreadCrumb data={breadCrumb} />
      <Spin spinning={loading}>
        <Form
          form={redPacketForm}
          className="red-packet-form"
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                label={i18n.t("sales.zhTitle")}
                name="zh_name"
                wrapperCol={{ span: 10 }}
                rules={[
                  { required: true, message: i18n.t("sales.zhTitleWarning") },
                ]}
              >
                <Input
                  addonAfter={
                    <TranslateBtn
                      context={getFieldValue("zh_name")}
                      onFinish={(value) =>
                        setFieldsValue({
                          ug_name: value,
                        })
                      }
                      from="zh"
                      to="ug"
                    />
                  }
                />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                label={i18n.t("sales.ugTitle")}
                name="ug_name"
                wrapperCol={{ span: 10 }}
                rules={[
                  { required: true, message: i18n.t("sales.ugTitleWarning") },
                ]}
              >
                <Input
                  addonAfter={
                    <TranslateBtn
                      context={getFieldValue("ug_name")}
                      onFinish={(value) =>
                        setFieldsValue({
                          zh_name: value,
                        })
                      }
                      from="ug"
                      to="zh"
                    />
                  }
                />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item
            label={i18n.t("sales.activity.time")}
            name="range"
            wrapperCol={{ span: 10 }}
            rules={[
              { required: true, message: i18n.t("sales.gift.rangeWarning") },
            ]}
          >
            <DatePicker.RangePicker
              showTime
              disabledDate={(current) =>
                current && current < moment().startOf("day")
              }
            />
          </Form.Item>
          
          <Form.Item
              name="status"
              label={i18n.t("course.status")}
              initialValue={1}
            >
              <Radio.Group>
                <Radio defaultChecked={true} value={1}>
                  {i18n.t("course.statusActive")}
                </Radio>
                <Radio value={0}>{i18n.t("course.statusInActive")}</Radio>
              </Radio.Group>
            </Form.Item>
          <Form.Item
            label={i18n.t("qrcode.priority")}
            name="priority"
            wrapperCol={{ span: 5 }}
            extra={i18n.t("qrcode.priorityRule")}
          >
            <InputNumber  min={0} />
          </Form.Item>
          <Form.Item
            name="qrcode_activity_id"
            label={i18n.t("qrcode.selectQrcode")}
          >
            {lotteryQrcode ? (
              <>
                <>{i18n.getPropNameByLocale(lotteryQrcode, "name")}</>
                <Button
                  type="link"
                  onClick={() => {
                    setLotteryQrcode();
                  }}
                >
                  {i18n.t("member.reselect")}
                </Button>
              </>
            ) : (
              <>
                <Select
                  style={{ width: 120 }}
                  onChange={(v) => {
                    redPacketForm.setFieldsValue({ qrcode_activity_id: v });
                  }}
                >
                  {qrcodes?.data.map((qrcode) => {
                    return (
                      <Select.Option key={qrcode.id} value={qrcode.id}>
                        {i18n.getPropNameByLocale(qrcode, "name")}
                      </Select.Option>
                    );
                  })}
                </Select>
                <>
                  <Button
                    onClick={() => {
                      queryClient.invalidateQueries(
                        "lottery-qrcode-activity"
                      );
                    }}
                    type="link"
                  >
                    {i18n.t("common.refresh")}
                  </Button>
                </>
                <>
                  <a
                    href="/main/lottery-qrcode/activity"
                    target="_blank"
                  >{`${i18n.t("qrcode.goToQrcodes")} >>`}</a>
                </>
              </>
            )}
          </Form.Item>
          <Form.Item
            name="region_id"
            label={i18n.t("qrcode.selectRegion")}
            extra={i18n.t("qrcode.regionRule")}
            wrapperCol={{ span: 8 }}
          >
            <Cascader options={regionData} showSearch />
          </Form.Item>
          

          
          <Form.Item noStyle shouldUpdate>
            {({ setFieldsValue }) => {
              return (
                <Form.Item
                  name="zh_description"
                  label={i18n.t("course.teacher.zhDescription")}
                  initialValue={data?.zh_description}
                >
                  <SlateRichTextEditor
                    name="zh_description"
                    setFieldsValue={(value) =>
                      setFieldsValue({
                        zh_description: value,
                      })
                    }
                    initialValue={id ? data?.zh_description : null}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>

          <Form.Item noStyle shouldUpdate>
            {({ setFieldsValue }) => (
              <Form.Item
                name="ug_description"
                label={i18n.t("course.teacher.ugDescription")}
                initialValue={data?.ug_description}
              >
                <SlateRichTextEditor
                  name="ug_description"
                  setFieldsValue={(value) =>
                    setFieldsValue({
                      ug_description: value,
                    })
                  }
                  initialValue={id ? data?.ug_description : null}
                />
              </Form.Item>
            )}
          </Form.Item>

          <Form.Item className="submit">
            <Button type="primary" htmlType="submit">
              {i18n.t("productEdit.saveAndBack")}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};

export default AddOrEditRedPacket;
