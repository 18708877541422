import React from "react";
import { Form, Input, Button, Spin, Tabs, Radio, Upload } from "antd";
import Base from "../Base";
import { connect } from "react-redux";
import { getUserInfo } from "../../utils/redux/reducers/User";
import AccountInfoSettingForm from "./AccountInfoSettingForm";
import MerchantInfoForm from "./MerchantInfoForm";
import { Locale, Util } from "../../utils";
import { unset } from "lodash";

const { TabPane } = Tabs;
const i18n = Locale.getInstance();
const util = Util.getInstance();
class MerchantBasicInfo extends Base {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      loading: false,
      shopId: null,
    };
    this.merchantBasicForm = React.createRef();
    this.accountInfoForm = React.createRef();
  }

  componentDidMount() {
    super.componentDidMount();
    this.onGetData();
  }

  onBeforeUpload = (file) => {
    const isLt200KB = file.size / 1024 < 200;
    if (!isLt200KB) {
      this.showMessage(
        this.i18n.t("productEdit.uploadNavImageSizeWarning"),
        Base.Message.error
      );
    }
    return isLt200KB;
  };

  uploadImages = async ({ file, onError, onSuccess }) => {
    if (!file) onError("invalid images");

    const token = this.getUserToken();
    if (!token) onError("no user token");

    const uploadFiles = [{ file }];
    const api = this.api.common.uploadImages;
    const apiConfig = {
      token,
      uploadFiles,
      uploadType: "merchant-info",
    };

    try {
      const res = await api.run(apiConfig);
      if (res.result) {
        onSuccess(res.data[0], file);
      } else {
        onError(res.message);
      }
    } catch (error) {
      onError(error);
    }
  };

  async onGetData() {
    const api = this.api.merchant.getMerchantBasicInfo;
    try {
      const token = this.getUserToken();
      if (!token) return;

      this.setState({ loading: true });
      const apiConfig = {
        token,
      };
      const res = await api.run(apiConfig);
      if (res.result) {
        this.onSetInitialValues(res.data);
        this.setState({
          shopId: res.data.id,
        });
      }
    } catch (error) {
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;
      this.setState({ loading: false });
    }
  }

  onFinish = async (values) => {
    try {
      const token = this.getUserToken();
      if (!token) return;
      this.setState({ loading: true });
      let api = this.api.merchant.updateMerchantBasicInfo;
      const apiConfig = {
        token,
      };
      apiConfig.path = { id: this.state.shopId };
      apiConfig.data = {
        ...values,
      };
      apiConfig.data.contact_email =
        values.contact_email + "@mail.bulutbazar.com";
      const res = await api.run(apiConfig);
      if (res.result) {
        this.onGetData();
      }
      this.showMessage(
        res.message,
        res.result ? Base.Message.success : Base.Message.error
      );
    } catch (error) {
      this.handleApiError(error);
    } finally {
      if (!this.mounted) return;
      this.setState({ loading: false });
    }
  };

  getDefaultFile = (fileUrl = "") => {
    if (fileUrl) {
      let file = {
        uid: -1,
        name: `file-${new Date().getTime()}`,
        status: "done",
        url: typeof fileUrl === "boolean" ? "" : fileUrl,
      };

      return [file];
    }
    return [];
  };
  onSetInitialValues(data) {
    const {
      organization_type,
      contact_type,
      contact_email,
      ug_name,
      zh_name,
      avatar,
    } = data;

    this.merchantBasicForm.current &&
      this.merchantBasicForm.current.setFieldsValue({
        organization_type,
        contact_type,
        contact_email: contact_email?.split("@")[0],
        ug_name,
        zh_name,
        logo: this.getDefaultFile(avatar),
      });
  }

  render() {
    const { loading } = this.state;
    return (
      <Spin spinning={loading}>
        <Tabs
          defaultActiveKey="1"
          type="card"
          style={{ backgroundColor: "#fff" }}
          tabBarStyle={{ backgroundColor: "#f1f1f1" }}
          direction={this.i18n.isLocaleRTL() ? "rtl" : "ltr"}
          items={[
            {
              label: this.i18n.t("financeAccount.basicInfo"),
              key: "1",
              children: (
                <Form
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 10 }}
                  onFinish={this.onFinish}
                  ref={this.merchantBasicForm}
                >
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: this.i18n.t("sales.mini.nameWarning"),
                      },
                    ]}
                    label={this.i18n.t("agentLevel.ug")}
                    name="ug_name"
                  >
                    <Input maxLength={64} />
                  </Form.Item>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: this.i18n.t("sales.mini.nameWarning"),
                      },
                    ]}
                    label={this.i18n.t("agentLevel.zh")}
                    name="zh_name"
                  >
                    <Input maxLength={64} />
                  </Form.Item>

                  <Form.Item
                    name="organization_type"
                    label={i18n.t("financeAccount.organizationType")}
                    rules={[
                      {
                        required: true,
                        message: i18n.t("product.headerSeatchHint"),
                      },
                    ]}
                  >
                    <Radio.Group>
                      <Radio value="2401">
                        {i18n.t("financeAccount.organizationTypeMicro")}
                      </Radio>
                      <Radio value="2500">
                        {i18n.t("financeAccount.organizationTypePersonal")}
                      </Radio>
                      <Radio value="4">
                        {i18n.t("financeAccount.organizationTypeIndividual")}
                      </Radio>
                      <Radio value="2">
                        {i18n.t("financeAccount.organizationTypeEnterprise")}
                      </Radio>
                      <Radio value="3">
                        {i18n.t("financeAccount.organizationTypeGovernment")}
                      </Radio>
                      <Radio value="1708">
                        {i18n.t("financeAccount.organizationTypeOther")}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    name="contact_type"
                    label={i18n.t("financeAccount.organizationType")}
                    rules={[
                      {
                        required: true,
                        message: i18n.t("product.headerSeatchHint"),
                      },
                    ]}
                  >
                    <Radio.Group>
                      <Radio value="65">
                        {i18n.t("financeAccount.contactTypeLegal")}
                      </Radio>
                      <Radio value="66">
                        {i18n.t("financeAccount.contactTypePersonInCharge")}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: this.i18n.t("sales.mini.nameWarning"),
                      },
                    ]}
                    label={this.i18n.t("financeAccount.contact_email")}
                    name="contact_email"
                  >
                    <Input maxLength={64} addonAfter="@mail.bulutbazar.com" />
                  </Form.Item>
                  <Form.Item wrapperCol={{ offset: 4 }}>
                    <Button type="primary" htmlType="submit" size="large">
                      {i18n.t("sales.submit")}
                    </Button>
                  </Form.Item>
                </Form>
              ),
            },
            {
              key: "2",
              label: this.i18n.t("financeAccount.merchantAccountInfo"),
              forceRender: true,
              children: <AccountInfoSettingForm />,
            },
            {
              key: "3",
              forceRender: true,
              label: this.i18n.t("financeAccount.merchantInfo"),
              children: <MerchantInfoForm />,
            },
          ]}
        />
      </Spin>
    );
  }
}

export default connect((state) => ({
  user: getUserInfo(state),
}))(MerchantBasicInfo);
