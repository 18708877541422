import React, { useCallback } from "react";
import {
  message,
  Upload,
  Button,
  Radio,
  Form,
  InputNumber,
  Input,
  Divider,
  Typography,
  Switch,
} from "antd";
import { UploadOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { ApiManager, Locale, Util } from "../../utils";
import { getUserInfo } from "../../utils/redux/reducers/User";
import SlateRichTextEditor from "../../components/editor/SlateRichTextEditor";

const api = ApiManager.getInstance();
const i18n = Locale.getInstance();
const util = Util.getInstance();

const { Text, Link } = Typography;

function onBeforeUpload(file) {
  if (file) {
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
      message.error(i18n.t("productEdit.uploadImageSizeWarning"));
    }
    return isLt1M;
  }
  return false;
}

function SubSettings({ data, user, groupKey }) {
  const [form] = Form.useForm();

  const uploadImages = useCallback(
    async ({ file, onSuccess, onError, uploadType }) => {
      if (!file) onError("invalid images");

      const { token } = user;
      if (!token) onError("no user token");

      const uploadFiles = [{ file }];
      const apiConfig = {
        token,
        uploadFiles,
        uploadType,
      };

      try {
        const res = await api.common.uploadImages.run(apiConfig);
        if (res.result) {
          onSuccess(res.data[0]);
        } else {
          onError(res.message);
        }
      } catch (error) {
        onError(error);
      }
    },
    [user]
  );

  const onFinish = useCallback(
    async (values) => {
      const { token } = user;
      if (token) {
        const apiUrl = api.setting.update;
        const groupKeys = Object.keys(values).filter((k) =>
          k.includes("group-")
        );
        const fileKeys = Object.keys(values).filter((k) => k.includes("file-"));
        const priceKeys = Object.keys(values).filter((k) =>
          k.includes("price-")
        );
        const otherKeys = Object.keys(values).filter(
          (k) =>
            !k.includes("file-") &&
            !k.includes("group-") &&
            !k.includes("price-")
        );
        const apiConfig = {
          token,
          data: {
            group: groupKey,
            data: {},
          },
        };
        if (groupKeys.length) {
          let groupName;
          const groupData = {};
          groupKeys.forEach((k) => {
            const [group, gName, subName] = k.split("-");
            if (!groupData[gName]) {
              groupData[gName] = {};
            }
            groupData[gName][subName] = values[k];
          });
          Object.keys(groupData).forEach((groupName) => {
            apiConfig.data.data[groupName] = { ...groupData[groupName] };
          });
        }
        if (fileKeys.length) {
          fileKeys.forEach((k) => {
            const [fileMark, fileKey] = k.split("-");
            if (
              values[k] &&
              values[k][0] &&
              values[k][0].uid !== -1 &&
              values[k][0].response
            ) {
              apiConfig.data.data[fileKey] = values[k][0].response.path;
            }
          });
        }
        if (priceKeys.length) {
          priceKeys.forEach((k) => {
            const [priceMark, priceKey] = k.split("-");
            apiConfig.data.data[priceKey] = values[k] * 100;
          });
        }
        if (otherKeys.length) {
          otherKeys.forEach((k) => {
            apiConfig.data.data[k] = values[k];
          });
        }
        try {
          console.log(
            "🚀 ~ file: SubSettings.jsx ~ line 133 ~ apiConfig",
            apiConfig
          );
          const res = await apiUrl.run(apiConfig);
          if (res.result) {
            message.success(res.message);
          } else {
            message.warning(res.message);
          }
        } catch (error) {
          message.error(error.message);
        }
      }
    },
    [user, groupKey]
  );

  const keys = Object.keys(data);
  if (keys.length > 0) {
    return (
      <Form
        form={form}
        wrapperCol={{ span: 12 }}
        labelCol={{ span: 8 }}
        onFinish={onFinish}
        style={{ marginTop: 20 }}
      >
        {keys.map((key) => {
          if (data[key].type.data_type === "file") {
            return (
              <Form.Item shouldUpdate noStyle key={`${key}`}>
                {({ getFieldValue }) => {
                  return (
                    <Form.Item
                      label={i18n.getPropNameByLocale(data[key], "name")}
                      name={`file-${key}`}
                      valuePropName="fileList"
                      getValueFromEvent={(e) => util.normFile4Img(e)}
                      initialValue={util.getDefaultImages(data[key].value)}
                      extra={
                        <Text>
                          {i18n.getPropNameByLocale(data[key], "comment")}
                          {data[key].default && (
                            <Link href={data[key].default} target="blank">
                              {i18n.t("common.example")}
                              <QuestionCircleOutlined />
                            </Link>
                          )}
                        </Text>
                      }
                    >
                      <Upload
                        accept="image/*"
                        multiple={false}
                        listType="picture-card"
                        beforeUpload={onBeforeUpload}
                        customRequest={(options) =>
                          uploadImages({ ...options, uploadType: `${key}` })
                        }
                      >
                        {getFieldValue(`file-${key}`) &&
                          !getFieldValue(`file-${key}`).length && (
                            <Button>
                              <UploadOutlined /> {i18n.t("sales.upload")}
                            </Button>
                          )}
                      </Upload>
                    </Form.Item>
                  );
                }}
              </Form.Item>
            );
          }

          if (data[key].type.data_type === "price") {
            return (
              <Form.Item
                key={`${key}`}
                label={i18n.getPropNameByLocale(data[key], "name")}
                name={`price-${key}`}
                initialValue={
                  data[key].value
                    ? data[key].value / 100
                    : data[key].default / 100
                }
                extra={i18n.getPropNameByLocale(data[key], "comment")}
              >
                <InputNumber min={0} formatter={(value) => `¥ ${value}`} />
              </Form.Item>
            );
          }

          if (data[key].type.data_type === "int") {
            return (
              <Form.Item
                key={`${key}`}
                label={i18n.getPropNameByLocale(data[key], "name")}
                name={`${key}`}
                initialValue={data[key].value || data[key].default}
                extra={i18n.getPropNameByLocale(data[key], "comment")}
              >
                <InputNumber min={0} />
              </Form.Item>
            );
          }

          if (data[key].type.data_type === "string") {
            return (
              <Form.Item
                key={`${key}`}
                label={i18n.getPropNameByLocale(data[key], "name")}
                name={`${key}`}
                initialValue={data[key].value || data[key].default}
                extra={i18n.getPropNameByLocale(data[key], "comment")}
              >
                <Input />
              </Form.Item>
            );
          }

          if (data[key].type.data_type === "boolean") {
            return (
              <Form.Item
                key={`${key}`}
                label={i18n.getPropNameByLocale(data[key], "name")}
                name={`${key}`}
                initialValue={Number(data[key].value) === 1}
                valuePropName="checked"
                extra={i18n.getPropNameByLocale(data[key], "comment")}
              >
                <Switch />
              </Form.Item>
            );
          }

          if (data[key].type.data_type === "richtext") {
            return (
              <Form.Item noStyle shouldUpdate key={`${key}`}>
                {({ getFieldValue, setFieldsValue }) => (
                  <Form.Item
                    label={i18n.getPropNameByLocale(data[key], "name")}
                    name={`${key}`}
                    initialValue={data[key].value}
                    extra={i18n.getPropNameByLocale(data[key], "comment")}
                  >
                    <SlateRichTextEditor
                      setFieldsValue={(value) => {
                        let formData = {};
                        formData[key] = value;
                        setFieldsValue(formData);
                        return;
                      }}
                      initialValue={data[key]?.value ? data[key].value : null}
                    />
                  </Form.Item>
                )}
              </Form.Item>
            );
          }

          if (data[key].type.data_type === "radio") {
            return (
              <Form.Item
                key={`${key}`}
                label={i18n.getPropNameByLocale(data[key], "name")}
                name={`${key}`}
                initialValue={Number(data[key].value)}
                extra={i18n.getPropNameByLocale(data[key], "comment")}
              >
                <Radio.Group buttonStyle="solid">
                  {data[key].type.values.map((value, index) => {
                    return (
                      <Radio.Button value={Number(index)} key={index}>
                        {i18n.getPropNameByLocale(value, "name")}
                      </Radio.Button>
                    );
                  })}
                </Radio.Group>
              </Form.Item>
            );
          }

          if (data[key].type.data_type === "object") {
            const formKeys = data[key].type.keys;
            return (
              <div key={`${key}`}>
                <Divider
                  orientation={i18n.isLocaleRTL() ? "right" : "left"}
                  plain
                >
                  {i18n.getPropNameByLocale(data[key], "name")}
                </Divider>
                {Object.keys(formKeys).map((k) => {
                  if (formKeys[k].type === "int") {
                    return (
                      <Form.Item
                        key={`${k}`}
                        label={i18n.getPropNameByLocale(formKeys[k], "name")}
                        name={`group-${key}-${k}`}
                        initialValue={formKeys[k].value || formKeys[k].default}
                        extra={i18n.getPropNameByLocale(formKeys[k], "comment")}
                      >
                        <InputNumber min={0} />
                      </Form.Item>
                    );
                  }
                  if (formKeys[k].type === "radio") {
                    return (
                      <Form.Item
                        key={`${k}`}
                        label={i18n.getPropNameByLocale(formKeys[k], "name")}
                        name={`group-${key}-${k}`}
                        initialValue={Number(formKeys[k].value)}
                        extra={i18n.getPropNameByLocale(formKeys[k], "comment")}
                      >
                        <Radio.Group buttonStyle="solid">
                          {formKeys[k].values.map((value, index) => {
                            return (
                              <Radio.Button value={index} key={index}>
                                {i18n.getPropNameByLocale(value, "name")}
                              </Radio.Button>
                            );
                          })}
                        </Radio.Group>
                      </Form.Item>
                    );
                  }
                  if (formKeys[k].type === "boolean") {
                    return (
                      <Form.Item
                        key={`${k}`}
                        label={i18n.getPropNameByLocale(formKeys[k], "name")}
                        name={`group-${key}-${k}`}
                        initialValue={Number(formKeys[k].value) === 1}
                        valuePropName="checked"
                        extra={i18n.getPropNameByLocale(formKeys[k], "comment")}
                      >
                        <Switch />
                      </Form.Item>
                    );
                  }
                  if (formKeys[k].type === "string") {
                    return (
                      <Form.Item
                        key={`${k}`}
                        label={i18n.getPropNameByLocale(formKeys[k], "name")}
                        name={`group-${key}-${k}`}
                        initialValue={formKeys[k].value}
                        extra={i18n.getPropNameByLocale(formKeys[k], "comment")}
                      >
                        <Input />
                      </Form.Item>
                    );
                  }
                  if (formKeys[k].type === "textarea") {
                    return (
                      <Form.Item
                        key={`${k}`}
                        label={i18n.getPropNameByLocale(formKeys[k], "name")}
                        name={`group-${key}-${k}`}
                        initialValue={formKeys[k].value}
                        extra={i18n.getPropNameByLocale(formKeys[k], "comment")}
                      >
                        <Input.TextArea
                          rows={10}
                          dir={
                            typeof k === "string" && k.includes("ug")
                              ? "rtl"
                              : "ltr"
                          }
                        />
                      </Form.Item>
                    );
                  }
                  return null;
                })}
              </div>
            );
          }
          return null;
        })}
        <Form.Item wrapperCol={{ offset: 3 }}>
          <Button type="primary" htmlType="submit">
            {i18n.t("sales.submit")}
          </Button>
        </Form.Item>
      </Form>
    );
  }

  return null;
}

export default connect((state) => ({ user: getUserInfo(state) }))(SubSettings);
