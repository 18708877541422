// Import React dependencies.
import React, { useEffect, useState } from "react";
// Import the Slate editor factory.
import { createEditor } from "slate";

// Import the Slate components and React plugin.
import { Slate, Editable, withReact } from "slate-react";
import SlateRichTextEditor from "./SlateRichTextEditor";

// Add the initial value.
const initialValue = [
  {
    type: "paragraph",
    children: [{ text: "A line of text in a paragraph." }],
  },
];

const EditorDemo = () => {
  const [editor] = useState(() => withReact(createEditor()));
  useEffect(() => {}, [editor]);
  return <SlateRichTextEditor initialValue="" />;
};

export default EditorDemo;
