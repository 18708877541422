import React, { useState, useEffect, useCallback } from "react";
import { Modal, Input, List, message, Button, Image } from "antd";
import { connect } from "react-redux";
import { getUserInfo } from "../../utils/redux/reducers/User";
import { Locale, ApiManager } from "../../utils";

const { Search } = Input;
const i18n = Locale.getInstance();
const api = ApiManager.getInstance();

function DeliverymanSelectModal({
  visible,
  onCancel,
  user,
  selectedDeliverymanId,
  orderId,
  onConfirm,
}) {
  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ data: [], current_page: 1 });

  const fetchData = useCallback(async () => {
    const token = user.token;
    let customApi = api.deliveryman.list;
    const query = {
      page: data.current_page,
      keyword,
      count: 10,
    };
    if (token) {
      const apiConfig = {
        token,
        query,
      };
      try {
        setLoading(true);
        const res = await customApi.run(apiConfig);
        setLoading(false);
        if (res.result) {
          setData(res.data);
        } else {
          message.error(res.message);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  }, [data.current_page, keyword, user]);

  useEffect(() => {
    fetchData();
  }, [data.current_page, keyword, fetchData]);

  const checkIfSelected = (itemId) => {
    return selectedDeliverymanId === itemId;
  };

  return (
    <Modal
      width={600}
      title={
        <Search
          allowClear
          style={{ width: 400 }}
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          onSearch={() => {
            if (data.current_page > 1) {
              setData({ ...data, current_page: 1 });
            } else {
              fetchData();
            }
          }}
        />
      }
      open={visible}
      onCancel={onCancel}
      footer={null}
    >
      <List
        itemLayout="horizontal"
        dataSource={data.data}
        loading={loading}
        pagination={{
          current: data.current_page,
          total: data.total,
          showSizeChanger: false,
          showQuickJumper: true,
          onChange: (page) => setData({ ...data, current_page: page }),
        }}
        renderItem={(item) => {
          return (
            <List.Item
              key={item.id}
              actions={[
                <Button
                  type={checkIfSelected(item.id) ? "default" : "primary"}
                  onClick={() => {
                    onConfirm({ deliveryman_id: item.id, orderId });
                  }}
                >
                  {checkIfSelected(item.id)
                    ? i18n.t("sales.coupons.selected")
                    : i18n.t("sales.coupons.select")}
                </Button>,
              ]}
            >
              <div style={{ width: "100%" }}>
                <List.Item.Meta
                  avatar={<Image alt="" src={item.avatar} width={80} />}
                  title={i18n.getPropNameByLocale(item, "name")}
                  description={item.mobile}
                />
              </div>
            </List.Item>
          );
        }}
      />
    </Modal>
  );
}

export default connect((state) => ({ user: getUserInfo(state) }))(
  DeliverymanSelectModal
);
