import { Form, Input, Table } from "antd";
import { Locale } from "../../utils";
import { useState } from "react";
const i18n = Locale.getInstance();
const columns = (groups) => {
    let columns = [];
    columns = groups?.map((group, key) => {
        return {
            width: 100,
            title: i18n.getPropNameByLocale(group, "name"),
            render: (item) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span>{i18n.getPropNameByLocale(item, `name-${key}`)}</span>
                        {key == 0 && item.image && (<img width={38} src={item.image} />)}
                    </div>

                )
            },

        }
    })
    if (!columns) return [];
    columns = columns.concat([
        {
            title: '价格',
            dataIndex: 'price',
            key: 'name',
            render: (_, item) => {
                return <>
                    <Form.Item
                        style={{ margin: 0, display: 'none' }}
                        name={['property_id', item.code.toString()]}
                        hidden={true}
                        initialValue={item.code}
                    >
                        <Input hidden width={150} />
                    </Form.Item>
                    <Form.Item
                        style={{ margin: 0 }}
                        name={['property_price', item.code.toString()]}
                        initialValue={item?.price}
                        rules={[
                            {
                                required: true,
                                message: i18n.t('property.priceWarning'),
                            },
                        ]}
                    >
                        <Input width={150} />
                    </Form.Item>
                </>
            }
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            key: 'quantity',
            render: (_, item) => {
                return <Form.Item
                    style={{ margin: 0 }}
                    name={['property_quantity', item.code.toString()]}
                    initialValue={item?.quantity}
                    rules={[
                        {
                            required: true,
                            message: i18n.t('property.quantityWarning'),
                        },
                    ]}
                >
                    <Input width={150} />
                </Form.Item>
            }
        },
        {
            title: '商品编码',
            dataIndex: 'product_no',
            key: 'product_no',
            render: (_, item) => {
                return <Form.Item
                    style={{ margin: 0 }}
                    name={['property_product_no', item.code.toString()]}
                    initialValue={item?.productNo}

                >
                    <Input width={150} />
                </Form.Item>
            }
        },
    ]);
    return columns;
}

const PropertyTable = ({ groups, skuList }) => {
    
    if (groups?.length < 1) return null;
    let dataSource = [];

    if (groups?.length == 1 && groups[0].childList) {
                dataSource = groups[0].childList.map(property => {
                        let price = '';
            let quantity = '';
            let productNo = '';
            skuList.map(sku => {
                if (sku.code == property.id) {
                    price = sku.price;
                    quantity = sku.quantity;
                    productNo = sku.productNo;
                }
            });
            return {
                'zh_name-0': property.zh_name,
                'ug_name-0': property.ug_name,
                'id-0': property.id,
                'code': property.id,
                'price': price,
                'quantity': quantity,
                'productNo': productNo,
            }
        })
    } else if (groups?.length == 2) {
        groups[0]?.childList?.map(property1 => {
            
            if (groups[1]?.childList?.length > 0) {
                groups[1]?.childList?.map(property2 => {
                    let price = '';
                    let quantity = '';
                    let productNo = '';
                    skuList.map(sku => {
                        if (sku.code === property1.id + '-' + property2.id) {
                            price = sku.price;
                            quantity = sku.quantity;
                            productNo = sku.productNo;
                        }
                    });
                    dataSource.push({
                        'zh_name-0': property1.zh_name,
                        'ug_name-0': property1.ug_name,
                        'id-0': property1.id,
                        'zh_name-1': property2.zh_name,
                        'ug_name-1': property2.ug_name,
                        'id-1': property2.id,
                        'code': property1.id + '-' + property2.id,
                        'price': price,
                        'quantity': quantity,
                        'productNo': productNo,
                    })
                });
            } else {
                let price = '';
                    let quantity = '';
                    let productNo = '';
                skuList.map(sku => {
                    if (sku.code == property1.id) {
                        price = sku.price;
                        quantity = sku.quantity;
                        productNo = sku.productNo;
                    }
                });
                dataSource.push({
                    'zh_name-0': property1.zh_name,
                    'ug_name-0': property1.ug_name,
                    'id-0': property1.id,
                    'code': property1.id,
                    'price': price,
                    'quantity': quantity,
                    'productNo': productNo,
                })
                
            }
        })
    }
    
    
    return (<Table
        style={{
            margin: '20px'
        }}
        virtual
        columns={columns(groups)}
        scroll={{ x: 1000, y: 400 }}
        rowKey="id"
        dataSource={dataSource ?? []}
        pagination={false}
        bordered

    />)
}

export default PropertyTable;