import { DeleteOutlined, DownOutlined, EditOutlined } from "@ant-design/icons";
import { Badge, Button, Popconfirm, Dropdown, Menu, Space } from "antd";
import { Locale } from "../../../utils";
import { typeLabels } from "./courseTypes";
const i18n = Locale.getInstance();
export const courseListColumns = (
  deleteMutation,
  token,
  setLoading,
  router,
  type
) => {
  const actionMenu = (id, type) => [
    {
      key: "1",
      label: (
        <Button
          size="small"
          type="link"
          onClick={() => {
            router.push({
              pathname: `/main/courses/${typeLabels[type]}-courses/students`,
              query: {
                id,
                type,
              },
            });
          }}
        >
          {i18n.t("course.studyStatus")}
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button size="small" type="link">
          {i18n.t("course.comment.title")}
        </Button>
      ),
    },
  ];

  return [
    {
      title: "ID",
      dataIndex: "id",
      width: 60,
    },
    {
      title: i18n.t("course.courseInfo"),
      render: (item) => {
        return (
          <div className="info">
            <div className="cover">
              <img
                src={`${i18n.getPropNameByLocale(item, "cover")}`}
                alt={i18n.getPropNameByLocale(item, "name")}
                width={130}
                height={88}
              />
            </div>
            <div className="name">
              <span>{i18n.getPropNameByLocale(item, "name")}</span>
              <span>
                {i18n.t("course.teacherLabel")}{" "}
                {i18n.getPropNameByLocale(item.teacher, "name")}
              </span>
            </div>
          </div>
        );
      },
    },

    {
      title: i18n.t("course.categoryName"),
      render: (item) => {
        return <span>{i18n.getPropNameByLocale(item.category, "name")}</span>;
      },
      width: 120,
    },
    {
      title: i18n.t("course.actualSold"),
      dataIndex: "actual_sold",
      width: 100,
    },
    {
      title: i18n.t("common.statusLabel"),
      render: (item) => {
        return (
          <Badge
            status={item.status ? "success" : "default"}
            text={item.status_label}
          />
        );
      },
      width: 100,
    },
    {
      title: i18n.t("shows.sortTypesTitle"),
      dataIndex: "order",
      width: 100,
    },
    {
      title: i18n.t("users.date"),
      dataIndex: "created_at",
      width: 150,
    },
    {
      title: i18n.t("users.actions"),
      width: 200,
      render: (item) => {
        return (
          <>
            {type !== 0 ? (
              <Button
                onClick={() => {
                  router.push({
                    pathname: `/main/courses/${typeLabels[type]}-courses/lessons`,
                    query: {
                      id: item.id,
                      type,
                    },
                  });
                }}
                type="link"
                size="small"
              >
                {i18n.t("course.lesson.title")}
              </Button>
            ) : null}
            <Popconfirm
              title={i18n.t("sales.deleteWarning")}
              onConfirm={() => {
                setLoading(true);
                deleteMutation.mutate({
                  resource: "courses",
                  params: {
                    id: item.id,
                    token,
                  },
                });
              }}
              okText={i18n.t("base.modal.okText")}
              cancelText={i18n.t("base.modal.cancelText")}
            >
              <Button danger type="link" size="small">
                {i18n.t("common.delete")}
              </Button>
            </Popconfirm>
            <Button
              type="link"
              onClick={() => {
                router.push({
                  pathname: `/main/courses/${typeLabels[type]}-courses/edit`,
                  state: {
                    id: item.id,
                    type,
                  },
                });
              }}
              size="small"
            >
              {i18n.t("product.productItem.actionEdit")}
            </Button>
            <Dropdown menu={{ items: actionMenu(item.id, type) }}>
              <Button icon={<DownOutlined />} type="link">
                {i18n.t("common.more")}
              </Button>
            </Dropdown>
          </>
        );
      },
    },
  ];
};
