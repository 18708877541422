import { Editable, Slate, withReact } from "slate-react";
import { Node, createEditor, Transforms } from "slate";
import React, { useCallback, useMemo, useState } from "react";
import { withHistory } from "slate-history";
class SlateEditorWithErrorBoundry extends React.Component {
  static getDerivedStateFromError(error) {
    return {};
  }
  componentDidCatch(error, info) {
    console.log(
      "🚀 ~ file: SlateEditorWithErrorBoundry.jsx:10 ~ SlateEditorWithErrorBoundry ~ componentDidCatch ~ error:",
      error,
      info
    );
    // if selection issue then
    Transforms.deselect(this.props.editor);
  }
  render() {
    return this.props.children;
  }
}

export default SlateEditorWithErrorBoundry;
