import { Modal, Spin, Table } from "antd";
import React, { useRef, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { Locale } from "../../../utils";
import {
  handleMutationError,
  showNotificationWithIcon,
} from "../../../utils/helpers/helpers";
import { getList, remove, create } from "../../../utils/http/dataProvider";
import { getUserInfo } from "../../../utils/redux/reducers/User";
import { BreadCrumb } from "../../../components/main/LayoutComponents";

import "./StudentList.scss";
import { studentListColumns } from "./studentListColumns";
import StudentListHeader from "./StudentListHeader";
import SimpleCourseInfo from "../courses/SimpleCourseInfo";
import AddStudent from "./AddStudent";
import { unset } from "lodash";
import { TYPE_AUDIO, TYPE_VIDEO } from "../courses/courseTypes";
import { useHistory, useLocation } from "react-router-dom";
const i18n = Locale.getInstance();

const StudentList = () => {
  const user = useSelector((state) => getUserInfo(state));
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const router = useHistory();
  const location = useLocation();
  const [openAddStudentModal, setOpenAddStudentModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const studentFormRef = useRef();

  if (!location.query.id) {
    router.goBack();
  }
  const courseId = location.query.id;

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleSave = () => {
    const values = studentFormRef.current.getFieldsValue();
    const data = {
      course_id: courseId,
      user_id: values.user_id,
    };
    if (values.valid_time) {
      data["expired_at"] = values.expired_at;
    }

    setLoading(true);
    const params = {
      data,
      token: user.token,
    };
    addStudentMutation.mutate({ resource: "course/students", params });
  };

  const addStudentMutation = useMutation(create, {
    onSuccess: () => {
      queryClient.invalidateQueries("course-students");
      showNotificationWithIcon(i18n.t("media.saveFinished"), "success");
      setLoading(false);
      setOpenAddStudentModal(false);
    },
    onError: (error) => {
      handleMutationError(error);
      if (error.status !== 422) {
        setOpenAddStudentModal(false);
      }
      setLoading(false);
    },
  });

  const { data, error, isLoading } = useQuery(
    ["course-students", user.token],
    () =>
      getList("course/students", {
        pagination: {
          perPage: 10,
          page,
        },
        sort: {
          field: "id",
          order: "desc",
        },
        filter: {
          course_id: courseId,
        },

        token: user.token,
      })
  );
  if (error) {
    showNotificationWithIcon(error.data.message);
  }
  const breadCrumbData = [
    {
      href:
        data?.data && data.data[0]?.course?.type === TYPE_AUDIO
          ? "/main/courses/audio-courses"
          : data?.data[0]?.course?.type === TYPE_VIDEO
          ? "/main/courses/video-courses"
          : "/main/courses/article-courses",
      title: i18n.t("course.title"),
    },
    {
      href: "/main/courses/students",
      title: i18n.t("course.student.title"),
    },
  ];

  const deleteMutation = useMutation(remove, {
    onSuccess: () => {
      queryClient.invalidateQueries("course-students");
      setLoading(false);
    },
    onError: (error) => {
      setLoading(false);
      handleMutationError(error);
    },
  });

  return (
    <>
      <BreadCrumb data={breadCrumbData} />
      <div className="student">
        <Spin spinning={loading || isLoading}>
          <SimpleCourseInfo courseId={courseId} />
          <StudentListHeader setOpenAddStudentModal={setOpenAddStudentModal} />
          <Table
            className="list"
            rowKey="id"
            columns={studentListColumns(deleteMutation, user.token, setLoading)}
            dataSource={data?.data}
            scroll={{
              y: 320,
            }}
            pagination={{ onChange: handleChangePage }}
          />
        </Spin>
      </div>
      <Modal
        title={i18n.t("course.student.add")}
        open={openAddStudentModal}
        onOk={handleSave}
        onCancel={() => {
          setOpenAddStudentModal(false);
          studentFormRef?.current?.resetFields();
        }}
        okText={i18n.t("base.modal.okText")}
        cancelText={i18n.t("base.modal.cancelText")}
        width={880}
      >
        <AddStudent ref={studentFormRef} loading={loading} />
      </Modal>
    </>
  );
};

export default StudentList;
