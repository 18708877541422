import { useHistory } from "react-router-dom";
import { Button, DatePicker, Radio } from "antd";
import { Locale } from "../../../utils";
import { useState } from "react";
import { CaretDownOutlined } from "@ant-design/icons";

const i18n = Locale.getInstance();
const OrderTypes = [
  { key: "all", name: "order.orderTypes.all", value: -1 },
  { key: "applied", name: "order.orderTypes.applied", value: 0 },
  { key: "unsend", name: "order.orderTypes.unsend", value: 1 },
  { key: "sent", name: "order.orderTypes.sent", value: 2 },
  { key: "delivered", name: "order.orderTypes.delivered", value: 3 },
  { key: "completed", name: "order.orderTypes.completed", value: 4 },
];

const OrderSorts = [
  { key: "created_at@desc", name: "order.sortTypes.created_at_desc" },
  { key: "created_at@asc", name: "order.sortTypes.created_at_asc" },
  { key: "sequence", name: "order.sortTypes.sequence" },
];

const RedeemOrderListHeader = ({ filter, setFilter }) => {
  const history = useHistory();
  return (
    <div className="filter-area">
      <div className="filter-row">
        <span className="label">{i18n.t("common.statusLabel")}</span>
        <Radio.Group
          value={filter?.state}
          onChange={(e) => {
            setFilter({ ...filter, state: e.target.value });
          }}
        >
          {OrderTypes.map((type, index) => {
            return (
              <Radio key={`type-${index}`} value={type.value}>
                {i18n.t(type.name)}
              </Radio>
            );
          })}
        </Radio.Group>
      </div>

      <div className="filter-row">
        <span className="label">{i18n.t("order.sortTypesTitle")}</span>
        <Radio.Group
          value={filter?.sort}
          onChange={(e) => {
            setFilter({ ...filter, sort: e.target.value });
          }}
        >
          {OrderSorts.map((type, index) => {
            return (
              <Radio key={`type-${index}`} value={type.key}>
                {i18n.t(type.name)}
              </Radio>
            );
          })}
        </Radio.Group>
      </div>
      <div className="filter-row">
        <span className="label">{i18n.t("order.rangeTimeTitle")}</span>
        <DatePicker.RangePicker
          defaultValue={filter?.dates}
          format="YYYY-MM-DD"
          allowClear={false}
          onChange={(dates) => {
            setFilter({ ...filter, dates });
          }}
          suffixIcon={<CaretDownOutlined />}
        />
      </div>
    </div>
  );
};
export default RedeemOrderListHeader;
