import { Modal, Spin, Table } from "antd";
import React, { useRef, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { Locale } from "../../../utils";
import {
  handleMutationError,
  showNotificationWithIcon,
} from "../../../utils/helpers/helpers";
import {
  getList,
  remove,
  create,
  update,
} from "../../../utils/http/dataProvider";
import { getUserInfo } from "../../../utils/redux/reducers/User";
import { BreadCrumb } from "../../../components/main/LayoutComponents";

import "./LessonList.scss";
import { lessonListColumns } from "./lessonListColumns";
import LessonListHeader from "./LessonListHeader";
import AddOrEditLesson from "./AddOrEditLesson";
import { TYPE_ARTICLE, TYPE_AUDIO } from "../courses/courseTypes";
import SimpleCourseInfo from "../courses/SimpleCourseInfo";
import { useHistory, useLocation } from "react-router-dom";
const i18n = Locale.getInstance();

const LessonList = () => {
  const user = useSelector((state) => getUserInfo(state));
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const [lesson, setLesson] = useState();

  const [openEditModal, setOpenEditModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const router = useHistory();
  const location = useLocation();
  const lessonFormRef = useRef();

  if (!location.query.id) {
    router.goBack();
  }
  const courseId = location.query.id;
  const type = location.query?.type;
  if (type === TYPE_ARTICLE) {
    router.goBack();
  }

  const selectLesson = (item) => {
    setLesson(item);
    const values = {
      zh_name: item?.zh_name,
      ug_name: item?.ug_name,
      media_id: item?.media ? [item?.media] : [],
      order: item?.order,
      trial: !!item?.trial,
      course_id: courseId,
      trial_duration: item?.trial_duration,
    };

    setTimeout(() => {
      lessonFormRef.current?.setFieldsValue(values);
    }, 100);
    setOpenEditModal(true);
  };
  const handleChangePage = (newPage) => {
    console.log(
      "🚀 ~ file: LessonList.jsx:70 ~ handleChangePage ~ newPage:",
      newPage
    );
    setPage(newPage);
  };

  const handleSave = () => {
    const values = lessonFormRef.current.getFieldsValue();
    if (values.media_id && values.media_id.length > 0) {
      values["media_id"] = values["media_id"][0]["id"];
    }
    values["course_id"] = courseId;
    setLoading(true);
    const params = {
      data: values,
      token: user.token,
    };

    if (lesson) {
      updateLessonMutation.mutate({
        resource: "course/lessons",
        params: { ...params, id: lesson.id },
      });
    } else {
      addLessonMutation.mutate({ resource: "course/lessons", params });
    }
  };

  const addLessonMutation = useMutation(create, {
    onSuccess: () => {
      queryClient.invalidateQueries("course-lessons");
      showNotificationWithIcon(i18n.t("media.saveFinished"), "success");
      setLoading(false);
      setOpenEditModal(false);
    },
    onError: (error) => {
      handleMutationError(error);
      if (error.status !== 422) {
        setOpenEditModal(false);
      }
      setLoading(false);
    },
  });

  const updateLessonMutation = useMutation(update, {
    onSuccess: () => {
      queryClient.invalidateQueries("course-lessons");
      showNotificationWithIcon(i18n.t("media.saveFinished"), "success");
      setOpenEditModal(false);
      setLoading(false);
    },
    onError: (error) => {
      handleMutationError(error);
      if (error.status !== 422) {
        setOpenEditModal(false);
      }
      setLoading(false);
    },
  });

  const { data, error, isLoading } = useQuery(
    ["course-lessons", [user.token, page, courseId]],
    () =>
      getList("course/lessons", {
        pagination: {
          perPage: 10,
          page,
        },
        sort: {
          field: "id",
          order: "desc",
        },
        filter: {
          course_id: courseId,
        },

        token: user.token,
      })
  );
  console.log("🚀 ~ file: LessonList.jsx:128 ~ LessonList ~ data:", data);

  if (error) {
    showNotificationWithIcon(error.data.message);
  }

  const breadCrumbData = [
    {
      href:
        data?.data && data.data[0]?.course?.type === TYPE_AUDIO
          ? "/main/courses/audio-courses"
          : "/main/courses/video-courses",
      title: i18n.t("course.title"),
    },
    {
      href: "/main/courses/lessons",
      title: i18n.t("course.lesson.title"),
    },
  ];

  const deleteMutation = useMutation(remove, {
    onSuccess: () => {
      queryClient.invalidateQueries("course-lessons");
      setLoading(false);
    },
    onError: (error) => {
      setLoading(false);
      handleMutationError(error);
    },
  });
  return (
    <>
      <BreadCrumb data={breadCrumbData} />
      <div className="lesson">
        <Spin spinning={loading || isLoading}>
          <SimpleCourseInfo courseId={courseId} />
          <LessonListHeader selectLesson={selectLesson} />
          <Table
            className="list"
            rowKey="id"
            columns={lessonListColumns(
              deleteMutation,
              user.token,
              setLoading,
              selectLesson
            )}
            dataSource={data?.data}
            scroll={{
              y: 320,
            }}
            onChange={(pagination) => {
              setPage(pagination.current);
            }}
            pagination={{
              total: data?.total,
              pageSize: 10,
            }}
          />
        </Spin>
      </div>
      <Modal
        title={
          lesson ? i18n.t("course.lesson.edit") : i18n.t("course.lesson.add")
        }
        open={openEditModal}
        onOk={handleSave}
        onCancel={() => {
          setOpenEditModal(false);
          lessonFormRef.current?.resetFields();
        }}
        okText={i18n.t("base.modal.okText")}
        cancelText={i18n.t("base.modal.cancelText")}
        width={880}
      >
        <AddOrEditLesson
          ref={lessonFormRef}
          loading={loading}
          lesson={lesson}
          type={type}
        />
      </Modal>
    </>
  );
};

export default LessonList;
