import { useState } from "react";
import { Locale } from "../../../utils";
import { useHistory } from "react-router-dom";

const i18n = Locale.getInstance();

const RedPacketListHeader = ({redPacketId}) => {
  const [q, setQ] = useState();
  const history = useHistory();
  return (
    <div className="header">
      <div className="actions">
        
      </div>
    </div>
  );
};
export default RedPacketListHeader;
