import React from "react";
import { LeftOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Layout,
  Divider,
  Input,
  Upload,
  Row,
  Col,
  Radio,
  Button,
  Spin,
  InputNumber,
  Form,
  Tabs,
  TreeSelect,
  Statistic,
  Space,
  Switch,
  DatePicker,
  Select,
} from "antd";
import BraftEditor, { EditorState } from "braft-editor";
import "braft-editor/dist/index.css";
import Base from "../Base";

//redux
import { connect } from "react-redux";
import { addMedia, removeMedia } from "../../utils/redux/actions/Medias";
import { getMedias } from "../../utils/redux/reducers/Medias";
import { getUserInfo } from "../../utils/redux/reducers/User";
import ProductPropertyForm from "./ProductPropertyForm";
import TranslateBtn from "../../components/product/TranslateBtn";
import { getMerchantSettings } from "../../utils/redux/reducers/Merchant";
import { Option } from "antd/lib/mentions";
import VideoUpload from "../../components/media/VideoUpload";
import { getUploadedVideoPaths } from "../../utils/helpers/mediaHelpers";
const { TabPane } = Tabs;

class ProductEdit extends Base {
  static Status = [
    { value: 0, name: "productEdit.status.offShelf" },
    { value: 1, name: "productEdit.status.onShelf" },
  ];

  static Suggestion = [
    { value: 0, name: "productEdit.suggest.off" },
    { value: 1, name: "productEdit.suggest.on" },
  ];

  productForm = React.createRef();

  /* lifecycle methods */

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      editProduct: this.getEditProduct(),
      uploadingImages: false,
      updatingProduct: false,
      fetchingImg: null,
      activeKey: "1",
      video: [],
      videoDeleted: false,
    };
  }

  /* render methods */

  render() {
    return (
      <Layout id="route-product-edit">
        {this._renderHeader()}
        {this._renderContent()}
        {this._renderFooter()}
        {this._renderModals()}
      </Layout>
    );
  }

  _renderHeader() {
    let title = this.i18n.t("productEdit.headerTitle");
    if (this.state.editProduct) {
      title = this.i18n.t("productEdit.headerTitleEdit");
    }

    return (
      <Layout.Header className="edit-header">
        <Button
          style={{
            boxShadow: "none",
            borderWidth: 0,
            backgroundColor: "transparent",
          }}
          onClick={this.onHeaderBackClick}
        >
          <LeftOutlined />
          {this.i18n.t("productEdit.headerBack")}
        </Button>
        <Divider type="vertical" />
        <span className="title">{title}</span>
      </Layout.Header>
    );
  }

  _renderContent() {
    const { activeKey } = this.state;

    const items = [
      {
        key: "1",
        label: (
          <div className="product-tab required">
            {this.i18n.t("productEdit.basicInfo")}
          </div>
        ),
        children: (
          <>
            {this._renderContentFormCategory()}
            {this._renderContentFormName()}
            {this._renderContentFormNameUg()}
            {this._renderContentJDImages()}
            {this._renderContentFormImage()}
            {this._renderContentFormVideo()}
            {this._renderContentFormOrder()}
            {this._renderContentFormStatus()}
          </>
        ),
      },
      {
        key: "2",
        forceRender: true,
        label: (
          <div className="product-tab required">
            {this.i18n.t("productEdit.priceInfo")}
          </div>
        ),
        children: (
          <>
            {this._renderContentFormStandardInPrice()}
            {this._renderContentFormStandardOutPrice()}
            {this._renderContentFormStandardQuantity()}
            {this._renderContentFormStandardShipPrice()}
            <Divider />
            {this._renderContentFormStandardPackPrice()}
            {this._renderContentFormStandardPackCount()}
            <Divider />
            {this._renderContentFormStandardMaxBuyLimitPrice()}
            {this._renderContentFormStandardMinBuyLimitPrice()}
          </>
        ),
      },
      {
        key: "6",
        label: (
          <div className="product-tab">
            {this.i18n.t("productEdit.otherInfo")}
          </div>
        ),
        children: (
          <>
            {this._renderContentFormDetailDescription()}
            {this._renderContentFormUgDetailDescription()}
            
            {this._renderContentFormBarcode()}
            {this._renderContentFormNumber()}
            {this._renderContentFormSuggest()}
            {this.renderAgentLevelProductSwitch()}
          </>
        ),
      },
      {
        key: "3",
        label: (
          <div className="product-tab">
            {this.i18n.t("productEdit.detailLabel")}
          </div>
        ),
        children: (
          <>
            {this.renderUpdateDescBtn()}
            {this._renderContentFormDescription()}
            {this._renderContentFormDescriptionUg()}
          </>
        ),
      },
    ];

    if (this.merchantHasCommission()) {
      items.push({
        label: (
          <div className="product-tab">
            {this.i18n.t("productEdit.commissionInfo")}
          </div>
        ),
        key: "4",
        forceRender: true,
        children: (
          <>
            {this._renderContentFormStandardCommission()}
            {this._renderContentFormStandardParentCommission()}
            {this.renderCommissionStatistics()}
          </>
        ),
      });
    }

    if (this.isEditMode()) {
      items.push({
        label: (
          <div className="product-tab">
            {this.i18n.t("productEdit.propertyLabel")}
          </div>
        ),
        key: "5",
      });
    }

    return (
      <Layout.Content className="edit-content">
        <Spin
          spinning={this.state.uploadingImages || this.state.updatingProduct}
        >
          <Form ref={this.productForm}>
            <Tabs
              className="product-tabs"
              defaultActiveKey="1"
              onChange={this.onTabChange}
              activeKey={activeKey}
              items={items}
            />
            {this._renderContentSkuImage()}
            {this._renderContentFormProperty()}
          </Form>
        </Spin>
      </Layout.Content>
    );
  }

  _renderContentFormCategory() {
    if (this.isJdShop()) return null;
    const item = {
      colon: false,
      label: this.i18n.t("productEdit.catLabel"),
      labelCol: { span: 4 },
      wrapperCol: { span: 8 },
      required: true,
    };
    let categories = this.getCategories();
    let treeData =
      Array.isArray(categories) && categories.length > 0
        ? categories.map((i) => ({
          title: this.i18n.getPropNameByLocale(i, "name"),
          value: `${i.id}`,
          children:
            Array.isArray(i.sub_categories) && i.sub_categories.length > 0
              ? i.sub_categories.map((s) => ({
                title: this.i18n.getPropNameByLocale(s, "name"),
                value: `${s.id}`,
              }))
              : [],
        }))
        : [];
    let currentCat = this.getDefaultCategory();
    const categorySelector = (
      <TreeSelect treeData={treeData} treeDefaultExpandAll />
    );
    return (
      <Form.Item
        {...item}
        name="category_id"
        rules={[
          {
            required: true,
            message: this.i18n.t("productEdit.catRequired"),
          },
        ]}
        initialValue={currentCat}
      >
        {categorySelector}
      </Form.Item>
    );
  }

  _renderContentFormNumber() {
    const item = {
      colon: false,
      label: this.i18n.t("productEdit.numberLabel"),
      labelCol: { span: 4 },
      wrapperCol: { span: 8 },
      required: false,
    };
    return (
      <Form.Item
        {...item}
        name="product_no"
        initialValue={this.getDefaultNumber()}
        rules={[
          {
            required: false,
            message: this.i18n.t("productEdit.numberRequired"),
          },
        ]}
      >
        <Input placeholder={this.i18n.t("productEdit.numberHint")} />
      </Form.Item>
    );
  }

  _renderContentFormValidDate() {
    if (this.isBBLbsShop()) {
      return null;
    }
    const item = {
      colon: false,
      label: this.i18n.t("productEdit.valid_date"),
      labelCol: { span: 15 },
      wrapperCol: { span: 20 },
      required: false,
    };

    const item1 = {
      labelCol: { span: 5 },
      wrapperCol: { span: 25 },
      required: false,
    };

    return (
      <div style={{ display: "flex" }}>
        <Form.Item
          {...item}
          name="valid_date"
          initialValue={this.getDefaultValidDate()}
          rules={[
            {
              required: false,
              message: this.i18n.t("productEdit.numberRequired"),
            },
          ]}
        >
          <Input placeholder={this.i18n.t("productEdit.validHint")} />
        </Form.Item>
        <Form.Item
          {...item1}
          name="validUnion"
          rules={[
            {
              required: false,
              message: this.i18n.t("productEdit.numberRequired"),
            },
          ]}
        >
          <Select
            onSelect={(value) =>
              this.productForm.current.setFieldsValue({
                validUnion: value,
              })
            }
            defaultValue="日"
          >
            <Option value="日">日</Option>
            <Option value="月">月</Option>
            <Option value="年">年</Option>
          </Select>
        </Form.Item>
      </div>
    );
  }

  _renderContentFormManufacturedDate() {
    if (this.isBBLbsShop()) {
      return null;
    }
    const item = {
      colon: false,
      label: this.i18n.t("productEdit.manufactured_date"),
      labelCol: { span: 4 },
      wrapperCol: { span: 8 },
      required: false,
    };
    return (
      <Form.Item
        {...item}
        name="manufactured_date"
        rules={[
          {
            required: false,
            message: this.i18n.t("productEdit.numberRequired"),
          },
        ]}
      >
        <DatePicker
          format="YYYY-MM-DD"
          allowClear={false}
          onChange={() => console.log("==>")}
        />
      </Form.Item>
    );
  }

  _renderContentFormRecommendedPrice() {
    if (this.isBBLbsShop()) {
      return null;
    }
    const item = {
      colon: false,
      label: this.i18n.t("productEdit.recommended_price"),
      labelCol: { span: 4 },
      wrapperCol: { span: 8 },
      required: false,
    };
    return (
      <Form.Item
        {...item}
        name="recommended_price"
        initialValue={this.getDefaultRecommendedPrice()}
        rules={[
          {
            required: false,
            message: this.i18n.t("productEdit.numberRequired"),
          },
        ]}
      >
        <Input placeholder={this.i18n.t("productEdit.recommendedHint")} />
      </Form.Item>
    );
  }

  _renderContentFormBasicSales() {
    const item = {
      colon: false,
      label: this.i18n.t("productEdit.basic_sales"),
      labelCol: { span: 4 },
      wrapperCol: { span: 8 },
      required: false,
    };
    return (
      <Form.Item
        {...item}
        name="basic_sales"
        initialValue={this.getDefaultBasicSales()}
        rules={[
          {
            required: false,
            message: this.i18n.t("productEdit.numberRequired"),
          },
        ]}
      >
        <Input placeholder={this.i18n.t("productEdit.basicHint")} />
      </Form.Item>
    );
  }
  _renderUgSquareImage() {
    const item = {
      colon: false,
      label: this.i18n.t("productEdit.ugImagesLabel"),
      labelCol: { span: 4 },
      wrapperCol: { span: 16 },
    };
    if (!this.shopHasAgents()) {
      return null;
    }
    return (
      <>
        <Form.Item
          label={this.i18n.t("productEdit.zhImagesLabel")}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
        >
          <img width={150} height={150} src={this.getEditProduct()?.zh_four_square_image} />
        </Form.Item>
        <Form.Item
          label={this.i18n.t("productEdit.ugImagesLabel")}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
        >
          <img width={150} height={150} src={this.getEditProduct()?.ug_four_square_image} />
        </Form.Item >

      </>
    );
  }
  _renderContentFormBarcode() {
    const item = {
      colon: false,
      label: this.i18n.t("productEdit.barcodeLabel"),
      labelCol: { span: 4 },
      wrapperCol: { span: 8 },
      required: false,
    };
    return (
      <Form.Item
        {...item}
        name="barcode"
        initialValue={this.getDefaultBarcode()}
        rules={[{ required: false }]}
      >
        <Input placeholder={this.i18n.t("productEdit.barcodeHint")} />
      </Form.Item>
    );
  }

  _renderContentFormName() {
    const item = {
      colon: false,
      label: this.i18n.t("productEdit.nameLabel"),
      labelCol: { span: 4 },
      wrapperCol: { span: 16 },
      required: true,
    };

    return (
      <Form.Item noStyle shouldUpdate>
        {() => (
          <Form.Item
            {...item}
            name="zh_name"
            initialValue={this.getDefaultName()}
            rules={[
              {
                required: true,
                message: this.i18n.t("productEdit.nameRequired"),
              },
            ]}
          >
            <Input
              disabled={this.hasEditProductSku()}
              placeholder={this.i18n.t("productEdit.nameHint")}
              allowClear
              addonAfter={
                <TranslateBtn
                  onFinish={(value) =>
                    this.productForm.current.setFieldsValue({
                      zh_name: value,
                    })
                  }
                  context={
                    this.productForm.current &&
                    this.productForm.current.getFieldValue("ug_name")
                  }
                  from="ug"
                  to="zh"
                />
              }
            />
          </Form.Item>
        )}
      </Form.Item>
    );
  }

  _renderContentFormNameUg() {
    const item = {
      colon: false,
      label: this.i18n.t("productEdit.nameUgLabel"),
      labelCol: { span: 4 },
      wrapperCol: { span: 16 },
    };
    return (
      <Form.Item noStyle shouldUpdate>
        {() => (
          <Form.Item
            {...item}
            name="ug_name"
            initialValue={this.getDefaultUgName()}
          >
            <Input
              disabled={this.hasEditProductSku()}
              placeholder={this.i18n.t("productEdit.nameUgHint")}
              dir="rtl"
              allowClear
              addonAfter={
                <TranslateBtn
                  onFinish={(value) =>
                    this.productForm.current.setFieldsValue({
                      ug_name: value,
                    })
                  }
                  context={
                    this.productForm.current &&
                    this.productForm.current.getFieldValue("zh_name")
                  }
                  from="zh"
                  to="ug"
                />
              }
            />
          </Form.Item>
        )}
      </Form.Item>
    );
  }

  _renderContentFormDetailDescription() {
    if (this.isBBLbsShop()) {
      return null;
    }
    const item = {
      colon: false,
      label: this.i18n.t("productEdit.detailDescLabel"),
      labelCol: { span: 4 },
      wrapperCol: { span: 16 },
    };

    return (
      <Form.Item noStyle shouldUpdate>
        {() => (
          <Form.Item
            {...item}
            name="zh_detail_description"
            initialValue={this.getDetailDesc()}
          >
            <Input
              disabled={this.hasEditProductSku()}
              placeholder={this.i18n.t("productEdit.detailDescLabelHint")}
              allowClear
              addonAfter={
                <TranslateBtn
                  context={
                    this.productForm.current &&
                    this.productForm.current.getFieldValue(
                      "ug_detail_description"
                    )
                  }
                  onFinish={(value) =>
                    this.productForm.current.setFieldsValue({
                      zh_detail_description: value,
                    })
                  }
                  from="ug"
                  to="zh"
                />
              }
            />
          </Form.Item>
        )}
      </Form.Item>
    );
  }

  _renderContentFormUgDetailDescription() {
    if (this.isBBLbsShop()) {
      return null;
    }
    const item = {
      colon: false,
      label: this.i18n.t("productEdit.detailDescUgLabel"),
      labelCol: { span: 4 },
      wrapperCol: { span: 16 },
    };

    return (
      <Form.Item noStyle shouldUpdate>
        {() => (
          <Form.Item
            {...item}
            name="ug_detail_description"
            initialValue={this.getDetailUgDesc()}
          >
            <Input
              disabled={this.hasEditProductSku()}
              placeholder={this.i18n.t("productEdit.detailUgDescLabelHint")}
              dir="rtl"
              allowClear
              addonAfter={
                <TranslateBtn
                  context={
                    this.productForm.current &&
                    this.productForm.current.getFieldValue(
                      "zh_detail_description"
                    )
                  }
                  onFinish={(value) =>
                    this.productForm.current.setFieldsValue({
                      ug_detail_description: value,
                    })
                  }
                  from="zh"
                  to="ug"
                />
              }
            />
          </Form.Item>
        )}
      </Form.Item>
    );
  }

  _renderContentFormVideo() {
    if (this.isBBLbsShop() || !this.shopHasAgents()) {
      return null;
    }
    return (
      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const video = getFieldValue("video");
          const item = {
            colon: false,
            label: this.i18n.t("productEdit.video"),
            labelCol: { span: 4 },
            wrapperCol: { span: 16 },
            required: false,
          };
          return (
            <Form.Item
              {...item}
              name="video"
              initialValue={this.getDefaultVideo()}
            >
              <Input hidden />
              <VideoUpload
                video={this.state.videoDeleted ? null : this.getDefaultVideo()}
                setSelectedVideo={(video) => {
                  this.productForm.current.setFieldsValue({ video });
                  this.setState({ videoDeleted: false });
                }}
                setDeleted={() => {
                  this.setState({ videoDeleted: true });
                }}
              />
            </Form.Item>
          );
        }}
      </Form.Item>
    );
  }

  _renderContentFormImage() {
    const item = {
      colon: false,
      label: this.i18n.t("productEdit.imagesLabel"),
      labelCol: { span: 4 },
      wrapperCol: { span: 16 },
      required: true,
    };
    return (
      <Form.Item
        {...item}
        name="images"
        valuePropName="fileList"
        initialValue={this.getDefaultImages()}
        rules={[
          {
            required: true,
            message: this.i18n.t("productEdit.imagesRequired"),
          },
        ]}
        getValueFromEvent={this.onUploadImageChange}
      >
        <Upload
          accept="image/*"
          action=""
          listType="picture-card"
          multiple
          beforeUpload={this.onUploadImageBefore}
          onPreview={this.onUploadImagePreview}
          onRemove={this.onUploadImageRemove}
          openFileDialogOnClick
        >
          {!this.hasEditProductSku() && (
            <div>
              <PlusOutlined />
              <div className="ant-upload-text">
                {this.i18n.t("productEdit.imagesUpload")}
              </div>
            </div>
          )}
        </Upload>
      </Form.Item>
    );
  }

  

  

  _renderContentJDImages() {
    if (this.isJdShop()) {
      return (
        <Form.Item
          label={this.i18n.t("productEdit.refetchImgs")}
          labelCol={{ span: this.i18n.isLocaleRTL() ? 6 : 4 }}
          wrapperCol={{ span: this.i18n.isLocaleRTL() ? 16 : 8 }}
        >
          <Button
            type="primary"
            onClick={this.getJdProductImg}
            disabled={this.state.fetchingImg === "img"}
            loading={this.state.fetchingImg === "img"}
          >
            {this.i18n.t("productEdit.refetchImgs")}
          </Button>
        </Form.Item>
      );
    }
    return null;
  }

  renderUpdateDescBtn() {
    if (this.isJdShop()) {
      return (
        <Form.Item
          label={this.i18n.t("productEdit.refetchDesc")}
          labelCol={{ span: this.i18n.isLocaleRTL() ? 6 : 4 }}
          wrapperCol={{ span: this.i18n.isLocaleRTL() ? 16 : 8 }}
        >
          <Button
            type="primary"
            onClick={this.getJdProductDescription}
            disabled={this.state.fetchingImg === "disc"}
            loading={this.state.fetchingImg === "disc"}
          >
            {this.i18n.t("productEdit.refetchDesc")}
          </Button>
        </Form.Item>
      );
    }
    return null;
  }

  _renderContentFormDescription() {
    if (this.isBBLbsShop()) {
      return null;
    }
    const item = {
      colon: false,
      label: this.i18n.t("productEdit.descLabel"),
      labelCol: { span: this.i18n.isLocaleRTL() ? 6 : 4 },
      wrapperCol: { span: 16 },
    };

    return (
      <Form.Item
        {...item}
        name="zh_description"
        initialValue={this.getDefaultDescription()}
      >
        <BraftEditor
          media={{
            items: this.props.images,
            validateFn: this.onDescriptionMediaValidate,
            uploadFn: this.onDescriptionMediaUpload,
          }}
          hooks={{
            "remove-medias": this.onDescriptionMediaRemove,
          }}
        />
      </Form.Item>
    );
  }

  _renderContentFormDescriptionUg() {
    if (this.isBBLbsShop()) {
      return null;
    }
    const item = {
      colon: false,
      label: this.i18n.t("productEdit.descUgLabel"),
      labelCol: { span: this.i18n.isLocaleRTL() ? 6 : 4 },
      wrapperCol: { span: 16 },
    };

    return (
      <Form.Item
        {...item}
        name="ug_description"
        initialValue={this.getDefaultUgDescription()}
      >
        <BraftEditor
          media={{
            items: this.props.images,
            validateFn: this.onDescriptionMediaValidate,
            uploadFn: this.onDescriptionMediaUpload,
          }}
          hooks={{
            "remove-medias": this.onDescriptionMediaRemove,
          }}
        />
      </Form.Item>
    );
  }

  _renderContentFormStandard() {
    return (
      <Row className="ant-form-item ant-form-item-no-colon standard">
        <Col
          span={4}
          className="ant-form-item-label ant-legacy-form-item-label"
        >
          <label className="ant-form-item-required ant-legacy-form-item-no-colon">
            {this.i18n.t("productEdit.standardLabel")}
          </label>
        </Col>
        <Col span={16} className="ant-form-item-control-wrapper standard">
          {this._renderContentFormStandardInPrice()}
          {this._renderContentFormStandardOutPrice()}
          {this._renderContentFormStandardCommission()}
          {this._renderContentFormStandardParentCommission()}
          {this._renderContentFormStandardQuantity()}
          {this._renderContentFormStandardPackPrice()}
          {this._renderContentFormStandardPackCount()}
        </Col>
      </Row>
    );
  }

  _renderContentFormProperty() {
    const { activeKey } = this.state;

    if (activeKey === "5") {
      const product = this.getEditProduct();
      return <ProductPropertyForm product={product} />;
    }
    return null;
  }

  _renderContentFormStandardInPrice() {
    if (this.isBBLbsShop()) {
      return null;
    }
    let item = {
      colon: false,
      label: this.i18n.t("productEdit.inPriceLabel"),
      required: true,
      className: "item-input",
      labelCol: { span: this.i18n.isLocaleRTL() ? 6 : 4 },
      wrapperCol: { span: this.i18n.isLocaleRTL() ? 16 : 8 },
    };

    return (
      <Form.Item
        {...item}
        name="purchase_price"
        initialValue={this.getDefaultInPrice()}
        rules={[
          {
            required: true,
            message: this.i18n.t("productEdit.standardRequired"),
          },
        ]}
      >
        <InputNumber min={0} step={1} formatter={(value) => `￥${value}`} />
      </Form.Item>
    );
  }

  renderFressShipppingSwitch() {
    if (this.isBBLbsShop()) {
      return null;
    }
    let item = {
      colon: false,
      label: this.i18n.t("productEdit.isFreeShipping"),
      className: "item-input",
      labelCol: { span: this.i18n.isLocaleRTL() ? 6 : 4 },
      wrapperCol: { span: this.i18n.isLocaleRTL() ? 16 : 8 },
    };

    return (
      <Form.Item
        {...item}
        name="is_free_shipping"
        initialValue={this.getDefaultFreeShipping()}
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
    );
  }
  renderAgentLevelProductSwitch() {
    if (!this.isAgentLevelProductEnabled()) {
      return null;
    }
    let item = {
      colon: false,
      label: this.i18n.t("productEdit.isAgentLevelProduct"),
      className: "item-input",
      labelCol: { span: this.i18n.isLocaleRTL() ? 6 : 4 },
      wrapperCol: { span: this.i18n.isLocaleRTL() ? 16 : 8 },
    };

    return (
      <Form.Item
        {...item}
        name="is_agent_level_product"
        initialValue={this.getDefaultAgentLevelProduct()}
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
    );
  }

  _renderContentFormStandardShipPrice() {
    if (this.isBBLbsShop()) {
      return null;
    }
    let item = {
      colon: false,
      label: this.i18n.t("productEdit.shipPriceLabel"),
      className: "item-input",
      labelCol: { span: this.i18n.isLocaleRTL() ? 6 : 4 },
      wrapperCol: { span: this.i18n.isLocaleRTL() ? 16 : 8 },
    };

    return (
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => (
          <Form.Item
            {...item}
            name="ship_price"
            initialValue={this.getDefaultShipPrice()}
          >
            <InputNumber
              disabled={getFieldValue("is_free_shipping")}
              min={0}
              step={1}
              formatter={(value) => `￥${value}`}
            />
          </Form.Item>
        )}
      </Form.Item>
    );
  }

  _renderContentFormStandardMinBuyLimitPrice() {
    let item = {
      colon: false,
      label: this.i18n.t("productEdit.minBuyLimitLabel"),
      className: "item-input",
      labelCol: { span: this.i18n.isLocaleRTL() ? 6 : 4 },
      wrapperCol: { span: this.i18n.isLocaleRTL() ? 16 : 8 },
    };

    return (
      <Form.Item
        {...item}
        name="min_buy_limit"
        initialValue={this.getDefaultMinBuyLimit()}
      >
        <InputNumber min={0} step={1} />
      </Form.Item>
    );
  }

  _renderContentFormStandardMaxBuyLimitPrice() {
    let item = {
      colon: false,
      label: this.i18n.t("productEdit.maxBuyLimitLabel"),
      className: "item-input",
      labelCol: { span: this.i18n.isLocaleRTL() ? 6 : 4 },
      wrapperCol: { span: this.i18n.isLocaleRTL() ? 16 : 8 },
    };

    return (
      <Form.Item
        {...item}
        name="max_buy_limit"
        initialValue={this.getDefaultMaxBuyLimit()}
      >
        <InputNumber min={0} step={1} />
      </Form.Item>
    );
  }

  _renderContentFormStandardOutPrice() {
    let item = {
      colon: false,
      label: this.i18n.t("productEdit.outPriceLabel"),
      required: true,
      className: "item-input",
      labelCol: { span: this.i18n.isLocaleRTL() ? 6 : 4 },
      wrapperCol: { span: this.i18n.isLocaleRTL() ? 16 : 8 },
    };

    return (
      <Form.Item
        {...item}
        name="price"
        initialValue={this.getDefaultOutPrice()}
        rules={[
          {
            required: true,
            message: this.i18n.t("productEdit.standardRequired"),
          },
        ]}
      >
        <InputNumber min={0} step={1} formatter={(value) => `￥${value}`} />
      </Form.Item>
    );
  }

  _renderContentFormStandardCommission() {
    if (this.merchantHasCommission()) {
      let item = {
        colon: true,
        label: this.i18n.t("productEdit.commission"),
        required: false,
        className: "item-input",
        labelCol: { span: this.i18n.isLocaleRTL() ? 6 : 4 },
        wrapperCol: { span: this.i18n.isLocaleRTL() ? 16 : 8 },
      };

      return (
        <Form.Item
          {...item}
          name="commission_percent"
          initialValue={this.getDefaultCommission()}
          rules={[
            {
              required: false,
              message: this.i18n.t("productEdit.standardRequired"),
            },
          ]}
        >
          <InputNumber min={0} step={1} formatter={(value) => `${value}%`} />
        </Form.Item>
      );
    }
    return null;
  }

  _renderContentFormStandardParentCommission() {
    if (
      this.isBBShopMerchant() ||
      this.isAdminWithDabazaShop() ||
      (this.isThirdPartyShopHasWeapp() && this.shopHasAgents())
    ) {
      let item = {
        colon: true,
        label: this.i18n.t("productEdit.parentCommission"),
        required: false,
        className: "item-input",
        labelCol: { span: this.i18n.isLocaleRTL() ? 6 : 4 },
        wrapperCol: { span: this.i18n.isLocaleRTL() ? 16 : 8 },
      };

      return (
        <Form.Item
          {...item}
          name="parent_commission_percent"
          initialValue={this.getDefaultParentCommission()}
          rules={[
            {
              required: false,
              message: this.i18n.t("productEdit.standardRequired"),
            },
          ]}
        >
          <InputNumber min={0} step={0.1} formatter={(value) => `${value}%`} />
        </Form.Item>
      );
    }

    return null;
  }

  renderCommissionStatistics() {
    const product = this.getEditProduct();
    const { settings } = this.props;
    let isJdShop = this.isJdShop();
    if (product) {
      let purchasePrice = product.purchase_price;
      let salePrice = product.price;
      const originalGross = (1 - product.purchase_price / salePrice) * 100;
      let warehouseCost =
        settings && settings.warehouse_cost > 0 ? settings.warehouse_cost : 0;
      if (isJdShop) {
        if (product.min_buy_limit > 1) {
          purchasePrice = purchasePrice * product.min_buy_limit;
          salePrice = salePrice * product.min_buy_limit;
        }
        purchasePrice = this.util.getFreeShppingPurchase(
          purchasePrice,
          isJdShop
        );
      }
      const gross = (1 - purchasePrice / salePrice) * 100;
      return (
        <Form.Item noStyle shouldUpdate>
          {({ getFieldsValue }) => {
            const { commission_percent, parent_commission_percent } =
              getFieldsValue();
            let originalNet =
              originalGross - commission_percent - parent_commission_percent;
            let net = 0;
            if (!isJdShop) {
              net -= warehouseCost;
              originalNet -= warehouseCost;
            } else {
              net = gross - commission_percent - parent_commission_percent;
            }
            const originalColor = this.util.getColor4Net(originalNet);
            const color = this.util.getColor4Net(net);
            return (
              <Form.Item
                label="利润比例"
                labelCol={{ span: this.i18n.isLocaleRTL() ? 6 : 4 }}
                wrapperCol={{ span: this.i18n.isLocaleRTL() ? 16 : 8 }}
              >
                <Space direction="vertical">
                  <Space direction="horizontal" size={20}>
                    <Statistic
                      className="percents"
                      title="毛利率"
                      value={Number.isNaN(originalGross) ? 0 : originalGross}
                      valueStyle={{ color: originalColor }}
                      precision={1}
                      suffix="%"
                    />
                    {isJdShop && (
                      <Statistic
                        className="percents"
                        title="包邮毛利率"
                        value={Number.isNaN(gross) ? 0 : gross}
                        precision={1}
                        suffix="%"
                      />
                    )}

                    <Statistic
                      className="percents"
                      title="佣金比例"
                      value={commission_percent ?? 0}
                      precision={1}
                      suffix="%"
                    />
                    <Statistic
                      className="percents"
                      title="父代佣金"
                      value={parent_commission_percent ?? 0}
                      precision={1}
                      suffix="%"
                    />
                    {settings &&
                      settings.warehouse_cost &&
                      settings.warehouse_cost > 0 ? (
                      <Statistic
                        className="percents"
                        title="仓储物流"
                        value={settings.warehouse_cost}
                        precision={1}
                        suffix="%"
                      />
                    ) : null}
                  </Space>
                  <Statistic
                    className="percents net"
                    title="利润率"
                    value={Number.isNaN(originalNet) ? 0 : originalNet}
                    precision={1}
                    valueStyle={{ color: originalColor }}
                    suffix="%"
                  />
                  {isJdShop && (
                    <Statistic
                      className="percents net"
                      title="包邮后利润率"
                      value={Number.isNaN(net) ? 0 : net}
                      precision={1}
                      valueStyle={{ color }}
                      suffix="%"
                    />
                  )}
                </Space>
              </Form.Item>
            );
          }}
        </Form.Item>
      );
    }
    return null;
  }

  _renderContentFormStandardQuantity() {
    let item = {
      colon: false,
      label: this.i18n.t("productEdit.quantityLabel"),
      required: true,
      className: "item-input",
      labelCol: { span: this.i18n.isLocaleRTL() ? 6 : 4 },
      wrapperCol: { span: this.i18n.isLocaleRTL() ? 16 : 8 },
    };

    return (
      <Form.Item
        {...item}
        name="quantity"
        initialValue={this.getDefaultQuantity()}
        rules={[
          {
            required: true,
            message: this.i18n.t("productEdit.standardRequired"),
          },
        ]}
      >
        <InputNumber min={0} step={1} />
      </Form.Item>
    );
  }

  _renderContentFormStandardPackPrice() {
    if (this.isLbsShop() || this.isBBLbsShop()) {
      let item = {
        colon: false,
        label: this.i18n.t("productEdit.packPriceLabel"),
        className: "item-input",
        labelCol: { span: this.i18n.isLocaleRTL() ? 6 : 4 },
        wrapperCol: { span: this.i18n.isLocaleRTL() ? 16 : 8 },
      };

      return (
        <Form.Item
          {...item}
          name="pack_price"
          initialValue={this.getDefaultPackPrice()}
        >
          <InputNumber min={0} step={1} formatter={(value) => `￥${value}`} />
        </Form.Item>
      );
    }
    return null;
  }

  _renderContentFormStandardPackCount() {
    if (this.isLbsShop() || this.isBBLbsShop()) {
      let item = {
        colon: false,
        label: this.i18n.t("productEdit.packCountLabel"),
        className: "item-input",
        labelCol: { span: this.i18n.isLocaleRTL() ? 6 : 4 },
        wrapperCol: { span: this.i18n.isLocaleRTL() ? 16 : 8 },
      };
      return (
        <Form.Item
          {...item}
          name="pack_count"
          initialValue={this.getDefaultPackCount()}
        >
          <InputNumber min={0} step={1} />
        </Form.Item>
      );
    }
    return null;
  }

  _renderContentFormStatus() {
    const item = {
      colon: false,
      label: this.i18n.t("productEdit.statusLabel"),
      labelCol: { span: 4 },
    };
    return (
      <Row>
        <Col span={24}>
          <Form.Item
            {...item}
            name="status"
            initialValue={this.getDefaultStatus()}
          >
            <Radio.Group>
              {ProductEdit.Status.map((status, index) => {
                return (
                  <Radio.Button key={`status-${index}`} value={status.value}>
                    {this.i18n.t(status.name)}
                  </Radio.Button>
                );
              })}
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
    );
  }

  _renderContentFormOrder() {
    const item = {
      colon: false,
      label: this.i18n.t("productEdit.orderLabel"),
      labelCol: { span: 4 },
      wrapperCol: { span: 2 },
    };
    return (
      <Form.Item {...item} name="order" initialValue={this.getDefaultOrder()}>
        <InputNumber min={0} step={1} />
      </Form.Item>
    );
  }

  _renderContentFormSuggest() {
    const item = {
      colon: false,
      label: this.i18n.t("productEdit.suggestLabel"),
      labelCol: { span: 4 },
      wrapperCol: { span: 16 },
    };
    return (
      <Form.Item
        {...item}
        name="recommend"
        initialValue={this.getDefaultSuggestion()}
      >
        <Radio.Group>
          {ProductEdit.Suggestion.map((suggest, index) => {
            return (
              <Radio key={`suggest-${index}`} value={suggest.value}>
                {this.i18n.t(suggest.name)}
              </Radio>
            );
          })}
        </Radio.Group>
      </Form.Item>
    );
  }

  _renderContentSkuImage() {
    if (this.hasEditProductSku()) {
      return (
        <img
          className="sku-img"
          alt=""
          src={process.env.PUBLIC_URL + this.i18n.t("productEdit.skuImage")}
        />
      );
    }

    return null;
  }

  _renderFooter() {
    return (
      <Layout.Footer className="edit-footer">
        <Button type="primary" onClick={() => this.onFooterConfirmClick(false)}>
          {this.i18n.t("productEdit.saveAndBack")}
        </Button>
        {this.isEditMode() && (
          <Button
            type="primary"
            onClick={() => this.onFooterConfirmClick(false, false)}
          >
            {this.i18n.t("productEdit.save")}
          </Button>
        )}
        {!this.isEditMode() ? (
          <Button onClick={() => this.onFooterConfirmClick(true)}>
            {this.i18n.t("productEdit.saveAndContinue")}
          </Button>
        ) : null}
        {this.isJdShop() && this.isEditMode() && (
          <Button type="primary" onClick={this.onFooterDeleteClick}>
            {this.i18n.t("financeDownload.historyItemActionDelete")}
          </Button>
        )}
        <Button onClick={this.onFooterCancelClick}>
          {this.i18n.t("productEdit.cancel")}
        </Button>
      </Layout.Footer>
    );
  }

  _renderModals() {
    let items = [];
    items.push(this.renderImagePreviewModal());
    return items;
  }

  /* user methods */

  onTabChange = (key) => {

    this.setState({ activeKey: key });
  };

  onHeaderBackClick = () => {
    this.goToProductList();
  };

  onUploadImageBefore = (file, fileList) => {
    if (typeof file.uid === "string") {
      if (!this.isImageTypeValid(file)) {
        this.showMessage(
          this.i18n.t("productEdit.uploadImageTypeWarning"),
          Base.Message.warning
        );
      }

      if (!this.isImageLessThan1MB(file)) {
        this.showMessage(
          this.i18n.t("productEdit.uploadImageSizeWarning"),
          Base.Message.warning
        );
      }
    }

    return false;
  };

  onUploadImageChange = (e) => {
    let arr = null;
    if (Array.isArray(e)) {
      arr = e;
    } else {
      arr = e && e.fileList;
    }
    let fileList = [];
    for (let i = 0; i < arr.length; i++) {
      let file = arr[i];
      if (typeof file.uid === "string") {
        if (!this.isImageTypeValid(file.originFileObj)) continue;
        if (!this.isImageLessThan1MB(file.originFileObj)) continue;
      }

      fileList.push(file);
    }
    return fileList;
  };

  onUploadImagePreview = (file) => {
    let fileList = this.productForm.current.getFieldValue("images");
    let fileIndex = fileList.findIndex((i) => file.uid === i.uid);
    this.setState({ previewImages: fileList, previewImageIndex: fileIndex });
    if (this.isJdShop()) {
      this.productForm.current.setFieldsValue({
        images: [...new Set([file, ...fileList])],
      });
    }
  };

  onUploadImageRemove = (file) => {
    if (this.hasEditProductSku()) {
      this.showMessage(
        this.i18n.t("productEdit.skuWarning"),
        Base.Message.warning
      );
      return false;
    }
    return true;
  };

  onUploadVideoBefore = (file) => {
    if (typeof file.uid === "string") {
      if (!this.isVideoLessThan10MB(file)) {
        this.showMessage(
          this.i18n.t("productEdit.uploadVideoSizeWarning"),
          Base.Message.warning
        );
      }
    }

    return false;
  };

  onUploadVideoPreview = () => {
    let fileList = this.editShowForm.current.getFieldValue("video");
    this.setState({
      previewImages: fileList,
      previewImageIndex: 0,
      isImg: false,
    });
  };

  onDescriptionMediaValidate = (file) => {
    if (!this.isImageTypeValid(file)) {
      this.showMessage(
        this.i18n.t("productEdit.uploadImageTypeWarning"),
        Base.Message.warning
      );
      return false;
    }

    if (!this.isImageLessThan1MB(file)) {
      this.showMessage(
        this.i18n.t("productEdit.uploadImageSizeWarning"),
        Base.Message.warning
      );
      return false;
    }

    return true;
  };

  onDescriptionMediaUpload = async (param) => {
    let token = this.getUserToken();
    if (!token) {
      param.error({ msg: "no user access token" });
      return;
    }

    let apiConfig = {
      token: token,
      uploadFiles: [{ file: param.file }],
      uploadType: "images",
    };

    try {
      let res = await this.api.common.uploadImages.run(apiConfig);
      if (res.result) {
        let data = res.data;

        let mediaItem = {
          type: "IMAGE",
          url: data[0].url,
        };

        this.props.addMedia(mediaItem);
      } else {
        param.error({ msg: "upload image error" });
      }
    } catch (error) {
      param.error({ msg: "upload image error" });
    }
  };

  onDescriptionMediaRemove = (href, target) => {
    this.props.removeMedia(href);
  };

  onFooterConfirmClick = (continueCreate = false, close = true) => {
    this.productForm.current.validateFields().then((values) => {
      if (!this.isEditMode()) {
        this.addProduct(values, continueCreate, close);
      } else {
        this.updateProduct(values, close);
      }
    }).catch(error => {
      console.log("🚀 ~ file: ProductEdit.js:1674 ~ ProductEdit ~ this.productForm.current.validateFields ~ error:", error)
      
    });
  };

  onFooterDeleteClick = () => {
    this.showModal(
      this.i18n.t("productEdit.delete"),
      Base.Modal.confirm,
      () => {
        this.deleteProduct();
      }
    );
  };

  onFooterCancelClick = () => {
    this.goToProductList();
  };

  /* server methods */

  async addProduct(values, continueCreate = false, close = true) {
    if (this.state.updatingProduct) return;

    let token = this.getUserToken();
    if (!token) return;

    let api = this.api.product.customAdd;
    let apiConfig = {
      token,
      data: {
        category_id: values.category_id,
        product_no: values.product_no,
        zh_name: values.zh_name,
        ug_name: values.ug_name,
        ug_description: values.ug_description
          ? values.ug_description.toHTML()
          : null,
        zh_description: values.zh_description
          ? values.zh_description.toHTML()
          : null,
        zh_detail_description: values.zh_detail_description,
        ug_detail_description: values.ug_detail_description,
        barcode: values.barcode,
        purchase_price: values.purchase_price * 100,
        price: values.price * 100,
        ship_price: values.ship_price * 100,
        max_buy_limit: values.max_buy_limit,
        min_buy_limit: values.min_buy_limit,
        quantity: values.quantity,
        pack_price: values.pack_price * 100,
        pack_count: values.pack_count,
        status: values.status,
        order: values.order,
        recommended: values.recommend,
        images: [],
        commission_percent: values.commission_percent,
        parent_commission_percent: values.parent_commission_percent,
        is_free_shipping: values.is_free_shipping,
      },
    };

    try {
      let imagePaths = [];
      let valueImages = values.images;
      let hasImages = valueImages && valueImages.length > 0;
      if (hasImages) {
        let images = valueImages.map((image, index) => {
          return {
            file: image.originFileObj,
            blob: image.thumbUrl,
          };
        });
        imagePaths = await this.uploadImages(images);
      }
      apiConfig.data.images = imagePaths;

    

      this.showMessage(
        this.i18n.t("productEdit.creatingProduct"),
        Base.Message.loading
      );
      this.setState({ updatingProduct: true });

      let res = await api.run(apiConfig);

      if (!this.mounted) return;

      if (res.result) {
        this.showMessage(
          this.i18n.t("productEdit.creatingProductSuccess"),
          Base.Message.success
        );
        if (continueCreate) {
          this.productForm.current.resetFields();
          this.productForm.current.setFieldsValue({
            zh_description: EditorState.createFrom(""),
            ug_description: EditorState.createFrom(""),
          });
        } else {
          if (close) {
            this.goToProductList();
          }
        }
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;
      this.setState({ updatingProduct: false });
    }
  }

  async updateProduct(values, close = true) {
    console.log("🚀 ~ file: ProductEdit.js:1816 ~ ProductEdit ~ updateProduct ~ values:", values)
    if (this.state.updatingProduct) return;

    let product = this.getEditProduct();
    if (!product) return;

    let token = this.getUserToken();
    if (!token) return;

    let api = this.api.product.customAdd;

    let apiConfig = {
      token: token,
      path: {},
      data: {
        ...values,
        category_id: values.category_id,
        zh_name: values.zh_name,
        ug_name: values.ug_name,
        ug_description: values.ug_description
          ? values.ug_description.toHTML()
          : null,
        zh_description: values.zh_description
          ? values.zh_description.toHTML()
          : null,
        zh_detail_description: values.zh_detail_description,
        ug_detail_description: values.ug_detail_description,
        barcode: values.barcode,
        purchase_price: values.purchase_price * 100,
        price: values.price * 100,
        quantity: values.quantity,
        pack_price: values.pack_price * 100,
        pack_count: values.pack_count,
        ship_price: values.ship_price * 100,
        max_buy_limit: values.max_buy_limit,
        min_buy_limit: values.min_buy_limit,
        status: values.status,
        order: values.order,
        recommended: values.recommend,
        images: [],
        video: "",
        commission_percent: values.commission_percent,
        parent_commission_percent: values.parent_commission_percent,
        is_free_shipping: values.is_free_shipping,
        valid_date: values.valid_date + values.validUnion,
        manufactured_date: values.manufactured_date
          ? `${values.manufactured_date.$y}-${values.manufactured_date.$M + 1
          }-${values.manufactured_date.$D}`
          : null,
        recommended_price: values.recommended_price,
        basic_sales: values.basic_sales,
        
      },
    };

    if (this.isEditMode() && !this.isCloneMode()) {
      api = this.api.product.customUpdate;
      if (this.isJdShop()) {
        api = this.api.product.jdCustomUpdate;
      } else {
        apiConfig.data.product_no = values.product_no;
      }
      apiConfig.path.id = product.id;
    }

    try {
      let imagePaths = [];
      let valueImages = values.images;
      let hasImages = valueImages && valueImages.length > 0;
      if (hasImages) {
        let images = valueImages
          .filter((image, index) => {
            if (!image.status) {
              return image;
            }
            return null;
          })
          .map((image, index) => {
            return {
              file: image.originFileObj,
              blob: image.thumbUrl,
            };
          });
        if (images && images.length > 0) {
          imagePaths = await this.uploadImages(images);
        }
        imagePaths = valueImages
          .filter((image, index) => {
            if (image.status) {
              return image;
            }
            return null;
          })
          .map((image, index) => {
            return image.path;
          })
          .concat(imagePaths);
      }
      apiConfig.data.images = imagePaths;

      

      const videoPaths = getUploadedVideoPaths(values.video);

      if (videoPaths) {
        apiConfig.data.video = videoPaths[0];
      }
      if (this.state.videoDeleted) {
        apiConfig.data.video = "";
      }

      this.showMessage(
        this.i18n.t("productEdit.updatingProductSuccess"),
        Base.Message.loading
      );
      this.setState({ updatingProduct: true });

      let res = await api.run(apiConfig);

      if (!this.mounted) return;

      if (res.result) {
        this.showMessage(
          this.i18n.t("productEdit.updatingProductSuccess"),
          Base.Message.success
        );
        if (close) {
          this.goToProductList();
        } else {
          const {
            location: { pathname, search, state },
            history,
          } = this.props;
          history.replace({
            pathname: pathname + search,
            state: {
              ...state,
              product: {
                ...res.data,
              },
            },
          });
        }
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;
      this.setState({ updatingProduct: false });
    }
  }

  async deleteProduct() {
    if (this.state.updatingProduct) return;

    let product = this.getEditProduct();
    if (!product) return;

    let token = this.getUserToken();
    if (!token) return;

    let api = this.api.product.jdCustomDelete;
    let apiConfig = {
      token: token,
      path: {
        id: product.id,
      },
    };

    try {
      this.showMessage(
        this.i18n.t("productEdit.updatingProductSuccess"),
        Base.Message.loading
      );
      this.setState({ updatingProduct: true });

      let res = await api.run(apiConfig);

      if (!this.mounted) return;

      if (res.result) {
        this.showMessage(res.message, Base.Message.success);
        this.goToProductList();
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;
      this.setState({ updatingProduct: false });
    }
  }

  async uploadImages(images) {
    if (this.state.uploadingImages) throw new Error("uploading");

    if (!images && images.length <= 0) throw new Error("invalid images");

    let token = this.getUserToken();
    if (!token) throw new Error("no user token");

    let apiConfig = {
      token: token,
      uploadFiles: images,
      uploadType: "images",
    };

    this.showMessage(
      this.i18n.t("productEdit.uploadingImages"),
      Base.Message.loading
    );
    this.setState({ uploadingImages: true });
    try {
      let res = await this.api.common.uploadImages.run(apiConfig);
      if (res.result) {
        let data = res.data;
        return data.map((item) => item.path);
      } else {
        throw new Error(res.message);
      }
    } catch (error) {
      throw error;
    } finally {
      if (!this.mounted) return;
      this.setState({ uploadingImages: false });
    }
  }

  getJdProductImg = async () => {
    let token = this.getUserToken();
    if (!token) throw new Error("no user token");

    let apiConfig = {
      token,
      path: {
        sku_id: this.state.editProduct.jd_sku,
      },
    };

    this.setState({ fetchingImg: "img" });

    try {
      let res = await this.api.product.jdProductImg.run(apiConfig);
      if (res.result) {
        this.productForm.current.setFieldsValue({
          images: res.data.map((i, indx) => ({
            name: `name${indx}`,
            status: "done",
            uid: -indx,
            ...i,
          })),
        });
      } else {
        throw new Error(res.message);
      }
    } catch (error) {
      throw error;
    } finally {
      if (!this.mounted) return;
      this.setState({ fetchingImg: null });
    }
  };

  getJdProductDescription = async () => {
    let token = this.getUserToken();
    if (!token) throw new Error("no user token");

    let apiConfig = {
      token,
      path: {
        sku_id: this.state.editProduct.jd_sku,
      },
    };

    this.setState({ fetchingImg: "disc" });

    try {
      let res = await this.api.product.jdProductDesc.run(apiConfig);
      if (res.result) {
        this.productForm.current.setFieldsValue({
          zh_description: EditorState.createFrom(res.data),
          ug_description: EditorState.createFrom(res.data),
        });
      } else {
        throw new Error(res.message);
      }
    } catch (error) {
      throw error;
    } finally {
      if (!this.mounted) return;
      this.setState({ fetchingImg: null });
    }
  };

  /* custom methods */

  goToProductList() {
    this.props.history.goBack();
  }

  getCategories() {
    return this.getFromRouterState("categories") || [];
  }

  getEditShow() {
    return this.getFromRouterState("show");
  }

  isEditMode() {
    return this.getFromRouterState("edit");
  }

  isCloneMode() {
    return this.getFromRouterState("clone");
  }

  getEditProduct() {
    return this.getFromRouterState("product");
  }

  hasEditProductSku() {
    let product = this.getEditProduct();
    return product ? product.sku_id > 0 : false;
  }

  getDefaultCategory() {
    let cats = this.getCategories();

    let cat = null;
    if (cats && cats.length > 0) {
      let stateCatId = this.getFromRouterState("currentCat");
      if (stateCatId) {
        cat = cats.find((cat) => cat.id === stateCatId);
      }

      if (!cat && this.isEditMode() && this.getEditProduct()) {
        cat = cats.find((cat) => cat.id === this.getEditProduct().category_id);
        if (!cat) {
          let subs = [];
          cats.forEach((c) => {
            if (
              Array.isArray(c.sub_categories) &&
              c.sub_categories.length > 0
            ) {
              subs = subs.concat(c.sub_categories);
            }
          });
          cat = subs.find((s) => s.id === this.getEditProduct().category_id);
        }
      }

      if (!cat) {
        cat = cats[0];
      }
    }

    return cat ? `${cat.id}` : -1;
  }

  getDefaultNumber() {
    let number = "";

    if (this.isEditMode() && this.getEditProduct()) {
      number = this.getEditProduct().product_no;
    }

    return number;
  }

  getDefaultValidDate() {
    let number = "";

    if (this.isEditMode() && this.getEditProduct()) {
      number = this.getEditProduct().valid_date;
    }

    return number;
  }

  getDefaultRecommendedPrice() {
    let number = "";

    if (this.isEditMode() && this.getEditProduct()) {
      number = this.getEditProduct().recommended_price;
    }

    return number;
  }

  getDefaultBasicSales() {
    let number = "";

    if (this.isEditMode() && this.getEditProduct()) {
      number = this.getEditProduct().basic_sales;
    }

    return number;
  }

  isVideoLessThan10MB(image) {
    return image.size / 1024 / 1024 < 20;
  }

  getDefaultVideo() {
    let video = [];

    if (this.isEditMode() && this.getEditProduct()) {
      let showVideo = this.getEditProduct().video;

      if (showVideo) {
        video.push({
          uid: -1 * (1 + 1),
          name: `video${1 + 1}`,
          status: "done",
          url: showVideo && showVideo.url,
          path: showVideo && showVideo.path,
        });
      }
    }

    return video;
  }

  getDefaultBarcode() {
    let number = "";

    if (this.isEditMode() && this.getEditProduct()) {
      number = this.getEditProduct().barcode;
    }

    return number;
  }

  getDefaultName() {
    let name = "";

    if (this.isEditMode() && this.getEditProduct()) {
      name = this.getEditProduct().zh_name;
    }

    return name;
  }

  getDefaultUgName() {
    let name = "";

    if (this.isEditMode() && this.getEditProduct()) {
      name = this.getEditProduct().ug_name;
    }

    return name;
  }

  getDetailDesc() {
    let desc = "";

    if (this.isEditMode() && this.getEditProduct()) {
      desc = this.getEditProduct().zh_detail_description;
    }

    return desc;
  }

  getDetailUgDesc() {
    let desc = "";

    if (this.isEditMode() && this.getEditProduct()) {
      desc = this.getEditProduct().ug_detail_description;
    }

    return desc;
  }

  getDefaultImages() {
    let images = [];

    if (this.isEditMode() && this.getEditProduct()) {
      let productImages = this.getEditProduct().images;
      if (productImages && productImages.length > 0) {
        for (let i = 0; i < productImages.length; i++) {
          images.push({
            uid: -1 * (i + 1),
            name: `image${i + 1}`,
            status: "done",
            url: productImages[i].url,
            path: productImages[i].path,
          });
        }
      }
    }

    return images;
  }

  

  getDefaultDescription() {
    let description = null;

    if (this.isEditMode() && this.getEditProduct()) {
      description = EditorState.createFrom(
        this.getEditProduct().zh_description
      );
    }

    return description;
  }

  getDefaultUgDescription() {
    let description = null;

    if (this.isEditMode() && this.getEditProduct()) {
      description = EditorState.createFrom(
        this.getEditProduct().ug_description
      );
    }

    return description;
  }

  // getDefaultTag() {
  // 	let tag = ['desert', 'shala'];

  // 	if (this.isEditMode() && this.getEditProduct()) {
  // 		tag = ['zhushi', 'gaifan'];
  // 	}

  // 	return tag;
  // }

  getDefaultInPrice() {
    let price = 0;

    if (this.isEditMode() && this.getEditProduct()) {
      price = this.util.getPrice(this.getEditProduct().purchase_price || 0);
    }

    return price;
  }

  getDefaultShipPrice() {
    let price = 0;

    if (this.isEditMode() && this.getEditProduct()) {
      price = this.util.getPrice(this.getEditProduct().ship_price || 0);
    }

    return price;
  }

  getDefaultFreeShipping() {
    if (this.isEditMode() && this.getEditProduct()) {
      return this.getEditProduct().is_free_shipping;
    }
    return false;
  }
  getDefaultAgentLevelProduct() {
    if (this.isEditMode() && this.getEditProduct()) {
      return this.getEditProduct().is_agent_level_product;
    }
    return false;
  }

  getDefaultMaxBuyLimit() {
    let price = 0;

    if (this.isEditMode() && this.getEditProduct()) {
      price = this.getEditProduct().max_buy_limit;
    }

    return price;
  }

  getDefaultMinBuyLimit() {
    let price = 0;

    if (this.isEditMode() && this.getEditProduct()) {
      price = this.getEditProduct().min_buy_limit;
    }

    return price;
  }

  getDefaultOutPrice() {
    let price = 0;
    let product = this.getEditProduct();
    if (this.isEditMode() && product) {
      let tmp = product.is_flash_sale ? product.original_price : product.price;
      price = this.util.getPrice(tmp);
    }
    return price;
  }

  getDefaultCommission() {
    let commission = 0;

    if (this.isEditMode() && this.getEditProduct()) {
      commission = this.getEditProduct().commission_percent;
    }

    return commission;
  }

  getDefaultParentCommission() {
    let commission = 0;

    if (this.isEditMode() && this.getEditProduct()) {
      commission = this.getEditProduct().parent_commission_percent;
    }

    return commission;
  }

  getDefaultQuantity() {
    let quantity = 0;

    if (this.isEditMode() && this.getEditProduct()) {
      quantity = this.getEditProduct().quantity;
    }

    return quantity;
  }

  getDefaultPackPrice() {
    let packPrice = 0;

    if (this.isEditMode() && this.getEditProduct()) {
      packPrice = this.util.getPrice(this.getEditProduct().pack_price);
    }

    return packPrice;
  }

  getDefaultPackCount() {
    let count = 0;

    if (this.isEditMode() && this.getEditProduct()) {
      count = this.getEditProduct().pack_count;
    }

    return count;
  }

  getDefaultStatus() {
    let status = ProductEdit.Status[0].value;

    if (this.isEditMode() && this.getEditProduct()) {
      status = this.getEditProduct().status;
    }

    return status;
  }

  getDefaultOrder() {
    let count = 0;

    if (this.isEditMode() && this.getEditProduct()) {
      count = this.getEditProduct().order;
    }

    return count;
  }

  getDefaultSuggestion() {
    let count = ProductEdit.Suggestion[0].value;
    if (this.isEditMode() && this.getEditProduct()) {
      count = this.getEditProduct().recommended ? 1 : 0;
    }
    return count;
  }

  isImageTypeValid(image) {
    const types = ["image/jpeg", "image/png"];
    return types.indexOf(image.type) !== -1;
  }

  isImageLessThan1MB(image) {
    return image.size / 1024 / 1024 < 1;
  }
}

export default connect(
  (state) => {
    return {
      user: getUserInfo(state),
      images: getMedias(state),
      settings: getMerchantSettings(state),
    };
  },
  { addMedia, removeMedia }
)(ProductEdit);
