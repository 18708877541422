import { Button, Drawer, Form, Input, Space, Table } from "antd";
import { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  handleMutationError,
  showNotificationWithIcon,
} from "../../../utils/helpers/helpers";
import { getList, update } from "../../../utils/http/dataProvider";
import { getUserInfo } from "../../../utils/redux/reducers/User";
import { BreadCrumb } from "../../../components/main/LayoutComponents";

import { agreementListColumns } from "./agreementListColumns";
import AgreementListHeader from "./AgreementListHeader";
import breadCrumbDefault from "./breadCrumb";
import { Locale } from "../../../utils";
import Base from "../../Base";

const i18n = Locale.getInstance();
const base = Base.getInstance();
const { TextArea } = Input;

const AgreementList = () => {
  const user = useSelector((state) => getUserInfo(state));
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const { data, error, isLoading } = useQuery(
    ["lottery-agreements", user.token],
    () =>
      getList("lottery-agreements", {
        pagination: {
          perPage: 10,
          page,
        },
        sort: {
          field: "id",
          order: "desc",
        },
        token: user.token,
      })
  );

  const updateAgreementMutation = useMutation(update, {
    onSuccess: () => {
      queryClient.invalidateQueries("lottery-agreements");
      showNotificationWithIcon(i18n.t("media.saveFinished"), "success");
    },
    onError: (error) => {
      handleMutationError(error);
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  if (error) {
    showNotificationWithIcon(error.data.message);
  }

  const handleApprove = (id) => {
    base.showModal("", Base.Modal.confirm, () => {
      try {
        setLoading(true);
        updateAgreementMutation.mutate({
          resource: `lottery-agreements`,
          params: { token: user.token, id, data: { status: 1 } },
        });
      } catch (error) {
        console.log(
          "🚀 ~ file: AgreementList.jsx:78 ~ handleApprove ~ error:",
          error
        );
      }
    });
  };
  const handleReject = (id) => {
    base.showModal("", Base.Modal.confirm, () => {
      try {
        setLoading(true);
        updateAgreementMutation.mutate({
          resource: `lottery-agreements`,
          params: { token: user.token, id, data: { status: 0 } },
        });
      } catch (error) {
        console.log(
          "🚀 ~ file: AgreementList.jsx:78 ~ handleApprove ~ error:",
          error
        );
      }
    });
  };

  return (
    <>
      <BreadCrumb data={breadCrumbDefault} />
      <div className="qrcode">
        <AgreementListHeader />
        <Table
          className="list"
          loading={isLoading || loading}
          rowKey="id"
          dataSource={data?.data}
          columns={agreementListColumns(history, handleApprove, handleReject)}
          scroll={{
            x: 1200,
          }}
          pagination={{ onChange: handleChangePage }}
        />
      </div>
    </>
  );
};

export default AgreementList;
