import {
  Button,
  Form,
  InputNumber,
  Radio,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useForm } from "antd/lib/form/Form";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Locale } from "../../../utils";
import {
  handleMutationError,
  showNotificationWithIcon,
} from "../../../utils/helpers/helpers";
import { getUserInfo } from "../../../utils/redux/reducers/User";
import { BreadCrumb } from "../../../components/main/LayoutComponents";
import breadCrumb from "../breadCrumb";
import { create, getList, getOne, update } from "../../../utils/http/dataProvider";
import "./redPacketDetailEdit.scss";

const i18n = Locale.getInstance();
let counter = 0;
const RedPacketDetailEdit = () => {
  const user = useSelector((state) => getUserInfo(state));
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const [redPacketDetailForm] = useForm();
  const history = useHistory();
  const [average, setAverage] = useState(false);
  const [type, setType] = useState(1);
  const { id, redPacketId } = useParams();
  counter += 1;

  const { data, isLoading } = useQuery(
    ["red-packet-detail-show", user.token],
    () => getOne("red-packet-detail", { id, token: user.token }),
    {
      enabled: !!id,
    }
  );
  
  useEffect(() => {
    if (data && id == data?.id) {
      let values = { ...data, red_packet_id: redPacketId };
      if(values.min){
        values['min'] = (values.min / 100).toFixed(2);
      }
      if(values.max){
        values['max'] = (values.max / 100).toFixed(2);
      }
      if(values.amount){
        values['amount'] = (values.amount / 100).toFixed(2);
      }
      redPacketDetailForm.setFieldsValue(values);
    }
    if (!id) {
      redPacketDetailForm.resetFields();
      redPacketDetailForm.setFieldValue("red_packet_id", redPacketId);
    }
  }, [data]);

  const addRedPacketMutation = useMutation(create, {
    onSuccess: () => {
      queryClient.invalidateQueries("red-packet-detail");
      showNotificationWithIcon(i18n.t("media.saveFinished"), "success");
      setLoading(false);
      history.goBack();
    },
    onError: (error) => {
      handleMutationError(error);
      setLoading(false);
    },
  });

  const updateRedPacketMutation = useMutation(update, {
    onSuccess: () => {
      queryClient.invalidateQueries("red-packet-detail");
      showNotificationWithIcon(i18n.t("media.saveFinished"), "success");
      setLoading(false);
      history.goBack();
    },
    onError: (error) => {
      handleMutationError(error);
      setLoading(false);
    },
  });
  const onFinish = (values) => {
    setLoading(true);
    const data = {...values};
    if(values.min){
      data['min'] = values.min * 100;
    }
    if(values.max){
      data['max'] = values.max * 100;
    }
    if(values.amount){
      data['amount'] = values.amount * 100;
    }
    const params = {
      data: data,
      token: user.token,
    };

    if (id) {
      updateRedPacketMutation.mutate({
        resource: "red-packet-detail",
        params: { ...params, id },
      });
    } else {
      addRedPacketMutation.mutate({ resource: "red-packet-detail", params });
    }
  };

  const onFinishFailed = (errorInfo) => {
    setLoading(false);
    showNotificationWithIcon(errorInfo);
  };

  if (isLoading) {
    return <Spin spinning={isLoading} />;
  }
  return (
    <>
      <BreadCrumb data={breadCrumb} />
      <Spin spinning={loading}>
        <Form
          disabled={data && data.id === id && data.status != 0}
          form={redPacketDetailForm}
          className="red-packet-form"
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          onFinish={onFinish}
          onChange={(e) => {
            setType(redPacketDetailForm.getFieldsValue()?.type);
            const values = redPacketDetailForm.getFieldsValue();
            if (values.amount && values.quantity) {
              setAverage((values.amount / values.quantity).toFixed(2));
            } else {
              setAverage(false);
            }
          }}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item hidden name="red_packet_id" initialValue={redPacketId}>
            <InputNumber value={redPacketId} />
          </Form.Item>

          <Form.Item
            label={i18n.t("redPacketActivity.amount")}
            name="amount"
            wrapperCol={{ span: 24 }}
            extra={i18n.t("redPacketActivity.amountDescription")}
          >
            <InputNumber addonBefore={<div>¥</div>} min={10} max={100000} />
          </Form.Item>
          <Form.Item
            label={i18n.t("redPacketActivity.quantity")}
            name="quantity"
            wrapperCol={{ span: 12 }}
            extra={average ? <div>{i18n.t('redPacketActivity.average', {average: average})}</div> : ""}
          >
            <InputNumber min={10} max={100000} />
          </Form.Item>
          <Form.Item
            name="type"
            label={i18n.t("redPacketActivity.type")}
            initialValue={1}
            extra={i18n.t("redPacketActivity.typeDescription")}
          >
            <Radio.Group>
              <Radio defaultChecked={true} value={1}>
                {i18n.t("redPacketActivity.typeSame")}
              </Radio>
              <Radio value={0}>{i18n.t("redPacketActivity.typeRandom")}</Radio>
            </Radio.Group>
          </Form.Item>
          {!type ? (
            
            <div>
              <Form.Item
                label={i18n.t("redPacketActivity.min")}
                name="min"
                wrapperCol={{ span: 5 }}
              >
                <InputNumber min={0.1} max={99} />
              </Form.Item>
              <Form.Item
                label={i18n.t("redPacketActivity.max")}
                name="max"
                wrapperCol={{ span: 5 }}
              >
                <InputNumber min={0.1} max={99} />
              </Form.Item>
            </div>
          ): null}
          <Form.Item  className="submit">
            <Button disabled={!average} type="primary" htmlType="submit">
              {i18n.t("productEdit.saveAndBack")}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};

export default RedPacketDetailEdit;
