import React, { useState, useEffect, useCallback } from "react";
import {
  Input,
  Select,
  Row,
  Col,
  Button,
  Space,
  Popover,
  Form,
  InputNumber,
  Spin,
  message,
  Modal,
  Image,
} from "antd";
import { DeleteOutlined, EditOutlined, MinusOutlined } from "@ant-design/icons";

import { Locale, ApiManager } from "../../utils";
import { connect } from "react-redux";
import { getUserInfo } from "../../utils/redux/reducers/User";
import TranslateBtn from "../../components/product/TranslateBtn";

import "./productPropertyForm.scss";
import ImageUpload from "../../components/media/ImageUpload";
import ImageUploadPreview from "../../components/media/ImageUploadPreview";
import PropertyTable from "./PropertyTable";

const i18n = Locale.getInstance();
const api = ApiManager.getInstance();
const { Option } = Select;
let renderCounter = 0;

function ProductPropertyForm({ user, product }) {
  const [groupList, setGroupList] = useState([]);
  const [skuList, setSkuList] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [openPropertyModal, setOpenPropertyModal] = useState(false);
  const [counter, setCounter] = useState(0);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [loading, setLoading] = useState(false);
  const [groupForm] = Form.useForm();
  const [propertyForm] = Form.useForm();

  const getListData = useCallback(async () => {
    const { token } = user;
    if (token && product) {
      const listApi = api.productProperty.list;
      const apiConfig = {
        token,
        query: {
          product_id: product.id,
        },
      };
      try {
        const res = await listApi.run(apiConfig);
        if (res.result) {
          setGroupList(res.data);
          let counter = 0;
          res.data.map((group, index) => {
            if (group.product_property.length > 0) {
              
              const newGroups = groups;
              group.childList = group.product_property;
              newGroups[counter] = group;
              if(counter === 0){
                group.index = 0;
                setSelectedGroup(group);
              }
              setGroups(newGroups);
              const newSelectedGroups = selectedGroups;
              newSelectedGroups[index] = group.id;
              setSelectedGroups(newSelectedGroups);
              counter++;
            }
          });
        }
      } catch (error) {
        console.log("getListData -> error", error);
      }
    }
  }, [product, user]);

  const getSkuListData = useCallback(async () => {
    const { token } = user;
    if (token && product) {
      const listApi = api.productProperty.skuList;
      const apiConfig = {
        token,
        query: {
          product_id: product.id,
        },
      };
      try {
        const res = await listApi.run(apiConfig);
        if (res.result) {
          setSkuList(res.data);
        }
      } catch (error) {
        console.log("getListData -> error", error);
      }
    }
  }, [product, user]);

  useEffect(() => {
    getListData();
    getSkuListData();
  }, []);

  function onAddChildProperty() {
    // setChildList(
    //   childList.concat([
    //     {
    //       product_sku_id: `new-${new Date().getTime()}`,
    //       zh_name: "",
    //       ug_name: "",
    //       quantity: 0,
    //       product_no: "",
    //     },
    //   ])
    // );
  }

  function onDeleteGroup(group, index) {
    if (!group?.id) {
      const newGroups = groups.filter((v, k) => k != index);
      setGroups(newGroups);
      return;
    }

    Modal.confirm({
      title: i18n.t("sales.deleteWarning"),
      content: i18n.t("property.deleteGroup"),
      okText: i18n.t("base.modal.okText"),
      cancelText: i18n.t("base.modal.cancelText"),
      onOk: async () => {
        const { token } = user;
        if (token) {
          const deleteApi = api.productProperty.delete;
          const apiConfig = {
            token,
            path: { id: group.id },
          };
          try {
            const res = await deleteApi.run(apiConfig);
            if (res.result) {
              message.success(res.message);
              getListData();
            } else {
              message.error(res.message);
            }
          } catch (error) {
            console.log("onDeleteGroup -> error", error);
          }
        }
      },
    });
  }

  async function onAddGroup(values) {
    const { token } = user;
    if (token) {
      const adApi = api.productProperty.add;
      setLoading(true);
      try {
        const { zh_name, ug_name } = values;
        const apiConfig = {
          token,
          data: {
            zh_name,
            ug_name,
            product_id: product.id,
          },
        };
        const res = await adApi.run(apiConfig);
        if (res.result) {
          let groupInfo = {
            zh_name: res.data.zh_name,
            ug_name: res.data.ug_name,
            id: res.data.id,
          };
          setGroupList(groupList.concat(groupInfo));
        } else {
          message.error(res.message);
        }
      } catch (error) {
        console.log("onAddGroup -> error", error);
      } finally {
        setLoading(false);
      }
    }
  }
  async function onAddNewProperty(values) {
    
    const { token } = user;
    if (token) {
      const propertyApi = api.productProperty.addChild;
      setLoading(true);
      try {
        const { zh_name, ug_name, order } = values;
        const apiConfig = {
          token,
          data: {
            zh_name,
            ug_name,
            order,
            property_group_id: selectedGroup.id,
            product_id: product.id,
          },
        };
        if (values.image && values.image.length > 0) {
          apiConfig.data.image = values["image"][0]["file_path"];
        }
        const res = await propertyApi.run(apiConfig);
        if (res.result) {
          getListData();
          setOpenPropertyModal(false);
        } else {
          message.error(res.message);
        }
      } catch (error) {
        console.log("onAddGroup -> error", error);
      } finally {
        setLoading(false);
      }
    }
  }
  async function onUpdateProperty(values) {
    const { token } = user;
    if (token) {
      const propertyApi = api.productProperty.updateChild;

      setLoading(true);
      try {
        const { zh_name, ug_name, order } = values;
        const apiConfig = {
          token,
          path: { id: selectedProperty.id },
          data: {
            zh_name,
            ug_name,
            property_group_id: selectedGroup.id,
            product_id: product.id,
            order,
          },
        };
        if (values.image && values.image.length > 0) {
          apiConfig.data.image = values["image"][0]["file_path"];
        }
        const res = await propertyApi.run(apiConfig);
        if (res.result) {
          getListData();
          setOpenPropertyModal(false);
        } else {
          message.error(res.message);
        }
      } catch (error) {
        console.log("onAddGroup -> error", error);
      } finally {
        setLoading(false);
      }
    }
  }

  function onSelectGroup(index, value) {
    let groupInfo = "";
    groupList.map((v) => {
      let childList = [];
      if (v.id == value) {
        groupInfo = v;
        v.product_property?.map((p) => {
          childList.push(p);
        });
        groupInfo["childList"] = childList;
      }
    });
    let newGroups = groups;
    newGroups[index] = groupInfo;

    setGroups(newGroups);
    const newSelectedGroups = selectedGroups;
    newSelectedGroups.push(value);
    setSelectedGroups(newSelectedGroups);
  }

  function onDeleteProperty(id) {
    Modal.confirm({
      title: i18n.t("sales.deleteWarning"),
      okText: i18n.t("base.modal.okText"),
      cancelText: i18n.t("base.modal.cancelText"),
      onOk: async () => {
        const { token } = user;
        if (token) {
          const deleteApi = api.productProperty.deleteChild;
          const apiConfig = {
            token,
            path: { id },
          };
          try {
            const res = await deleteApi.run(apiConfig);
            if (res.result) {
              message.success(res.message);
              getListData();
            } else {
              message.error(res.message);
            }
          } catch (error) {
            console.log("onDeleteGroup -> error", error);
          }
        }
      },
    });
  }

  function onEditProperty(property, group, index) {
    group.index = index;
    console.log("🚀 ~ file: ProductPropertyForm.jsx:319 ~ onEditProperty ~ index:", index)
    setSelectedGroup(group);
    setSelectedProperty(property);
    
    setOpenPropertyModal(true);
    propertyForm.setFieldsValue({
      zh_name: property.zh_name,
      ug_name: property.ug_name,
      order: property.order,
      image: property.image.length > 0 ? property.image : null,
    });
  }

  function addNewGroup() {
    return (
      <Popover
        trigger="click"
        content={
          <Space direction="horizontal" align="center">
            <Form form={groupForm} onFinish={(values) => onAddGroup(values)}>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue, setFieldsValue }) => (
                  <Form.Item
                    name="zh_name"
                    rules={[
                      {
                        required: true,
                        message: i18n.t("sales.zhTitleWarning"),
                      },
                    ]}
                  >
                    <Input
                      placeholder={i18n.t("property.groupZh")}
                      addonAfter={
                        <TranslateBtn
                          context={getFieldValue("zh_name")}
                          onFinish={(value) =>
                            setFieldsValue({
                              ug_name: value,
                            })
                          }
                          from="zh"
                          to="ug"
                        />
                      }
                    />
                  </Form.Item>
                )}
              </Form.Item>
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue, setFieldsValue }) => (
                  <Form.Item
                    name="ug_name"
                    rules={[
                      {
                        required: true,
                        message: i18n.t("sales.ugTitleWarning"),
                      },
                    ]}
                  >
                    <Input
                      placeholder={i18n.t("property.groupUg")}
                      addonAfter={
                        <TranslateBtn
                          context={getFieldValue("ug_name")}
                          onFinish={(value) =>
                            setFieldsValue({
                              zh_name: value,
                            })
                          }
                          from="ug"
                          to="zh"
                        />
                      }
                    />
                  </Form.Item>
                )}
              </Form.Item>
              <Button loading={loading} disabled={loading} htmlType="submit">
                {i18n.t("property.submit")}
              </Button>
            </Form>
          </Space>
        }
      >
        <Button>{i18n.t("property.addGroup")}</Button>
      </Popover>
    );
  }

  if (!product || !product.id) return null;
  
  return (
    <>
      {groups.map((group, index) => {
        return (
          <Row
            key={index}
            className="ant-form-item ant-form-item-no-colon standard property-area"
          >
            <Col span={24} className="ant-form-item-control-wrapper standard">
              <Space
                direction="horizontal"
                size={12}
                style={{ marginLeft: 8, marginRight: 8 }}
              >
                {groupList.length > 0 && (
                  <Select
                    style={{ width: 200 }}
                    value={group?.id}
                    placeholder={i18n.t("property.selectGroup")}
                    onChange={(value) => {
                      onSelectGroup(index, value);
                      setCounter(counter + 1);
                    }}
                  >
                    {groupList.map((g) => (
                      <Option
                        key={g.id}
                        value={g.id}
                        disabled={selectedGroups.includes(g.id) ? true : false}
                      >
                        {i18n.getPropNameByLocale(g, "name")}
                      </Option>
                    ))}
                  </Select>
                )}
                {!group.id && addNewGroup()}
                <Button
                  className="delete-group"
                  icon={<DeleteOutlined />}
                  type="danger"
                  onClick={() => onDeleteGroup(group, index)}
                ></Button>
              </Space>
              <Spin spinning={loading}>
                <div className="properties">
                  {group?.childList?.length > 0 &&
                    group.childList.map((i, key) => {
                      return (
                        <Space
                          key={key}
                          style={{
                            marginRight: 8,
                            marginLeft: 8,
                            marginTop: 8,
                          }}
                          align="center"
                          className="property-item"
                        >
                          <Row className="name-wrapper">
                            <span className="name">
                              {i18n.getPropNameByLocale(i, "name")}
                            </span>
                            <Button
                              className="delete-property"
                              icon={<DeleteOutlined />}
                              type="danger"
                              onClick={() => onDeleteProperty(i.id)}
                            ></Button>
                            <Button
                              className="edit-property"
                              icon={<EditOutlined />}
                              type="danger"
                              onClick={() => onEditProperty(i, group, index)}
                            ></Button>
                          </Row>
                          {i.image && (
                            <Row>
                              <Image width={50} src={i.image} />
                            </Row>
                          )}
                        </Space>
                      );
                    })}
                </div>
              </Spin>
              <Space style={{ margin: "20px" }}>
                {group.id && (
                  <Button
                    onClick={() => {
                      group.index = index;
                      setSelectedProperty(null);
                      setSelectedGroup(group);
                      setOpenPropertyModal(true);
                    }}
                  >
                    {i18n.t("property.addChild")}
                  </Button>
                )}
              </Space>
            </Col>
          </Row>
        );
      })}
      {groups.length < 2 && (
        <Row className="">
          <Button
            type="primary"
            onClick={() => {
              if (groups.length > 2) {
                return;
              }
              let newGroups = groups;
              newGroups[groups.length] = {};

              setCounter(counter + 1);
              setGroups(newGroups);
              return;
            }}
          >
            {i18n.t("property.newGroup")}
          </Button>
        </Row>
      )}
      {groups.length > 0 && <PropertyTable groups={groups} skuList={skuList} />}
      <Modal
        title={i18n.t("property.addChild")}
        open={openPropertyModal}
        onCancel={() => {
          setOpenPropertyModal(false);
        }}
        onOk={() => {
          propertyForm.submit();
        }}
        okText={i18n.t("base.modal.okText")}
        cancelText={i18n.t("base.modal.cancelText")}
        width={480}
      >
        <Space direction="horizontal" align="center">
          <Form
            form={propertyForm}
            onFinish={(values) => {
              propertyForm.resetFields();
              if (selectedProperty) {
                onUpdateProperty(values);
              } else {
                onAddNewProperty(values);
              }
            }}
          >
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldsValue }) => (
                <Form.Item
                  name="zh_name"
                  rules={[
                    {
                      required: true,
                      message: i18n.t("sales.zhTitleWarning"),
                    },
                  ]}
                >
                  <Input
                    placeholder={i18n.t("property.groupZh")}
                    addonAfter={
                      <TranslateBtn
                        context={getFieldValue("zh_name")}
                        onFinish={(value) =>
                          setFieldsValue({
                            ug_name: value,
                          })
                        }
                        from="zh"
                        to="ug"
                      />
                    }
                  />
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldsValue }) => (
                <Form.Item
                  name="ug_name"
                  rules={[
                    {
                      required: true,
                      message: i18n.t("sales.ugTitleWarning"),
                    },
                  ]}
                >
                  <Input
                    placeholder={i18n.t("property.groupUg")}
                    addonAfter={
                      <TranslateBtn
                        context={getFieldValue("ug_name")}
                        onFinish={(value) =>
                          setFieldsValue({
                            zh_name: value,
                          })
                        }
                        from="ug"
                        to="zh"
                      />
                    }
                  />
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item name="order">
              <Input placeholder={i18n.t("common.orderDescription")} />
                          </Form.Item>
            {selectedGroup?.index === 0 && (
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue, setFieldsValue }) => {
                  const images = getFieldValue("image") ?  [getFieldValue("image")] : [];
                  return (
                  <Form.Item name="image">
                    <ImageUpload 
                    
                    setSelectedImages={(image) => {
                      setFieldsValue({
                        image,
                      });
                      
                    }}
                    images={images}
                    />
                                      </Form.Item>
                )}}
              </Form.Item>
            )}
            <Button loading={loading} disabled={loading} htmlType="submit">
              {i18n.t("property.submit")}
            </Button>
          </Form>
        </Space>
      </Modal>
    </>
  );
}

export default connect((state) => ({ user: getUserInfo(state) }))(
  ProductPropertyForm
);
