import { Card, Image, Tag } from "antd";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Locale } from "../../../utils";
import { getOne } from "../../../utils/http/dataProvider";
import { getUserInfo } from "../../../utils/redux/reducers/User";
import "./SimpleCourseInfo.scss";
const i18n = Locale.getInstance();

const SimpleCourseInfo = ({ courseId }) => {
  const user = useSelector((state) => getUserInfo(state));
  const { data: course } = useQuery(
    ["course-detail", user.token],
    () => getOne("courses", { id: courseId, token: user.token }),
    {
      enabled: !!courseId,
    }
  );

  let labelColor = "magenta";
  if (course?.type === 0) {
    labelColor = "cyan";
  } else if (course?.type === 1) {
    labelColor = "geekblue";
  }
  return (
    <Card className="course">
      <div className="cover">
        <Image width={200} src={i18n.getPropNameByLocale(course, "cover")} />
      </div>
      <div className="name-price">
        <span>
          {i18n.getPropNameByLocale(course, "name")}
          <Tag className="label" color={labelColor}>
            {course?.type_label}
          </Tag>
        </span>

        <span className="price">{course?.price}</span>
      </div>
    </Card>
  );
};

export default SimpleCourseInfo;
