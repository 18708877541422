import React from "react";

import { LeftOutlined } from "@ant-design/icons";

import {
  Layout, // Steps
  Divider,
  Button,
} from "antd";

import Base from "../Base";

//redux
import { connect } from "react-redux";
import { getUserInfo } from "../../utils/redux/reducers/User";

class FinanceBillDetail extends Base {
  static SettlementTypes = ["financeAccount.settlementTypes.cycle"];

  /* lifcyle methods */

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,

      data: null,
      loadingData: null,
    };
  }

  componentDidMount() {
    super.componentDidMount();

    if (this.getUser()) {
      this.showMessage(
        this.i18n.t("financeBillDetail.loadingData"),
        Base.Message.loading
      );
      this.loadData();
    }
  }

  /* render methods */

  render() {
    return (
      <Layout id="route-finance-bill-detail">
        {this._renderHeader()}
        {this._renderBillInfo()}
        {/* {this._renderBillProgress()} */}
      </Layout>
    );
  }

  _renderHeader() {
    return (
      <div className="header-wrap">
        <Button onClick={this.onHeaderBackClick}>
          <LeftOutlined />
          {this.i18n.t("financeBillDetail.headerBack")}
        </Button>
        <Divider type="vertical" />
        <span className="title">
          {this.i18n.t("financeBillDetail.headerTitle")}
        </span>
      </div>
    );
  }

  _renderBillInfo() {
    let data = this.state.data;
    if (data) {
      let accountInfo = data.account_info;
      let record = data.record;
      if (accountInfo && record) {
        return (
          <div className="info-wrap">
            <div className="info-row">
              <span className="label">
                {this.i18n.t("financeBillDetail.billAmount")}
              </span>
              <span className="value">
                {this.i18n.t("financeBillDetail.billAmountValue", {
                  amount: this.util.getFormattedPrice(
                    record.total_merchant_income
                  ),
                })}
              </span>
            </div>
            <div className="info-row">
              <span className="label">
                {this.i18n.t("financeBillDetail.billAccount")}
              </span>
              <span className="value">
                {`${accountInfo.bank_name} ${accountInfo.bank_account}`}
              </span>
            </div>
            <div className="info-row">
              <span className="label">
                {this.i18n.t("financeBillDetail.billType")}
              </span>
              <span className="value">
                {this.i18n.t(
                  FinanceBillDetail.SettlementTypes[accountInfo.settlement_type]
                )}
              </span>
            </div>
            <div className="info-row">
              <span className="label">
                {this.i18n.t("financeBillDetail.billTime")}
              </span>
              <span className="value">{record.sign_date}</span>
            </div>
            {/* <div className="info-row">
							<span className="label">流水单号</span>
							<span className="value">201805230045</span>
						</div> */}
            {/* <div className="info-row">
							<span className="label">备注</span>
							<span className="value">布鲁街周期结算</span>
						</div> */}
          </div>
        );
      } else {
        return (
          <div className="info-wrap">
            <div className="info-message">
              {this.i18n.t("financeBillDetail.loadingEmpty")}
            </div>
          </div>
        );
      }
    } else {
      if (this.state.loadingData) {
        return (
          <div className="info-wrap">
            <div className="info-message">
              {this.i18n.t("financeBillDetail.loadingData")}
            </div>
          </div>
        );
      }

      return (
        <div className="info-wrap">
          <div className="info-message">
            {this.i18n.t("financeBillDetail.loadingEmpty")}
          </div>
        </div>
      );
    }
  }

  // _renderBillProgress() {
  // 	let data = this.state.data;
  // 	let record = data ? data.record : null;
  // 	if (record) {
  // 		return (
  // 			<div className="progress-wrap">
  // 				<div className="title">结算账单进度</div>
  // 				<div className="content">
  // 					<Steps size="small" progressDot={true} current={2}>
  // 						<Steps.Step title="结算开始" description={record.sign_date} />
  // 						<Steps.Step title="处理中" description={record.sign_date} />
  // 						<Steps.Step title="已到账" description={record.sign_date} />
  // 					</Steps>
  // 				</div>
  // 			</div>
  // 		);
  // 	} else {
  // 		return null;
  // 	}
  // }

  /* user methods */

  onHeaderBackClick = () => {
    this.props.history.goBack();
  };

  /* server methods */

  async loadData() {
    if (this.loadingData) return;

    let id = this.getRouterQueryValueByKey(
      "billId",
      Base.QueryValueType.Integer
    );
    if (isNaN(id) || id <= 0) return;

    let token = this.getUserToken();
    if (!token) return;

    let api = this.api.checkout.checkoutShow;
    let apiConfig = {
      token: token,
      path: {
        id: id,
      },
    };

    this.setState({ loadingData: true });
    try {
      let res = await api.run(apiConfig);

      if (!this.mounted) return;

      if (res.result) {
        this.setState({ data: res.data });
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;
      this.setState({ loadingData: false });
    }
  }
}

export default connect((state) => {
  return {
    user: getUserInfo(state),
  };
}, {})(FinanceBillDetail);
