import {
  Button,
  Card,
  Form,
  Input,
  Spin,
  DatePicker,
  Switch,
  Radio,
Select,
} from "antd";
import { Locale } from "../../../utils";
import {
  handleMutationError,
  showNotificationWithIcon,
} from "../../../utils/helpers/helpers";
import { BreadCrumb } from "../../../components/main/LayoutComponents";
import ImageUpload from "../../../components/media/ImageUpload";
import TranslateBtn from "../../../components/product/TranslateBtn";
import SlateRichTextEditor from "../../../components/editor/SlateRichTextEditor";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  create,
  getList,
  getOne,
  update,
} from "../../../utils/http/dataProvider";
import { useParams, useHistory, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { getUserInfo } from "../../../utils/redux/reducers/User";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;
const i18n = Locale.getInstance();

const EditActivity = () => {
  const [actForm] = Form.useForm();
  const { actId } = useParams();
  const history = useHistory();
  const user = useSelector((state) => getUserInfo(state));
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);

  const options = [];
  const { data: qrcodes } = useQuery(
    ["lottery-qrcode", user.token],
    () =>
      getList("lottery-qrcode", {
        pagination: {
          perPage: 100,
          page: 1,
        },
        sort: {
          field: "id",
          order: "desc",
        },
        token: user.token,
      }),
    { retry: 1 }
  );

  const { data: actData } = useQuery(
    ["lottery-qrcode-activity", actId, user.token],
    () =>
      getOne("lottery-qrcode-activity", {
        id: actId,
        token: user.token,
      }),
    { retry: 1, enabled: !isNaN(actId) }
  );

  useEffect(() => {
    if (actData) {
      const {
        id,
        created_at,
        updated_at,
        deleted_at,
        details,
        start_time,
        end_time,
        zh_image,
        ug_image,
        ...rest
      } = actData;
      const range = [dayjs(start_time), dayjs(end_time)];
      const qrcode_id = details?details.map(v=>v.qrcode_id):[];
      actForm.setFieldsValue({
        ...rest,
        range,
        qrcode_id,
        zh_image: zh_image ? [zh_image] : [],
        ug_image: ug_image ? [ug_image] : [],
      });
    }
  }, [actData]);

  const addCourseMutation = useMutation(create, {
    onSuccess: () => {
      queryClient.invalidateQueries("lottery-qrcode-activity");
      showNotificationWithIcon(i18n.t("media.saveFinished"), "success");
      setLoading(false);
    },
    onError: (error) => {
      handleMutationError(error);
      setLoading(false);
    },
  });

  const updateCourseMutation = useMutation(update, {
    onSuccess: () => {
      queryClient.invalidateQueries("lottery-qrcode-activity");
      showNotificationWithIcon(i18n.t("media.saveFinished"), "success");
      setLoading(false);
    },
    onError: (error) => {
      handleMutationError(error);
      setLoading(false);
    },
  });

  const onFinish = (values) => {
    if (values.ug_image && values.ug_image.length > 0) {
      values["ug_image"] = values["ug_image"][0]["file_path"];
    }
    if (values.zh_image && values.zh_image.length > 0) {
      values["zh_image"] = values["zh_image"][0]["file_path"];
    }

    const { range } = values;
    values["start_time"] = range[0].format("YYYY-MM-DD HH:mm:ss");
    values["end_time"] = range[1].format("YYYY-MM-DD HH:mm:ss");
    delete values["range"];

    setLoading(true);

    const params = {
      data: values,
      token: user.token,
    };

    if (actId !== "add") {
      updateCourseMutation.mutate({
        resource: "lottery-qrcode-activity",
        params: { ...params, id: actId },
      });
    } else {
      addCourseMutation.mutate({ resource: "lottery-qrcode-activity", params });
    }

    history.goBack();
  };

  const onFinishFailed = (errorInfo) => {
    setLoading(false);
    showNotificationWithIcon(errorInfo);
  };

  const breadCrumbData = [
    {
      href: "/main/lottery-qrcode/activity",
      title: <span>{i18n.t("qrcode.activity")}</span>,
    },
    {
      title: (
        <span>
          {actId !== "add"
            ? i18n.t("sales.activity.edit")
            : i18n.t("sales.activity.add")}
        </span>
      ),
    },
  ];
  console.log("🚀 ~ file: EditAct.jsx:173 ~ EditActivity ~ actData:", actData);
  return (
    <div>
      <BreadCrumb data={breadCrumbData} />
      <Spin spinning={loading}>
        <Form
          form={actForm}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Card>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldsValue }) => (
                <Form.Item
                  shouldUpdate
                  name="ug_name"
                  label={i18n.t("course.ugName")}
                  rules={[
                    {
                      required: true,
                      message: i18n.t("productEdit.standardRequired"),
                    },
                  ]}
                >
                  <Input
                    addonAfter={
                      <TranslateBtn
                        context={getFieldValue("ug_name")}
                        onFinish={(value) =>
                          setFieldsValue({
                            zh_name: value,
                          })
                        }
                        from="ug"
                        to="zh"
                      />
                    }
                  />
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldsValue }) => (
                <Form.Item
                  name="zh_name"
                  label={i18n.t("course.zhName")}
                  rules={[
                    {
                      required: true,
                      message: i18n.t("productEdit.standardRequired"),
                    },
                  ]}
                >
                  <Input
                    addonAfter={
                      <TranslateBtn
                        context={getFieldValue("zh_name")}
                        onFinish={(value) =>
                          setFieldsValue({
                            ug_name: value,
                          })
                        }
                        from="zh"
                        to="ug"
                      />
                    }
                  />
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item
              name="range"
              label={i18n.t("home.chart.dateAlias")}
              rules={[
                {
                  required: true,
                  message: i18n.t("productEdit.standardRequired"),
                },
              ]}
            >
              <RangePicker showTime />
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldsValue }) => (
                <Form.Item
                  name="ug_image"
                  label={i18n.t("sales.ad.ugImg")}
                  rules={[
                    {
                      required: true,
                      message: i18n.t("productEdit.standardRequired"),
                    },
                  ]}
                >
                  <ImageUpload
                    images={getFieldValue("ug_image")}
                    setSelectedImages={(image) => {
                      setFieldsValue({
                        ug_image: image,
                      });
                    }}
                  />
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldsValue }) => (
                <Form.Item
                  name="zh_image"
                  label={i18n.t("sales.ad.zhImg")}
                  rules={[
                    {
                      required: true,
                      message: i18n.t("productEdit.standardRequired"),
                    },
                  ]}
                >
                  <ImageUpload
                    images={getFieldValue("zh_image")}
                    setSelectedImages={(image) => {
                      setFieldsValue({
                        zh_image: image,
                      });
                    }}
                  />
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item
              name="points"
              label={i18n.t("member.points")}
              rules={[
                {
                  required: true,
                  message: i18n.t("productEdit.standardRequired"),
                },
              ]}
            >
              <Input style={{ width: 120 }} />
            </Form.Item>
            <Form.Item
              name="status"
              label={i18n.t("commissionList.status")}
              valuePropName="checked"
            >
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
            
            <Form.Item name="customer_type" label={i18n.t("qrcode.customer")}>
              <Radio.Group>
                <Radio value={0}>{i18n.t("qrcode.client")}</Radio>
                <Radio value={1}>{i18n.t("qrcode.shop")}</Radio>
              </Radio.Group>
            </Form.Item>


            <Form.Item name="type" label={i18n.t("qrcode.type")}>
              <Radio.Group>
                <Radio value={0}>{i18n.t("lottery.title")}</Radio>
                <Radio value={1}>{i18n.t("redPacketActivity.title")}</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item label={i18n.t("qrcode.selectQrcode")}>
              <Form.Item noStyle name="qrcode_id">
                <Select style={{ width: 300 }}  mode="multiple">
                  {qrcodes?.data?.map((qrcode) => {
                    return (
                      <Select.Option key={qrcode.id} value={qrcode.id}>
                        {i18n.getPropNameByLocale(qrcode, "name")}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Button
                onClick={() => {
                  queryClient.invalidateQueries("lottery-qrcode");
                }}
                type="link"
              >
                {i18n.t("common.refresh")}
              </Button>
              <Link to="/main/lottery-qrcode/list" target="_blank">
                {`${i18n.t("qrcode.goToQrcodes")} >>`}
              </Link>
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {({ setFieldsValue }) => {
                return (
                  <Form.Item
                    name="zh_description"
                    label={i18n.t("course.teacher.zhDescription")}
                    initialValue={actData?.zh_description}
                  >
                    <SlateRichTextEditor
                      name="zh_description"
                      setFieldsValue={(value) =>
                        setFieldsValue({
                          zh_description: value,
                        })
                      }
                      initialValue={
                        actData?.zh_description ? actData?.zh_description : null
                      }
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>

            <Form.Item noStyle shouldUpdate>
              {({ setFieldsValue }) => (
                <Form.Item
                  name="ug_description"
                  label={i18n.t("course.teacher.ugDescription")}
                  initialValue={actData?.ug_description}
                >
                  <SlateRichTextEditor
                    name="ug_description"
                    setFieldsValue={(value) =>
                      setFieldsValue({
                        ug_description: value,
                      })
                    }
                    initialValue={actData ? actData?.ug_description : null}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Card>
          <Form.Item className="submit">
            <Button type="primary" htmlType="submit">
              {i18n.t("productEdit.saveAndBack")}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default EditActivity;
