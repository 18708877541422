import React from "react";

import { MinusOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";

import {
  Modal,
  Layout,
  Select,
  Input,
  InputNumber,
  Button,
  Divider,
  Row,
  Col,
  Form,
  Upload,
} from "antd";

import Base from "../Base";

const Tracks = [
  {
    id: "7",
    pn_name: "yunda",
    zh_name: "品骏快递",
    ug_name: "پىنجۈن يوللانمىسى",
  },
  {
    id: "1",
    pn_name: "youzheng",
    zh_name: "邮政快递",
    ug_name: "پوچتا يوللانمىسى",
  },
  {
    id: "2",
    pn_name: "shunfeng",
    zh_name: "顺丰快递",
    ug_name: "شۈنفېڭ يوللانمىسى",
  },
  {
    id: "3",
    pn_name: "yuantong",
    zh_name: "圆通快递",
    ug_name: "يۈەنتوڭ يوللانمىسى",
  },
  {
    id: "4",
    pn_name: "zhongtong",
    zh_name: "中通快递",
    ug_name: "جوڭتوڭ يوللانمىسى",
  },
  {
    id: "5",
    pn_name: "shentong",
    zh_name: "申通快递",
    ug_name: "شېنتوڭ يوللانمىسى",
  },
  {
    id: "6",
    pn_name: "yunda",
    zh_name: "韵达快递",
    ug_name: "يۈندا يوللانمىسى",
  },
  {
    id: "8",
    pn_name: "self",
    zh_name: "自配送",
    ug_name: "ئۆزى يەتكۈزۈش",
  },
  {
    id: "9",
    pn_name: "debang",
    zh_name: "德邦",
    ug_name: "دېباڭ",
  },
  {
    id: "10",
    pn_name: "jingdong",
    zh_name: "京东快递",
    ug_name: "جىڭدۇڭ",
  },
];

class OrderSendModal extends Base {
  formRef = React.createRef();
  state = {
    forms: this.onInitState(),
  };

  render() {
    const { forms } = this.state;
    return (
      <Modal
        className="route-order-list-send-modal"
        title={this.getTitle()}
        onCancel={this.onCancel}
        onOk={this.onConfirm}
        confirmLoading={this.getPropConfirmLoading()}
        open
      >
        <Layout className="order-send-wrap">
          {forms.map((item) => (
            <Form
              ref={this.formRef}
              key={item}
              initialValues={{
                [`tracking_name@${item}`]: this.getDefaultTrackName(item),
                [`tracking_number@${item}`]: this.getDefaultTrackNumber(item),
                [`tracking_reason@${item}`]: this.getDefaultTrackReason(item),
                [`pack_weight@${item}`]: this.getDefaultShipWeight(item),
                [`shipping_price@${item}`]: this.getDefaultShipPrice(item),
                [`tracking_image@${item}`]: this.getDefaultshipImg(item),
              }}
            >
              {this._renderTrackingName(item)}
              {this._renderTrackingNumber(item)}
              {this._renderShippingWeight(item)}
              {this._renderShippingPrice(item)}
              {this._renderShippingReceipt(item)}
              {this.renderAddFormBtn(item)}
            </Form>
          ))}
        </Layout>
      </Modal>
    );
  }

  _renderTrackingName(formIndex) {
    const item = {
      colon: false,
      label: this.i18n.t("order.sendTrackName"),
      labelCol: { span: 4 },
      wrapperCol: { span: 8 },
      required: true,
    };
    return (
      <Form.Item
        {...item}
        name={`tracking_name@${formIndex}`}
        rules={[
          {
            required: true,
            message: this.i18n.t("order.sendTrackNameHint"),
          },
        ]}
      >
        <Select
          placeholder={this.i18n.t("order.sendTrackNameHint")}
          filterOption={this.onFilterTrackName}
        >
          {Tracks.map((item, index) => {
            return (
              <Select.Option
                key={`tarack-item-${index}`}
                data={item}
                value={item.id}
              >
                {this.i18n.getPropNameByLocale(item, "name")}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    );
  }

  _renderTrackingNumber(formIndex) {
    const item = {
      colon: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 8 },
    };

    return (
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) =>
          getFieldValue(`tracking_name@${formIndex}`) === "8" ? (
            <Form.Item
              {...item}
              name={`tracking_reason@${formIndex}`}
              label={this.i18n.t("order.sendTrackReason")}
            >
              <Input.TextArea />
            </Form.Item>
          ) : (
            <Form.Item
              {...item}
              label={this.i18n.t("order.sendTrackNumber")}
              name={`tracking_number@${formIndex}`}
              rules={[
                {
                  required: true,
                  message: this.i18n.t("order.sendTrackNumberHint"),
                },
              ]}
            >
              <Input placeholder={this.i18n.t("order.sendTrackNumberHint")} />
            </Form.Item>
          )
        }
      </Form.Item>
    );
  }

  _renderShippingWeight(formIndex) {
    const item = {
      colon: false,
      label: this.i18n.t("order.sendShipWeight"),
      labelCol: { span: 4 },
      wrapperCol: { span: 8 },
    };
    return (
      <Form.Item
        {...item}
        name={`pack_weight@${formIndex}`}
        extra={
          <span className="unit">
            {this.i18n.t("order.sendShipWeightUnit")}
          </span>
        }
      >
        <InputNumber min={0} max={1000} step={0.5} />
      </Form.Item>
    );
  }

  _renderShippingPrice(formIndex) {
    const item = {
      colon: false,
      label: this.i18n.t("order.sendShipPrice"),
      labelCol: { span: 4 },
      wrapperCol: { span: 8 },
    };
    return (
      <Form.Item
        {...item}
        name={`shipping_price@${formIndex}`}
        extra={
          <span className="unit">{this.i18n.t("order.sendShipPriceUnit")}</span>
        }
      >
        <InputNumber min={0} max={1000} />
      </Form.Item>
    );
  }

  _renderShippingReceipt(formIndex) {
    const { uploadImg } = this.props;
    const item = {
      colon: false,
      label: this.i18n.t("order.sendShipReceipt"),
      labelCol: { span: 4 },
      wrapperCol: { span: 8 },
    };
    return (
      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) => (
          <Form.Item
            {...item}
            name={`tracking_image@${formIndex}`}
            valuePropName="fileList"
            getValueFromEvent={(e) => this.util.normFile4Img(e)}
          >
            <Upload
              accept="image/*"
              multiple={false}
              listType="picture-card"
              customRequest={uploadImg}
            >
              {getFieldValue(`tracking_image@${formIndex}`) &&
                !getFieldValue(`tracking_image@${formIndex}`).length && (
                  <span>
                    <UploadOutlined /> {this.i18n.t("sales.upload")}
                  </span>
                )}
            </Upload>
          </Form.Item>
        )}
      </Form.Item>
    );
  }

  renderAddFormBtn = (formIndex) => {
    const { forms } = this.state;
    if (formIndex !== forms[forms.length - 1]) return <Divider />;
    return (
      <Row type="flex" gutter={20}>
        <Col span={4}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={this.onAddOtherForm}
          >
            {this.i18n.t("order.addForm")}
          </Button>
        </Col>
        <Col span={4}>
          {forms.length > 1 && (
            <Button
              type="danger"
              icon={<MinusOutlined />}
              onClick={this.onDeleteThisForm}
            >
              {this.i18n.t("order.deleteForm")}
            </Button>
          )}
        </Col>
      </Row>
    );
  };

  onAddOtherForm = () => {
    const { forms } = this.state;
    this.setState({
      forms: forms.concat(forms[forms.length - 1] + 1),
    });
  };

  onDeleteThisForm = () => {
    const { forms } = this.state;
    if (forms.length > 1) {
      forms.pop();
    } else {
      return;
    }
    this.setState({
      forms,
    });
  };

  onFilterTrackName = (input, option) => {
    let key = input.toLowerCase();

    if (option.props.data.pn_name.indexOf(key) >= 0) return true;
    else if (option.props.data.ug_name.indexOf(key) >= 0) return true;
    else if (option.props.data.zh_name.indexOf(key) >= 0) return true;

    return false;
  };

  onCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  onConfirm = () => {
    this.formRef.current
      .validateFields()
      .then((values) => {
        if (this.props.onConfirm) {
          const { tracking_data } = this.props.order;
          const { forms } = this.state;
          const orderId = this.getPropOrder().id;

          const trackingData = [];
          forms.forEach((formIndex) => {
            const track = Tracks.find(
              (item) => item.id === values[`tracking_name@${formIndex}`]
            );
            if (track) {
              trackingData.push({
                id:
                  tracking_data && tracking_data[formIndex]
                    ? tracking_data[formIndex].id
                    : null,
                tracking_name: track.zh_name,
                pack_weight: (values[`pack_weight@${formIndex}`] *= 1000),
                shipping_price: (values[`shipping_price@${formIndex}`] *= 100),
                tracking_number: values[`tracking_number@${formIndex}`],
                tracking_reason: values[`tracking_reason@${formIndex}`],
                tracking_image: values[`tracking_image@${formIndex}`],
              });
            }
          });

          const isEdit = this.isEditMode();
          this.props.onConfirm({ isEdit, orderId, trackingData });
        }
      })
      .catch((err) => {
        console.log("OrderSendModal -> onConfirm -> err", err);
      });
  };

  getPropOrder() {
    return this.props.order;
  }

  getPropConfirmLoading() {
    return this.props.confirmLoading;
  }

  getOrderShipInfo() {
    let order = this.getPropOrder();
    return order.tracking_data;
  }

  isEditMode() {
    const order = this.getPropOrder();
    return order.status === 2;
  }

  getTitle() {
    let title = this.i18n.t("order.sendTitle");
    if (this.isEditMode()) {
      title = this.i18n.t("order.sendChangeTitle");
    }
    return title;
  }

  getDefaultTrackName(formIndex) {
    let defaultId = Tracks[1].id;

    let trackData = this.getOrderShipInfo()
      ? this.getOrderShipInfo()[formIndex]
      : null;
    if (!trackData) {
      return defaultId;
    }
    let track = Tracks.find(
      (item) =>
        item.zh_name === trackData.tracking_name ||
        item.ug_name === trackData.tracking_name
    );
    if (track) defaultId = track.id;

    return defaultId;
  }

  getDefaultTrackNumber(formIndex) {
    let defaultNum = "";
    const data = this.getOrderShipInfo()
      ? this.getOrderShipInfo()[formIndex]
      : null;
    if (!data) return defaultNum;
    defaultNum = data.tracking_number;
    return defaultNum;
  }

  getDefaultTrackReason(formIndex) {
    let defaultReason = "";
    const data = this.getOrderShipInfo()
      ? this.getOrderShipInfo()[formIndex]
      : null;
    if (!data) return defaultReason;
    defaultReason = data.tracking_reason;
    return defaultReason;
  }

  getDefaultShipWeight(formIndex) {
    let defaultWeight = 0;

    let trackData = this.getOrderShipInfo()
      ? this.getOrderShipInfo()[formIndex]
      : null;
    if (!trackData) return defaultWeight;
    defaultWeight = Number(trackData.pack_weight || "0") / 1000;

    return defaultWeight;
  }

  getDefaultShipPrice(formIndex) {
    let defaultPrice = 0;

    let trackData = this.getOrderShipInfo()
      ? this.getOrderShipInfo()[formIndex]
      : null;
    if (!trackData) return defaultPrice;
    defaultPrice = Number(trackData.shipping_price || "0") / 100;

    return defaultPrice;
  }

  getDefaultshipImg(formIndex) {
    let defaultImg = [];
    let trackData = this.getOrderShipInfo()
      ? this.getOrderShipInfo()[formIndex]
      : null;
    if (!trackData) return defaultImg;
    defaultImg.push({
      uid: -1,
      name: "image",
      status: "done",
      url:
        trackData && trackData.tracking_image
          ? trackData.tracking_image.url
          : "",
      path:
        trackData && trackData.tracking_image
          ? trackData.tracking_image.path
          : "",
    });
    return defaultImg;
  }

  onInitState() {
    const order = this.getPropOrder();
    if (order.tracking_data && order.tracking_data.length > 0) {
      return order.tracking_data.map((item, index) => index);
    } else {
      return [0];
    }
  }
}

export default OrderSendModal;
