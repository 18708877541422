import { stringify } from "qs";
import newRequest, { buildBaseURL } from "../../utils/http/newRuequest"

export const getMediaList = async (params) => {
    const { page, perPage } = params.pagination;
    let query = {
        page,
        limit: perPage,
    };

    if (params.filter && Object.keys(params.filter).length > 0) {
        query = { ...query, ...params.filter }
    }

    const url = buildBaseURL('v1', 'media') + `merchant/list?${stringify(query)}`;
    const result = await newRequest.get(url, params.token);


    return {
        data: result.data.data ?? [],
        total: result.data.total ? result.total : 10,
    };
}


export const saveStorageSettings = async ({ data, token }) => {
    const url = buildBaseURL('v1', 'media') + `merchant/storage-settings`;
    const result = await newRequest.post(url, data, token);

    return {
        data: result.data ?? [],
        total: result.total ? result.total : 10,
    };
}


export const getStorageSettings = async ({ token }) => {
    const url = buildBaseURL('v1', 'media') + `merchant/storage-setting`;
    const result = await newRequest.get(url, token);

    return {
        data: result.data ?? [],
        total: result.total ? result.total : 10,
    };
}

export const deleteFile = async ({ id, token }) => {
    const url = buildBaseURL('v1', 'media') + `merchant/media/${id}`;
    const result = await newRequest.delete(url, token);
    if (!result.result) {
        throw new Error(result.message);
    }
    return {
        data: result.data ?? [],
        total: result.total ? result.total : 10,
    };
}


export const batchDeleteFiles = async ({ ids, token }) => {
    const url = buildBaseURL('v1', 'media') + `merchant/media/batch-delete`;
    const result = await newRequest.post(url, {
        ids
    }, token);
    if (!result.result) {
        throw new Error(result.message);
    }
    return {
        data: result.data ?? [],
        total: result.total ? result.total : 10,
    };
}
export const uploadFile = async ({ type, data, token, config }) => {
    let url = buildBaseURL('v1', 'media') + `merchant/image-upload`;
    if (type === 'audio') {
        url = buildBaseURL('v1', 'media') + `merchant/audio-upload`;
    } else if (type === 'video') {
        url = buildBaseURL('v1', 'media') + `merchant/video-upload`;
    }
    
    const result = await newRequest.upload(url, data.files, token, type, data.folderId, config);
    if (!result.result) {
        throw new Error(result.message);
    }
    return {
        data: result.data ?? [],
        total: result.total ? result.total : 10,
    };
}

