import React from "react";
import {
  List,
  Button,
  Space,
  Radio,
  Divider,
  Tag,
  Image,
  Avatar,
  Tooltip,
} from "antd";
import moment from "dayjs";
import { connect } from "react-redux";

import { getUserInfo } from "../../../utils/redux/reducers/User";
import Base from "../../Base";
import { FILTER_STATUS } from "../../../constants";

const FLASHACTIVITY = {
  INACTIVATED: 0,
  ACTIVATED: 1,
};

class FlashActivityList extends Base {
  constructor() {
    super();
    this.state = {
      data: { data: [] },
      loading: false,
      filterStatus: FILTER_STATUS.ALL.value,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.onGetDataList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.filterStatus !== this.state.filterStatus) {
      this.onGetDataList();
    }
  }

  async onGetDataList(page = 1) {
    const api = this.api.flash.list;

    if (this.mounted) {
      this.setState({ loading: true });
      try {
        const token = this.getUserToken();
        const { filterStatus } = this.state;
        if (token) {
          const apiConfig = {
            token,
            query: {
              page,
              count: 10,
              "filter[status]": filterStatus,
            },
          };
          const res = await api.run(apiConfig);
          if (res.result) {
            this.setState({
              data: res.data,
            });
          } else {
            this.showMessage(res.message);
          }
        }
      } catch (error) {
        this.handleApiError(api, error);
      } finally {
        this.setState({ loading: false });
      }
    }
  }

  onDeleteItem(id) {
    this.showModal(
      this.i18n.t("sales.deleteWarning"),
      Base.Modal.confirm,
      async () => {
        const api = this.api.flash.delete;
        if (this.mounted) {
          try {
            const token = this.getUserToken();
            if (token) {
              const apiConfig = {
                token,
                path: {
                  id,
                },
              };
              const res = await api.run(apiConfig);
              if (res.result) {
                this.onGetDataList();
              } else {
                this.showMessage(res.message, Base.Message.warning);
              }
            }
          } catch (error) {
            this.handleApiError(api, error);
          } finally {
            this.setState({ loading: false });
          }
        }
      }
    );
  }

  onGo2AddProduct(item) {
    this.props.history.push({
      pathname: "/main/sales/flash-edit",
      state: {
        item,
      },
    });
  }

  getFormattedDateString(date) {
    if (moment.isDayjs(date)) {
      return date.format("YYYY-MM-DD HH:mm:ss");
    }
    if (typeof date === "string") {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    }
    return null;
  }
  getStatusColor(gStatus) {
    if (gStatus === FLASHACTIVITY.ACTIVATED) return "#87d068";
    if (gStatus === FLASHACTIVITY.INACTIVATED) return "#ccc";
  }
  render() {
    const { data, loading, filterStatus } = this.state;
    return (
      <List
        bordered
        size="large"
        style={{ backgroundColor: "#fff" }}
        header={
          <Space direction="horizontal" wrap>
            <Button type="primary" onClick={() => this.onGo2AddProduct()}>
              {this.i18n.t("sales.activity.add")}
            </Button>
            <Radio.Group
              style={{ marginRight: 20, marginLeft: 20 }}
              onChange={(e) => {
                this.setState({ filterStatus: e.target.value });
              }}
              value={filterStatus}
            >
              <Radio value={FILTER_STATUS.ALL.value}>
                {FILTER_STATUS.ALL.name}
              </Radio>
              <Radio value={FILTER_STATUS.OPENED.value}>
                {FILTER_STATUS.OPENED.name}
              </Radio>
              <Radio value={FILTER_STATUS.CLOSED.value}>
                {FILTER_STATUS.CLOSED.name}
              </Radio>
            </Radio.Group>
          </Space>
        }
        itemLayout={window.innerWidth < 600 ? "vertical" : "horizontal"}
        dataSource={data.data}
        loading={loading}
        pagination={{
          current: data.current_page,
          total: data.total,
          pageSize: 10,
          showQuickJumper: true,
          onChange: (page) => this.onGetDataList(page),
        }}
        renderItem={(item) => (
          <List.Item
            key={item.id}
            style={{
              backgroundColor: item.status === 1 ? "#ffffff" : "#f1f1f1",
            }}
            actions={[
              <Button
                key="list-loadmore-edit"
                onClick={() => this.onGo2AddProduct(item)}
                type="link"
              >
                {this.i18n.t("sales.activity.edit")}
              </Button>,
              <Button
                key="list-loadmore-more"
                onClick={() => this.onDeleteItem(item.id)}
                type="link"
                danger
              >
                {this.i18n.t("sales.activity.delete")}
              </Button>,
            ]}
          >
            <List.Item.Meta
              title={
                <Space direction="horizontal" wrap>
                  {`#${item.id}`}
                  <Tag
                    style={{
                      fontFamily: this.i18n.isLocaleRTL() ? "Alp Ekran" : "",
                    }}
                    color={this.getStatusColor(item.status)}
                  >
                    {item.status === FLASHACTIVITY.ACTIVATED
                      ? this.i18n.t("article.opened")
                      : this.i18n.t("article.closed")}
                  </Tag>
                  <Divider type="vertical" />
                  <Space direction="vertical">
                    <div>
                      {`${this.i18n.t(
                        "sales.activity.start_date"
                      )} ${this.getFormattedDateString(
                        item.start_time
                      )} - ${this.i18n.t(
                        "sales.activity.end_date"
                      )} ${this.getFormattedDateString(item.end_time)}`}
                    </div>
                    <Avatar.Group>
                      {item?.products?.map((product, index) => {
                        return (
                          <Tooltip
                            key={index}
                            title={this.i18n.t("sales.priceLabel", {
                              original_price: this.util.getPrice(product.price),
                              price: this.util.getPrice(
                                product.discounted_price
                              ),
                            })}
                            placement="top"
                          >
                            <Avatar size="large" src={product.image} />
                          </Tooltip>
                        );
                      })}
                    </Avatar.Group>
                  </Space>
                </Space>
              }
            />
          </List.Item>
        )}
      />
    );
  }
}

export default connect((state) => {
  return {
    user: getUserInfo(state),
  };
})(FlashActivityList);
