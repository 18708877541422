import { Form, Input, Spin, Switch } from "antd";
import React from "react";
import { useForm } from "antd/lib/form/Form";
import { Locale } from "../../../utils";

import "./AddOrEditCategory.scss";
import TranslateBtn from "../../../components/product/TranslateBtn";
import LinkSelector from "../../../components/linkSelector/LinkSelector";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

const i18n = Locale.getInstance();

const AddOrEditCategory = React.forwardRef(({ category, loading }, ref) => {
  const [categoryForm] = useForm();

  const updateFields = (item, value) => {
    const formDispatch = categoryForm.getInternalHooks(
      "RC_FORM_INTERNAL_HOOKS"
    ).dispatch;
    formDispatch({
      type: "updateValue",
      namePath: item,
      value,
    });
  };

  if (loading) {
    return <Spin spinning={loading} />;
  }

  return (
    <>
      <Form
        ref={ref}
        form={categoryForm}
        className="category-form"
        name="basic"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        autoComplete="off"
      >
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldsValue }) => (
            <Form.Item
              shouldUpdate
              name="parent_course_category_id"
              label={i18n.t("course.category.ugName")}
            >
              <LinkSelector
                items={["courseCategories"]}
                selectedLink={
                  category?.parentCategory
                    ? {
                        key: item.id,
                        ug_name: `دەرس تۈرى~${item.ug_name}`,
                        zh_name: `课程分类~${item.zh_name}`,
                        url: `/course/category/index?courseId=${item.id}`,
                      }
                    : false
                }
                setSelectedLink={(link) => {
                  setFieldsValue({
                    parent_course_category_id: link.key,
                  });
                }}
              />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldsValue }) => (
            <Form.Item
              shouldUpdate
              name="ug_name"
              label={i18n.t("course.category.ugName")}
              rules={[
                {
                  required: true,
                  message: i18n.t("productEdit.standardRequired"),
                },
              ]}
            >
              <Input
                addonAfter={
                  <TranslateBtn
                    context={getFieldValue("ug_name")}
                    onFinish={(value) => updateFields("zh_name", value)}
                    from="ug"
                    to="zh"
                  />
                }
              />
            </Form.Item>
          )}
        </Form.Item>

        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldsValue }) => (
            <Form.Item
              name="zh_name"
              label={i18n.t("course.category.zhName")}
              rules={[
                {
                  required: true,
                  message: i18n.t("productEdit.standardRequired"),
                },
              ]}
            >
              <Input
                addonAfter={
                  <TranslateBtn
                    context={getFieldValue("zh_name")}
                    onFinish={(value) => updateFields("ug_name", value)}
                    from="zh"
                    to="ug"
                  />
                }
              />
            </Form.Item>
          )}
        </Form.Item>

        <Form.Item
          name="order"
          label={i18n.t("shows.sortTypesTitle")}
          shouldUpdate
          rules={[
            {
              required: true,
              message: i18n.t("productEdit.standardRequired"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            return (
              <Form.Item
                name="status"
                label={i18n.t("common.statusLabel")}
                shouldUpdate
                valuePropName="checked"
                initialValue={getFieldValue("status")}
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item
          name="recommend"
          label={i18n.t("productEdit.suggest.on")}
          valuePropName="checked"
          getValueFromEvent={(e) => (e ? 1 : 0)}
        >
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </Form>
    </>
  );
});

export default AddOrEditCategory;
