import React from "react";

import { Layout, Carousel, Button, Pagination } from "antd";

import Base from "../Base";

class PromotionShop extends Base {
  static Promotions = [
    {
      key: "shop-ticket",
      name: "店内领券",
      description: "提升客单价",
      iconText: "券",
      iconColor: "#f87e2f",
    },
    {
      key: "new-user",
      name: "新客立减",
      description: "拉新",
      iconText: "新",
      iconColor: "#b978de",
    },
    {
      key: "demand-reduce",
      name: "满减活动",
      description: "引流&促销",
      iconText: "满",
      iconColor: "#ff5851",
    },
    {
      key: "discounted-product",
      name: "折扣商品",
      description: "引流&促销",
      iconText: "折",
      iconColor: "#b978de",
    },
    {
      key: "ship-price",
      name: "减配送费",
      description: "引流&促销",
      iconText: "减",
      iconColor: "#6152ab",
    },
  ];

  static Tabs = [
    { key: "current", name: "当前活动" },
    { key: "history", name: "历史活动" },
  ];

  /* render methods */

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,

      listTab: PromotionShop.Tabs[0],
    };
  }

  /* render methods */

  render() {
    return (
      <Layout id="route-promotion-shop">
        {this._renderHeaderSlider()}
        {this._renderPromotionGrid()}
        {this._renderPromotionList()}
      </Layout>
    );
  }

  _renderHeaderSlider() {
    return (
      <Carousel className="header-slider" autoplay={true}>
        <div>
          <h3>1</h3>
        </div>
        <div>
          <h3>2</h3>
        </div>
        <div>
          <h3>3</h3>
        </div>
        <div>
          <h3>4</h3>
        </div>
      </Carousel>
    );
  }

  _renderPromotionGrid() {
    return (
      <div className="grid-wrap">
        <div className="grid-title">创建活动</div>
        <div className="grid-content">
          {PromotionShop.Promotions.map((promotion, index) => {
            return (
              <div key={`grid-item-${index}`} className="grid-item-wrap">
                <div className="grid-item">
                  <span
                    className="item-icon"
                    style={{ backgroundColor: promotion.iconColor }}
                  >
                    {promotion.iconText}
                  </span>
                  <div className="item-info">
                    <span className="item-name">{promotion.name}</span>
                    <span className="item-description">
                      {promotion.description}
                    </span>
                  </div>
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => this.onPromotionCreateClick(promotion)}
                  >
                    立即创建
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  _renderPromotionList() {
    return (
      <div className="list-wrap">
        <div className="list-title">活动列表</div>
        <div className="list-content">
          {this._renderPromotionListTabs()}
          {this._renderPromotionListHeader()}
          {this._renderPromotionListItems()}
          {this._renderPromotionListPagination()}
        </div>
      </div>
    );
  }

  _renderPromotionListTabs() {
    return (
      <div className="list-tabs">
        {PromotionShop.Tabs.map((tab, index) => {
          let className =
            this.state.listTab.key === tab.key ? "primary" : "default";

          return (
            <Button
              key={`tab-${index}`}
              type={className}
              onClick={() => this.onListTabClick(tab)}
            >
              {tab.name}
            </Button>
          );
        })}
      </div>
    );
  }

  _renderPromotionListHeader() {
    return (
      <div className="list-header">
        <div className="name">活动名称</div>
        <div className="status">活动状态</div>
        <div className="time">活动时间</div>
        <div className="content">活动内容</div>
        <div className="actions">操作</div>
      </div>
    );
  }

  _renderPromotionListItems() {
    let data = [1, 2];
    if (this.isListTabHistory()) {
      data = [1, 2, 3, 4, 5, 6];
    }

    return (
      <div className="list-items">
        {data.map((item, index) => {
          let statusTextClass = "text";
          let statusText = "进行中";
          if (this.isListTabHistory()) {
            statusTextClass += " finish";
            statusText = "已结束";
          }

          return (
            <div key={`list-item-${index}`} className="list-header item">
              <div className="name">
                <span className="icon" style={{ backgroundColor: "#b978de" }}>
                  折
                </span>
                <span>折扣商品</span>
              </div>
              <div className="status">
                <span className={statusTextClass}>{statusText}</span>
                {this.isListTabHistory() ? null : <span>剩余6天</span>}
              </div>
              <div className="time">2018-05-20至2018-05-30</div>
              <div className="content">
                <span>1个商品参与</span>
                <span>总折扣价位23.00元</span>
              </div>
              <div className="actions">
                <Button type="text">查看更多</Button>
                <Button type="text">撤销活动</Button>
                <Button type="text">重新发起</Button>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  _renderPromotionListPagination() {
    if (this.isListTabHistory()) {
      return (
        <div className="list-pagination">
          <Pagination defaultCurrent={1} total={6} />
        </div>
      );
    }

    return null;
  }

  /* user methods */

  onPromotionCreateClick(promotion) {
    if (!promotion) return;

    switch (promotion.key) {
      case "shop-ticket": {
        this.props.history.push({
          pathname: "/main/promotion/shop-ticket",
        });

        break;
      }
      case "new-user": {
        this.props.history.push({
          pathname: "/main/promotion/new-user",
        });

        break;
      }
      case "demand-reduce": {
        this.props.history.push({
          pathname: "/main/promotion/demand-reduce",
        });

        break;
      }
      case "ship-price": {
        this.props.history.push({
          pathname: "/main/promotion/ship-price",
        });

        break;
      }
      default: {
        break;
      }
    }
  }

  onListTabClick(tab) {
    this.setState({ listTab: tab });
  }

  /* custom methods */

  isListTabCurrent() {
    return this.state.listTab.key === PromotionShop.Tabs[0].key;
  }

  isListTabHistory() {
    return this.state.listTab.key === PromotionShop.Tabs[1].key;
  }
}

export default PromotionShop;
