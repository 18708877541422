import {
  DownloadOutlined,
  InboxOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  List,
  message,
  Button,
  Modal,
  Input,
  Form,
  Radio,
  Upload,
  Space,
  Divider,
  Tag,
} from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ApiManager, Locale, Util } from "../../utils";
import { getUserInfo } from "../../utils/redux/reducers/User";
import Base from "../Base";

const api = ApiManager.getInstance();
const i18n = Locale.getInstance();
const util = Util.getInstance();
const base = Base.getInstance();
const COURSE = 2;
const LOTTERY = 1;
const STANDARD = 0;
const { Dragger } = Upload;

function WeappManagement() {
  const fetchData = useRef(() => {});
  const user = useSelector((state) => getUserInfo(state));
  const [loading, setLoading] = useState(false);
  const [showModal, setUploadModal] = useState(false);
  const [data, setListData] = useState({ data: [] });
  const [type, setType] = useState(0);
  const [form] = Form.useForm();

  fetchData.current = useCallback(
    async (page = 1) => {
      if (user.token) {
        const apiUrl = api.admin.list;
        const apiConfig = {
          token: user.token,
          query: { page, count: 10, type },
        };
        setLoading(true);
        try {
          const res = await apiUrl.run(apiConfig);
          if (res.result) {
            setListData(res.data);
          } else {
            message.warning(res.message);
          }
        } catch (error) {
          base.handleApiError(apiUrl, error);
        } finally {
          setLoading(false);
        }
      }
    },
    [user.token, type]
  );

  async function onUploadFile({ file, onError, onSuccess, uploadType }) {
    if (!file) onError("invalid file");
    const { token } = user;
    if (!token) onError("no user token");
    const uploadFiles = [{ file }];
    const apiUrl = api.common.uploadImages;
    const apiConfig = {
      token,
      uploadFiles,
      uploadType,
    };
    try {
      const res = await apiUrl.run(apiConfig);
      if (res.result) {
        onSuccess(res.data);
      } else {
        onError(res.message);
      }
    } catch (error) {
      onError(error);
    }
  }

  function onDeleteItem(id) {
    base.showModal(
      i18n.t("sales.deleteWarning"),
      Base.Modal.confirm,
      async () => {
        const apiUrl = api.admin.delete;
        const { token } = user;
        try {
          if (token) {
            const apiConfig = {
              token,
              path: {
                id,
              },
            };
            const res = await apiUrl.run(apiConfig);
            if (res.result) {
              base.showMessage(res.message, Base.Message.success);
              fetchData.current(data.current_page);
            } else {
              base.showMessage(res.message, Base.Message.warning);
            }
          }
        } catch (error) {
          base.handleApiError(api, error);
        }
      }
    );
  }

  const getDefaultFile = (fileUrl = "") => {
    if (fileUrl) {
      let file = {
        uid: -1,
        name: `file-${new Date().getTime()}`,
        status: "done",
        url: typeof fileUrl === "boolean" ? "" : fileUrl,
      };

      return [file];
    }
    return [];
  };

  function onFinish() {
    form
      .validateFields()
      .then(async (values) => {
        const { token } = user;
        if (token) {
          let apiUrl = api.admin.add;
          let apiConfig = {
            token,
            data: { ...values },
          };
          if (showModal && showModal.id) {
            apiUrl = api.admin.update;
            apiConfig.path = { id: showModal.id };
          }
          const { file } = values;
          if (file[0] && file[0].uid !== -1 && file[0].response) {
            apiConfig.data.path = file[0].response[0].path;
          }
          try {
            setLoading(true);
            const res = await apiUrl.run(apiConfig);
            if (res.result) {
              base.showMessage(res.message, Base.Message.success);
              setUploadModal(false);
              fetchData.current(data.current_page);
            } else {
              base.showModal(res.message, Base.Modal.warning);
            }
          } catch (error) {
            base.handleApiError(apiUrl, error);
          } finally {
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        console.log("onFinish -> error", error);
      });
  }

  useEffect(() => {
    fetchData.current();
  }, [fetchData, type]);

  useEffect(() => {
    if (showModal && showModal.id) {
      form.setFieldsValue({
        version: showModal.version,
        type: showModal.type,
        file: getDefaultFile(showModal.url),
      });
    }
  }, [showModal, form]);

  return (
    <>
      <List
        header={
          <Space direction="horizontal">
            <Button
              icon={<PlusOutlined />}
              onClick={() => {
                setUploadModal(true);
              }}
            >
              {i18n.t("weapp.add")}
            </Button>
            <Radio.Group
              options={[
                { value: 0, label: i18n.t("weapp.standard") },
                { value: 1, label: i18n.t("weapp.lottery") },
                { value: 2, label: i18n.t("weapp.course") },
              ]}
              onChange={(e) => {
                setType(e.target.value);
              }}
              value={type}
              optionType="button"
              buttonStyle="solid"
            />
          </Space>
        }
        bordered
        size="large"
        style={{ backgroundColor: "#fff" }}
        loading={loading}
        dataSource={data.data}
        pagination={{
          current: data.current_page,
          pageSize: 10,
          total: data.total,
          showQuickJumper: true,
          onChange: (page) => fetchData.current(page),
        }}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button
                key="list-loadmore-download"
                icon={<DownloadOutlined />}
                type="link"
                href={item.url}
              >
                {i18n.t("financeHome.listItemActionDownload")}
              </Button>,
              <Button
                key="list-loadmore-edit"
                onClick={() => {
                  setUploadModal(item);
                }}
                type="link"
              >
                {i18n.t("product.productItem.actionEdit")}
              </Button>,
              <Button
                key="list-loadmore-more"
                onClick={() => onDeleteItem(item.id)}
                type="link"
                danger
              >
                {i18n.t("sales.activity.delete")}
              </Button>,
            ]}
          >
            <List.Item.Meta
              title={
                <Space direction="horizontal">
                  {item.version}
                  <Divider type="vertical" />
                  <Tag
                    color={
                      item.type === STANDARD
                        ? "blue"
                        : item.type === LOTTERY
                        ? "orange"
                        : "red"
                    }
                  >
                    {item.type === STANDARD
                      ? i18n.t("weapp.standard")
                      : item.type === LOTTERY
                      ? i18n.t("weapp.lottery")
                      : i18n.t("weapp.course")}
                  </Tag>
                  <Divider type="vertical" />
                  {item.created_at}
                </Space>
              }
            />
          </List.Item>
        )}
      />
      <Modal
        title={i18n.t("weapp.add")}
        open={showModal}
        onCancel={() => {
          setUploadModal(false);
          form.resetFields();
        }}
        onOk={onFinish}
        okText={i18n.t("base.modal.okText")}
        cancelText={i18n.t("base.modal.cancelText")}
        okButtonProps={{ loading: loading, disabled: loading }}
      >
        <Form form={form} labelCol={{ span: 4 }}>
          <Form.Item
            name="version"
            label={i18n.t("weapp.version")}
            rules={[
              { required: true, message: i18n.t("weapp.versionWarning") },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="type"
            label={i18n.t("weapp.type")}
            initialValue={STANDARD}
          >
            <Radio.Group
              buttonStyle="solid"
              disabled={showModal && showModal.id}
            >
              <Radio.Button value={STANDARD}>
                {i18n.t("weapp.standard")}
              </Radio.Button>
              <Radio.Button value={LOTTERY}>
                {i18n.t("weapp.lottery")}
              </Radio.Button>
              <Radio.Button value={COURSE}>
                {i18n.t("weapp.course")}
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="file"
            label={i18n.t("weapp.build")}
            valuePropName="fileList"
            getValueFromEvent={(e) => util.normFile(e)}
          >
            <Dragger
              disabled={showModal && showModal.id}
              accept=".zip"
              customRequest={(e) =>
                onUploadFile({ ...e, uploadType: "weapp-build" })
              }
              listType="picture-card"
            >
              <InboxOutlined style={{ fontSize: 32, color: "blueviolet" }} />
              <p style={{ color: "#666" }}>{i18n.t("weapp.drag")}</p>
            </Dragger>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default WeappManagement;
