import { DeleteOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from "antd";
import { Locale } from "../../utils";
const i18n = Locale.getInstance();
export const mediaListColumns = (
  deleteMutation,
  token,
  setMediaLoading,
) => {
  const getFile = (item) => {
    let file = (
      <img src={`${item.url}`} alt={item.file_name} className="file" />
    );
    if (item.collection_name === "videos") {
      file = <video src={`${item.url}`}></video>;
    } else if (item.collection_name === "audios") {
      file = <audio src={`${item.url}`} controls />;
    }
    return file;
  };
  return [
    {
      title: i18n.t("media.fileInfo"),
      render: (item) => {
        return (
          <div className="info">
            <div className="file">{getFile(item)}</div>
            <div className="name">{item.file_name}</div>
          </div>
        );
      },
    },
    {
      title: i18n.t("media.size"),
      dataIndex: "size",
      width: 100,
    },
    {
      title: i18n.t("users.date"),
      width: 120,
      dataIndex: "created_at",
    },
    {
      title: i18n.t("users.actions"),
      width: 100,
      render: (item) => {
        return (
          <>
            <Popconfirm
              title={i18n.t("sales.deleteWarning")}
              onConfirm={() => {
                setMediaLoading(true);
                deleteMutation.mutate({
                  id: item.id,
                  token,
                });
              }}
              okText={i18n.t("base.modal.okText")}
              cancelText={i18n.t("base.modal.cancelText")}
            >
              <Button size="small" icon={<DeleteOutlined />}>
                {i18n.t("common.delete")}
              </Button>
            </Popconfirm>
          </>
        );
      },
    },
  ];
};
