import React from "react";
import { List, Button, Radio, Space } from "antd";
import moment from "dayjs";
import { connect } from "react-redux";

import { getUserInfo } from "../../../utils/redux/reducers/User";
import Base from "../../Base";
import { FILTER_STATUS } from "../../../constants";

class GrouponManagement extends Base {
  constructor() {
    super();
    this.state = {
      data: { data: [] },
      loading: false,
      filterStatus: FILTER_STATUS.ALL.value,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.onGetDataList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.filterStatus !== this.state.filterStatus) {
      this.onGetDataList();
    }
  }

  async onGetDataList(page = 1) {
    const api = this.api.groupon.list;

    if (this.mounted) {
      try {
        const token = this.getUserToken();
        if (token) {
          const { filterStatus } = this.state;
          const apiConfig = {
            token,
            query: {
              page,
              count: 10,
              "filter[status]": filterStatus,
            },
          };
          const res = await api.run(apiConfig);
          if (res.result) {
            this.setState({
              data: res.data,
            });
          } else {
            this.showMessage(res.message);
          }
        }
      } catch (error) {
        this.handleApiError(api, error);
      }
    }
  }

  onDeleteItem(id) {
    this.showModal(
      this.i18n.t("sales.deleteWarning"),
      Base.Modal.confirm,
      async () => {
        const api = this.api.groupon.delete;
        if (this.mounted) {
          try {
            const token = this.getUserToken();
            if (token) {
              const apiConfig = {
                token,
                path: {
                  id,
                },
              };
              const res = await api.run(apiConfig);
              if (res.result) {
                this.onGetDataList();
              } else {
                this.showMessage(res.message, Base.Message.warning);
              }
            }
          } catch (error) {
            this.handleApiError(api, error);
          } finally {
            this.setState({ loading: false });
          }
        }
      }
    );
  }

  onGo2AddProduct(item) {
    this.props.history.push({
      pathname: "/main/sales/flash-edit",
      state: {
        item,
      },
    });
  }

  getFormattedDateString(date) {
    if (moment.isDayjs(date)) {
      return date.format("YYYY-MM-DD HH:mm:ss");
    }
    if (typeof date === "string") {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    }
    return null;
  }

  render() {
    const { data, loading, filterStatus } = this.state;
    return (
      <List
        bordered
        size="large"
        style={{ backgroundColor: "#fff" }}
        header={
          <Space direction="horizontal">
            <Button type="primary" onClick={() => this.onGo2AddProduct()}>
              {this.i18n.t("sales.groupon.add")}
            </Button>
            <Radio.Group
              style={{ marginRight: 20, marginLeft: 20 }}
              onChange={(e) => {
                this.setState({ filterStatus: e.target.value });
              }}
              value={filterStatus}
            >
              <Radio value={FILTER_STATUS.ALL.value}>
                {FILTER_STATUS.ALL.name}
              </Radio>
              <Radio value={FILTER_STATUS.OPENED.value}>
                {FILTER_STATUS.OPENED.name}
              </Radio>
              <Radio value={FILTER_STATUS.CLOSED.value}>
                {FILTER_STATUS.CLOSED.name}
              </Radio>
            </Radio.Group>
          </Space>
        }
        itemLayout="horizontal"
        dataSource={data.data}
        loading={loading}
        pagination={{
          current: data.current_page,
          pageSize: 10,
          showQuickJumper: true,
          onChange: (page) => this.onGetDataList(page),
          total: data ? data.total : 0,
        }}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button
                key="list-loadmore-edit"
                onClick={() => this.onGo2AddProduct(item)}
                type="link"
              >
                {this.i18n.t("sales.groupon.edit")}
              </Button>,
              <Button
                key="list-loadmore-more"
                onClick={() => this.onDeleteItem(item.id)}
                type="link"
                danger
              >
                {this.i18n.t("sales.activity.delete")}
              </Button>,
            ]}
          >
            <List.Item.Meta
              title={`${this.i18n.t(
                "sales.activity.start_date"
              )} ${this.getFormattedDateString(
                item.start_time
              )} - ${this.i18n.t(
                "sales.activity.end_date"
              )} ${this.getFormattedDateString(item.end_time)}`}
            />
          </List.Item>
        )}
      />
    );
  }
}

export default connect((state) => {
  return {
    user: getUserInfo(state),
  };
})(GrouponManagement);
