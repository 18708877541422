import { Locale } from "./utils";

export const JD_ID = 380;
export const GROUPON_ID = 391;
export const SUPERMARKET_ID = 263;

export const AMAP_KEY = "7ed71032dd78e3fdf46eef8c0b951c5d";
export const AMAP_VERSION = "2.0";
export const AMAP_ZOOM = 16.8;

const i18n = Locale.getInstance();
export const FILTER_STATUS = {
  ALL: { value: -1, name: i18n.t("article.all") },
  CLOSED: { value: 0, name: i18n.t("shows.status.invalid") },
  OPENED: { value: 1, name: i18n.t("shows.status.valid") },
};

export const FILTER_THEME_STATUS = {
  ALL: { value: -1, name: i18n.t("article.all") },
  WHEEL: { value: 0, name: i18n.t("lottery.wheel") },
  NINE: { value: 1, name: i18n.t("lottery.nine") },
  FRUIT: { value: 2, name: i18n.t("lottery.fruit") },
};
