import { Button, Image, Popconfirm, Space, Tag } from "antd";
import { Locale } from "../../utils";
import {
  CheckCircleOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { getUserInfo } from "../../utils/redux/reducers/User";
const i18n = Locale.getInstance();

export const redPacketListColumns = (history,deleteMutation, setLoading) => {
  const user = useSelector((state) => getUserInfo(state));
  return [
    {
      title: i18n.t("redPacketActivity.name"),
      render: (item) => {
        return <span>{i18n.getPropNameByLocale(item, "name")}</span>;
      },
    },
    {
      title: i18n.t("redPacketActivity.date"),
      render: (item) => {
        return <span>{item?.start_time} ~ {item?.end_time}</span>;
      },
    },
    
    {
      title: i18n.t("qrcode.regionName"),
      render: (item) => {
        return (
          <div>
            {item?.province ? item.province?.name.zh + " > " : ""}
            {item?.city ? item.city?.name.zh + " > " : ""}
            {item?.region ? item.region?.name.zh : ""}
          </div>
        );
      },
    },
    
    {
      title: i18n.t("common.statusLabel"),
      render: (item) => {
        let color;
        let icon;
        if (item.status === 0) {
          color = "purple";
          icon = <InfoCircleOutlined />;
        } else if (item.status === 3) {
          color = "red";
          icon = <QuestionCircleOutlined />;
        } else {
          color = "green";
          icon = <CheckCircleOutlined />;
        }
        return (
          <>
            <Tag icon={icon} color={color}>
              {item.status_label}
            </Tag>
            <span>
              {item.zh_refuse_reason ? i18n.getPropNameByLocale(item, 'refuse_reason') : ''}
            </span>
          </>
        );
      },
    },

    {
      title: i18n.t("users.actions"),
      fixed: "right",
      width: 200,
      render: (item) => {
        return (
          <Space direction="">
            <Button
              onClick={() => {
                history.push({
                  pathname: `/main/red-packet/edit/${item.id}`,
                });
              }}
              type="link"
              size="small"
            >
              {i18n.t("product.productItem.actionEdit")}
            </Button>

            <Button
              onClick={() => {
                history.push({
                  pathname: `/main/red-packet/${item.id}/detail`,
                });
              }}
              type="link"
              size="small"
            >
              {i18n.t("redPacketActivity.redPacket")}
            </Button>
            <Popconfirm
              title={i18n.t("sales.deleteWarning")}
              onConfirm={() => {
                setLoading(true);
                deleteMutation.mutate({
                  resource: "red-packet",
                  params: {
                    id: item.id,
                    token: user?.token,
                  },
                });
              }}
              okText={i18n.t("base.modal.okText")}
              cancelText={i18n.t("base.modal.cancelText")}
            >
              <Button size="small" icon={<DeleteOutlined />}>
                {i18n.t("common.delete")}
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
};
