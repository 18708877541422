import { stringify } from "qs";

import newRequest, { buildBaseURL } from "../../utils/http/newRuequest"


export const getMediaFolderList = async ({ filter, token }) => {
    let query;
    if (filter && Object.keys(filter).length > 0) {
        query = { ...query, ...filter }
    }
    const url = buildBaseURL('v1', 'media') + `merchant/folder-list?${stringify(query)}`;
    const result = await newRequest.get(url, token);

    return {
        data: result.data ?? [],
        total: result.total ? result.total : 10,
    };
}


export const addFolder = async ({ data, token }) => {
    const url = buildBaseURL('v1', 'media') + `merchant/folders`;
    const result = await newRequest.post(url, data, token);

    return {
        data: result.data ?? [],
        total: result.total ? result.total : 10,
    };
}

export const renameFolder = async ({ id, data, token }) => {
    const url = buildBaseURL('v1', 'media') + `merchant/folder/${id}`;
    const result = await newRequest.put(url, data, token);

    return {
        data: result.data ?? [],
        total: result.total ? result.total : 10,
    };
}

export const deleteFolder = async ({ id, token }) => {
    const url = buildBaseURL('v1', 'media') + `merchant/folder/${id}`;
    const result = await newRequest.delete(url, token);
    if (!result.result) {
        throw new Error(result.message);
    }
    return {
        data: result.data ?? [],
        total: result.total ? result.total : 10,
    };
}