import React, { useState, useCallback, useEffect, useRef } from "react";
import {
  Table,
  message,
  Tag,
  Avatar,
  Button,
  Space,
  Descriptions,
  Timeline,
  Popover,
  DatePicker,
  Input,
} from "antd";
import { connect } from "react-redux";
import { CaretDownOutlined } from "@ant-design/icons";
import Search from "antd/lib/input/Search";
import { getUserInfo } from "../../utils/redux/reducers/User";
import { ApiManager, Locale, Util } from "../../utils";
import Base from "../Base";

const api = ApiManager.getInstance();
const i18n = Locale.getInstance();
const util = Util.getInstance();
const base = Base.getInstance();

function WithdrawCommissionsList({ user, location }) {
  const thisSorter = useRef();
  const thisFilters = useRef();

  const [list, setList] = useState({ data: [], current_page: 1 });
  const [range, setRange] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchList = useCallback(
    async (page = 1) => {
      const { token } = user;
      if (token) {
        setLoading(true);
        try {
          const apiUrl = api.commission.withdrawCommissionList;
          const apiConfig = {
            token,
            path: {
              id: location.query.id,
            },
            query: {
              page,
              keyword,
              count: 10,
            },
          };

          if (thisSorter.current && thisSorter.current.field) {
            const order = thisSorter.current.order.split("end")[0];
            apiConfig.query[
              `filter[sort]`
            ] = `${thisSorter.current.field}@${order}`;
          }
          if (thisFilters && thisFilters.current) {
            for (let key in thisFilters.current) {
              if (thisFilters.current[key]) {
                apiConfig.query[`filter[${key}]`] =
                  thisFilters.current[key].join(",");
              }
            }
          }
          if (range && range.length > 0) {
            apiConfig.query["filter[start_date]"] =
              range[0].format("YYYY-MM-DD");
            apiConfig.query["filter[end_date]"] = range[1].format("YYYY-MM-DD");
          }
          const res = await apiUrl.run(apiConfig);
          if (res.result) {
            setList(res.data);
          } else {
            message.warning(res.message);
          }
        } catch (error) {
          message.error(error.message);
        } finally {
          setLoading(false);
        }
      }
    },
    [user, range, keyword, location.query.id]
  );

  function onSubmit(orderId, type) {
    base.showModal(
      i18n.t("base.modal.confirmTitle"),
      Base.Modal.confirm,
      async () => {
        let apiUrl = api.commission[type];
        const { token } = user;
        if (apiUrl && token) {
          const apiConfig = {
            token,
            path: {
              orderId,
            },
          };
          try {
            setLoading(true);
            const res = await apiUrl.run(apiConfig);
            if (res.result) {
              fetchList(list.current_page);
            } else {
              message.warning(res.message);
            }
          } catch (error) {
            setLoading(false);
            base.handleApiError(apiUrl, error);
          }
        }
      }
    );
  }

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  function getStatusColor(recordStatus) {
    let color;
    switch (recordStatus) {
      case 1:
        color = "green";
        break;
      case 4:
        color = "blue";
        break;
      default:
        color = "red";
        break;
    }
    return color;
  }
  const routerState = location.state;
  const data = routerState ? routerState["data"] : "";
  return (
    <>
      {/* <PageHeader
        className="site-page-header-responsive"
        onBack={() => window.history.back()}
        title={data.user.nickname}
        subTitle={i18n.t("commissionList.appliedDate", {
          date: data.created_at,
        })}
        avatar={{ src: data.user.avatar }}
        extra={[
          <Statistic
            title={i18n.t("commissionList.appliedAmount")}
            prefix="￥"
            value={util.getFormattedPrice(data.received_amount)}
          />,
        ]}
      /> */}
      <Input.Group compact style={{ marginBottom: 16 }}>
        <DatePicker.RangePicker
          defaultValue={range}
          format="YYYY-MM-DD"
          allowClear
          onChange={(dates) => setRange(dates)}
          suffixIcon={<CaretDownOutlined />}
        />
        <Search
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          onSearch={() => fetchList()}
          placeholder={i18n.t("users.searchPlace")}
          style={{ width: 300 }}
          enterButton
          allowClear
        />
      </Input.Group>
      <Table
        expandable={{
          expandedRowRender: (record) => {
            if (record.order) {
              return (
                <div>
                  <Descriptions
                    title={i18n.t("financeOrderDetail.headerTitle")}
                  >
                    <Descriptions.Item
                      label={i18n.t("commissionList.receiver")}
                    >
                      {record.order.receiver}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={i18n.t("commissionList.order_no")}
                    >
                      {record.order.order_no}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={i18n.t("commissionList.order_id")}
                    >
                      {record.order.id}
                    </Descriptions.Item>
                    <Descriptions.Item label={i18n.t("commissionList.tel")}>
                      {record.order.tel}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={i18n.t("commissionList.original")}
                    >
                      {util.getFormattedPrice(record.original_price, "¥")}
                    </Descriptions.Item>
                    <Descriptions.Item label={i18n.t("commissionList.price")}>
                      {util.getFormattedPrice(record.price, "¥")}
                    </Descriptions.Item>
                    <Descriptions.Item label={i18n.t("commissionList.ship")}>
                      {util.getFormattedPrice(record.ship_price, "¥")}
                    </Descriptions.Item>
                    <Descriptions.Item label={i18n.t("commissionList.real")}>
                      {util.getFormattedPrice(record.real_payment, "¥")}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={i18n.t("review.reviewItem.merchantText")}
                    >
                      {i18n.getPropNameByLocale(record.merchant, "name")}(
                      {record.merchant.id})
                    </Descriptions.Item>
                    <Descriptions.Item label={i18n.t("commissionList.address")}>
                      {record.order.address}
                    </Descriptions.Item>
                  </Descriptions>
                  <Space direction="vertical">
                    {Array.isArray(record.order.status_changelog) && (
                      <Popover
                        placement={
                          i18n.isLocaleRTL() ? "bottomLeft" : "bottomRight"
                        }
                        title={i18n.t("order.orderTypesTitle")}
                        content={
                          <Timeline
                            mode={i18n.isLocaleRTL() ? "right" : "left"}
                          >
                            {record.order.status_changelog.map((i) => (
                              <Timeline.Item
                                key={i.changed_at.date}
                                label={i.changed_at.date}
                              >
                                {i.change_log}
                              </Timeline.Item>
                            ))}
                          </Timeline>
                        }
                      >
                        <Button type="link" style={{ padding: 0 }}>
                          {i18n.t("order.orderTypesTitle")}(
                          {record.order.status_label})
                        </Button>
                      </Popover>
                    )}
                    {Array.isArray(record.tracking) &&
                      record.tracking.length > 0 &&
                      record.tracking.map((t) => (
                        <Popover
                          key={t.id}
                          placement={
                            i18n.isLocaleRTL() ? "bottomLeft" : "bottomRight"
                          }
                          title={i18n.t("order.orderTypesTitle")}
                          content={
                            <Timeline
                              mode={i18n.isLocaleRTL() ? "right" : "left"}
                            >
                              {t.details &&
                                t.details.length > 0 &&
                                t.details.map((i) => (
                                  <Timeline.Item key={i.time} label={i.time}>
                                    {i.context}
                                  </Timeline.Item>
                                ))}
                            </Timeline>
                          }
                        >
                          <Button type="link" style={{ padding: 0 }}>
                            {t.tracking_name} {t.tracking_number}
                          </Button>
                        </Popover>
                      ))}
                  </Space>
                </div>
              );
            }
          },
        }}
        loading={loading}
        dataSource={list.data}
        rowKey={(record) => `${record.id}`}
        onChange={(pagination, filters, sorter) => {
          thisSorter.current = sorter;
          thisFilters.current = filters;
          fetchList(pagination.current);
        }}
        pagination={{
          current: list.current_page,
          total: list.total,
          pageSize: 10,
          showSizeChanger: false,
          showQuickJumper: true,
        }}
        columns={[
          {
            title: "id",
            key: "id",
            dataIndex: "id",
          },
          {
            title: i18n.t("users.avatar"),
            key: "avatar",
            render: (text, record) => {
              const { user } = record;
              return (
                <Avatar
                  size="large"
                  shape="circle"
                  src={user.wechat && user.wechat.avatar}
                  alt={user.nickname}
                />
              );
            },
          },
          {
            title: i18n.t("users.name"),
            key: "nickname",
            render: (text, record) => record.user.nickname,
          },
          {
            title: i18n.t("commissionList.channel"),
            key: "channel",
            align: "center",
            filters: list.channels
              ? Object.keys(list.channels).map((k) => ({
                  text: list.channels[k],
                  value: k,
                }))
              : [],

            render: (text, record) => {
              return <Tag color="purple">{record.level_label}</Tag>;
            },
          },
          {
            title: i18n.t("commissionList.mobile"),
            key: "mobile",
            align: "center",
            render: (text, record) => record.user.mobile,
          },
          {
            title: i18n.t("commissionList.comm"),
            key: "amount",
            dataIndex: "amount",
            sorter: true,
            render: (text, record) => {
              return util.getFormattedPrice(record.commission, "¥");
            },
          },
          {
            title: i18n.t("commissionList.status"),
            key: "status",
            align: "center",
            filters: list.status
              ? list.status.map((i, idx) => ({
                  text: i,
                  value: idx,
                }))
              : [],
            render: (text, record) => {
              if (record.user_id) {
                return (
                  <Tag color={getStatusColor(record.status)}>
                    {record.status_label}
                  </Tag>
                );
              }
              return null;
            },
          },
          {
            title: i18n.t("users.date"),
            key: "date",
            dataIndex: "created_at",
            align: "center",
            sorter: true,
          },
          {
            title: i18n.t("users.actions"),
            key: "actions",
            align: "center",
            render: (text, record) => {
              if (record.order) {
                if (record.order.status === 4 && record.status === 4) {
                  return (
                    <Button
                      type="link"
                      onClick={() => onSubmit(record.order.id, "unfreeze")}
                    >
                      {i18n.t("commissionList.unfreeze")}
                    </Button>
                  );
                }
                if (record.order.status === 3) {
                  return (
                    <Button
                      type="link"
                      onClick={() => onSubmit(record.order.id, "finish")}
                    >
                      {i18n.t("commissionList.confirmFinished")}
                    </Button>
                  );
                }
                if (record.order.status === 2) {
                  return (
                    <Space direction="horizontal">
                      <Button
                        type="link"
                        onClick={() => onSubmit(record.order.id, "update")}
                      >
                        {i18n.t("commissionList.update")}
                      </Button>
                      <Button
                        type="link"
                        danger
                        onClick={() => onSubmit(record.order.id, "delivered")}
                      >
                        {i18n.t("commissionList.confirmReceived")}
                      </Button>
                    </Space>
                  );
                }
                if (record.order.status === 9) {
                  return (
                    <Button
                      type="link"
                      onClick={() => onSubmit(record.order.id, "send")}
                    >
                      {i18n.t("commissionList.confirmSend")}
                    </Button>
                  );
                }
              }
              return null;
            },
          },
        ]}
      />
    </>
  );
}

export default connect((state) => ({ user: getUserInfo(state) }))(
  WithdrawCommissionsList
);
