import { Card, List, Row, Spin, Image, Divider, Popconfirm, Col } from "antd";
import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { Locale } from "../../../utils";
import { showNotificationWithIcon } from "../../../utils/helpers/helpers";
import { getList, remove } from "../../../utils/http/dataProvider";
import { getUserInfo } from "../../../utils/redux/reducers/User";
import { BreadCrumb } from "../../../components/main/LayoutComponents";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import Meta from "antd/lib/card/Meta";
import { Route, useHistory } from "react-router-dom";

import "./CourseSliderAdList.scss";
import CourseSliderAdListHeader from "./CourseSliderAdListHeader";
import AddOrEditCourseSlider from "./AddOrEditCourseSlider";
const i18n = Locale.getInstance();

const CourseSliderAdList = () => {
  const user = useSelector((state) => getUserInfo(state));
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(12);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };
  const handlePaginationPageSizeChange = (event, pageSize) => {
    setPerPage(pageSize);
  };

  const { data, error, isLoading } = useQuery(
    ["course-slider-ads", user.token],
    () =>
      getList("ad", {
        pagination: {
          perPage,
          page,
        },
        sort: {
          field: "id",
          order: "desc",
        },
        filter: {
          usage: "course-slider",
        },
        token: user.token,
      })
  );

  if (error) {
    showNotificationWithIcon(error.data.message);
  }

  const breadCrumbData = [
    {
      href: "/main/courses",
      title: i18n.t("course.title"),
    },
    {
      href: "/main/courses/slider-ads",
      title: i18n.t("course.sliderAd.title"),
    },
  ];

  const deleteMutation = useMutation(remove, {
    onSuccess: () => {
      queryClient.invalidateQueries("course-slider-ads");
      setLoading(false);
    },
    onError: (error) => {
      setLoading(false);
      handleMutationError(error);
    },
  });
  if (isLoading) {
    return <Spin spinning={isLoading} />;
  }
  return (
    <>
      <BreadCrumb data={breadCrumbData} />
      <div className="slider-ad">
        <Spin spinning={loading}>
          <List
            grid={{ gutter: 16, column: 4 }}
            dataSource={data?.data}
            renderItem={(item) => (
              <List.Item>
                <Card
                  hoverable
                  style={{ flexDirection: "column", width: 378 }}
                  cover={
                    <Row>
                      <Image
                        style={{ width: 180 }}
                        alt={item.zh_title}
                        src={item.zh_image}
                      />
                      <Divider type="vertical" style={{ height: 80 }} />
                      <Image
                        style={{ width: 180 }}
                        alt={item.ug_title}
                        src={item.ug_image}
                      />
                    </Row>
                  }
                  actions={[
                    <EditOutlined
                      key="edit"
                      onClick={() =>
                        history.push({
                          pathname: "/main/course/slider-ads/edit",
                          state: {
                            id: item.id,
                          },
                        })
                      }
                    />,
                    <Popconfirm
                      key="delete"
                      title={i18n.t("sales.deleteWarning")}
                      onConfirm={() => {
                        setLoading(true);
                        deleteMutation.mutate({
                          resource: "ad",
                          params: {
                            id: item.id,
                            token: user.token,
                          },
                        });
                      }}
                      okText={i18n.t("base.modal.okText")}
                      cancelText={i18n.t("base.modal.cancelText")}
                    >
                      <DeleteOutlined />
                    </Popconfirm>,
                  ]}
                >
                  <Meta
                    title={item.data}
                    description={`${item.zh_title}/${item.ug_title}`}
                  />
                </Card>
              </List.Item>
            )}
            header={<CourseSliderAdListHeader />}
            pagination={{
              current: page,
              showSizeChanger: true,
              pageSize: perPage,
              pageSizeOptions: ["12", "24", "36"],
              onChange: handleChangePage,
              onShowSizeChange: handlePaginationPageSizeChange,
              showQuickJumper: true,
              total: data ? data.total : 0,
            }}
          />
        </Spin>
      </div>
    </>
  );
};

const FinalCourseSliderAdList = () => {
  return (
    <>
      <Route
        path="/main/course/slider-ads"
        exact
        component={CourseSliderAdList}
      />
      <Route
        path="/main/course/slider-ads/edit"
        component={AddOrEditCourseSlider}
      />
    </>
  );
};

export default FinalCourseSliderAdList;
