export default {
  list: {
    name: "member list api",
    version: 6,
    url: "member@member",
    method: "GET",
  },
  add: {
    name: "add member api",
    version: 6,
    url: "member@member",
    method: "POST",
  },
  update: {
    name: "update member api",
    version: 6,
    url: "member@member/%{id}",
    method: "PUT",
  },
  delete: {
    name: "delete member api",
    version: 6,
    url: "member@member/%{id}",
    method: "DELETE",
  },
  deleteMemberCard: {
    name: "delete member card api",
    version: 6,
    url: "member@member-card/%{id}",
    method: "DELETE",
  }
};
