import {
  DeleteOutlined,
  EditOutlined,
  FolderAddOutlined,
} from "@ant-design/icons";
import { notification, Button, Popover } from "antd";
import { useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { Locale } from "../../utils";
import { handleMutationError } from "../../utils/helpers/helpers";
import { getUserInfo } from "../../utils/redux/reducers/User";
import { deleteFolder } from "./mediaFolderApi";

const i18n = Locale.getInstance();

const MediaFolderItem = ({
  item,
  setOpenAddMedia,
  setFolderName,
  setAction,
  setSelectedFolder,
  isParentItem,
}) => {
  const queryClient = useQueryClient();
  const user = useSelector((state) => getUserInfo(state));

  const deleteFoldermutation = useMutation(deleteFolder, {
    onSuccess: () => {
      queryClient.invalidateQueries("folder-list");
    },
    onError: (error) => {
      handleMutationError(error);
    },
  });
  return (
    <div className="item">
      <Popover
        placement="topLeft"
        title={i18n.t("users.actions")}
        content={
          <div className="actions">
            <Button
              onClick={() => {
                setOpenAddMedia(true);
                setFolderName(item.folder_name);
                setSelectedFolder(item);
                setAction("update");
              }}
              icon={<EditOutlined />}
            >
              {i18n.t("media.rename")}
            </Button>
            {isParentItem && (
              <Button
                onClick={() => {
                  setOpenAddMedia(true);
                  setSelectedFolder(item);
                }}
                icon={<FolderAddOutlined />}
              >
                {i18n.t("media.addSubFolder")}
              </Button>
            )}
            <Button
              onClick={() => {
                deleteFoldermutation.mutate({
                  id: item.id,
                  token: user.token,
                });
              }}
              icon={<DeleteOutlined />}
            >
              {i18n.t("media.deleteFolder")}
            </Button>
          </div>
        }
      >
        <div className="title">
          <span>{item.folder_name}</span>
        </div>
      </Popover>
    </div>
  );
};

export default MediaFolderItem;
