import { Table, Button, Spin, Upload, notification } from "antd";
import Input from "antd/lib/input/Input";
import { UploadOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import React, { useEffect, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { getUserInfo } from "../../utils/redux/reducers/User";
import { deleteFile, getMediaList, uploadFile } from "./mediaApi";
import { mediaListColumns } from "./mediaListColumns";
import MediaListFooter from "./MediaListFooter";
import { Locale } from "../../utils";
import {
  handleMutationError,
  showNotificationWithIcon,
} from "../../utils/helpers/helpers";
import { values } from "lodash";

const i18n = Locale.getInstance();

const MediaList = ({
  folderId,
  setMediaLoading,
  collectionName,
  selectFiles,
}) => {
  const user = useSelector((state) => getUserInfo(state));
  const [selectedIds, setSelectedIds] = useState([]);
  const [keyword, setKeyword] = useState();
  const [page, setPage] = useState(1);
  const queryClient = useQueryClient();
  const [progress, setProgress] = useState(0);
  const history = useHistory();

  // const CHUNK_SIZE = 2 * 1024 * 1024;
  // const [chunkList, setChunkList] = useState([])

  useEffect(() => {
    setPage(1);
  }, [folderId]);
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const onSelectChange = (newSelectedIds) => {
    const files = newSelectedIds.map((item) => {
      return data?.data.filter((value) => value.id == item)[0];
    });
    if (typeof selectFiles === "function") {
      selectFiles(files);
    }

    setSelectedIds(newSelectedIds);
  };

  const rowSelection = {
    selectedIds,
    onChange: onSelectChange,
  };

  const loadMediaList = () => {
    return getMediaList({
      pagination: {
        perPage: 20,
        page,
      },
      filter: {
        keyword,
        folder_id: folderId,
        collection_name: collectionName,
      },
      token: user.token,
    });
  };

  const { data, isLoading, error } = useQuery(
    ["media-list", collectionName, folderId, keyword, user.token],
    loadMediaList
  );
  if (error) {
    showNotificationWithIcon(error.data.message);
    if (error.status === 400) {
      history.push("/main/media/storage-setting");
    }
  }
  const onBeforeUpload = (file) => {
    if (file) {
      return true;
    }
    return false;
  };

  const onUploadImg = async ({ file, onError, onSuccess }) => {
    if (!file) onError("invalid images");
    if (!user.token) onError("no user token");
    setMediaLoading(true);
    const uploadFiles = [{ file }];
    let type = "image";
    console.log(
      "🚀 ~ file: MediaList.jsx:100 ~ onUploadImg ~ collectionName",
      collectionName
    );
    if (collectionName === "audios") {
      type = "audio";
    }
    if (collectionName === "videos") {
      type = "video";
    }
    if (collectionName === "licence") {
      type = "licence";
    }

    const config = {
      onUploadProgress: (event) => {
        console.log(
          "🚀 ~ file: MediaList.jsx ~ line 104 ~ onUploadImg ~ event",
          event
        );
        const percent = Math.floor((event.loaded / event.total) * 100);
        setProgress(percent);
        if (percent === 100) {
          setTimeout(() => setProgress(0), 1000);
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };

    try {
      const res = uploadMutation.mutate({
        type,
        data: {
          files: uploadFiles,
          folderId: folderId,
        },
        token: user.token,
        config,
      });
      if (res) {
        onSuccess(res.data[0], file);
      } else {
        onError(res.message);
      }
    } catch (error) {
      onError(error);
    }
  };
  const uploadMutation = useMutation(uploadFile, {
    onSuccess: () => {
      queryClient.invalidateQueries("media-list");
      setMediaLoading(false);
    },
    onError: (error) => {
      setMediaLoading(false);
      handleMutationError(error);
    },
  });

  const deleteMutation = useMutation(deleteFile, {
    onSuccess: () => {
      queryClient.invalidateQueries("media-list");
      setMediaLoading(false);
    },
    onError: (error) => {
      setMediaLoading(false);
      handleMutationError(error);
    },
  });

  if (isLoading) {
    return <Spin spinning={isLoading} />;
  }
  let supportedType = i18n.t("media.supportedImageType", {
    imageType: "jpg/jpeg/png/gif",
  });
  let accept = "image/*";
  if (collectionName === "audios") {
    supportedType = i18n.t("media.supportedAudioType", {
      audioType: "mp3",
    });
    accept = "audio/mp3";
  }

  if (collectionName === "videos") {
    supportedType = i18n.t("media.supportedVideoType", {
      videoType: "mp4/mov",
    });
    accept = "video/*";
  }

  return (
    <>
      <div className="header">
        <div className="search">
          <Input
            defaultValue={keyword}
            onChange={(e) => {
              setKeyword(e.target.value);
            }}
            placeholder={i18n.t("media.fileName")}
          />
          <span>{supportedType}</span>
        </div>
        <div className="actions">
          <Upload
            itemRender={() => {}}
            accept={accept}
            multiple={true}
            beforeUpload={onBeforeUpload}
            customRequest={onUploadImg}
          >
            <Button icon={<UploadOutlined />} type="primary">
              {i18n.t("media.upload")}
            </Button>
          </Upload>
        </div>
      </div>
      <Table
        className="list"
        rowKey="id"
        rowSelection={rowSelection}
        columns={mediaListColumns(deleteMutation, user.token, setMediaLoading)}
        dataSource={data?.data}
        footer={() => (
          <MediaListFooter
            setMediaLoading={setMediaLoading}
            selectedIds={selectedIds}
          />
        )}
        scroll={{
          y: 320,
        }}
        pagination={{ onChange: handleChangePage, pageSize: 20 }}
      />
    </>
  );
};

export default MediaList;
