import { Button, Image, Popconfirm, Space, Tag } from "antd";
import { Locale, Util } from "../../../utils";
import {
  CheckCircleOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { getUserInfo } from "../../../utils/redux/reducers/User";
const i18n = Locale.getInstance();
const util = Util.getInstance();
export const redPacketDetailListColumns = (
  history,
  redPacketId,
  handleGenerate,
  deleteMutation,
  setLoading
) => {
  const user = useSelector((state) => getUserInfo(state));
  return [
    {
      title: i18n.t("redPacketActivity.amount"),
      render: item => {
        return <div>¥{util.getPrice(item.amount)}</div>
      }
    },
    {
      title: i18n.t("redPacketActivity.quantity"),
      render: item => {
        return <div>{item.quantity}</div>
      } 
    },
    {
      title: i18n.t("redPacketActivity.type"),
      render: (item) => {
        return  <Tag>{item.type_label}</Tag>;
      },
    },
    {
      title: i18n.t("redPacketActivity.min"),
      render: item => {
        return item.min ? <div>{util.getPrice(item.min)}</div> : '';
      }
    },
    {
      title: i18n.t("redPacketActivity.max"),
      render: item => {
        return item.max ? <div>{util.getPrice(item.max)}</div> : '';
      }
    },
    {
      title: i18n.t("common.statusLabel"),
      render: (item) => {
        let color;
        let icon;
        if (item.status === 0) {
          color = "purple";
          icon = <InfoCircleOutlined />;
        } else if (item.status === 3) {
          color = "red";
          icon = <QuestionCircleOutlined />;
        } else {
          color = "green";
          icon = <CheckCircleOutlined />;
        }
        return (
          <>
            <Tag icon={icon} color={color}>
              {item.status_label}
            </Tag>
          </>
        );
      },
    },
    {
      title: i18n.t("users.actions"),
      fixed: "right",
      width: 200,
      render: (item) => {
        return (
          <Space direction="">
            {item.status == 0 && (
              <Button
                onClick={() => {
                  history.push({
                    pathname: `/main/red-packet/${redPacketId}/detail/edit/${item.id}`,
                  });
                }}
                type="link"
                size="small"
              >
                {i18n.t("product.productItem.actionEdit")}
              </Button>
            )}
            {item.status == 0 && (
              <Button
                onClick={()=>handleGenerate(item.id)}
                type="link"
                size="small"
              >
                {i18n.t("redPacketActivity.generate")}
              </Button>
            )}
            <Button
              onClick={() => {
                history.push({
                  pathname: `/main/red-packet/${redPacketId}/detail/${item.id}/items`,
                });
              }}
              type="link"
              size="small"
            >
              {i18n.t("redPacketActivity.items")}
            </Button>
            <Popconfirm
              title={i18n.t("sales.deleteWarning")}
              onConfirm={() => {
                setLoading(true);
                deleteMutation.mutate({
                  resource: "red-packet-detail",
                  params: {
                    id: item.id,
                    token: user?.token,
                  },
                });
              }}
              okText={i18n.t("base.modal.okText")}
              cancelText={i18n.t("base.modal.cancelText")}
            >
              <Button size="small" icon={<DeleteOutlined />}>
                {i18n.t("common.delete")}
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
};
