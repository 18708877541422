export default {
  list: {
    name: "lottery list api",
    version: 1,
    url: "llottery",
    method: "GET",
  },
  add: {
    name: "add lottery api",
    version: 1,
    url: "llottery",
    method: "POST",
  },
  update: {
    name: "update lottery api",
    version: 1,
    url: "llottery/%{id}",
    method: "PUT",
  },
  delete: {
    name: "delete lottery api",
    version: 1,
    url: "llottery/%{id}",
    method: "DELETE",
  },
  send: {
    name: "send lottery api",
    version: 6,
    url: "lottery-ticket/%{id}/send",
    method: "POST",
  },
  exchange: {
    name: "exchange lottery api",
    version: 6,
    url: "lottery/%{drawId}/exchanged",
    method: "POST",
  },
  exchangeProduct: {
    name: "exchange lottery product prize api",
    version: 1,
    url: "llottery/%{drawId}/take-order",
    method: "POST",
  },
  revert: {
    name: "revert lottery api",
    version: 6,
    url: "lottery-ticket/%{id}/revert",
    method: "POST",
  },
  distributedList: {
    name: "get lottery distributed list api",
    version: 6,
    url: "lottery-ticket/%{id}/list",
    method: "GET",
  },
  recommendersList: {
    name: "get recommenders list api",
    version: 1,
    url: "llottery-recommenders/%{id}/list",
    method: "GET",
  },
  transLog: {
    name: "get lottery transaction list api",
    version: 6,
    url: "lottery-ticket/%{id}/history",
    method: "GET",
  },
  drawLog: {
    name: "get lottery prize list api",
    version: 6,
    url: "lottery-ticket/%{id}/draw-list",
    method: "GET",
  },
  bought: {
    name: "get lottery ticket bought list api",
    version: 6,
    url: "lottery-ticket/%{id}/bought-list",
    method: "GET",
  },
  reduce: {
    name: "reduce lottery ticket amount",
    version: 6,
    url: "lottery-ticket/%{id}/reduce",
    method: "POST",
  },
  theme: {
    name: "lottery themes list api",
    version: 1,
    url: "theme",
    method: "GET",
  },
  themeAdd: {
    name: "lottery theme add api",
    version: 1,
    url: "theme",
    method: "POST",
  },
  themeUpdate: {
    name: "lottery theme update api",
    version: 1,
    url: "theme/%{id}",
    method: "PUT",
  },
  themeDelete: {
    name: "delete lottery theme api",
    version: 1,
    url: "theme/%{id}",
    method: "DELETE",
  },
  prizeList: {
    name: "lottery prize list api",
    version: 1,
    url: "llottery/%{id}/list",
    method: "GET",
  },
  preList: {
    name: "lottery pre list api",
    version: 1,
    url: "llottery/prize/%{id}/big-prizes",
    method: "GET",
  },
  preAdd: {
    name: "lottery pre add api",
    version: 1,
    url: "llottery/prize/%{id}/big-prizes",
    method: "POST",
  },
  preDelete: {
    name: "lottery pre delete api",
    version: 1,
    url: "llottery/prize/%{id}/big-prizes",
    method: "DELETE",
  },
  prizeIncrease: {
    name: "lottery prize increase api",
    version: 1,
    url: "llottery/%{id}/increase",
    method: "POST",
  },
  prizeDecrease: {
    name: "lottery prize decrease api",
    version: 1,
    url: "llottery/%{id}/decrease",
    method: "POST",
  },
  prizeSpecial: {
    name: "lottery prize special prize api",
    version: 1,
    url: "llottery/%{id}/special",
    method: "POST",
  },
};
