import React, { useState, useEffect, useCallback } from "react";
import {
  Modal,
  Input,
  List,
  Select,
  message,
  Button,
  Space,
  InputNumber,
  Form,
  Image,
} from "antd";
import { connect } from "react-redux";
import { getUserInfo } from "../../../utils/redux/reducers/User";
import { Locale, ApiManager } from "../../../utils";
import { JD_ID } from "../../../constants";
import SimpleProductInfo from "../../../components/product/SimpleProductInfo";

const { Search } = Input;
const { Option } = Select;
const i18n = Locale.getInstance();
const api = ApiManager.getInstance();

function ProductListModal({ visible, onCancel, user, onAddProduct }) {
  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(false);
  const [addId, setAddId] = useState(null);
  const [searchType, setSearchType] = useState("name");
  const [data, setData] = useState({ data: [], current_page: 1 });
  const [form] = Form.useForm();

  const fetchData = useCallback(async () => {
    const token = user.token;
    let customApi = api.product.products;
    if (user && user.merchant.id === JD_ID) {
      customApi = api.product.jdProducts;
    }
    if (token) {
      const query = {
        category_id: -1,
        page: data.current_page,
        keyword,
        search_type: searchType,
        count: 5,
      };
      const apiConfig = {
        token,
        query,
      };
      try {
        setLoading(true);
        const res = await customApi.run(apiConfig);
        setLoading(false);
        if (res.result) {
          setData(res.data);
        } else {
          message.error(res.message);
        }
      } catch (error) {
        setLoading(false);
        console.log("loadProducts -> error", error);
      }
    }
  }, [data.current_page, keyword, searchType, user]);

  async function onFinish(values) {
    onAddProduct({
      ...values,
      product_id: addId,
      discounted_price: values.discounted_price * 100,
    });
    onAfterAddFinished();
  }

  function onAfterAddFinished() {
    form.resetFields();
    setAddId(null);
  }

  useEffect(() => {
    fetchData();
  }, [data.current_page, keyword, fetchData]);

  const selectBefore = (
    <Select
      value={searchType}
      onChange={(value) => setSearchType(value)}
      style={{ width: 120 }}
    >
      <Option value="name"> {i18n.t("product.search.name")}</Option>
      <Option value="product_no">{i18n.t("product.search.product_no")}</Option>
    </Select>
  );

  return (
    <Modal
      width={1020}
      title={
        <Search
          allowClear
          style={{ width: 400 }}
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          addonBefore={selectBefore}
          onSearch={() => {
            if (data.current_page > 1) {
              setData({ ...data, current_page: 1 });
            } else {
              fetchData();
            }
          }}
        />
      }
      open={visible}
      onCancel={onCancel}
      footer={null}
    >
      <List
        itemLayout="horizontal"
        dataSource={data.data}
        loading={loading}
        pagination={{
          current: data.current_page,
          total: data.total,
          pageSize: 5,
          showSizeChanger: false,
          showQuickJumper: true,
          onChange: (page) => setData({ ...data, current_page: page }),
        }}
        renderItem={(item) => {
          let formItem;
          if (item.id === addId) {
            formItem = (
              <Form
                layout="inline"
                form={form}
                onFinish={(values) => onFinish(values)}
              >
                <Form.Item
                  name="discounted_price"
                  label={i18n.t("sales.activity.discount_price")}
                  rules={[
                    {
                      required: true,
                      message: i18n.t("sales.activity.discountWarning"),
                    },
                  ]}
                >
                  <InputNumber />
                </Form.Item>
                <Form.Item
                  name="min_limit"
                  label={i18n.t("sales.activity.min")}
                  rules={[
                    {
                      required: true,
                      message: i18n.t("sales.activity.minWarning"),
                    },
                  ]}
                >
                  <InputNumber min={1} />
                </Form.Item>
                <Form.Item
                  name="max_limit"
                  label={i18n.t("sales.activity.max")}
                  rules={[
                    {
                      required: true,
                      message: i18n.t("sales.activity.maxWarning"),
                    },
                  ]}
                >
                  <InputNumber min={1} />
                </Form.Item>
                <Form.Item shouldUpdate>
                  {() => (
                    <Space direction="horizontal" align="center">
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={
                          !form.isFieldsTouched(true) ||
                          form
                            .getFieldsError()
                            .filter(({ errors }) => errors.length).length
                        }
                      >
                        {i18n.t("sales.submit")}
                      </Button>
                      <Button onClick={() => setAddId(null)}>
                        {i18n.t("proxy.editCancel")}
                      </Button>
                    </Space>
                  )}
                </Form.Item>
              </Form>
            );
          }
          return (
            <List.Item
              key={item.id}
              actions={
                item.status
                  ? [
                      <Button
                        onClick={() => {
                          form.resetFields();
                          setAddId(item.id);
                        }}
                      >
                        {i18n.t("sales.activity.add_product")}
                      </Button>,
                    ]
                  : []
              }
            >
              <div style={{ width: "100%" }}>
                <List.Item.Meta
                  avatar={
                    <Image alt="" src={item?.images[0]?.url} width={80} />
                  }
                  title={i18n.getPropNameByLocale(item, "name")}
                  description={<SimpleProductInfo item={item} />}
                />
                {formItem}
              </div>
            </List.Item>
          );
        }}
      />
    </Modal>
  );
}

export default connect((state) => ({ user: getUserInfo(state) }))(
  ProductListModal
);
