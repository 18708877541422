import React from "react";
import { connect } from "react-redux";
import {
  Layout,
  Divider,
  Spin,
  Form,
  DatePicker,
  Button,
  List,
  Typography,
  Image,
  Switch,
  Space,
} from "antd";
import { LeftOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "dayjs";
import Base from "../../Base";
import { getUserInfo } from "../../../utils/redux/reducers/User";
import ProductListModal from "./ProductListModal";

const { RangePicker } = DatePicker;
const { Text } = Typography;

class FlashActivityEdit extends Base {
  constructor(props) {
    super(props);
    const {
      location: {
        state: { item },
      },
    } = this.props;
    this.newItem = item;
    this.state = {
      loading: false,
      dateSet: item
        ? [moment(item.start_time), moment(item.end_time)]
        : [moment(), moment()],
      showModal: false,
      isOpened: item ? item.status : 1,
      listData: [],
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.onGetProductList();
  }

  onAddDate = async () => {
    const api = this.api.flash.add;
    try {
      const token = this.getUserToken();
      if (!token) return;
      const { dateSet } = this.state;
      const apiConfig = {
        token,
        data: {
          start_time: this.getFormattedDateString(dateSet[0]),
          end_time: this.getFormattedDateString(dateSet[1]),
        },
      };
      const res = await api.run(apiConfig);
      if (res.result) {
        return res.data;
      } else {
        this.showMessage(res.message);
      }
    } catch (error) {
      this.handleApiError(api, error);
    }
  };

  async onEditDate() {
    const api = this.api.flash.update;
    const token = this.getUserToken();
    if (token) {
      try {
        const { dateSet, isOpened } = this.state;
        const apiConfig = {
          token,
          path: {
            id: this.newItem.id,
          },
          data: {
            start_time: this.getFormattedDateString(dateSet[0]),
            end_time: this.getFormattedDateString(dateSet[1]),
            status: isOpened,
          },
        };
        this.setState({ loading: true, showModal: false });
        const res = await api.run(apiConfig);
        if (res.result) {
          this.onHeaderBackClick();
        } else {
          this.showMessage(res.message);
        }
      } catch (error) {
        this.handleApiError(api, error);
      } finally {
        if (this.mounted) {
          this.setState({ loading: false });
        }
      }
    }
  }

  async onGetProductList() {
    const token = this.getUserToken();
    if (token && this.newItem) {
      const api = this.api.flash.productList;
      const apiConfig = {
        token,
        path: { id: this.newItem.id },
      };
      try {
        this.setState({ loading: true });
        const res = await api.run(apiConfig);
        if (res.result) {
          this.setState({ listData: res.data });
        } else {
          this.showMessage(res.message, Base.Message.error);
        }
      } catch (error) {
        this.handleApiError(api, error);
      } finally {
        if (this.mounted) {
          this.setState({ loading: false });
        }
      }
    }
  }

  onAddProduct2Activity = async (data) => {
    const token = this.getUserToken();
    if (!this.newItem) {
      this.newItem = await this.onAddDate();
    }
    if (token) {
      const api = this.api.flash.productAdd;
      const apiConfig = {
        token,
        path: { id: this.newItem.id },
        data,
      };
      try {
        this.setState({ loading: true });
        const res = await api.run(apiConfig);
        if (res.result) {
          this.showMessage(res.message);
          this.onGetProductList();
        } else {
          this.showMessage(res.message, Base.Message.error);
        }
      } catch (error) {
        this.handleApiError(api, error);
      } finally {
        if (this.mounted) {
          this.setState({ loading: false });
        }
      }
    }
  };

  onDeleteProductFromActivity(productId) {
    this.showModal(
      this.i18n.t("sales.deleteWarning"),
      Base.Modal.confirm,
      async () => {
        const token = this.getUserToken();
        if (token) {
          const api = this.api.flash.productDelete;
          const apiConfig = {
            token,
            path: { id: this.newItem.id, product_id: productId },
          };
          try {
            this.setState({ loading: true });
            const res = await api.run(apiConfig);
            if (res.result) {
              this.showMessage(res.message);
              this.onGetProductList();
            } else {
              this.showMessage(res.message, Base.Message.error);
            }
          } catch (error) {
            this.handleApiError(api, error);
          } finally {
            if (this.mounted) {
              this.setState({ loading: false });
            }
          }
        }
      }
    );
  }

  onSaveAndBack = () => {
    const { dateSet, isOpened } = this.state;
    if (
      dateSet[0].isSame(moment(this.newItem.start_time)) &&
      dateSet[1].isSame(moment(this.newItem.end_time)) &&
      this.newItem.status === isOpened
    ) {
      this.onHeaderBackClick();
    } else {
      this.onEditDate();
    }
  };

  onHeaderBackClick = () => {
    this.props.history.goBack();
  };

  onChangeDate = (dates, dateStrings) => {
    this.setState({
      dateSet: dates,
    });
  };

  onToggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  getFormattedDateString(date) {
    if (moment.isDayjs(date)) {
      return date.format("YYYY-MM-DD HH:mm:ss");
    }
    if (typeof date === "string") {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    }
    return null;
  }

  render() {
    const { loading, dateSet, showModal, listData, isOpened } = this.state;

    return (
      <Layout id="route-product-edit">
        <Layout.Header className="edit-header">
          <Button type="link" className="back" onClick={this.onHeaderBackClick}>
            <LeftOutlined />
            {this.i18n.t("productEdit.headerBack")}
          </Button>
          <Divider type="vertical" />
          <span className="title">{this.i18n.t("sales.activity.product")}</span>
        </Layout.Header>
        <Layout.Content className="edit-content">
          <Spin spinning={loading}>
            <Form labelCol={{ span: 2 }} wrapperCol={{ span: 12 }}>
              <Form.Item label={this.i18n.t("sales.activity.time")}>
                <RangePicker
                  showTime
                  allowClear
                  format="YYYY-MM-DD HH:mm:ss"
                  onChange={this.onChangeDate}
                  value={dateSet}
                  disabledDate={(current) =>
                    current && current < moment().startOf("day")
                  }
                />
              </Form.Item>
              <Form.Item label={this.i18n.t("sales.status")}>
                <Switch
                  checked={isOpened}
                  onChange={(value) =>
                    this.setState({ isOpened: value ? 1 : 0 })
                  }
                />
              </Form.Item>
              <Form.Item label={this.i18n.t("sales.activity.product")}>
                <Button
                  icon={<PlusOutlined />}
                  type="primary"
                  onClick={this.onToggleModal}
                >
                  {this.i18n.t("sales.activity.product")}
                </Button>
              </Form.Item>
              <Form.Item label={this.i18n.t("sales.activity.product_list")}>
                <List
                  bordered
                  dataSource={listData}
                  itemLayout="horizontal"
                  renderItem={(item) => (
                    <List.Item
                      key={item.id}
                      actions={[
                        <Button
                          danger
                          onClick={() =>
                            this.onDeleteProductFromActivity(item.product_id)
                          }
                        >
                          {this.i18n.t("sales.activity.delete")}
                        </Button>,
                      ]}
                    >
                      <List.Item.Meta
                        avatar={
                          <Image alt="" src={item.images[0]} width={80} />
                        }
                        title={this.i18n.getPropNameByLocale(
                          item.product,
                          "name"
                        )}
                        description={
                          <Space direction="vertical">
                            <div>
                              <Text delete>
                                {this.util.getFormattedPrice(
                                  item.product.price,
                                  "¥"
                                )}
                              </Text>
                              <Text type="danger">
                                {this.util.getFormattedPrice(
                                  item.discounted_price,
                                  "¥"
                                )}
                              </Text>
                            </div>
                            <Text type="secondary">
                              {this.i18n.t("product.productItem.quantity")}:{" "}
                              {item.product.quantity}
                            </Text>
                          </Space>
                        }
                      />
                    </List.Item>
                  )}
                />
              </Form.Item>
            </Form>
          </Spin>
        </Layout.Content>
        <Layout.Footer className="edit-footer">
          <Button type="primary" onClick={this.onSaveAndBack}>
            {this.i18n.t("productEdit.saveAndBack")}
          </Button>
          <Button onClick={this.onHeaderBackClick}>
            {this.i18n.t("productEdit.cancel")}
          </Button>
        </Layout.Footer>
        <ProductListModal
          visible={showModal}
          onCancel={this.onToggleModal}
          onAddProduct={this.onAddProduct2Activity}
        />
      </Layout>
    );
  }
}

export default connect((state) => {
  return {
    user: getUserInfo(state),
  };
})(FlashActivityEdit);
