import React from "react";
import { Layout, Divider, Radio, Spin, Image } from "antd";
import { Chart, Geom, Axis, Tooltip } from "bizcharts";
import { connect } from "react-redux";
import { getUserInfo } from "../../utils/redux/reducers/User";
import Base from "../Base";
import { setMerchantSettings } from "../../utils/redux/actions/Merchant";

class Home extends Base {
  static ChartTimes = [
    { key: "week", name: "home.chartTime.week" },
    { key: "month", name: "home.chartTime.month" },
    { key: "season", name: "home.chartTime.season" },
  ];

  /* lifcyle methods */

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      countChartTime: this.getRouterQueryItemByKey(
        Home.ChartTimes,
        "countTime"
      ),
      moneyChartTime: this.getRouterQueryItemByKey(
        Home.ChartTimes,
        "incomeTime"
      ),
      statistic: null,
      loadingStatistic: false,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    if (this.getUser()) {
      this.showMessage(this.i18n.t("home.loadingData"), Base.Message.loading);
      this.loadStatistics();
      this.getMerchantSettings();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);
    if (
      prevState.countChartTime.key !== this.state.countChartTime.key ||
      prevState.moneyChartTime.key !== this.state.moneyChartTime.key
    ) {
      this.setRouterQuery({
        countTime: this.state.countChartTime.key,
        incomeTime: this.state.moneyChartTime.key,
      });
    }
  }

  /* render methods */

  render() {
    return (
      <Layout id="route-home">
        {this._renderStatisticNumbers()}
        {this._renderStatisticOrderCountChart()}
        {this._renderStatisticOrderMoneyChart()}
        {this._renderStatisticQrcode()}
      </Layout>
    );
  }

  _renderStatisticNumbers() {
    return (
      <div className="statistic-numbers">
        {this._renderStatisticDailyNumbers()}
        {this._renderStatisticMonthlyNumbers()}
      </div>
    );
  }

  _renderStatisticQrcode() {
    let data = this.state.statistic;
    let qrcode = data ? data.qrcode : null;

    return (
      <div className="block-container">
        <div className="block-header">
          <span className="title">{this.i18n.t("home.numbers.qrcode")}</span>
        </div>
        <div className="block-content">
          <div className="qrcode">
            {qrcode ? <Image src={qrcode} width={220} /> : null}
          </div>
        </div>
      </div>
    );
  }

  _renderStatisticDailyNumbers() {
    let data = this.state.statistic;
    let orders = data ? data.orders_count_amount : null;
    let today = orders ? orders.today : null;
    let yesterday = orders ? orders.yesterday : null;

    return (
      <div className="numbers-col three">
        <Spin spinning={this.state.loadingStatistic}>
          <div className="col-box">
            <div className="box-block">
              <h5>{this.i18n.t("home.numbers.dailyOrderCount")}</h5>
              <div>
                <span className="num">{today ? today.orders_count : "--"}</span>
                <span className="unit">
                  {this.i18n.t("home.numbers.dailyOrderCountUnit")}
                </span>
              </div>
              <h5>
                {this.i18n.t("home.numbers.yesterdayOrderCount", {
                  count: yesterday ? yesterday.orders_count : " -- ",
                })}
              </h5>
            </div>
            <Divider type="vertical" />
            <div className="box-block">
              <h5>{this.i18n.t("home.numbers.dailyOrderIncome")}</h5>
              <div>
                <span className="unit">￥</span>
                <span className="num">
                  {today
                    ? this.util.getFormattedPrice(today.merchant_income)
                    : "--"}
                </span>
              </div>
              <h5>
                {this.i18n.t("home.numbers.yesterdayOrderIncome", {
                  income: yesterday
                    ? this.util.getFormattedPrice(yesterday.merchant_income)
                    : "--",
                })}
              </h5>
            </div>
            <Divider type="vertical" />
            <div className="box-block">
              <h5>{this.i18n.t("home.numbers.dailyOrderTurnover")}</h5>
              <div>
                <span className="unit">￥</span>
                <span className="num">
                  {today ? this.util.getFormattedPrice(today.turnover) : "--"}
                </span>
              </div>
              <h5>
                {this.i18n.t("home.numbers.yesterdayOrderTurnover", {
                  income: yesterday
                    ? this.util.getFormattedPrice(yesterday.turnover)
                    : "--",
                })}
              </h5>
            </div>
          </div>
        </Spin>
      </div>
    );
  }

  _renderStatisticMonthlyNumbers() {
    let data = this.state.statistic;
    let orders = data ? data.orders_count_amount : null;
    let thisMonth = orders ? orders.this_month : null;
    let prevMonth = orders ? orders.last_month : null;

    return (
      <div className="numbers-col">
        <Spin spinning={this.state.loadingStatistic}>
          <div className="col-box">
            <div className="box-block">
              <h5>{this.i18n.t("home.numbers.monthlyOrderCount")}</h5>
              <div>
                <span className="num">
                  {thisMonth ? thisMonth.orders_count : "--"}
                </span>
                <span className="unit">
                  {this.i18n.t("home.numbers.monthlyOrderCountUnit")}
                </span>
              </div>
              <h5>
                {this.i18n.t("home.numbers.prevMonthOrderCount", {
                  count: prevMonth ? prevMonth.orders_count : " -- ",
                })}
              </h5>
            </div>
            <Divider type="vertical" />
            <div className="box-block">
              <h5>{this.i18n.t("home.numbers.monthlyOrderIncome")}</h5>
              <div>
                <span className="unit">￥</span>
                <span className="num">
                  {thisMonth
                    ? this.util.getFormattedPrice(thisMonth.merchant_income)
                    : "--"}
                </span>
              </div>
              <h5>
                {this.i18n.t("home.numbers.prevMonthOrderIncome", {
                  income: prevMonth
                    ? this.util.getFormattedPrice(prevMonth.merchant_income)
                    : " -- ",
                })}
              </h5>
            </div>
          </div>
        </Spin>
      </div>
    );
  }

  _renderStatisticOrderCountChart() {
    const data = this.getChartData(true);
    const scale = {
      checkout_date: {
        alias: this.i18n.t("home.chart.dateAlias"),
        formatter: (val) => {
          return this.moment(val).format("YY/MM/DD");
        },
      },
      order_count: {
        alias: this.i18n.t("home.chart.countAlias"),
        min: 0,
        max: this.getCountChartMax(data),
      },
    };

    return (
      <div className="block-container">
        <div className="block-header">
          <span className="title">{this.i18n.t("home.chart.countTitle")}</span>
          <Radio.Group
            size="small"
            value={this.state.countChartTime.key}
            onChange={this.handleCountChartTimeChange}
          >
            {Home.ChartTimes.map((time, index) => {
              return (
                <Radio.Button
                  key={`count-chart-radio-${index}`}
                  value={time.key}
                >
                  {this.i18n.t(time.name)}
                </Radio.Button>
              );
            })}
          </Radio.Group>
        </div>
        <div className="block-content">
          <Spin spinning={this.state.loadingStatistic}>
            <Chart
              autoFit
              height={200}
              padding={[20, 30, 40, 80]}
              data={data}
              scale={scale}
            >
              <Axis name="checkout_date" />
              <Axis name="order_count" />
              <Geom
                type="line"
                position="checkout_date*order_count"
                color="#419cb4"
                size={2}
              />
              <Geom
                type="area"
                position="checkout_date*order_count"
                color="#419cb4"
              />
              <Tooltip crosshairs={{ type: "cross" }} />
            </Chart>
          </Spin>
        </div>
      </div>
    );
  }

  _renderStatisticOrderMoneyChart() {
    let data = this.getChartData(false).map((i) => ({
      ...i,
      total_merchant_income: Number(i.total_merchant_income),
    }));
    const scale = {
      checkout_date: {
        type: "time",
        alias: this.i18n.t("home.chart.dateAlias"),
        formatter: (val) => {
          return this.moment(val).format("YY/MM/DD");
        },
      },
      total_merchant_income: {
        alias: this.i18n.t("home.chart.incomeAlias"),
        formatter: (val) => {
          return this.util.getFormattedPrice(val);
        },
        min: 0,
      },
    };

    return (
      <div className="block-container">
        <div className="block-header">
          <span className="title">{this.i18n.t("home.chart.incomeTitle")}</span>
          <Radio.Group
            size="small"
            value={this.state.moneyChartTime.key}
            onChange={this.handleMoneyChartTimeChange}
          >
            {Home.ChartTimes.map((time, index) => {
              return (
                <Radio.Button
                  key={`money-chart-radio-${index}`}
                  value={time.key}
                >
                  {this.i18n.t(time.name)}
                </Radio.Button>
              );
            })}
          </Radio.Group>
        </div>
        <div className="block-content">
          <Spin spinning={this.state.loadingStatistic}>
            <Chart
              autoFit
              height={200}
              padding={[20, 30, 40, 80]}
              data={data}
              scale={scale}
            >
              <Axis name="checkout_date" />
              <Axis name="total_merchant_income" />
              <Geom
                type="line"
                position="checkout_date*total_merchant_income"
                color="#419cb4"
                size={2}
              />
              <Geom
                type="area"
                position="checkout_date*total_merchant_income"
                color="#419cb4"
              />
              <Tooltip crosshairs={{ type: "cross" }} />
            </Chart>
          </Spin>
        </div>
      </div>
    );
  }

  /* user methods */

  handleCountChartTimeChange = (e) => {
    if (this.state.loadingStatistic) {
      this.showMessage(
        this.i18n.t("home.loadingDataWarning"),
        Base.Message.warning
      );
      return;
    }

    let countTime = Home.ChartTimes.find((time) => time.key === e.target.value);
    this.setState({ countChartTime: countTime });
  };

  handleMoneyChartTimeChange = (e) => {
    if (this.state.loadingStatistic) {
      this.showMessage(
        this.i18n.t("home.loadingDataWarning"),
        Base.Message.warning
      );
      return;
    }

    let moneyTime = Home.ChartTimes.find((time) => time.key === e.target.value);
    this.setState({ moneyChartTime: moneyTime });
  };

  /* server methods */
  async getMerchantSettings() {
    try {
      const { setMerchantSettings, user } = this.props;
      const token = this.getUserToken();
      if (!token) return;

      const api = this.api.user.merchantSettings;
      const apiConfig = {
        token,
        path: {
          merchant_id: user.merchant.id,
        },
      };

      requestAnimationFrame(() => {
        this.setState({ drawerVisible: false });
      });

      const res = await api.run(apiConfig);
      if (res.result) {
        setMerchantSettings(res.data);
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      this.handleApiError(error);
    }
  }

  async loadStatistics() {
    if (this.state.loadingStatistic) return;

    let token = this.getUserToken();
    if (!token) return;

    let api = this.api.common.homeStatistics;
    if (this.isThirdPartyShopHasWeapp()) {
      api = this.api.common.weappHomeStatistics;
    }
    let apiConfig = {
      token: token,
    };

    this.setState({ loadingStatistic: true });
    try {
      let res = await api.run(apiConfig);

      if (!this.mounted) return;

      if (res.result) {
        this.setState({ statistic: res.data });
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;
      this.setState({ loadingStatistic: false });
    }
  }

  /* custom methods */

  getChartData(isCount = false) {
    let data = this.state.statistic;
    let ordersTrend = data ? data.orders_trend : null;
    let chartData = null;
    if (ordersTrend) {
      let timeKey = isCount
        ? this.state.countChartTime.key
        : this.state.moneyChartTime.key;
      if (timeKey === Home.ChartTimes[0].key) {
        chartData = ordersTrend.seven_days;
      } else if (timeKey === Home.ChartTimes[1].key) {
        chartData = ordersTrend.thirty_days;
      } else if (timeKey === Home.ChartTimes[2].key) {
        chartData = ordersTrend.ninety_days;
      }
    }

    if (!chartData) chartData = [];

    return chartData;
  }

  getCountChartMax(data) {
    if (!data) data = [];

    let itemMax = 0;
    for (let i = 0; i < data.length; i++) {
      if (data[i].order_count > itemMax) itemMax = data[i].order_count;
    }

    let max = 5;
    if (itemMax > 150) {
      max = 200;
    } else if (itemMax > 100 && itemMax <= 150) {
      max = 150;
    } else if (itemMax > 50 && itemMax <= 100) {
      max = 100;
    } else if (itemMax > 30 && itemMax <= 50) {
      max = 50;
    } else if (itemMax > 10 && itemMax <= 30) {
      max = 30;
    } else if (itemMax > 5 && itemMax <= 10) {
      max = 10;
    } else if (itemMax > 0 && itemMax <= 5) {
      max = 5;
    }

    return max;
  }
}

export default connect(
  (state) => {
    return {
      user: getUserInfo(state),
    };
  },
  { setMerchantSettings }
)(Home);
