import React from "react";
import { List, Card, Row, Divider, Button, Spin, Switch, Image } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";

import Base from "../../Base";
import { getUserInfo } from "../../../utils/redux/reducers/User";
import EditAdForm from "./EditAdForm";

const { Meta } = Card;

class SplashManagement extends Base {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      data: null,
      adData: null,
      loading: false,
      currentPage: this.getRouterQueryPage(),
      currentPageSize: this.getRouterQueryPageSize(12),
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.onGetAdsList();
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);
    const { currentPage, currentPageSize } = this.state;

    if (
      prevState.currentPage !== currentPage ||
      prevState.currentPageSize !== currentPageSize
    ) {
      this.showMessage(this.i18n.t("proxy.updatingData"), Base.Message.loading);
      this.setRouterQuery({
        page: currentPage,
        pageSize: currentPageSize,
      });
      this.onGetAdsList();
    }
  }

  async onGetAdsList() {
    const token = this.getUserToken();
    if (!token) return;

    const { currentPageSize, currentPage } = this.state;
    const api = this.api.ads.list;
    const apiConfig = {
      token,
      query: {
        "filter[usage]": "splash",
        page: currentPage,
        count: currentPageSize,
      },
    };

    try {
      this.setState({ loading: true });
      const res = await api.run(apiConfig);
      if (!this.mounted) return;

      if (res.result) {
        this.setState({ data: res.data });
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;
      this.setState({ loading: false, adData: null });
    }
  }

  onHandleCancel = () => {
    this.setState({ adData: null });
  };

  onAddNewAd = () => {
    this.setState({ adData: {} });
  };

  onPaginationPageChange = (page, pageSize) => {
    this.setState({ currentPage: page });
  };

  onPaginationPageSizeChange = (page, pageSize) => {
    this.setState({ currentPageSize: pageSize, currentPage: 1 });
  };

  uploadImages = async ({ file, onError, onSuccess }) => {
    if (!file) onError("invalid images");

    const token = this.getUserToken();
    if (!token) onError("no user token");

    const uploadFiles = [{ file }];
    const apiConfig = {
      token,
      uploadFiles,
      uploadType: "splash",
    };

    try {
      const res = await this.api.common.uploadImages.run(apiConfig);
      if (res.result) {
        onSuccess(res.data[0], file);
      } else {
        onError(res.message);
      }
    } catch (error) {
      onError(error);
    }
  };

  getAdStatus(status) {
    if (typeof status === "number") return status;
    return status ? 1 : 0;
  }

  onFinishForm = async (values) => {
    const token = this.getUserToken();
    if (!token) return;

    const { zh_title, ug_title, order, status } = values;

    let api = this.api.ads.add;
    let urlData = null;
    let link_data = null;

    if (values.data) {
      link_data = values.data;
      urlData = values.data.url;
    }

    let apiConfig = {
      token,
      data: {
        zh_title,
        ug_title,
        link_data,
        data: urlData,
        usage: "splash",
        order,
        status: this.getAdStatus(status),
      },
    };

    if (values.ug_image && values.ug_image.length > 0) {
      apiConfig.data.ug_image = values["ug_image"][0]["file_path"];
    }
    if (values.zh_image && values.zh_image.length > 0) {
      apiConfig.data.zh_image = values["zh_image"][0]["file_path"];
    }

    const { adData } = this.state;
    if (adData && adData.id) {
      api = this.api.ads.update;
      apiConfig.path = { id: adData.id };
    }

    this.setState({ loading: true });
    try {
      const res = await api.run(apiConfig);
      if (!this.mounted) return;
      if (res.result) {
        this.onGetAdsList();
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;
      this.setState({ loading: false });
    }
  };

  onDeleteAd(id) {
    this.showModal(
      this.i18n.t("sales.deleteWarning"),
      Base.Modal.confirm,
      async () => {
        const token = this.getUserToken();
        if (!token) return;

        const api = this.api.ads.delete;
        const apiConfig = {
          token,
          path: { id },
        };
        try {
          const res = await api.run(apiConfig);
          if (res.result) {
            this.onGetAdsList();
          } else {
            this.showMessage(res.message);
          }
        } catch (error) {
          if (!this.mounted) return;
          this.handleApiError(api, error);
        }
      }
    );
  }

  onEditAd(adData) {
    this.setState({ adData });
  }

  async onChangeAdStatus(item) {
    const token = this.getUserToken();
    if (!token) return;

    let api = this.api.ads.update;
    const { ug_image, zh_image, ...otherEl } = item;
    let apiConfig = {
      token,
      path: { id: item.id },
      data: {
        ...otherEl,
        usage: "splash",
        status: item.status === 0 ? 1 : 0,
      },
    };

    this.setState({ loading: true });
    try {
      const res = await api.run(apiConfig);
      if (!this.mounted) return;
      if (res.result) {
        this.onGetAdsList();
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;
      this.setState({ loading: false });
    }
  }

  render() {
    const { currentPage, currentPageSize, data, adData, loading } = this.state;

    return (
      <Spin spinning={loading}>
        <List
          pagination={{
            current: currentPage,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSize: currentPageSize,
            pageSizeOptions: ["12", "24", "36"],
            onChange: this.onPaginationPageChange,
            onShowSizeChange: this.onPaginationPageSizeChange,
            total: data ? data.total : 0,
          }}
          header={
            <Row>
              <Button size="large" type="primary" onClick={this.onAddNewAd}>
                {this.i18n.t("sales.ad.add")}
              </Button>
            </Row>
          }
          grid={{
            gutter: 12,
            xs: 1,
            sm: 1,
            md: 1,
            lg: 2,
            xl: 3,
            xxl: 4,
          }}
          dataSource={data ? data.data : []}
          renderItem={(item) => (
            <List.Item>
              <Card
                hoverable
                style={{ flexDirection: "column" }}
                cover={
                  <Row
                    justify="center"
                    style={{
                      display: "flex",
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  >
                    <Image
                      style={{ width: 180 }}
                      alt={item.zh_title}
                      src={item.zh_image}
                    />
                    <Divider type="vertical" style={{ height: 80 }} />
                    <Image
                      style={{ width: 180 }}
                      alt={item.ug_title}
                      src={item.ug_image}
                    />
                  </Row>
                }
                actions={[
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={item.status !== 0}
                    onClick={() => this.onChangeAdStatus(item)}
                  />,
                  <EditOutlined
                    key="edit"
                    onClick={() => this.onEditAd(item)}
                  />,
                  <DeleteOutlined
                    key="delete"
                    onClick={() => this.onDeleteAd(item.id)}
                  />,
                ]}
              >
                <Meta
                  title={item.data}
                  description={`${item.zh_title}/${item.ug_title}`}
                />
              </Card>
            </List.Item>
          )}
        />
        <EditAdForm
          adData={adData}
          onCancel={this.onHandleCancel}
          onFinish={this.onFinishForm}
          onUploadImg={this.uploadImages}
          loading={loading}
          hasWeapp={this.isThirdPartyShopHasWeapp()}
          isBBShopMerchant={this.isBBShopMerchant()}
          tip="splash"
        />
      </Spin>
    );
  }
}

export default connect((state) => ({
  user: getUserInfo(state),
}))(SplashManagement);
