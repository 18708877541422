import React, { useEffect } from "react";

import { Modal, Input, Form, Button, InputNumber, Switch } from "antd";

import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Locale, Util } from "../../../utils";
import TranslateBtn from "../../../components/product/TranslateBtn";
import LinkSelector from "../../../components/linkSelector/LinkSelector";
import ImageUpload from "../../../components/media/ImageUpload";

const i18n = Locale.getInstance();
const util = Util.getInstance();

const EditAdForm = ({ adData, onCancel, onFinish, loading }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (adData && Object.keys(adData).length > 0) {
      const { zh_title, ug_title, order, zh_image, ug_image, status } = adData;
      form.setFieldsValue({
        zh_title,
        ug_title,
        order: !Number.isNaN(Number(order)) ? Number(order) : 0,
        status: status === 1,
        zh_image: util.getDefaultImages(zh_image),
        ug_image: util.getDefaultImages(ug_image),
      });
    }
    return () => {
      form.resetFields();
    };
  }, [adData, form]);
  console.log("🚀 ~ EditAdForm ~ adData:", adData);

  return (
    <Modal
      width={600}
      title={i18n.t("sales.ad.title")}
      open={!!adData}
      onCancel={onCancel}
      footer={null}
      forceRender
    >
      <Form labelCol={{ span: 6 }} form={form} onFinish={onFinish}>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldsValue }) => (
            <Form.Item
              shouldUpdate
              name="ug_title"
              label={i18n.t("agentLevel.ug")}
              initialValue={adData?.ug_title}
              rules={[
                {
                  required: true,
                  message: i18n.t("productEdit.standardRequired"),
                },
              ]}
            >
              <Input
                addonAfter={
                  <TranslateBtn
                    context={getFieldValue("ug_title")}
                    onFinish={(value) =>
                      setFieldsValue({
                        zh_title: value,
                      })
                    }
                    from="ug"
                    to="zh"
                  />
                }
              />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldsValue }) => (
            <Form.Item
              name="zh_title"
              label={i18n.t("agentLevel.zh")}
              initialValue={adData?.zh_title}
              rules={[
                {
                  required: true,
                  message: i18n.t("productEdit.standardRequired"),
                },
              ]}
            >
              <Input
                addonAfter={
                  <TranslateBtn
                    context={getFieldValue("zh_title")}
                    onFinish={(value) =>
                      setFieldsValue({
                        ug_title: value,
                      })
                    }
                    from="zh"
                    to="ug"
                  />
                }
              />
            </Form.Item>
          )}
        </Form.Item>

        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldsValue }) => (
            <Form.Item
              name="ug_image"
              label={i18n.t("sales.ad.ugImg")}
              rules={[
                {
                  required: true,
                  message: i18n.t("productEdit.standardRequired"),
                },
              ]}
            >
              <ImageUpload
                images={getFieldValue("ug_image")}
                setSelectedImages={(image) => {
                  setFieldsValue({
                    ug_image: image,
                  });
                }}
              />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldsValue }) => (
            <Form.Item
              name="zh_image"
              label={i18n.t("sales.ad.zhImg")}
              rules={[
                {
                  required: true,
                  message: i18n.t("productEdit.standardRequired"),
                },
              ]}
            >
              <ImageUpload
                images={getFieldValue("zh_image")}
                setSelectedImages={(image) => {
                  setFieldsValue({
                    zh_image: image,
                  });
                }}
              />
            </Form.Item>
          )}
        </Form.Item>

        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldsValue }) => (
            <Form.Item name="data" shouldUpdate label={i18n.t("sales.ad.type")}>
              <LinkSelector
                selectedLink={adData?.link_data ?? false}
                setSelectedLink={(link) => {
                  setFieldsValue({
                    data: link,
                  });
                }}
              />
            </Form.Item>
          )}
        </Form.Item>

        <Form.Item label={i18n.t("sales.ad.order")} name="order">
          <InputNumber
            style={{ width: "100%" }}
            max={99999}
            placeholder={i18n.t("sales.ad.orderPlace")}
          />
        </Form.Item>
        <Form.Item
          name="status"
          label={i18n.t("sales.status")}
          valuePropName="checked"
        >
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 10 }}>
          <Button
            disabled={loading}
            loading={loading}
            type="primary"
            htmlType="submit"
          >
            {i18n.t("sales.submit")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditAdForm;
