export default {

    accept: {
        name: "refund accept api",
        version: 6,
        url: "refund/%{refundId}/accept",
        method: "POST",
    },
    reject: {
        name: "refund reject api",
        version: 6,
        url: "refund/%{refundId}/reject",
        method: "POST",
    },

};
