import { Locale } from "../../utils";
import ArticleSelector from "./ArticleSelector";
import CourseCategorySelector from "./CourseCategorySelector";
import CourseSelector from "./CourseSelector";
import PlatformPages from "./PlatformPages";
import ProductCategorySelector from "./ProductCategorySelector";
import ProductSelector from "./ProductSelector";
import WeappInput from "./WeappInput";
import WebviewInput from "./WebviewInput";

const i18n = Locale.getInstance();
const linkTabsData = (link, selectLink, items) => {

  const links = {
    platformLinks: 0,
    productCategories: 1,
    products: 2,
    courseCategories: 3,
    courses: 4,
    articles: 5,
    weapp: 6,
    web: 7,
    agentLevelProduct: 8,

  };
  const tabs = [
    {
      label: i18n.t("link.platformLinks"),
      key: 1,
      disabled: false,
      children: (
        <PlatformPages selectedLink={link} setSelectedLink={selectLink} />
      ),
    },
    {
      label: i18n.t("productEdit.catLabel"),
      key: 2,
      disabled: false,
      children: (
        <ProductCategorySelector
          selectedLink={link}
          setSelectedLink={selectLink}
        />
      ),
    },
    {
      label: i18n.t("link.products"),
      key: 3,
      disabled: false,
      children: (
        <ProductSelector selectedLink={link} setSelectedLink={selectLink} />
      ),
    },
    {
      label: i18n.t("link.coursesCategories"),
      key: 4,
      disabled: false,
      children: (
        <CourseCategorySelector
          selectedLink={link}
          setSelectedLink={selectLink}
        />
      ),
    },
    {
      label: i18n.t("link.courses"),
      key: 5,
      disabled: false,
      children: (
        <CourseSelector selectedLink={link} setSelectedLink={selectLink} />
      ),
    },
    {
      label: i18n.t("article.title"),
      key: 6,
      disabled: false,
      children: (
        <ArticleSelector selectedLink={link} setSelectedLink={selectLink} />
      ),
    },
    {
      label: i18n.t("link.weapp"),
      key: 7,
      disabled: false,
      children: <WeappInput selectedLink={link} setSelectedLink={selectLink} />,
    },
    {
      label: i18n.t("link.webview"),
      key: 8,
      disabled: false,
      children: (
        <WebviewInput selectedLink={link} setSelectedLink={selectLink} />
      ),
    },
    {
      label: i18n.t("link.products"),
      key: 9,
      disabled: false,
      children: (
        <ProductSelector selectedLink={link} setSelectedLink={selectLink} agentLevelProduct={true} />
      ),
    },
  ];

  if (!items || items.length < 1) {
    delete(tabs[8])
    return tabs;
  }
  let result = [];
  items.map((value) => {
    if (Object.keys(links).includes(value)) {
      result.push(tabs[links[value]]);
    }
  });
  return result;
};
export default linkTabsData;
