import React from "react";

import {
  CheckCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
  ClockCircleOutlined,
  MinusCircleOutlined,
  LeftOutlined,
} from "@ant-design/icons";

import {
  Layout,
  Divider,
  Pagination,
  Spin,
  Button,
  Tag,
  Popover,
  Steps,
} from "antd";
import Base from "../Base";

//redux
import { connect } from "react-redux";
import { getUserInfo } from "../../utils/redux/reducers/User";

class FinanceCheckDaily extends Base {
  static Tabs = [
    {
      key: "order",
      name: "financeCheckDaily.tabs.order.name",
      tabs: [
        { key: "all", name: "financeCheckDaily.tabs.order.tabs.all" },
        { key: "normal", name: "financeCheckDaily.tabs.order.tabs.normal" },
        { key: "refund", name: "financeCheckDaily.tabs.order.tabs.refund" },
      ],
    },
    {
      key: "other",
      name: "financeCheckDaily.tabs.other.name",
      tabs: [
        { key: "all", name: "financeCheckDaily.tabs.other.tabs.all" },
        { key: "ship_fee", name: "financeCheckDaily.tabs.other.tabs.ship_fee" },
        { key: "bad_food", name: "financeCheckDaily.tabs.other.tabs.bad_food" },
        {
          key: "serve_return",
          name: "financeCheckDaily.tabs.other.tabs.serve_return",
        },
        {
          key: "part_refund",
          name: "financeCheckDaily.tabs.other.tabs.part_refund",
        },
        {
          key: "refund_neutralize",
          name: "financeCheckDaily.tabs.other.tabs.refund_neutralize",
        },
      ],
    },
  ];

  /* lifecycle methods */

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,

      currentMainTab: this.getRouterQueryItemByKey(
        FinanceCheckDaily.Tabs,
        "tab"
      ),
      currentSubTab: this.getRouterQueryItemByKey(
        FinanceCheckDaily.Tabs[0].tabs,
        "subTab"
      ),

      data: null,
      loadingData: false,
      currentPage: this.getRouterQueryPage(),
      currentPageSize: this.getRouterQueryPageSize(),
    };
  }

  componentDidMount() {
    super.componentDidMount();

    if (this.getUser()) {
      this.showMessage(
        this.i18n.t("financeCheckDaily.loadingData"),
        Base.Message.loading
      );
      this.loadData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);

    if (
      prevState.currentMainTab.key !== this.state.currentMainTab.key ||
      prevState.currentSubTab.key !== this.state.currentSubTab.key ||
      prevState.currentPage !== this.state.currentPage ||
      prevState.currentPageSize !== this.state.currentPageSize
    ) {
      this.showMessage(
        this.i18n.t("financeCheckDaily.loadingData"),
        Base.Message.loading
      );
      this.loadData();

      this.setRouterQuery({
        tab: this.state.currentMainTab.key,
        subTab: this.state.currentSubTab.key,
        page: this.state.currentPage,
        pageSize: this.state.currentPageSize,
      });
    }
  }

  /* render methods */

  render() {
    return (
      <Layout id="route-finance-check-daily">
        {this._renderHeader()}
        {this._renderList()}
      </Layout>
    );
  }

  _renderHeader() {
    let date = this.getRouterStateDataDate();
    let signDate = this.getRouterStateDataSignDate();

    let statusClass = "status";
    let statusIcon = <CheckCircleOutlined />;
    let statusText = `${signDate}${this.i18n.t(
      "financeCheckDaily.headerInfo"
    )}`;
    if (!this.getRouterStateDataIsSigned()) {
      statusClass += " unsettled";
      statusIcon = <ClockCircleOutlined />;
      statusText = this.i18n.t("financeCheckDaily.headerInfoUnsettled", {
        sign_date: signDate,
      });
    }

    return (
      <div className="header-wrap">
        <div className="header-title">
          <Button className="back" onClick={this.onHeaderBackClick} type="text">
            <LeftOutlined />
            {this.i18n.t("financeCheckDaily.headerBack")}
          </Button>
          <Divider type="vertical" />
          <span className="title">
            {this.i18n.t("financeCheckDaily.headerDate", {
              date: date,
            })}
          </span>
          <div className={statusClass}>
            {statusIcon}
            {statusText}
          </div>
        </div>
        <div className="header-sum">
          {this.util.getFormattedPrice(this.getRouterStateDataTotal(), "￥")}(
          {this.i18n.t("financeOrderDetail.detailTotal")}:
          {this.util.getFormattedPrice(
            this.getRouterStateDataSucceeded(),
            "￥"
          )}
          )
        </div>
      </div>
    );
  }

  _renderList() {
    return (
      <div className="list-wrap">
        {this._renderListTabs()}
        {this._renderListHeader()}
        {this._renderListItems()}
        {this._renderListPagination()}
        {this._renderListFooter()}
      </div>
    );
  }

  _renderListTabs() {
    let tabs = FinanceCheckDaily.Tabs;
    // let subTabs = this.state.currentMainTab.tabs;

    return (
      <div className="list-tabs">
        <div className="main-tabs">
          {tabs.map((tab, index) => {
            let className =
              this.state.currentMainTab.key === tab.key ? "current" : "";
            let sum =
              tab.key === FinanceCheckDaily.Tabs[0].key
                ? this.util.getFormattedPrice(this.getRouterStateDataTotal())
                : "0.00";

            return (
              <span
                key={`main-tab-${index}`}
                className={className}
                onClick={() => this.onMainTabClick(tab)}
              >
                {this.i18n.t(tab.name)} {sum}
              </span>
            );
          })}
          <div className="unit">
            {this.i18n.t("financeCheckDaily.listUnit")}
          </div>
        </div>
        {/* <div className="sub-tabs">
					{
						subTabs.map((tab, index) => {
							let className = (this.state.currentSubTab.key === tab.key) ? "current" : "";

							let items = [];

							items.push(
								<a
									key={`sub-tab-${index}`}
									className={className}
									onClick={() => this.onSubTabClick(tab)}>
									{this.i18n.t(tab.name)}
								</a>
							);

							if (index !== subTabs.length - 1) {
								items.push(
									<Divider key={`sub-tab-divider-${index}`} type="vertical" />
								);
							}

							return items;
						})
					}
				</div> */}
      </div>
    );
  }
  getStatusColor = (recordStatus) => {
    let color;
    switch (recordStatus) {
      case 3:
        color = "success";
        break;
      case 1:
      case 2:
        color = "processing";
        break;
      case 0:
        color = "default";
        break;
      default:
        color = "error";
        break;
    }
    return color;
  };
  getStatusIcon = (status) => {
    let icon = null;
    switch (status) {
      case 0:
        icon = <ClockCircleOutlined />;
        break;
      case 1:
      case 2:
        icon = <SyncOutlined spin />;
        break;
      case 3:
        icon = <CheckCircleOutlined />;
        break;
      case 4:
      case 5:
        icon = <CloseCircleOutlined />;
        break;
      case 10:
        icon = <MinusCircleOutlined />;
        break;
      default:
        icon = <MinusCircleOutlined />;
        break;
    }
    return icon;
  };
  _renderListHeader() {
    if (this.isCurrentMainTabOther()) {
      return (
        <div className="list-header other">
          <span className="date">
            {this.i18n.t("financeCheckDaily.listHeaderOtherDate")}
          </span>
          <span className="type">
            {this.i18n.t("financeCheckDaily.listHeaderOtherType")}
          </span>
          <span className="type-details">
            {this.i18n.t("financeCheckDaily.listHeaderOtherDetails")}
          </span>
          <span className="total-fee">
            {this.i18n.t("financeCheckDaily.listHeaderOtherTotal")}
          </span>
          <span className="actions">
            {this.i18n.t("financeCheckDaily.listHeaderOtherAction")}
          </span>
        </div>
      );
    }

    return (
      <div className="list-header">
        <span className="date">
          {this.i18n.t("financeCheckDaily.listHeaderOrderDate")}
        </span>
        <span className="product-fee">
          {this.i18n.t("financeCheckDaily.listHeaderOrderPrice")}
        </span>
        <span className="donate-fee">{this.i18n.t("commissionList.real")}</span>
        <span className="pack-fee">
          {this.i18n.t("financeCheckDaily.listHeaderOrderPacking")}
        </span>
        <span className="event-fee">
          {this.i18n.t("financeCheckDaily.listHeaderOrderEvent")}
        </span>
        <span className="platform-fee">
          {this.i18n.t("financeCheckDaily.listHeaderOrderPlatform")}
        </span>
        <span className="ship-fee">
          {this.i18n.t("financeCheckDaily.listHeaderOrderShip")}
        </span>
        <span className="total-fee">
          {this.i18n.t("financeCheckDaily.listHeaderOrderTotal")}
        </span>
        <span className="status">{this.i18n.t("sales.coupons.status")}</span>
        <span className="actions">
          {this.i18n.t("financeCheckDaily.listHeaderOrderAction")}
        </span>
      </div>
    );
  }
  statusPopoverContent = (changeLog, moment) => {
    let steps = [];
    for (let i = changeLog.length - 1; i >= 0; i--) {
      let status = changeLog[i];
      steps.push({
        key: `status-step-${i}`,
        icon: "profile",
        title: `[${status.changed_at.date.substr(0, 19)}] ${status.change_log}`,
      });
    }
    return (
      <div id="route-order-list-item-status-popover">
        <Steps direction="vertical" size="small" current={0} items={steps} />
      </div>
    );
  };
  _renderListItems() {
    if (this.isCurrentMainTabOther()) {
      return (
        <div className="list-items">
          <div className="no-data">
            {this.i18n.t("financeCheckDaily.loadingEmpty")}
          </div>
        </div>
      );
    }
    let placement = "bottom";
    let data = this.state.data;
    if (data) {
      let details = data ? data.details : null;
      let detailsData = details ? details.data : null;
      if (detailsData && detailsData.length > 0) {
        return (
          <Spin spinning={this.state.loadingData}>
            <div className="list-items">
              {detailsData.map((item, index) => {
                let shipPrice = "--";
                if (item.deliver_by_us !== 1) {
                  shipPrice = this.util.getFormattedPrice(item.ship_price);
                }

                return (
                  <div key={`list-item-${index}`} className="list-item">
                    <span className="date">
                      {this.getRouterStateDataDate()} #{item.sequence}
                    </span>
                    <span className="product-fee">
                      {this.util.getFormattedPrice(item.original_price)}
                    </span>
                    <span className="total-fee">
                      {this.util.getFormattedPrice(item.price)}
                    </span>
                    <span className="pack-fee">
                      {this.util.getFormattedPrice(item.packing_fee)}
                    </span>
                    <span className="event-fee">
                      -{this.util.getFormattedPrice(item.merchant_expenses)}
                    </span>
                    <span className="platform-fee">
                      {this.util.getFormattedPrice(item.platform_fee)}
                    </span>
                    <span className="ship-fee">{shipPrice}</span>

                    <span className="total-fee">
                      {this.util.getFormattedPrice(item.merchant_income)}
                    </span>
                    <span className="status">
                      <Popover
                        content={this.statusPopoverContent(
                          item.status_change_log,
                          this.moment
                        )}
                        placement={placement}
                      >
                        <Tag
                          icon={this.getStatusIcon(item.status)}
                          color={this.getStatusColor(item.status)}
                        >
                          {item.status_label}
                        </Tag>
                      </Popover>
                    </span>
                    <span className="actions">
                      <Button
                        type="text"
                        onClick={() => this.onListItemDetailClick(item)}
                      >
                        {this.i18n.t("financeCheckDaily.listItemActionDetail")}
                      </Button>
                    </span>
                  </div>
                );
              })}
            </div>
          </Spin>
        );
      } else {
        return (
          <div className="list-items">
            <div className="no-data">
              {this.i18n.t("financeCheckDaily.loadingEmpty")}
            </div>
          </div>
        );
      }
    } else {
      if (this.state.loadingData) {
        return (
          <div className="list-items">
            <div className="no-data">
              {this.i18n.t("financeCheckDaily.loadingData")}
            </div>
          </div>
        );
      }

      return (
        <div className="list-items">
          <div className="no-data">
            {this.i18n.t("financeCheckDaily.loadingEmpty")}
          </div>
        </div>
      );
    }
  }

  _renderListPagination() {
    if (this.isCurrentMainTabOther()) {
      return null;
    }

    let data = this.state.data;
    let details = data ? data.details : null;
    if (details && details.data && details.data.length > 0) {
      return (
        <div className="list-pagination">
          <Pagination
            total={details.total}
            current={this.state.currentPage}
            showSizeChanger={true}
            pageSizeOptions={["10", "20", "30"]}
            pageSize={this.state.currentPageSize}
            onChange={this.onPaginationPageChange}
            onShowSizeChange={this.onPaginationPageSizeChange}
            showQuickJumper
          />
        </div>
      );
    } else {
      return null;
    }
  }

  _renderListFooter() {
    if (this.isCurrentMainTabOther()) {
      return null;
    }

    let data = this.state.data;
    if (data) {
      let totalShipFee = "--";
      if (data.deliver_by_us !== 1) {
        totalShipFee = this.util.getFormattedPrice(data.total_ship_price);
      }

      return (
        <div className="list-footer">
          <span className="total-count">
            {this.i18n.t("financeCheckDaily.listFooterTotal", {
              order_count: data.order_count,
            })}
          </span>
          <span className="product-fee">
            {this.util.getFormattedPrice(data.total_price)}
          </span>
          <span className="pack-fee">
            {this.util.getFormattedPrice(data.total_packing_fee)}
          </span>
          <span className="event-fee">
            -{this.util.getFormattedPrice(data.total_merchant_expenses)}
          </span>
          <span className="platform-fee">
            -{this.util.getFormattedPrice(data.total_platform_fee)}
          </span>
          <span className="ship-fee">{totalShipFee}</span>

          <span className="total-fee">
            {this.util.getFormattedPrice(data.total_merchant_income)}
          </span>
          <span className="actions"></span>
        </div>
      );
    } else {
      return null;
    }
  }

  /* user methods */

  onHeaderBackClick = () => {
    this.props.history.goBack();
  };

  onMainTabClick(tab) {
    if (this.state.currentMainTab.key === tab.key) return;

    this.setState({
      currentMainTab: tab,
      currentSubTab: tab.tabs[0],
      // currentPage: 1,
    });
  }

  onSubTabClick(tab) {
    if (this.state.currentSubTab.key === tab.key) return;

    this.setState({
      currentSubTab: tab,
      // currentPage: 1,
    });
  }

  onListItemDetailClick(item) {
    this.props.history.push({
      pathname: "/main/finance/order-detail",
      state: {
        order: item,
      },
    });
  }

  onPaginationPageChange = (page, pageSize) => {
    this.setState({ currentPage: page });
  };

  onPaginationPageSizeChange = (page, pageSize) => {
    this.setState({ currentPageSize: pageSize, currentPage: 1 });
  };

  /* server methods */

  async loadData() {
    if (this.state.loadingData) return;

    let id = this.getRouterStateDataId();
    if (id <= 0) return;

    let token = this.getUserToken();
    if (!token) return;

    let api = this.api.checkout.dailyCheckout;
    let apiConfig = {
      token: token,
      path: {
        id: id,
      },
      query: {
        page: this.state.currentPage,
        count: this.state.currentPageSize,
      },
    };

    this.setState({ loadingData: true });
    try {
      let res = await api.run(apiConfig);

      if (!this.mounted) return;

      if (res.result) {
        this.setState({ data: res.data });
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;
      this.setState({ loadingData: false });
    }
  }

  /* custom methods */

  isCurrentMainTabOther() {
    return this.state.currentMainTab.key === FinanceCheckDaily.Tabs[1].key;
  }

  getRouterStateData() {
    return this.getFromRouterState("data");
  }

  getRouterStateDataId() {
    let data = this.getRouterStateData();
    return data ? data.id : -1;
  }

  getRouterStateDataDate() {
    let data = this.getRouterStateData();
    return data ? data.checkout_date : "--";
  }

  getRouterStateDataSignDate() {
    let data = this.getRouterStateData();
    return data ? data.sign_date : "--";
  }

  getRouterStateDataIsSigned() {
    let data = this.getRouterStateData();
    return data ? data.is_signed : false;
  }

  getRouterStateDataTotal() {
    let data = this.getRouterStateData();
    return data ? data.total_merchant_income : 0;
  }
  getRouterStateDataSucceeded() {
    let data = this.getRouterStateData();
    return data ? data.succeeded_amount : 0;
  }
}

export default connect((state) => {
  return {
    user: getUserInfo(state),
  };
}, {})(FinanceCheckDaily);
