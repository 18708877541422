import {
  Avatar,
  Button,
  Card,
  Form,
  Input,
  Radio,
  Select,
  Spin,
  Switch,
} from "antd";
import React, { useEffect, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useForm } from "antd/lib/form/Form";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom";

import { Locale } from "../../../utils";
import {
  handleMutationError,
  showNotificationWithIcon,
} from "../../../utils/helpers/helpers";
import { getUserInfo } from "../../../utils/redux/reducers/User";
import { BreadCrumb } from "../../../components/main/LayoutComponents";

import "./AddOrEditCourse.scss";
import {
  create,
  getList,
  getOne,
  update,
} from "../../../utils/http/dataProvider";
import ImageUpload from "../../../components/media/ImageUpload";
import TranslateBtn from "../../../components/product/TranslateBtn";
import SlateRichTextEditor from "../../../components/editor/SlateRichTextEditor";
import { TYPE_ARTICLE } from "./courseTypes";
import LinkSelector from "../../../components/linkSelector/LinkSelector";
const i18n = Locale.getInstance();

const AddOrEditCourse = ({ initialType = TYPE_ARTICLE }) => {
  const user = useSelector((state) => getUserInfo(state));
  const [loading, setLoading] = useState(false);
  const [teacher, setTeacher] = useState(false);
  const [category, setCategory] = useState(false);
  const [showValidDays, setShowValidDays] = useState(false);
  const queryClient = useQueryClient();
  const [courseForm] = useForm();
  const history = useHistory();
  const location = useLocation();

  const id = location?.state?.id;
  const type = location?.state?.type ?? initialType;

  const { data, isLoading } = useQuery(
    ["course-detail", user.token],
    () => getOne("courses", { id, token: user.token }),
    {
      enabled: !!id,
    }
  );

  const { data: teachers } = useQuery(["course-teachers", user.token], () =>
    getList("course/teachers", {
      pagination: {
        perPage: 100,
        page: 1,
      },
      sort: {
        field: "id",
        order: "desc",
      },
      token: user.token,
    })
  );

  const { data: categories } = useQuery(["course-categories", user.token], () =>
    getList("course/categories", {
      pagination: {
        perPage: 100,
        page: 1,
      },
      sort: {
        field: "id",
        order: "desc",
      },
      token: user.token,
    })
  );

  useEffect(() => {
    if (id === data?.id && data) {
      const values = {
        zh_name: data.zh_name,
        ug_name: data.ug_name,
        zh_subtitle: data.zh_subtitle,
        ug_subtitle: data.ug_subtitle,
        zh_description: data.zh_description,
        ug_description: data.ug_description,
        zh_brief: data.zh_brief,
        ug_brief: data.ug_brief,
        ug_cover: data?.ug_cover_media ? [data?.ug_cover_media] : [],
        zh_cover: data?.zh_cover_media ? [data?.zh_cover_media] : [],
        course_category_id: data.course_category_id,
        teacher_id: data.teacher_id,
        status: data.status,
        default_view: data.default_view,
        actual_view: data.actual_view,
        default_sold: data.default_sold,
        actual_sold: data.actual_sold,
        market_price: data.market_price,
        price: data.price,
        order: data.order,
        valid_days: data.valid_days,
        valid_forever: data.valid_forever,
        no_commission: !!data.commission_percent,
        commission_percent: data.commission_percent,
        parent_commission_percent: data.parent_commission_percent,
      };
      if (data.teacher) {
        setTeacher(data.teacher);
      }

      if (data.category) {
        setCategory(data.category);
      }
      if (values.valid_forever === 0) {
        setShowValidDays(true);
      }

      courseForm.setFieldsValue(values);
    }
    if (!id) {
      const values = {
        zh_name: "",
        ug_name: "",
        zh_subtitle: "",
        ug_subtitle: "",
        zh_description: null,
        ug_description: null,
        zh_brief: null,
        ug_brief: null,
        ug_cover: [],
        zh_cover: [],
        course_category_id: "",
        teacher_id: "",
        default_view: "",
        actual_view: "",
        default_sold: "",
        actual_sold: "",
        market_price: "",
        price: "",
        order: "",
        valid_days: "",
        no_commission: "",
        commission_percent: "",
        parent_commission_percent: "",
      };
      courseForm.setFieldsValue(values);
    }
  }, [data, id]);

  const addCourseMutation = useMutation(create, {
    onSuccess: () => {
      queryClient.invalidateQueries("courses");
      showNotificationWithIcon(i18n.t("media.saveFinished"), "success");
      setLoading(false);
    },
    onError: (error) => {
      handleMutationError(error);
      setLoading(false);
    },
  });

  const updateCourseMutation = useMutation(update, {
    onSuccess: () => {
      queryClient.invalidateQueries("courses");
      showNotificationWithIcon(i18n.t("media.saveFinished"), "success");
      setLoading(false);
    },
    onError: (error) => {
      handleMutationError(error);
      setLoading(false);
    },
  });

  const onFinish = (values) => {
    if (values.zh_cover && values.zh_cover.length > 0) {
      values["zh_cover"] = values["zh_cover"][0]["file_path"];
    }
    if (values.ug_cover && values.ug_cover.length > 0) {
      values["ug_cover"] = values["ug_cover"][0]["file_path"];
    }
    values["type"] = type;
    setLoading(true);
    const params = {
      data: values,
      token: user.token,
    };

    if (id) {
      updateCourseMutation.mutate({
        resource: "courses",
        params: { ...params, id },
      });
    } else {
      addCourseMutation.mutate({ resource: "courses", params });
    }
    history.goBack();
  };

  const onFinishFailed = (errorInfo) => {
    setLoading(false);
    showNotificationWithIcon(errorInfo);
  };

  const breadCrumbData = [
    {
      href: "/main/courses",
      title: i18n.t("course.title"),
    },
    {
      title: id ? i18n.t("course.edit") : i18n.t("course.add"),
    },
  ];

  if (isLoading) {
    return <Spin spinning={isLoading} />;
  }
  return (
    <div className="edit-course-page">
      <BreadCrumb data={breadCrumbData} />
      <Spin spinning={loading}>
        <Form
          form={courseForm}
          className="course-form"
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Card title={i18n.t("course.basicInfo")} className="basic-info info">
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldsValue }) => (
                <Form.Item
                  shouldUpdate
                  name="ug_name"
                  label={i18n.t("course.ugName")}
                  initialValue={data?.ug_name}
                  rules={[
                    {
                      required: true,
                      message: i18n.t("productEdit.standardRequired"),
                    },
                  ]}
                >
                  <Input
                    addonAfter={
                      <TranslateBtn
                        context={getFieldValue("ug_name")}
                        onFinish={(value) =>
                          setFieldsValue({
                            zh_name: value,
                          })
                        }
                        from="ug"
                        to="zh"
                      />
                    }
                  />
                </Form.Item>
              )}
            </Form.Item>

            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldsValue }) => (
                <Form.Item
                  name="zh_name"
                  label={i18n.t("course.zhName")}
                  initialValue={data?.zh_name}
                  rules={[
                    {
                      required: true,
                      message: i18n.t("productEdit.standardRequired"),
                    },
                  ]}
                >
                  <Input
                    addonAfter={
                      <TranslateBtn
                        context={getFieldValue("zh_name")}
                        onFinish={(value) =>
                          setFieldsValue({
                            ug_name: value,
                          })
                        }
                        from="zh"
                        to="ug"
                      />
                    }
                  />
                </Form.Item>
              )}
            </Form.Item>

            <Form.Item name="teacher_id" label={i18n.t("course.selectTeacher")}>
              {teacher ? (
                <>
                  <>
                    <Avatar src={teacher.avatar} shape="square" size="large" />
                    {i18n.getPropNameByLocale(teacher, "name")}
                  </>
                  <Button
                    type="link"
                    onClick={() => {
                      setTeacher();
                    }}
                  >
                    {i18n.t("member.reselect")}
                  </Button>
                </>
              ) : (
                <>
                  <Select
                    style={{ width: 120 }}
                    onChange={(v) => {
                      courseForm.setFieldsValue({ teacher_id: v });
                    }}
                  >
                    {teachers?.data.map((teacher) => {
                      return (
                        <Select.Option key={teacher.id} value={teacher.id}>
                          {i18n.getPropNameByLocale(teacher, "name")}
                        </Select.Option>
                      );
                    })}
                  </Select>
                  <>
                    <Button type="link">{i18n.t("common.refresh")}</Button>
                  </>
                  <>
                    <Button type="link">{`${i18n.t(
                      "course.goToTeachers"
                    )} >>`}</Button>
                  </>
                </>
              )}
            </Form.Item>

            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldsValue }) => (
                <Form.Item
                  name="course_category_id"
                  label={i18n.t("course.selectCategory")}
                  rules={[
                    {
                      required: true,
                      message: i18n.t("productEdit.standardRequired"),
                    },
                  ]}
                >
                  <>
                    <LinkSelector
                      items={["courseCategories"]}
                      selectedLink={
                        category
                          ? {
                              key: category.id,
                              ug_name: `دەرس تۈرى~${category.ug_name}`,
                              zh_name: `课程分类~${category.zh_name}`,
                              url: `/main/course/category/index?courseId=${category.id}`,
                            }
                          : false
                      }
                      setSelectedLink={(link) => {
                        setCategory({
                          id: link.key,
                          ug_name: link.ug_name.replace("دەرس تۈرى~", ""),
                          zh_name: link.zh_name.replace("课程分类~", ""),
                        });
                        if (!link.key) {
                          setCategory();
                        }
                        setFieldsValue({
                          course_category_id: link.key,
                        });
                      }}
                    />
                  </>
                </Form.Item>
              )}
            </Form.Item>

            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldsValue }) => (
                <Form.Item
                  name="zh_cover"
                  label={i18n.t("course.zhCover")}
                  rules={[
                    {
                      required: true,
                      message: i18n.t("productEdit.standardRequired"),
                    },
                  ]}
                >
                  <ImageUpload
                    images={getFieldValue("zh_cover")}
                    setSelectedImages={(image) => {
                      setFieldsValue({
                        zh_cover: image,
                      });
                    }}
                  />
                </Form.Item>
              )}
            </Form.Item>

            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldsValue }) => (
                <Form.Item
                  name="ug_cover"
                  label={i18n.t("course.ugCover")}
                  rules={[
                    {
                      required: true,
                      message: i18n.t("productEdit.standardRequired"),
                    },
                  ]}
                >
                  <ImageUpload
                    images={getFieldValue("ug_cover")}
                    setSelectedImages={(image) => {
                      setFieldsValue({
                        ug_cover: image,
                      });
                    }}
                  />
                </Form.Item>
              )}
            </Form.Item>

            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldsValue }) => (
                <>
                  {type === 0 ? (
                    <Form.Item noStyle shouldUpdate>
                      {({ getFieldValue, setFieldsValue }) => (
                        <Form.Item
                          name="zh_brief"
                          label={i18n.t("course.zhBrief")}
                          initialValue={data?.zh_brief}
                        >
                          <SlateRichTextEditor
                            setFieldsValue={(value) =>
                              setFieldsValue({
                                zh_brief: value,
                              })
                            }
                            initialValue={id ? data?.zh_brief : null}
                          />
                        </Form.Item>
                      )}
                    </Form.Item>
                  ) : null}
                  <Form.Item
                    name="zh_description"
                    label={i18n.t("course.zhDescription")}
                    initialValue={id ? data?.zh_description : null}
                  >
                    <SlateRichTextEditor
                      setFieldsValue={(value) =>
                        setFieldsValue({
                          zh_description: value,
                        })
                      }
                      initialValue={id ? data?.zh_description : null}
                    />
                  </Form.Item>
                </>
              )}
            </Form.Item>

            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldsValue }) => (
                <>
                  {type === 0 ? (
                    <Form.Item noStyle shouldUpdate>
                      {({ getFieldValue, setFieldsValue }) => (
                        <Form.Item
                          name="ug_brief"
                          label={i18n.t("course.ugBrief")}
                          initialValue={data?.ug_brief}
                        >
                          <SlateRichTextEditor
                            setFieldsValue={(value) =>
                              setFieldsValue({
                                ug_brief: value,
                              })
                            }
                            initialValue={id ? data?.ug_brief : null}
                          />
                        </Form.Item>
                      )}
                    </Form.Item>
                  ) : null}

                  <Form.Item
                    name="ug_description"
                    label={i18n.t("course.ugDescription")}
                    initialValue={data?.ug_description}
                  >
                    <SlateRichTextEditor
                      setFieldsValue={(value) =>
                        setFieldsValue({
                          ug_description: value,
                        })
                      }
                      initialValue={id ? data?.ug_description : null}
                    />
                  </Form.Item>
                </>
              )}
            </Form.Item>

            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldsValue }) => (
                <Form.Item
                  shouldUpdate
                  name="ug_subtitle"
                  label={i18n.t("course.ugSubTitle")}
                  initialValue={data?.ug_subtitle}
                  rules={[
                    {
                      required: true,
                      message: i18n.t("productEdit.standardRequired"),
                    },
                  ]}
                >
                  <Input
                    addonAfter={
                      <TranslateBtn
                        context={getFieldValue("ug_subtitle")}
                        onFinish={(value) =>
                          setFieldsValue({
                            zh_subtitle: value,
                          })
                        }
                        from="ug"
                        to="zh"
                      />
                    }
                  />
                </Form.Item>
              )}
            </Form.Item>

            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldsValue }) => (
                <Form.Item
                  name="zh_subtitle"
                  label={i18n.t("course.zhSubTitle")}
                  initialValue={data?.zh_subtitle}
                  rules={[
                    {
                      required: true,
                      message: i18n.t("productEdit.standardRequired"),
                    },
                  ]}
                >
                  <Input
                    addonAfter={
                      <TranslateBtn
                        context={getFieldValue("zh_subtitle")}
                        onFinish={(value) =>
                          setFieldsValue({
                            ug_subtitle: value,
                          })
                        }
                        from="zh"
                        to="ug"
                      />
                    }
                  />
                </Form.Item>
              )}
            </Form.Item>

            <Form.Item
              name="default_view"
              label={i18n.t("course.defaultView")}
              shouldUpdate
            >
              <Input style={{ width: 120 }} />
            </Form.Item>

            <Form.Item name="actual_view" label={i18n.t("course.actualView")}>
              <Input style={{ width: 120 }} disabled />
            </Form.Item>
            <Form.Item
              name="status"
              label={i18n.t("course.status")}
              initialValue={1}
            >
              <Radio.Group>
                <Radio defaultChecked={true} value={1}>
                  {i18n.t("course.statusActive")}
                </Radio>
                <Radio value={0}>{i18n.t("course.statusInActive")}</Radio>
              </Radio.Group>
            </Form.Item>
          </Card>
          <Card
            title={i18n.t("course.saleInfo")}
            className="info commission-info"
          >
            <Form.Item
              name="market_price"
              label={i18n.t("course.marketPrice")}
              rules={[
                {
                  required: true,
                  message: i18n.t("productEdit.standardRequired"),
                },
              ]}
            >
              <Input
                style={{ width: 120 }}
                addonAfter={<span>{i18n.t("common.yuan")}</span>}
              />
            </Form.Item>
            <Form.Item
              name="price"
              label={i18n.t("course.price")}
              rules={[
                {
                  required: true,
                  message: i18n.t("productEdit.standardRequired"),
                },
              ]}
            >
              <Input
                style={{ width: 120 }}
                addonAfter={<span>{i18n.t("common.yuan")}</span>}
              />
            </Form.Item>
          </Card>

          <Card
            title={i18n.t("course.commissionInfo")}
            className="commission-info info"
          >
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldsValue }) => (
                <Form.Item
                  name="no_commission"
                  label={i18n.t("member.independentCommission")}
                  valuePropName="checked"
                  initialValue={getFieldValue("no_commission")}
                >
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldsValue }) =>
                !!getFieldValue("no_commission") && (
                  <>
                    <Form.Item
                      name="commission_percent"
                      label={i18n.t("course.commissionPercent")}
                    >
                      <Input
                        style={{ width: 120 }}
                        addonAfter={<span>%</span>}
                      />
                    </Form.Item>
                    <Form.Item
                      name="parent_commission_percent"
                      label={i18n.t("course.parentCommissionPercent")}
                    >
                      <Input
                        style={{ width: 120 }}
                        addonAfter={<span>%</span>}
                      />
                    </Form.Item>
                  </>
                )
              }
            </Form.Item>
          </Card>

          <Card title={i18n.t("course.otherInfo")} className="other-info info">
            <Form.Item
              name="valid_forever"
              label={i18n.t("course.validDate")}
              initialValue={1}
            >
              <Radio.Group
                onChange={(v) => {
                  if (v.target.value === 0) {
                    setShowValidDays(true);
                  } else {
                    setShowValidDays(false);
                  }
                }}
              >
                <Radio defaultChecked={true} value={1}>
                  {i18n.t("course.validForever")}
                </Radio>
                <Radio value={0}>{i18n.t("course.validDays")}</Radio>
              </Radio.Group>
            </Form.Item>
            {showValidDays ? (
              <Form.Item
                name="valid_days"
                shouldUpdate
                label={i18n.t("course.validDays")}
              >
                <Input
                  style={{ width: 120 }}
                  addonAfter={<span>{i18n.t("common.dayUnit")}</span>}
                />
              </Form.Item>
            ) : null}
            <Form.Item
              name="order"
              shouldUpdate
              label={i18n.t("shows.sortTypesTitle")}
            >
              <Input style={{ width: 120 }} />
            </Form.Item>
          </Card>

          <Form.Item className="submit">
            <Button type="primary" htmlType="submit">
              {i18n.t("productEdit.saveAndBack")}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default AddOrEditCourse;
