import { Avatar, Input, message, Space, Statistic, Table, Tag } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Locale, Request } from "../../utils";
import { getUserInfo } from "../../utils/redux/reducers/User";

const i18n = Locale.getInstance();

function MembersOrder() {
  const user = useSelector((state) => getUserInfo(state));
  const fetchOrders = useRef();
  const [orders, setOrders] = useState({ data: [] });
  const [loading, setLoading] = useState(false);
  fetchOrders.current = async (keyword = "") => {
    if (user.token) {
      try {
        const url = `/member/api/v6/merchant/order?keyword=${keyword}`;
        setLoading(true);
        const res = await Request.get(url, user.token);
        if (res.result) {
          setOrders(res.data);
        } else {
          message.warning(res.message);
        }
      } catch (error) {
        message.error(error.message);
      } finally {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    fetchOrders.current();
  }, []);
  const columns = [
    {
      key: "user",
      title: i18n.t("member.info"),
      width: 240,
      render: (text, record) => {
        if (record.user) {
          const { avatar, nickname, mobile } = record.user;
          return (
            <Space>
              <Avatar shape="circle" size="large" src={avatar} />
              <Space direction="vertical">
                {nickname}
                {mobile}
              </Space>
            </Space>
          );
        }
        return null;
      },
    },
    {
      key: "price",
      title: i18n.t("member.price"),
      width: 140,
      render: (text, record) => (
        <Statistic prefix="¥" precision={2} value={record.price / 100} />
      ),
    },
    {
      key: "status",
      title: i18n.t("order.status"),
      render: (text, record) => <Tag>{record.status_label}</Tag>,
      width: 140,
    },
    {
      key: "paid",
      title: i18n.t("member.paidAt"),
      dataIndex: "paid_at",
      width: 200,
    },
  ];
  return (
    <>
      <Input.Search
        allowClear
        enterButton={i18n.t("main.search")}
        size="large"
        style={{ marginBottom: 20, width: "30vw" }}
        onSearch={(keyword) => fetchOrders.current(keyword)}
      />
      <Table
        rowKey={(record) => `member-${record.id}`}
        dataSource={orders.data}
        loading={loading}
        columns={columns}
        pagination={{
          current: orders.current_page,
          total: orders.total,
          showQuickJumper: true,
          showSizeChanger: true,
        }}
        scroll={{
          x: 720,
        }}
      />
    </>
  );
}

export default MembersOrder;
