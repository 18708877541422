import { Avatar, Button, Drawer, Form, Input, Space, Table } from "antd";
import { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  handleMutationError,
  showNotificationWithIcon,
} from "../../../utils/helpers/helpers";
import { getList, update } from "../../../utils/http/dataProvider";
import { getUserInfo } from "../../../utils/redux/reducers/User";
import { BreadCrumb } from "../../../components/main/LayoutComponents";

import { redeemOrderListColumns } from "./redeemOrderListColumns";
import RedeemOrderListHeader from "./RedeemOrderListHeader";
import breadCrumbDefault from "./breadCrumb";
import { Locale } from "../../../utils";
import Base from "../../Base";
import "./redeemOrder.scss";

const i18n = Locale.getInstance();
const base = Base.getInstance();
const { TextArea } = Input;

const RedeemOrderList = () => {
  const user = useSelector((state) => getUserInfo(state));
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({ state: -1, sort: "created_at@desc" });
  const history = useHistory();

  const { sort } = filter;
  const [field, order] = sort.split("@");
  const queryFilter = {};
  if (filter.state !== -1) queryFilter.status = filter.state;
  if (filter.dates)
    queryFilter.start_date = filter.dates[0].format("YYYY-MM-DD");
  if (filter.dates) queryFilter.end_date = filter.dates[1].format("YYYY-MM-DD");
  const { data, error, isLoading } = useQuery(
    ["lottery-redeem-orders", user.token, filter],
    () =>
      getList("lottery-redeem-orders", {
        pagination: {
          perPage: 10,
          page,
        },
        sort: {
          field,
          order,
        },
        filter: queryFilter,
        token: user.token,
      })
  );

  const updateRedeemOrderMutation = useMutation(update, {
    onSuccess: () => {
      queryClient.invalidateQueries("lottery-redeem-orderes");
      showNotificationWithIcon(i18n.t("media.saveFinished"), "success");
    },
    onError: (error) => {
      handleMutationError(error);
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  if (error) {
    showNotificationWithIcon(error.data.message);
  }

  const handleApprove = (id) => {
    base.showModal("", Base.Modal.confirm, () => {
      try {
        setLoading(true);
        updateRedeemOrderMutation.mutate({
          resource: `lottery-redeem-orderes`,
          params: { token: user.token, id, data: { status: 1 } },
        });
      } catch (error) {
        console.log(
          "🚀 ~ file: RedeemOrderList.jsx:78 ~ handleApprove ~ error:",
          error
        );
      }
    });
  };
  const handleReject = (id) => {
    base.showModal("", Base.Modal.confirm, () => {
      try {
        setLoading(true);
        updateRedeemOrderMutation.mutate({
          resource: `lottery-redeem-orderes`,
          params: { token: user.token, id, data: { status: 0 } },
        });
      } catch (error) {
        console.log(
          "🚀 ~ file: RedeemOrderList.jsx:78 ~ handleApprove ~ error:",
          error
        );
      }
    });
  };

  return (
    <div className="redeem-order-list">
      <BreadCrumb data={breadCrumbDefault} />
      <RedeemOrderListHeader setFilter={setFilter} filter={filter} />
      <div className="qrcode">
        <Table
          expandable={{
            expandedRowRender: (record) => {
              if (record?.details && record.details.length > 0) {
                return (
                  <div className="details">
                    {record.details.map((detail) => {
                      return (
                        <div key={detail.id} className="detail">
                          <Avatar
                            size="large"
                            src={i18n.getPropNameByLocale(
                              detail.prize,
                              "image"
                            )}
                          />
                          <div className="name">
                            {i18n.getPropNameByLocale(detail.prize, "name")}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              }
            },
          }}
          className="list"
          loading={isLoading || loading}
          rowKey="id"
          dataSource={data?.data}
          columns={redeemOrderListColumns(history, handleApprove, handleReject)}
          scroll={{
            x: 1200,
          }}
          pagination={{ onChange: handleChangePage }}
        />
      </div>
    </div>
  );
};

export default RedeemOrderList;
