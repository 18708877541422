import { Button } from "antd";
import { Locale } from "../../../utils";

const i18n = Locale.getInstance();
const CategoryListHeader = ({ selectCategory }) => {
  return (
    <div className="header">
      <div className="actions">
        <Button
          onClick={() => {
            selectCategory();
          }}
          type="primary"
        >
          {i18n.t("course.category.add")}
        </Button>
      </div>
    </div>
  );
};
export default CategoryListHeader;
