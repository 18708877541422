import {
  CheckOutlined,
  CloseOutlined,
  LeftOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Form,
  Input,
  Upload,
  Button,
  Switch,
  message,
  Layout,
  TreeSelect,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import BraftEditor from "braft-editor";
import "braft-editor/dist/index.css";

import { ApiManager, Locale, Util } from "../../utils";
import { getUserInfo } from "../../utils/redux/reducers/User";
import Base from "../Base";
import TranslateBtn from "../../components/product/TranslateBtn";
import { useHistory, useLocation } from "react-router-dom";

const api = ApiManager.getInstance();
const i18n = Locale.getInstance();
const util = Util.getInstance();
const base = Base.getInstance();

function onBeforeUpload(file) {
  if (file) {
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
      message.error(i18n.t("productEdit.uploadImageSizeWarning"));
    }
    return isLt1M;
  }
  return false;
}

function ArticleEdit() {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => getUserInfo(state));
  const router = useHistory();
  const location = useLocation();

  const braftUpload = async (param) => {
    const token = user.token;
    if (token) {
      const uploadFiles = [{ file: param.file }];
      const apiConfig = {
        token,
        uploadFiles,
        uploadType: "article-content",
      };
      try {
        const res = await api.common.uploadImages.run(apiConfig);
        if (res.result) {
          param.success({
            url: res.data[0].url,
          });
        } else {
          param.error({
            msg: res.message,
          });
        }
      } catch (error) {
        param.error({
          msg: error.message,
        });
      }
    }
  };

  const onUploadImg = async ({ file, onError, onSuccess }) => {
    if (!file) onError("invalid images");
    const token = user.token;
    if (!token) onError("no user token");
    const uploadFiles = [{ file }];
    const apiConfig = {
      token,
      uploadFiles,
      uploadType: "article-cover",
    };
    try {
      const res = await api.common.uploadImages.run(apiConfig);
      if (res.result) {
        onSuccess(res.data[0], file);
      } else {
        onError(res.message);
      }
    } catch (error) {
      onError(error);
    }
  };

  async function onFinish(values) {
    const { token } = user;
    if (token) {
      let apiUrl = api.article.add;
      const {
        zh_image,
        ug_image,
        status,
        ug_description,
        zh_description,
        ...otherValues
      } = values;
      let apiConfig = {
        token,
        data: {
          ...otherValues,
          status: status ? 1 : 0,
          zh_description: zh_description && zh_description.toHTML(),
          ug_description: ug_description && ug_description.toHTML(),
        },
      };
      if (
        zh_image &&
        zh_image[0] &&
        zh_image[0].uid !== -1 &&
        zh_image[0].response
      ) {
        apiConfig.data.zh_image = zh_image[0].response.path;
      }
      if (
        ug_image &&
        ug_image[0] &&
        ug_image[0].uid !== -1 &&
        ug_image[0].response
      ) {
        apiConfig.data.ug_image = ug_image[0].response.path;
      }

      const { state } = location;
      if (state && state.data) {
        apiUrl = api.article.update;
        apiConfig.path = { id: state.data.id };
      }

      try {
        setLoading(true);
        const res = await apiUrl.run(apiConfig);
        if (res.result) {
          base.showMessage(res.message, Base.Message.success);
          router.goBack();
        } else {
          base.showModal(res.message, Base.Modal.warning);
        }
      } catch (error) {
        base.handleApiError(apiUrl, error);
      } finally {
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    const { state } = location;
    if (state && state.data) {
      const {
        zh_name,
        zh_image,
        zh_description,
        ug_image,
        ug_name,
        ug_description,
        status,
        category_id,
      } = state.data;
      form.setFieldsValue({
        zh_name,
        ug_name,
        zh_description: BraftEditor.createEditorState(zh_description),
        ug_description: BraftEditor.createEditorState(ug_description),
        zh_image: util.getDefaultImages(zh_image),
        ug_image: util.getDefaultImages(ug_image),
        status: status === 1,
        category_id: `${category_id}`,
      });
    }
  }, [form, location]);

  const { state } = location;
  let treeData =
    state && state.categories.length > 0
      ? state.categories.map((i) => ({
          title: i18n.getPropNameByLocale(i, "name"),
          value: `${i.id}`,
        }))
      : [];

  return (
    <Layout>
      <Layout.Header style={{ backgroundColor: "#fff" }}>
        <Button
          type="link"
          onClick={() => {
            router.goBack();
          }}
        >
          <LeftOutlined />
          {i18n.t("productEdit.headerBack")}
        </Button>
      </Layout.Header>
      <Layout.Content>
        <Form
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 6 }}
          form={form}
          onFinish={onFinish}
          style={{ backgroundColor: "#fff", paddingTop: 20, paddingBottom: 20 }}
        >
          <Form.Item
            label={i18n.t("article.cat")}
            name="category_id"
            rules={[
              {
                required: true,
                message: i18n.t("productEdit.catRequired"),
              },
            ]}
          >
            <TreeSelect treeData={treeData} treeDefaultExpandAll />
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                rules={[
                  { required: true, message: i18n.t("sales.zhTitleWarning") },
                ]}
                label={i18n.t("sales.zhTitle")}
                name="zh_name"
              >
                <Input
                  addonAfter={
                    <TranslateBtn
                      context={getFieldValue("ug_name")}
                      onFinish={(value) =>
                        setFieldsValue({
                          zh_name: value,
                        })
                      }
                      from="ug"
                      to="zh"
                    />
                  }
                />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                rules={[
                  { required: true, message: i18n.t("sales.ugTitleWarning") },
                ]}
                label={i18n.t("sales.ugTitle")}
                name="ug_name"
              >
                <Input
                  addonAfter={
                    <TranslateBtn
                      context={getFieldValue("zh_name")}
                      onFinish={(value) =>
                        setFieldsValue({
                          ug_name: value,
                        })
                      }
                      from="zh"
                      to="ug"
                    />
                  }
                />
              </Form.Item>
            )}
          </Form.Item>
          {[
            { label: i18n.t("sales.ad.zhImg"), name: "zh_image" },
            { label: i18n.t("sales.ad.ugImg"), name: "ug_image" },
          ].map((item) => (
            <Form.Item noStyle shouldUpdate key={item.name}>
              {({ getFieldValue }) => (
                <Form.Item
                  label={item.label}
                  name={item.name}
                  valuePropName="fileList"
                  getValueFromEvent={(e) => util.normFile4Img(e)}
                >
                  <Upload
                    accept="image/*"
                    multiple={false}
                    listType="picture-card"
                    beforeUpload={onBeforeUpload}
                    customRequest={onUploadImg}
                  >
                    {(!getFieldValue(item.name) ||
                      !getFieldValue(item.name).length) && (
                      <span>
                        <UploadOutlined /> {i18n.t("sales.upload")}
                      </span>
                    )}
                  </Upload>
                </Form.Item>
              )}
            </Form.Item>
          ))}
          <Form.Item
            colon={false}
            label={i18n.t("productEdit.descLabel")}
            name="zh_description"
            wrapperCol={{ span: 13 }}
          >
            <BraftEditor media={{ uploadFn: braftUpload }} />
          </Form.Item>
          <Form.Item
            colon={false}
            label={i18n.t("productEdit.descUgLabel")}
            name="ug_description"
            wrapperCol={{ span: 13 }}
          >
            <BraftEditor
              contentStyle={{ fontFamily: "Alp Ekran" }}
              media={{ uploadFn: braftUpload }}
            />
          </Form.Item>
          <Form.Item
            name="status"
            label={i18n.t("sales.status")}
            valuePropName="checked"
            initialValue={true}
          >
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 2 }}>
            <Button
              disabled={loading}
              loading={loading}
              type="primary"
              htmlType="submit"
            >
              {i18n.t("sales.submit")}
            </Button>
          </Form.Item>
        </Form>
      </Layout.Content>
    </Layout>
  );
}

export default ArticleEdit;
