import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Avatar, Badge, Button, Popconfirm, Progress } from "antd";
import { Locale } from "../../../utils";
const i18n = Locale.getInstance();
export const studentListColumns = (
    deleteMutation,
    token,
    setLoading
) => {

    return [
        {
            title: i18n.t("course.student.info"),
            render: (item) => {
                return (
                    <div className="info">
                        <div className="avatar"><Avatar size={48} src={item.avatar} /></div>
                        <div className="name">{item.nickname}</div>
                    </div>
                );
            },
        },
        {
            title: i18n.t("course.student.studyProgress"),
            render: (item) => {
                return (
                    <Progress className="progres" type="circle" percent={item.study_progress ?? 0} width={40} />
                );
            },
        },
        {
            title: i18n.t("course.student.studyTime"),
            render: (item) => {
                return (
                    <span className="study-time">{item.study_time ?? 0}{i18n.t('common.minute')}</span>
                );
            },
        },
        {
            title: i18n.t("course.student.validTime"),
            render: (item) => {
                return (
                    <span className="study-time">{item.expired_at}</span>
                );
            },
        },

        {
            title: i18n.t("users.actions"),
            render: (item) => {
                return (
                    <>
                        <Button onClick={() => {
                            setLoading(true);
                            deleteMutation.mutate({
                                resource: 'course/students',
                                params: {
                                    id: item.id,
                                    token,
                                }
                            });
                        }} size="small" icon={<DeleteOutlined />}>
                            {i18n.t("common.delete")}
                        </Button>
                    </>
                );
            },
        },
    ];
};
