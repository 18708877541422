import { PlusOutlined } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import { useEffect, useState } from "react";
import { Locale } from "../../utils";
import Media from "./Media";

import "./ImageUpload.scss";
import ImageUploadPreview from "./ImageUploadPreview";
const i18n = Locale.getInstance();
const ImageUpload = ({ images, setSelectedImages, multiple = false }) => {
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [selectedFiles, selectFiles] = useState(false);
  const [selectedImages, selectImages] = useState([]);

  useEffect(() => {
    if (images && images.length > 0) {
      selectImages(images);
    }
  }, [images]);

  const handleFinishSelection = () => {
    selectImages(selectedFiles);
    setSelectedImages(selectedFiles);

    setOpenUploadModal(false);
  };
  const hideModal = () => {
    setOpenUploadModal(false);
  };

  const showConfirm = () => {
    setOpenUploadModal(true);
  };

  if (!multiple && selectedImages.length > 0) {
    return (
      <div className="image-upload">
        <ImageUploadPreview
          selectedImages={selectedImages}
          selectImages={selectImages}
        />
      </div>
    );
  }

  return (
    <>
      <div className="image-upload">
        <ImageUploadPreview
          selectedImages={selectedImages}
          selectImages={selectImages}
        />
        <div className="upload-button" onClick={showConfirm}>
          <PlusOutlined />
          <div className="ant-upload-text">
            {i18n.t("productEdit.imagesUpload")}
          </div>
        </div>
      </div>

      <Modal
        title={i18n.t("media.selectImages")}
        open={openUploadModal}
        onOk={handleFinishSelection}
        onCancel={hideModal}
        okText={i18n.t("base.modal.okText")}
        cancelText={i18n.t("base.modal.cancelText")}
        width={880}
      >
        <Media selectFiles={selectFiles} collectionName="images" />
      </Modal>
    </>
  );
};

export default ImageUpload;
