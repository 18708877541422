
export const getUploadedVideoPaths = (valueVideos) => {
    let hasVideos = valueVideos && valueVideos.length > 0;
    if (!hasVideos) { return null; }
    let videoPaths = [];
    videoPaths = valueVideos
        .filter((video, index) => {
            if (video.url) {
                return video;
            }
            return null;
        })
        .map((image, index) => {
            return image.file_path;
        })
        .concat(videoPaths);

    return videoPaths;
}


export const getUploadedImagePaths = (valueImages) => {
    let hasImages = valueImages && valueImages.length > 0;
    if (!hasImages) { return null; }
    let imagePaths = [];
    imagePaths = valueImages
        .filter((image, index) => {
            if (image.url) {
                return image;
            }
            return null;
        })
        .map((image, index) => {
            return image.file_path;
        })
        .concat(imagePaths);

    return imagePaths;
}