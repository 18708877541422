import React from "react";

import { AppstoreOutlined, LeftOutlined } from "@ant-design/icons";

import { Layout, Divider, Button } from "antd";

import Base from "../Base";

class FinanceOrderDetail extends Base {
  /* lifcyle methods */

  /* render methods */

  render() {
    return (
      <Layout id="route-finance-order-detail">
        {this._renderHeader()}
        {this._renderDetails()}
        {this._renderFooter()}
      </Layout>
    );
  }

  _renderHeader() {
    let order = this.getRouterStateOrder();

    return (
      <div className="header-wrap">
        <Button type="text" className="back" onClick={this.onHeaderBackClick}>
          <LeftOutlined />
          {this.i18n.t("financeOrderDetail.headerBack")}
        </Button>
        <Divider type="vertical" />
        <span className="title">
          {this.i18n.t("financeOrderDetail.headerTitle")}
          <span className="no">#{order ? order.sequence : ""}</span>
        </span>
      </div>
    );
  }

  _renderDetails() {
    return (
      <div className="detail-wrapper">
        {this.renderDetailsProducts()}
        <Divider dashed={true} />
        {this.renderPaymentFee()}
        {this.renderMerchantExpence()}
        {this.renderPlatformIncome()}
        <Divider dashed={true} />
        {this.renderShipFee()}
        {this.renderDonationFee()}
        <Divider dashed={true} />
        {this.renderMerchantIncome()}
      </div>
    );
  }

  renderDetailsProducts() {
    let order = this.getRouterStateOrder();
    let products = order ? order.products : [];

    let items = [];
    if (products && products.length > 0) {
      items.push(
        <div key="products-label" className="detail-row">
          <div className="label black">
            <AppstoreOutlined />
            <span>{this.i18n.t("financeOrderDetail.detailProducts")}</span>
          </div>
        </div>
      );

      items = items.concat(
        products.map((product, index) => {
          let productPrice = product.original_price || product.price;

          return (
            <div key={`product-item-${index}`} className="detail-row">
              <div className="label">
                <span>{this.i18n.getPropNameByLocale(product, "name")}</span>
              </div>
              <div className="amount">
                <span>x{product.amount}</span>
              </div>
              <div className="price">
                <span className="original_price">
                  {this.util.getFormattedPrice(
                    product.original_price * product.amount,
                    "￥"
                  )}
                </span>
                <span>
                  {this.util.getFormattedPrice(
                    product.price * product.amount,
                    "￥"
                  )}
                </span>
              </div>
            </div>
          );
        })
      );
    }

    return items;
  }

  renderMerchantExpence() {
    let order = this.getRouterStateOrder();

    if (order) {
      return (
        <div className="detail-row">
          <div className="label">
            <span>{this.i18n.t("financeOrderDetail.detailMerchant")}</span>
          </div>
          <div className="amount">
            <span>
              -{this.util.getFormattedPrice(order.merchant_expenses, "￥")}
            </span>
          </div>
        </div>
      );
    }

    return null;
  }

  renderPaymentFee() {
    let order = this.getRouterStateOrder();

    if (order) {
      return (
        <div className="detail-row">
          <div className="label">
            <span>{this.i18n.t("financeOrderDetail.detailPaymentFee")}</span>
          </div>
          <div className="amount">
            <span>-{this.util.getFormattedPrice(order.payment_fee, "￥")}</span>
          </div>
        </div>
      );
    }

    return null;
  }

  renderPlatformIncome() {
    let order = this.getRouterStateOrder();

    if (order) {
      return (
        <div className="detail-row">
          <div className="label">
            <span>{this.i18n.t("financeOrderDetail.detailPlatform")}</span>
          </div>
          <div className="amount">
            <span>
              -{this.util.getFormattedPrice(order.platform_fee, "￥")}
            </span>
          </div>
        </div>
      );
    }

    return null;
  }

  renderShipFee() {
    let order = this.getRouterStateOrder();

    if (order) {
      let shipPrice = "--";
      if (order.deliver_by_us !== 1) {
        shipPrice = this.util.getFormattedPrice(order.ship_price);
      }

      return (
        <div className="detail-row">
          <div className="label">
            <span>{this.i18n.t("financeOrderDetail.detailShip")}</span>
          </div>
          <div className="amount">
            <span>{shipPrice}</span>
          </div>
        </div>
      );
    }

    return null;
  }

  renderDonationFee() {
    let order = this.getRouterStateOrder();

    if (order) {
      return (
        <div className="detail-row">
          <div className="label">
            <span>{this.i18n.t("financeOrderDetail.detailDonation")}</span>
          </div>
          <div className="amount">
            <span>--</span>
          </div>
        </div>
      );
    }

    return null;
  }

  renderMerchantIncome() {
    let order = this.getRouterStateOrder();

    if (order) {
      return (
        <div className="detail-row">
          <div className="label black bold">
            <span>{this.i18n.t("financeOrderDetail.detailTotal")}</span>
          </div>
          <div className="amount color-green bold">
            <span>
              {this.util.getFormattedPrice(order.merchant_income, "￥")}
            </span>
          </div>
        </div>
      );
    }

    return null;
  }

  _renderFooter() {
    let order = this.getRouterStateOrder();

    if (order) {
      return (
        <div className="detail-footer">
          <span>
            {this.i18n.t("financeOrderDetail.footerSequence", {
              sequence: order.sequence,
            })}
          </span>
          <Divider type="vertical" />
          <span>
            {this.i18n.t("financeOrderDetail.footerNo", {
              no: order.order_no,
            })}
          </span>
          <Divider type="vertical" />
          <span>
            {this.i18n.t("financeOrderDetail.footerTime", {
              time: order.created_at,
            })}
          </span>
          <Divider type="vertical" />
          <span>
            {this.i18n.t("financeOrderDetail.footerTimeFinish", {
              time: order.received_at,
            })}
          </span>
        </div>
      );
    }

    return null;
  }

  /* user methods */

  onHeaderBackClick = () => {
    this.props.history.goBack();
  };

  /* custom methods */

  getRouterStateOrder() {
    return this.getFromRouterState("order");
  }
}

export default FinanceOrderDetail;
