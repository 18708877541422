import { Form, Input, InputNumber, Spin, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useForm } from "antd/lib/form/Form";
import { Locale } from "../../../utils";

import "./AddOrEditLesson.scss";
import TranslateBtn from "../../../components/product/TranslateBtn";
import { TYPE_VIDEO } from "../courses/courseTypes";
import VideoUpload from "../../../components/media/VideoUpload";
import AudioUpload from "../../../components/media/AudioUpload";

const i18n = Locale.getInstance();

const AddOrEditLesson = React.forwardRef(({ lesson, loading, type }, ref) => {
  const [lessonForm] = useForm();
  const [showTrialDuration, setShowTrialDuration] = useState(false);
  const updateFields = (item, value) => {
    const formDispatch = lessonForm.getInternalHooks(
      "RC_FORM_INTERNAL_HOOKS"
    ).dispatch;
    formDispatch({
      type: "updateValue",
      namePath: item,
      value,
    });
  };
  const [key, setKey] = React.useState(0);
  useEffect(() => {
    setKey((n) => n + 1);
  }, [showTrialDuration]);

  if (lesson && lesson.trial && !showTrialDuration) {
    setShowTrialDuration(true);
  }

  if (lesson && !lesson.trial && showTrialDuration) {
    setShowTrialDuration(false);
  }

  if (loading) {
    return <Spin spinning={loading} />;
  }
  return (
    <>
      <Form
        ref={ref}
        form={lessonForm}
        className="lessson-form"
        name="basic"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        autoComplete="off"
      >
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldsValue }) => (
            <Form.Item
              shouldUpdate
              name="ug_name"
              label={i18n.t("course.lesson.ugName")}
              rules={[
                {
                  required: true,
                  message: i18n.t("productEdit.standardRequired"),
                },
              ]}
            >
              <Input
                addonAfter={
                  <TranslateBtn
                    context={getFieldValue("ug_name")}
                    onFinish={(value) => updateFields("zh_name", value)}
                    from="ug"
                    to="zh"
                  />
                }
              />
            </Form.Item>
          )}
        </Form.Item>

        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldsValue }) => (
            <Form.Item
              name="zh_name"
              label={i18n.t("course.lesson.zhName")}
              rules={[
                {
                  required: true,
                  message: i18n.t("productEdit.standardRequired"),
                },
              ]}
            >
              <Input
                addonAfter={
                  <TranslateBtn
                    context={getFieldValue("zh_name")}
                    onFinish={(value) => updateFields("ug_name", value)}
                    from="zh"
                    to="ug"
                  />
                }
              />
            </Form.Item>
          )}
        </Form.Item>
        {parseInt(type) === TYPE_VIDEO ? (
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                name="media_id"
                label={i18n.t("course.lesson.lessonVideo")}
                rules={[
                  {
                    required: true,
                    message: i18n.t("productEdit.standardRequired"),
                  },
                ]}
              >
                <VideoUpload
                  video={getFieldValue("media_id")}
                  setSelectedVideo={(video) => {
                    setFieldsValue({ media_id: video });
                  }}
                />
              </Form.Item>
            )}
          </Form.Item>
        ) : (
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                name="media_id"
                label={i18n.t("course.lesson.lessonAudio")}
                rules={[
                  {
                    required: true,
                    message: i18n.t("productEdit.standardRequired"),
                  },
                ]}
              >
                <AudioUpload
                  audio={getFieldValue("media_id")}
                  setSelectedAudio={(audio) => {
                    setFieldsValue({ media_id: audio });
                  }}
                />
              </Form.Item>
            )}
          </Form.Item>
        )}

        <Form.Item
          name="order"
          label={i18n.t("shows.sortTypesTitle")}
          shouldUpdate
        >
          <Input />
        </Form.Item>
        <Form.Item name="course_id" style={{ display: "none" }} shouldUpdate>
          <Input hidden />
        </Form.Item>

        <Form.Item
          name="trial"
          label={i18n.t("course.lesson.trial")}
          valuePropName="checked"
        >
          <Switch
            key={key}
            defaultChecked={lessonForm.getFieldValue("trial")}
            onChange={(value) => {
              if (!value) {
                lessonForm.setFieldsValue({ trial_duration: 0 });
              }
            }}
          />
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldsValue }) =>
            !!getFieldValue("trial") && (
              <Form.Item
                name="trial_duration"
                label={i18n.t("course.lesson.trialDuration")}
                shouldUpdate
              >
                <InputNumber />
              </Form.Item>
            )
          }
        </Form.Item>
      </Form>
    </>
  );
});

export default AddOrEditLesson;
