import { Avatar, Button, Tag } from "antd";
import { Locale, Util } from "../../../utils";
const util = Util.getInstance();
const i18n = Locale.getInstance();
export const courseOrderListColumns = (router) => {
  return [
    {
      title: i18n.t("course.courseInfo"),
      width: 220,
      fixed: "left",
      render: (item) => {
        return (
          <div className="info">
            <div>ID: {item.order_no}</div>
            <div className="cover">
              <img
                src={`${i18n.getPropNameByLocale(item?.course, "cover")}`}
                alt={i18n.getPropNameByLocale(item?.course, "name")}
                width={130}
                height={88}
              />
            </div>
            <div className="name">
              <span>{i18n.getPropNameByLocale(item?.course, "name")}</span>
              <span>
                {i18n.t("course.teacherLabel")}{" "}
                {i18n.getPropNameByLocale(item?.course?.teacher, "name")}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      title: i18n.t("course.order.customerInfo"),
      render: (item) => {
        return (
          <div className="info">
            <div className="avatar">
              <Avatar size={48} src={item?.user?.avatar} />
            </div>
            <div className="name">{item?.user?.nickname}</div>
          </div>
        );
      },
      width: 160,
    },
    {
      title: i18n.t("course.order.status"),
      render: (item) => {
        return <Tag>{item.status_label}</Tag>;
      },
      width: 120,
    },
    {
      title: i18n.t("course.order.paidAt"),
      render: (item) => {
        return <span>{item.paid_at}</span>;
      },
      width: 120,
    },
    {
      title: i18n.t("course.order.price"),
      render: (item) => {
        return (
          <div className="info">
            <div className="name">
              {util.getFormattedPriceForYuan(item.price)}
            </div>
            <div className="name">
              {i18n.t("course.order.realPayment")} :{" "}
              {util.getFormattedPriceForYuan(item.price)}
            </div>
          </div>
        );
      },
      width: 150,
    },
    {
      title: i18n.t("users.actions"),
      width: 100,
      render: (item) => {
        return (
          <>
            <Button
              type="link"
              onClick={() => {
                router.push({
                  pathname: `/main/course/orders/detail`,
                  query: {
                    id: item.id,
                  },
                });
              }}
              size="small"
            >
              {i18n.t("course.order.detail")}
            </Button>
          </>
        );
      },
    },
  ];
};
