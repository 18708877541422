import { HomeOutlined } from "@ant-design/icons";
import { Tabs } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { Locale } from "../../utils";
import { getUserInfo } from "../../utils/redux/reducers/User";
import { BreadCrumb } from "../main/LayoutComponents";
import Media from "./Media";
import "./MediaManagement.scss";

const i18n = Locale.getInstance();

const MediaManagement = ({ collectionName }) => {
  const user = useSelector((state) => getUserInfo(state));
  let items = [
    {
      label: i18n.t("shows.type.image"),
      key: "item-1",
      children: <Media collectionName="images" className="images" />,
    }, // remember to pass the key prop
    {
      label: i18n.t("shows.type.audio"),
      key: "item-2",
      children: <Media collectionName="audios" className="audio" />,
    },
    {
      label: i18n.t("shows.type.video"),
      key: "item-3",
      children: <Media collectionName="videos" className="video" />,
    },
  ];
  if (user.merchant && user.merchant.is_bulutbazar_lbs_shop) {
    items.push({
      label: i18n.t("financeAccount.licences"),
      key: "item-4",
      children: <Media collectionName="licence" className="images" />,
    });
  }
  const breadCrumbData = [
    {
      title: i18n.t("main.sideMenus.media"),
    },
  ];
  return (
    <>
      <BreadCrumb data={breadCrumbData} />
      <div className="media-management">
        <Tabs defaultActiveKey="1" items={items} />
      </div>
    </>
  );
};

export default MediaManagement;
