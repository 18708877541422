import { useState } from "react";
import { Button, Radio } from "antd";
import { Locale } from "../../../utils";
import { useHistory } from "react-router-dom";

const i18n = Locale.getInstance();

const RedPacketListHeader = ({redPacketId}) => {
  const [q, setQ] = useState();
  const history = useHistory();
  return (
    <div className="header">
      <div className="actions">
        <Button
          onClick={() => {
            history.push({
              pathname: "/main/red-packet/" + redPacketId + "/detail/edit",
            });
          }}
          type="primary"
        >
          {i18n.t("redPacketActivity.add")}
        </Button>
      </div>
    </div>
  );
};
export default RedPacketListHeader;
