import { Form, Input } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect } from "react";
import { Locale } from "../../utils";

const i18n = Locale.getInstance();

const WebviewInput = ({ setSelectedLink, selectedLink }) => {
  const [weappInputForm] = useForm();

  useEffect(() => {
    if (selectedLink) {
      weappInputForm.setFieldsValue({
        webview_path: selectedLink?.url.split("https://")[1],
      });
    }
  }, []);

  return (
    <Form
      form={weappInputForm}
      onValuesChange={(e) =>
        setSelectedLink({
          url: `https://${e.webview_path}`,
          zh_name: "网页广告",
          ug_name: "توربەت ئېلان",
        })
      }
    >
      <Form.Item name="webview_path" label={i18n.t("link.webviewUrl")}>
        <Input addonBefore="https://" />
      </Form.Item>
    </Form>
  );
};

export default WebviewInput;
