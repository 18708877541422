import React, { useState, useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
  Table,
  message,
  Tag,
  Avatar,
  Button,
  Divider,
  Typography,
  Space,
  Timeline,
  InputNumber,
  Image,
  Form,
} from "antd";
import Search from "antd/lib/input/Search";
import { ApiManager, Locale, Util } from "../../utils";
import { getUserInfo } from "../../utils/redux/reducers/User";
import { LeftOutlined } from "@ant-design/icons";

import Base from "../Base";
import { useHistory, useLocation } from "react-router-dom";

const { Text } = Typography;
const api = ApiManager.getInstance();
const i18n = Locale.getInstance();
const base = Base.getInstance();
const util = Util.getInstance();

function LotteryRecommenders() {
  const thisSorter = useRef();
  const thisPagination = useRef();
  const thisFilters = useRef();
  const thisKeyword = useRef();
  const fetchList = useRef();
  const [list, setList] = useState({ data: [], current_page: 1 });
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(1);
  const [selectedRecordId, setSelectedRecordId] = useState(null);
  const user = useSelector((state) => getUserInfo(state));
  const location = useLocation();
  const router = useHistory();

  fetchList.current = useCallback(
    async (page = 1, keyword = "", sorter = null) => {
      const { token } = user;
      if (token) {
        setLoading(true);
        try {
          const { id, type } = location.state;
          let apiUrl = api.lottery.recommendersList;
           
          const apiConfig = {
            token,
            path: { id },
            query: {
              page,
              keyword,
              count: 10,
            },
          };
          if (
            thisSorter.current &&
            thisSorter.current.field &&
            thisSorter.current.order
          ) {
            const order = thisSorter.current.order.split("end")[0];
            apiConfig.query[
              `filter[sort]`
            ] = `${thisSorter.current.field}@${order}`;
          }
          if (thisFilters && thisFilters.current) {
            for (let key in thisFilters.current) {
              if (thisFilters.current[key]) {
                apiConfig.query[`filter[${key}]`] =
                  thisFilters.current[key].join(",");
              }
            }
          }
          const res = await apiUrl.run(apiConfig);
          if (res.result) {
            setList(res.data);
          } else {
            message.warning(res.message);
          }
        } catch (error) {
          message.error(error.message);
        } finally {
          setLoading(false);
        }
      }
    },
    [user, location.state]
  );


  function getColumns(list) {
    let columns = [];
    
      columns = columns.concat([
        {
          title: i18n.t("users.avatar"),
          key: "avatar",
          render: (text, record) => {
            return (
              <Avatar
                size="large"
                shape="circle"
                src={record.avatar}
                alt={record.nickname}
              />
            );
          },
        },
        {
          title: i18n.t("users.name"),
          dataIndex: "nickname",
          key: "nickname",
          render: (text, record) => {
            return record.nickname;
          },
        },
        {
          title: i18n.t("commissionList.mobile"),
          key: "mobile",
          dataIndex: "mobile",
          align: "center",
          render: (text, record) => {
            return record.mobile;
          },
        },
        {
          title: i18n.t("lottery.recommendCount"),
          key: "count",
          dataIndex: "count",
          align: "center",
          sorter: true,
          
        },
        {
          title: i18n.t("lottery.recommendAmount"),
          key: "amount",
          dataIndex: "amount",
          align: "center",
          sorter: true,
          render: (text, record) => {
            
            return `¥${util.getFormattedPriceForYuan(record.amount)}`;
          },
        },
        {
          title: i18n.t("lottery.recommendCommission"),
          key: "commission",
          dataIndex: "commission",
          align: "center",
          sorter: true,
          render: (text, record) => {
            
            return `¥${util.getFormattedPriceForYuan(record.commission)}`;
          },
        },
        
      ]);
      

    return columns;
  }

  useEffect(() => {
    fetchList.current();
  }, [fetchList]);
  
  const { type } = location.state;
  return (
    <>
      <Space>
        <Button
          type="link"
          onClick={() => {
            router.goBack();
          }}
        >
          <LeftOutlined />
          {i18n.t("productEdit.headerBack")}
        </Button>
        <Divider type="vertical" />
        <Text>{location.state.title}</Text>
      </Space>
      <Search
        onSearch={(keyword) => {
          thisKeyword.current = keyword;
          fetchList.current(1, keyword);
        }}
        allowClear
        placeholder={i18n.t("users.searchPlace")}
        style={{
          width: 300,
          marginBottom: 16,
          marginLeft: 16,
          marginRight: 16,
        }}
        enterButton
      />
      <Table
        loading={loading}
        dataSource={list.data}
        rowKey={(record) => `${record.id}`}
        onChange={(pagination, filters, sorter) => {
          thisSorter.current = sorter;
          thisPagination.current = pagination.current;
          thisFilters.current = filters;
          fetchList.current(pagination.current, thisKeyword.current, sorter);
        }}
        pagination={{
          current: list.current_page,
          total: list.total,
          pageSize: 10,
          showSizeChanger: false,
          showQuickJumper: true,
        }}
        expandable={
          !type && {
            expandedRowRender: (record) => {
              if (
                Array.isArray(record.change_log) &&
                record.change_log.length > 0
              ) {
                return (
                  <div>
                    <Timeline mode={i18n.isLocaleRTL() ? "right" : "left"}>
                      {record.change_log.map((i, idx) => (
                        <Timeline.Item
                          key={`record-${idx}`}
                          label={i.changed_at.date}
                        >
                          {i.action} {i.change_log}
                        </Timeline.Item>
                      ))}
                    </Timeline>
                  </div>
                );
              }
            },
          }
        }
        columns={getColumns(list)}
      />
      
    </>
  );
}

export default LotteryRecommenders;
