import { Button, Spin } from "antd";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Locale } from "../../utils";
import { showNotificationWithIcon } from "../../utils/helpers/helpers";
import { getMany } from "../../utils/http/dataProvider";
import { getUserInfo } from "../../utils/redux/reducers/User";

import "./PlatformPages.scss";

const i18n = Locale.getInstance();

const PlatformPages = ({ setSelectedLink, selectedLink }) => {
  const user = useSelector((state) => getUserInfo(state));
  const { data, error, isLoading } = useQuery(
    ["platform-links", user.token],
    () => getMany("home/links", { token: user.token })
  );

  if (error) {
    console.log(
      "🚀 ~ file: PlatformPages.jsx ~ line 20 ~ PlatformPages ~ error",
      error
    );
    showNotificationWithIcon(error?.data?.message);
  }

  return (
    <>
      <Spin spinning={isLoading}>
        <div className="links">
          {data?.data &&
            Object.keys(data?.data).map((key) => {
              const link = data.data[key];

              return (
                <Button
                  type={selectedLink.url === link.url ? "primary" : "default"}
                  key={key}
                  className="item"
                  onClick={() => {
                    setSelectedLink(link);
                  }}
                >
                  {i18n.getPropNameByLocale(link, "name")}
                </Button>
              );
            })}
        </div>
      </Spin>
    </>
  );
};

export default PlatformPages;
