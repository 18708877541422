import { Button, Form, Input, Radio, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useForm } from "antd/lib/form/Form";
import { useLocation, useHistory } from "react-router-dom";

import { Locale } from "../../../utils";
import {
  handleMutationError,
  showNotificationWithIcon,
} from "../../../utils/helpers/helpers";
import { getUserInfo } from "../../../utils/redux/reducers/User";
import { BreadCrumb } from "../../../components/main/LayoutComponents";

import "./AddOrEditTeacher.scss";
import { create, getOne, update } from "../../../utils/http/dataProvider";
import ImageUpload from "../../../components/media/ImageUpload";
import TranslateBtn from "../../../components/product/TranslateBtn";
import SlateRichTextEditor from "../../../components/editor/SlateRichTextEditor";
import UserSelector from "../../../components/main/UserSelector";

const i18n = Locale.getInstance();
let counter = 0;
const AddOrEditTeacher = () => {
  const user = useSelector((state) => getUserInfo(state));
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const [teacherForm] = useForm();
  const history = useHistory();
  const location = useLocation();

  const id = location?.state?.id;
  counter += 1;

  const { data, isLoading } = useQuery(
    ["course-teachers-detail", user.token],
    () => getOne("course/teachers", { id, token: user.token }),
    {
      enabled: !!id,
    }
  );

  useEffect(() => {
    if (data && id === data?.id) {
      const values = {
        zh_name: data.zh_name,
        ug_name: data.ug_name,
        zh_description: data.zh_description,
        ug_description: data.ug_description,
        mobile: data.mobile,
        gender: data.gender,
        avatar: data?.avatar_media ? [data?.avatar_media] : [],
      };

      teacherForm.setFieldsValue(values);
    }
    if (!id) {
      teacherForm.setFieldsValue({
        zh_name: "",
        ug_name: "",
        zh_description: [],
        ug_description: [],
        mobile: "",
        gender: "",
        avatar: [],
      });
    }
  }, [data]);

  const addTeacherMutation = useMutation(create, {
    onSuccess: () => {
      queryClient.invalidateQueries("course-teachers");
      showNotificationWithIcon(i18n.t("media.saveFinished"), "success");
      setLoading(false);
    },
    onError: (error) => {
      handleMutationError(error);
      setLoading(false);
    },
  });

  const updateTeacherMutation = useMutation(update, {
    onSuccess: () => {
      queryClient.invalidateQueries("course-teachers");
      showNotificationWithIcon(i18n.t("media.saveFinished"), "success");
      setLoading(false);
    },
    onError: (error) => {
      handleMutationError(error);
      setLoading(false);
    },
  });

  const onFinish = (values) => {
    if (values.avatar && values.avatar.length > 0) {
      values["avatar"] = values["avatar"][0]["file_path"];
    }
    setLoading(true);
    const params = {
      data: values,
      token: user.token,
    };

    if (id) {
      updateTeacherMutation.mutate({
        resource: "course/teachers",
        params: { ...params, id },
      });
    } else {
      addTeacherMutation.mutate({ resource: "course/teachers", params });
    }
    history.goBack();
  };

  const onFinishFailed = (errorInfo) => {
    setLoading(false);
    showNotificationWithIcon(errorInfo);
  };

  const breadCrumbData = [
    {
      href: "/main/courses/article-courses",
      title: i18n.t("course.title"),
    },
    {
      href: "/main/course/teachers",
      title: i18n.t("course.teacher.title"),
    },
    {
      title: id ? i18n.t("course.teacher.edit") : i18n.t("course.teacher.add"),
    },
  ];

  if (isLoading) {
    return <Spin spinning={isLoading} />;
  }
  return (
    <>
      <BreadCrumb data={breadCrumbData} />
      <Spin spinning={loading}>
        <Form
          form={teacherForm}
          className="teacher-form"
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                name="user_id"
                label={i18n.t("member.selectUser")}
                initialValue={data?.user_id}
                rules={[
                  {
                    required: true,
                    message: i18n.t("productEdit.standardRequired"),
                  },
                ]}
              >
                <UserSelector
                  propUser={id ? data?.user : false}
                  setFieldsValue={setFieldsValue}
                />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                shouldUpdate
                name="ug_name"
                label={i18n.t("course.teacher.ugName")}
                initialValue={data?.ug_name}
                rules={[
                  {
                    required: true,
                    message: i18n.t("productEdit.standardRequired"),
                  },
                ]}
              >
                <Input
                  addonAfter={
                    <TranslateBtn
                      context={getFieldValue("ug_name")}
                      onFinish={(value) =>
                        setFieldsValue({
                          zh_name: value,
                        })
                      }
                      from="ug"
                      to="zh"
                    />
                  }
                />
              </Form.Item>
            )}
          </Form.Item>

          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                name="zh_name"
                label={i18n.t("course.teacher.zhName")}
                initialValue={data?.zh_name}
                rules={[
                  {
                    required: true,
                    message: i18n.t("productEdit.standardRequired"),
                  },
                ]}
              >
                <Input
                  addonAfter={
                    <TranslateBtn
                      context={getFieldValue("zh_name")}
                      onFinish={(value) =>
                        setFieldsValue({
                          ug_name: value,
                        })
                      }
                      from="zh"
                      to="ug"
                    />
                  }
                />
              </Form.Item>
            )}
          </Form.Item>

          <Form.Item
            name="gender"
            label={i18n.t("course.teacher.gender")}
            initialValue={data?.gender ?? 0}
          >
            <Radio.Group>
              <Radio value={1}>{i18n.t("course.teacher.male")}</Radio>
              <Radio defaultChecked={true} value={0}>
                {i18n.t("course.teacher.female")}
              </Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                name="avatar"
                label={i18n.t("users.avatar")}
                rules={[
                  {
                    required: true,
                    message: i18n.t("productEdit.standardRequired"),
                  },
                ]}
              >
                <ImageUpload
                  images={getFieldValue("avatar")}
                  setSelectedImages={(image) => {
                    setFieldsValue({
                      avatar: image,
                    });
                  }}
                />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item
            name="mobile"
            label={i18n.t("common.mobile")}
            initialValue={data?.mobile}
            shouldUpdate
            rules={[
              {
                required: true,
                message: i18n.t("productEdit.standardRequired"),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item noStyle shouldUpdate>
            {({ setFieldsValue }) => {
              return (
                <Form.Item
                  name="zh_description"
                  label={i18n.t("course.teacher.zhDescription")}
                  initialValue={data?.zh_description}
                >
                  <SlateRichTextEditor
                    name="zh_description"
                    setFieldsValue={(value) =>
                      setFieldsValue({
                        zh_description: value,
                      })
                    }
                    initialValue={id ? data?.zh_description : null}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>

          <Form.Item noStyle shouldUpdate>
            {({ setFieldsValue }) => (
              <Form.Item
                name="ug_description"
                label={i18n.t("course.teacher.ugDescription")}
                initialValue={data?.ug_description}
              >
                <SlateRichTextEditor
                  name="ug_description"
                  setFieldsValue={(value) =>
                    setFieldsValue({
                      ug_description: value,
                    })
                  }
                  initialValue={id ? data?.ug_description : null}
                />
              </Form.Item>
            )}
          </Form.Item>

          <Form.Item className="submit">
            <Button type="primary" htmlType="submit">
              {i18n.t("productEdit.saveAndBack")}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};

export default AddOrEditTeacher;
