import React from "react";

import { Modal, Layout, Input, InputNumber, Radio, Switch, Form } from "antd";

import Base from "../../routes/Base";
import { getMerchantSettings } from "../../utils/redux/reducers/Merchant";
import { getUserInfo } from "../../utils/redux/reducers/User";
import { connect } from "react-redux";

class ProxyEditModal extends Base {
  formRef = React.createRef();
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Modal
        open
        className="route-proxy-list-edit-modal"
        title={this.getTitle()}
        onCancel={this.onCancel}
        onOk={this.onConfirm}
        confirmLoading={this.getPropConfirmLoading()}
        okText={this.i18n.t("proxy.editOk")}
        cancelText={this.i18n.t("proxy.editCancel")}
      >
        <Layout className="proxy-edit-wrap">
          <Form
            ref={this.formRef}
            initialValues={{
              mobile: this.getDefaultMobile(),
              level: this.getDefaultLevel(),
              group_owner: this.getDefaultSwithValue(),
              status: this.getDefaultStatusSwithValue(),
              is_partner: this.getDefaultIsPartnerSwithValue(),
              is_shop_owner: this.getDefaultIsShopOwnerSwithValue(),
              commission_percent: this.getDefaultPercent(),
              parent_mobile: this.getDefaultParentMobile(),
              parent_commission_percent: this.getDefaultParentPercent(),
            }}
          >
            {this._renderProxyUserMobile()}
            {this._renderProxyUserLevel()}
            {this._renderProxyParentMobile()}
            {this._renderProxyStatusSwitch()}
            {this._renderIsParnterSwitch()}
            {this._renderIsShopOwnerSwitch()}
          </Form>
        </Layout>
      </Modal>
    );
  }

  _renderProxyUserMobile() {
    const item = {
      colon: false,
      label: this.i18n.t("proxy.editMobile"),
      labelCol: { span: 4 },
      wrapperCol: { span: 8 },
      required: true,
    };

    const input = (
      <Input
        disabled={this.isEditMode()}
        placeholder={this.i18n.t("proxy.editMobileHint")}
      />
    );

    return (
      <Form.Item
        {...item}
        name="mobile"
        rules={[
          {
            required: true,
            message: this.i18n.t("proxy.editMobileHint"),
          },
          {
            pattern: /^1\d{10}$/g,
            message: this.i18n.t("proxy.editMobileLength"),
          },
        ]}
        getValueFromEvent={this.onMobileChange}
      >
        {input}
      </Form.Item>
    );
  }

  _renderProxyUserLevel() {
    let levels = this.getPropLevels();
    if (!levels || levels.length <= 0) return null;

    const item = {
      colon: false,
      label: this.i18n.t("proxy.editLevel"),
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      required: true,
    };

    const radioGroup = (
      <Radio.Group>
        {levels.map((item, index) => {
          return (
            <Radio key={`level-${index}`} value={`${item.id}`}>
              {this.i18n.getPropNameByLocale(item, "name")}
            </Radio>
          );
        })}
      </Radio.Group>
    );

    return (
      <Form.Item
        {...item}
        name="level"
        rules={[
          {
            required: true,
            message: this.i18n.t("proxy.editLevelHint"),
          },
        ]}
        getValueFromEvent={this.onLevelChange}
      >
        {radioGroup}
      </Form.Item>
    );
  }

  _renderProxyStatusSwitch() {
    const item = {
      colon: false,
      label: this.i18n.t("proxy.editProxyStatusSwitch"),
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      required: false,
    };

    const switchBtn = (
      <Switch defaultChecked={this.getDefaultStatusSwithValue() === 1} />
    );

    return (
      <Form.Item
        {...item}
        name="status"
        valuePropName="checked"
        getValueFromEvent={this.onChangeStatusSwitch}
      >
        {switchBtn}
      </Form.Item>
    );
  }
  _renderIsParnterSwitch() {
    if (!this.isPartnerEnabled()) {
      return null;
    }
    const item = {
      colon: false,
      label: this.i18n.t("proxy.editIsPartnerSwitch"),
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      required: false,
    };

    const switchBtn = (
      <Switch defaultChecked={this.getDefaultIsPartnerSwithValue() === 1} />
    );

    return (
      <Form.Item
        {...item}
        name="is_partner"
        valuePropName="checked"
        getValueFromEvent={this.onChangeIsPartnerSwitch}
      >
        {switchBtn}
      </Form.Item>
    );
  }
  _renderIsShopOwnerSwitch() {
    if (!this.isShopOwnerEnabled()) {
      return null;
    }
    const item = {
      colon: false,
      label: this.i18n.t("proxy.editIsShopOwnerSwitch"),
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      required: false,
    };

    const switchBtn = (
      <Switch defaultChecked={this.getDefaultIsShopOwnerSwithValue() === 1} />
    );

    return (
      <Form.Item
        {...item}
        name="is_shop_owner"
        valuePropName="checked"
        getValueFromEvent={this.onChangeIsShopOwnerSwitch}
      >
        {switchBtn}
      </Form.Item>
    );
  }

  _renderProxyUserPercent() {
    const item = {
      colon: false,
      label: this.i18n.t("proxy.editPercent"),
      labelCol: { span: 4 },
      wrapperCol: { span: 8 },
      required: false,
    };

    const input = <InputNumber min={0} formatter={(value) => `${value}%`} />;

    return (
      <Form.Item {...item} name="commission_percent">
        {input}
      </Form.Item>
    );
  }

  _renderProxyParentMobile() {
    const item = {
      colon: false,
      label: this.i18n.t("proxy.editParentMobile"),
      labelCol: { span: 4 },
      wrapperCol: { span: 8 },
      required: false,
    };

    const input = (
      <Input placeholder={this.i18n.t("proxy.editParentMobileHint")} />
    );

    return (
      <Form.Item
        {...item}
        name="parent_mobile"
        getValueFromEvent={this.onMobileChange}
      >
        {input}
      </Form.Item>
    );
  }

  _renderProxyParentPercent() {
    const item = {
      colon: false,
      label: this.i18n.t("proxy.editParantPercent"),
      labelCol: { span: 4 },
      wrapperCol: { span: 8 },
      required: false,
    };

    const input = <InputNumber min={0} formatter={(value) => `${value}%`} />;

    return (
      <Form.Item {...item} name="parent_commission_percent">
        {input}
      </Form.Item>
    );
  }

  onMobileChange = (e) => {
    let value = e.target.value;

    if (value.match(/^\d{0,11}$/g)) {
      return value;
    }

    return value.substr(0, value.length - 1);
  };

  onChangeSwitch = (value) => {
    return value ? 1 : 0;
  };
  onChangeStatusSwitch = (value) => {
    return value ? 1 : 0;
  };
  onChangeIsPartnerSwitch = (value) => {
    return value ? 1 : 0;
  };
  onChangeIsShopOwnerSwitch = (value) => {
    return value ? 1 : 0;
  };

  onLevelChange = (e) => {
    let value = e.target.value;

    let levels = this.getPropLevels();
    if (levels && levels.length > 0) {
      let level = levels.find((item) => `${item.id}` === value);
      this.formRef.current.setFieldsValue({
        commission_percent: level.commission_percent,
        parent_commission_percent: level.parent_commission_percent,
      });
    }

    return value;
  };

  onCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  onConfirm = () => {
    this.formRef.current
      .validateFields()
      .then((values) => {
        if (this.props.onConfirm) {
          let editMode = this.isEditMode();
          if (editMode) {
            values.proxyId = this.getPropProxy().id;
          }

          this.props.onConfirm(values, editMode);
        }
      })
      .catch((err) => {
        console.log("onConfirm -> err", err);
      });
  };

  getPropProxy() {
    return this.props.proxy;
  }

  getPropLevels() {
    let levels = Object.assign([], this.props.levels);
    if (levels && levels.length > 0) {
      levels.shift();
    }
    return levels;
  }

  getPropConfirmLoading() {
    return this.props.confirmLoading;
  }

  isEditMode() {
    return this.getPropProxy() ? true : false;
  }

  getTitle() {
    let title = this.i18n.t("proxy.editTitle");
    if (this.isEditMode()) {
      title = this.i18n.t("proxy.editTitleEdit");
    }
    return title;
  }

  getDefaultMobile() {
    let mobile = "";

    if (this.isEditMode()) {
      mobile = this.getPropProxy().mobile;
    }

    return mobile;
  }

  getDefaultSwithValue() {
    let owner = 1;
    if (this.isEditMode()) {
      owner = this.getPropProxy().group_owner;
    }
    return owner;
  }

  getDefaultStatusSwithValue() {
    let status = 1;

    if (this.isEditMode()) {
      status = this.getPropProxy().status;
    }

    return status;
  }
  getDefaultIsPartnerSwithValue() {
    let is_partner = 0;

    if (this.isEditMode()) {
      is_partner = this.getPropProxy().is_partner;
    }

    return is_partner;
  }
  getDefaultIsShopOwnerSwithValue() {
    let is_shop_owner = 0;

    if (this.isEditMode()) {
      is_shop_owner = this.getPropProxy().is_shop_owner;
    }

    return is_shop_owner;
  }

  getDefaultLevel() {
    let level = "-1";

    if (this.isEditMode()) {
      level = `${this.getPropProxy().level}`;
    } else {
      let levels = this.getPropLevels();
      if (levels && levels.length > 0) {
        level = `${levels[0].id}`;
      }
    }

    return level;
  }

  getDefaultPercent() {
    let percent = 0;

    if (this.isEditMode()) {
      percent = this.getPropProxy().commission_percent;
    } else {
      let levels = this.getPropLevels();
      if (levels && levels.length > 0) {
        percent = levels[0].commission_percent;
      }
    }

    return percent;
  }

  getDefaultParentMobile() {
    let mobile = "";

    if (this.isEditMode()) {
      mobile = this.getPropProxy().parent_mobile;
    }

    return mobile;
  }

  getDefaultParentPercent() {
    let percent = 0;

    if (this.isEditMode()) {
      percent = this.getPropProxy().parent_commission_percent;
    } else {
      let levels = this.getPropLevels();
      if (levels && levels.length > 0) {
        percent = levels[0].parent_commission_percent;
      }
    }

    return percent;
  }
}

export default connect(
  (state) => {
    return {
      user: getUserInfo(state),
      settings: getMerchantSettings(state),
    };
  },
  {}
)(ProxyEditModal);

