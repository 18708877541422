import React, { useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  InputNumber,
  DatePicker,
  Button,
  Switch,
} from "antd";
import moment from "dayjs";
import { Locale } from "../../../utils";
import TranslateBtn from "../../../components/product/TranslateBtn";

const i18n = Locale.getInstance();
const { RangePicker } = DatePicker;

function EditCouponForm({ visible, onFinish, onCancel, coupon }) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (coupon && coupon.id) {
      const {
        ug_name,
        zh_name,
        face_price,
        start_price,
        quantity,
        expire_date,
        status,
        start_date,
        end_date,
        is_public_coupon,
      } = coupon;
      form.setFieldsValue({
        ug_name,
        zh_name,
        face_price: face_price / 100,
        use_start_price: start_price / 100,
        quantity,
        expire_date: moment(expire_date),
        status: status === 1,
        is_public_coupon: is_public_coupon === 1,
        range: [moment(start_date), moment(end_date)],
      });
    }
    return () => {
      form.resetFields();
    };
  });

  const disabled = coupon && coupon.id !== null;
  return (
    <Modal
      forceRender
      open={visible}
      onCancel={onCancel}
      footer={null}
      title={i18n.t("sales.coupons.title")}
      width={700}
    >
      <Form
        form={form}
        onFinish={onFinish}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 14 }}
      >
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldsValue }) => (
            <Form.Item
              label={i18n.t("sales.zhTitle")}
              name="zh_name"
              rules={[
                { required: true, message: i18n.t("sales.zhTitleWarning") },
              ]}
            >
              <Input
                disabled={disabled}
                addonAfter={
                  <TranslateBtn
                    context={getFieldValue("ug_name")}
                    onFinish={(value) =>
                      setFieldsValue({
                        zh_name: value,
                      })
                    }
                    from="ug"
                    to="zh"
                  />
                }
              />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldsValue }) => (
            <Form.Item
              label={i18n.t("sales.ugTitle")}
              name="ug_name"
              rules={[
                { required: true, message: i18n.t("sales.ugTitleWarning") },
              ]}
            >
              <Input
                disabled={disabled}
                addonAfter={
                  <TranslateBtn
                    context={getFieldValue("zh_name")}
                    onFinish={(value) =>
                      setFieldsValue({
                        ug_name: value,
                      })
                    }
                    from="zh"
                    to="ug"
                  />
                }
              />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item
          rules={[
            { required: true, message: i18n.t("sales.coupons.priceWarning") },
          ]}
          label={i18n.t("sales.coupons.price")}
          name="face_price"
        >
          <InputNumber disabled={disabled} />
        </Form.Item>
        <Form.Item
          rules={[
            { required: true, message: i18n.t("sales.coupons.limitWarning") },
          ]}
          label={i18n.t("sales.coupons.limit")}
          name="use_start_price"
        >
          <InputNumber disabled={disabled} />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: i18n.t("sales.coupons.quantityWarning"),
            },
          ]}
          label={i18n.t("sales.coupons.quantity")}
          name="quantity"
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          rules={[
            { required: true, message: i18n.t("sales.coupons.rangeWarning") },
          ]}
          label={i18n.t("sales.coupons.range")}
          name="range"
        >
          <RangePicker showTime />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: i18n.t("sales.coupons.experationWarning"),
            },
          ]}
          label={i18n.t("sales.coupons.experation")}
          name="expire_date"
        >
          <DatePicker showToday={false} showTime />
        </Form.Item>
        <Form.Item
          label={i18n.t("sales.coupons.isPublic")}
          name="is_public_coupon"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          label={i18n.t("sales.coupons.status")}
          name="status"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 10 }}>
          <Button type="primary" htmlType="submit">
            {i18n.t("sales.submit")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default EditCouponForm;
