import Modal from "antd/lib/modal/Modal";
import imageExtensions from "image-extensions";
import { Button, Icon } from "./SlateEditorComponentes";
import { useSlateStatic } from "slate-react";
import { Transforms } from "slate";
import { useState } from "react";
import { Locale } from "../../utils";
import Media from "../media/Media";

const i18n = Locale.getInstance();
const InsertImageButton = () => {
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [selectedFiles, selectFiles] = useState(false);

  const editor = useSlateStatic();

  const handleFinishSelection = () => {
    selectedFiles.map((file) => {
      insertImage(editor, file.url);
    });

    setOpenUploadModal(false);
  };
  const hideModal = () => {
    setOpenUploadModal(false);
  };

  return (
    <>
      <Button
        onMouseDown={(event) => {
          event.preventDefault();
          setOpenUploadModal(true);
          //    insertImage(editor, url);
        }}
      >
        <Icon>image</Icon>
      </Button>

      <Modal
        title={i18n.t("media.selectImages")}
        open={openUploadModal}
        onOk={handleFinishSelection}
        onCancel={hideModal}
        okText={i18n.t("base.modal.okText")}
        cancelText={i18n.t("base.modal.cancelText")}
        width={880}
      >
        <Media selectFiles={selectFiles} collectionName="images" />
      </Modal>
    </>
  );
};

export default InsertImageButton;

export const withImages = (editor) => {
  const { insertData, isVoid } = editor;

  editor.isVoid = (element) => {
    return element.type === "image" ? true : isVoid(element);
  };

  editor.insertData = (data) => {
    const text = data.getData("text/plain");
    const { files } = data;

    if (files && files.length > 0) {
      for (const file of files) {
        const reader = new FileReader();
        const [mime] = file.type.split("/");

        if (mime === "image") {
          reader.addEventListener("load", () => {
            const url = reader.result;
            insertImage(editor, url);
          });

          reader.readAsDataURL(file);
        }
      }
    } else if (isImageUrl(text)) {
      insertImage(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

const insertImage = (editor, url) => {
  const position = { at: [editor.children.length] };
  const image = { type: "image", url, children: [{ text: "" }] };
  console.log(
    "🚀 ~ file: InsertImageButton.jsx:95 ~ insertImage ~ position:",
    position
  );
  const emptyParagraf = { type: "paragraph", children: [{ text: "" }] };

  Transforms.insertFragment(editor, [image, emptyParagraf]);
};

const isImageUrl = (url) => {
  if (!url) return false;
  const ext = new URL(url).pathname.split(".").pop();
  return imageExtensions.includes(ext);
};
