import { DeleteOutlined } from "@ant-design/icons";
import {
  Button,
  Image,
  message,
  Statistic,
  Table,
  Tag,
  Typography,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ApiManager, Locale, Request } from "../../utils";
import { getUserInfo } from "../../utils/redux/reducers/User";
import Base from "../Base";

const api = ApiManager.getInstance();
const i18n = Locale.getInstance();
const { Text } = Typography;
const base = Base.getInstance();

function MembersCard() {
  const user = useSelector((state) => getUserInfo(state));
  const fetchCards = useRef();
  const [cards, setCards] = useState([]);
  const history = useHistory();

  fetchCards.current = async () => {
    try {
      const url = "/member/api/v6/merchant/member-card";
      const res = await Request.get(url, user.token);
      if (res.result) {
        setCards(res.data.data);
      } else {
        message.warning(res.message);
      }
    } catch (error) {
      message.error(error.message);
    }
  };
  useEffect(() => {
    fetchCards.current();
  }, []);

  const onDeleteItem = (id) => {
    base.showModal(
      i18n.t("sales.deleteWarning"),
      Base.Modal.confirm,
      async () => {
        const apiUrl = api.member.deleteMemberCard;
        const { token } = user;
        try {
          if (token) {
            const apiConfig = {
              token,
              path: {
                id,
              },
            };
            const res = await apiUrl.run(apiConfig);
            if (res.result) {
              fetchCards.current(data.current_page);
            } else {
              base.showMessage(res.message, Base.Message.warning);
            }
          }
        } catch (error) {
          base.handleApiError(api, error);
        }
      }
    );
  };
  const columns = [
    {
      key: "zh_name",
      title: i18n.t("agentLevel.zh"),
      dataIndex: "zh_name",
      width: 100,
      fixed: "left",
    },
    {
      key: "ug_name",
      title: i18n.t("agentLevel.ug"),
      render: (text, record) => (
        <Text style={{ fontFamily: "Alp Ekran" }}>{record.ug_name}</Text>
      ),
      width: 100,
    },

    {
      key: "price",
      title: i18n.t("member.price"),
      render: (text, record) => (
        <Statistic prefix="¥" value={record.price / 100} />
      ),
      width: 120,
    },
    {
      key: "should_all_course_free",
      title: i18n.t("member.shouldAllCourseFree"),
      render: (text, record) => (
        <Tag color={record.should_all_course_free ? "success" : "default"}>
          {record.should_all_course_free
            ? i18n.t("common.yes")
            : i18n.t("common.no")}
        </Tag>
      ),
      width: 80,
    },
    {
      key: "multiple",
      title: i18n.t("member.multiple"),
      render: (text, record) => (
        <Statistic prefix="x" value={record.points_multiple_by} />
      ),
      width: 100,
    },
    {
      key: "discount",
      title: i18n.t("member.discount"),
      render: (text, record) => (
        <Statistic suffix="%" value={record.discount_percent} />
      ),
      width: 100,
    },
    {
      key: "actions",
      title: i18n.t("product.cats.actions"),
      align: "center",
      render: (text, record) => (
        <>
          <Button
            type="link"
            onClick={() => {
              history.push({
                pathname: "/main/members/editCard",
                state: {
                  cardData: record,
                },
              });
            }}
          >
            {i18n.t("product.productItem.actionEdit")}
          </Button>
          <Button
            size="small"
            style={{ borderWidth: 0, backgroundColor: "transparent" }}
            key="action-delete"
            onClick={() => onDeleteItem(record.id)}
          >
            <DeleteOutlined />
            {i18n.t("product.productItem.actionDelete")}
          </Button>
        </>
      ),
      width: 200,
    },
  ];
  return (
    <>
      <Button
        onClick={() => {
          history.push({ pathname: "/main/members/editCard" });
        }}
        type="primary"
        style={{ marginBottom: 20 }}
      >
        {i18n.t("member.addCard")}
      </Button>
      <Table
        dataSource={cards}
        columns={columns}
        rowKey={(record) => `card-${record.id}`}
        scroll={{
          x: 700,
        }}
      />
    </>
  );
}

export default MembersCard;
