import { Avatar, Card, Col, Row, Table, Tag } from "antd";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { BreadCrumb } from "../../../components/main/LayoutComponents";
import { Locale, Util } from "../../../utils";
import { showNotificationWithIcon } from "../../../utils/helpers/helpers";
import { getOne } from "../../../utils/http/dataProvider";
import { getUserInfo } from "../../../utils/redux/reducers/User";
import "./courseOrderDetail.scss";

const util = Util.getInstance();
const i18n = Locale.getInstance();

const CourseOrderDetail = () => {
  const user = useSelector((state) => getUserInfo(state));
  const location = useLocation();
  const history = useHistory();

  if (!location.query.id) {
    history.goBack();
  }
  const orderId = location.query.id;

  const { data, error, isLoading } = useQuery(
    ["courses-order-detail", user.token],
    () =>
      getOne("course/orders", {
        id: orderId,
        token: user.token,
      })
  );
  if (error) {
    showNotificationWithIcon(error.data?.message);
  }
  const columns = [
    {
      title: i18n.t("course.courseInfo"),
      render: (item) => {
        return (
          <div className="info">
            <div className="cover">
              <img
                src={`${i18n.getPropNameByLocale(item?.course, "cover")}`}
                alt={i18n.getPropNameByLocale(item?.course, "name")}
                width={130}
                height={88}
              />
            </div>
            <div className="name">
              <span>{i18n.getPropNameByLocale(item?.course, "name")}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: i18n.t("course.order.status"),
      render: (item) => {
        return <Tag>{item.status_label}</Tag>;
      },
      width: 120,
    },
    {
      title: i18n.t("course.order.price"),
      render: (item) => {
        return (
          <div className="info">
            <div className="name">
              {util.getFormattedPriceForYuan(item.price)}
            </div>
            <div className="name">
              {i18n.t("course.order.realPayment")} :{" "}
              {util.getFormattedPriceForYuan(item.price)}
            </div>
          </div>
        );
      },
      width: 150,
    },
    {
      title: i18n.t("course.student.validTime"),
      render: (item) => {
        return (
          <Tag>
            {item?.course_snap?.valid_forever
              ? i18n.t("course.student.validForever")
              : dayjs(item?.paid_at)
                  .add(item?.course_snap?.valid_days)
                  .format("YYYY-MM-DD HH:mm:ss")}
          </Tag>
        );
      },
      width: 120,
    },
  ];

  const dataSource = data ? [data] : [];
  const breadCrumbData = [
    {
      href: "/main/courses",
      title: i18n.t("course.title"),
    },
    {
      href: "/main/course/orders",
      title: i18n.t("course.order.title"),
    },
    {
      title: i18n.t("course.order.detail"),
    },
  ];

  return (
    <>
      <BreadCrumb data={breadCrumbData} />
      <div className="course-order-detail">
        <Card title={i18n.t("course.order.detail")} className="info">
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <span>
                {i18n.t("course.order.idLabel")} {data?.order_no}
              </span>
              <span>
                {i18n.t("course.order.transactionIdLabel")}{" "}
                {data?.transaction_id}
              </span>
              <span>
                {i18n.t("course.order.statusLabel")} {data?.status_label}
              </span>
              <span>
                {i18n.t("course.order.createTimeLabel")} {data?.created_at}
              </span>
              <span>
                {i18n.t("course.order.payTimeLabel")} {data?.paid_at}
              </span>
            </Col>
            <Col span={8}>
              <span>
                {i18n.t("course.order.avatarLabel")}
                <Avatar size={48} src={data?.user?.avatar} />
              </span>
              <span>
                {i18n.t("course.order.nicknameLabel")} {data?.user?.nickname}
              </span>
              <span>
                {i18n.t("course.order.telLabel")} {data?.user?.mobile}
              </span>
              <span>
                {i18n.t("course.order.realPaymentLabel")} {data?.price}
              </span>
            </Col>
            <Col span={8}>
              <span>{i18n.t("course.order.commissionInfoLabel")}</span>
            </Col>
          </Row>
        </Card>
        <Card title={i18n.t("course.courseInfo")} className="info">
          <Table
            className="list"
            rowKey="id"
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            scroll={{
              y: 220,
            }}
          />
        </Card>
      </div>
    </>
  );
};

export default CourseOrderDetail;
