import React from "react";

import { Layout, Col, Radio, DatePicker, Pagination, Spin } from "antd";
import Search from "antd/lib/input/Search";
import printJS from "print-js";

import Base from "../Base";

import RefundListItem from "../../components/order/RefundListItem";
import OrderSendModal from "../../components/order/OrderSendModal";
import Order100Modal from "../../components/order/Order100Modal";
import OrderRefundModal from "../../components/order/OrderRefundModal";

import DeliverymanSelectModal from "../deliveryman/DeliverymanSelectModal";

//redux
import { connect } from "react-redux";
import { getUserInfo } from "../../utils/redux/reducers/User";
import { CaretDownOutlined } from "@ant-design/icons";
import "../../styles/print.scss";


class RefundList extends Base {
  static PayTypes = [
    { key: "all", name: "order.payTypes.all" },
    { key: "wechat", name: "order.payTypes.wechat" },
    { key: "cash", name: "order.payTypes.cash" },
    { key: "balance", name: "order.payTypes.balance" },
  ];

  static RefundTypes = [
    { key: "all", name: "order.refundTypes.all" },
    { key: "waiting", name: "order.refundTypes.waiting" },
    { key: "accepted", name: "order.refundTypes.accepted" },
    { key: "refunding", name: "order.refundTypes.refunding" },
    { key: "refunded", name: "order.refundTypes.refunded" },
    { key: "refused", name: "order.refundTypes.refused" },
    { key: "failed", name: "order.refundTypes.failed" },
  ];

  static OrderSorts = [
    { key: "created_at@desc", name: "order.sortTypes.created_at_desc" },
    { key: "created_at@asc", name: "order.sortTypes.created_at_asc" },
    { key: "sequence", name: "order.sortTypes.sequence" },
  ];

  /* lifecycle mehotds */

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,

      payType: this.getRouterQueryItemByKey(RefundList.PayTypes, "payType"),
      refundType: this.getRouterQueryItemByKey(
        RefundList.RefundTypes,
        "refundType",
        3
      ),
      orderSort: this.getRouterQueryItemByKey(
        RefundList.OrderSorts,
        "sortType",
        1
      ),
      rangeTime: this.getDefaultTimeRange(),
      currentPage: this.getRouterQueryPage(),
      currentPageSize: this.getRouterQueryPageSize(),
      search: null,
      data: null,
      loadingData: false,

      acceptingOrder: false,

      cancelingOrder: false,

      showSendModal: false,
      showSendModalForLbsShops: false,
      sendModalOrder: null,
      sendingOrder: false,
      printingOrder: false,

      showKuaidi100Modal: false,
      kuaidi100ModalOrder: null,

      refundOrderDetails: [],
    };
  }

  componentDidMount() {
    super.componentDidMount();

    if (this.getUser()) {
      this.showMessage(this.i18n.t("order.loadingData"), Base.Message.loading);
      this.loadOrderList();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);

    if (
      prevState.search !== this.state.search ||
      prevState.payType.key !== this.state.payType.key ||
      prevState.refundType.key !== this.state.refundType.key ||
      prevState.orderSort.key !== this.state.orderSort.key ||
      prevState.rangeTime[0].diff(this.state.rangeTime[0]) !== 0 ||
      prevState.rangeTime[1].diff(this.state.rangeTime[1]) !== 0 ||
      prevState.currentPage !== this.state.currentPage ||
      prevState.currentPageSize !== this.state.currentPageSize
    ) {
      this.showMessage(this.i18n.t("order.updatingData"), Base.Message.loading);
      this.loadOrderList();

      this.setRouterQuery({
        search: this.state.search,
        payType: this.state.payType.key,
        refundType: this.state.refundType.key,
        sortType: this.state.orderSort.key,
        timeRange: `${this.state.rangeTime[0].format(
          "YYYY-MM-DD"
        )}~${this.state.rangeTime[1].format("YYYY-MM-DD")}`,
        page: this.state.currentPage,
        pageSize: this.state.currentPageSize,
      });
    }
  }

  /* render mehotds */

  render() {
    return (
      <Layout id="route-order-list">
        {this._renderFilters()}
        {this._renderListContainer()}
        {this._renderModals()}
      </Layout>
    );
  }

  _renderFilters() {
    return (
      <Col className="filter-container">
        {this._renderFiltersPayType()}
        {this._renderFiltersRefundType()}
        {this._renderFiltersOrderSort()}
        {this._renderFiltersRangeTime()}
        {this._renderFiltersSearch()}
      </Col>
    );
  }

  _renderFiltersSearch() {
    return (
      <div className="filter-row">
        <span className="label">{this.i18n.t("main.search")}</span>
        <Search
          onSearch={(keyword) => {
            this.setState({ search: keyword, currentPage: 1 });
          }}
          placeholder={this.i18n.t("main.searchHint")}
          style={{ width: 300, marginBottom: 16 }}
          enterButton
        />
      </div>
    );
  }

  _renderFiltersPayType() {
    return (
      <div className="filter-row">
        <span className="label">{this.i18n.t("order.payTypesTitle")}</span>
        <Radio.Group
          value={this.state.payType.key}
          onChange={this.handleFilterPayTypeChange}
        >
          {RefundList.PayTypes.map((type, index) => {
            return (
              <Radio key={`paytype-${index}`} value={type.key}>
                {this.i18n.t(type.name)}
              </Radio>
            );
          })}
        </Radio.Group>
      </div>
    );
  }

  _renderFiltersRefundType() {
    return (
      <div className="filter-row">
        <span className="label">{this.i18n.t("order.orderTypesTitle")}</span>
        <Radio.Group
          value={this.state.refundType.key}
          onChange={this.handleFilterRefundTypeChange}
        >
          {RefundList.RefundTypes.map((type, index) => {
            return (
              <Radio key={`refundtype-${index}`} value={type.key}>
                {this.i18n.t(type.name)}
              </Radio>
            );
          })}
        </Radio.Group>
      </div>
    );
  }

  _renderFiltersOrderSort() {
    return (
      <div className="filter-row">
        <span className="label">{this.i18n.t("order.sortTypesTitle")}</span>
        <Radio.Group
          value={this.state.orderSort.key}
          onChange={this.handleFilterOrderSortChange}
        >
          {RefundList.OrderSorts.map((sort, index) => {
            return (
              <Radio key={`orderSort-${index}`} value={sort.key}>
                {this.i18n.t(sort.name)}
              </Radio>
            );
          })}
        </Radio.Group>
      </div>
    );
  }

  _renderFiltersRangeTime() {
    return (
      <div className="filter-row">
        <span className="label">{this.i18n.t("order.rangeTimeTitle")}</span>
        <DatePicker.RangePicker
          defaultValue={this.state.rangeTime}
          format="YYYY-MM-DD"
          allowClear={false}
          onChange={this.handleFiltersTimeChange}
          suffixIcon={<CaretDownOutlined />}
        />
      </div>
    );
  }

  _renderListContainer() {
    return (
      <div className="list-container">
        <div className="list-wrap">
          {this._renderListOrders()}
          {this._renderListOrdersPagination()}
        </div>
        <div className="list-wrap todays-order">
          <div className="col-box todays-order">
            <div className="box-header">
              <span className="title">{this.i18n.t("order.todaysTitle")}</span>
            </div>
            {this._renderListToadysOrder()}
          </div>
        </div>
      </div>
    );
  }

  _renderListOrders() {
    let data = this.state.data;
    if (data) {
      let orders = data ? data.orders : null;
      let ordersData = orders ? orders.data : null;
      let user = this.getUser();
      const merchant = user.merchant ? user.merchant : null;
      if (ordersData && ordersData.length > 0) {
        let spinning =
          this.state.loadingData ||
          this.state.acceptingOrder ||
          this.state.cancelingOrder ||
          this.state.printingOrder ||
          this.state.sendingOrder;

        return [
          <div key="order-list-header" className="col-box todays-order">
            <div className="box-header">
              <span className="title">
                {this.i18n.t("order.ordersTitleCount", {
                  total: orders.total,
                })}
              </span>
            </div>
          </div>,
          <Spin key="order-list-spin" spinning={spinning}>
            {ordersData.map((order, index) => {
              return (
                <RefundListItem
                  key={`order-${index}`}
                  order={order}
                  onPrint={(orderId) => this.printOrder(orderId)}
                  merchant={merchant}
                  bbshop={
                    this.isBBShopMerchant() ||
                    this.isThirdPartyShop() ||
                    this.isDabazaShop()
                  }
                  onAction={(action, data) =>
                    this.onOrderAction(order, action, data)
                  }
                />
              );
            })}
          </Spin>,
        ];
      } else {
        return (
          <div className="col-box todays-order">
            <div className="box-header">
              <span className="title">{this.i18n.t("order.ordersTitle")}</span>
            </div>
            <div className="box-container">
              <h4>{this.i18n.t("order.loadingOrderEmpty")}</h4>
            </div>
          </div>
        );
      }
    } else {
      if (this.state.loadingData) {
        return (
          <div className="col-box todays-order">
            <div className="box-header">
              <span className="title">{this.i18n.t("order.ordersTitle")}</span>
            </div>
            <div className="box-container">
              <h4>{this.i18n.t("order.loadingOrder")}</h4>
            </div>
          </div>
        );
      }

      return (
        <div className="col-box todays-order">
          <div className="box-header">
            <span className="title">{this.i18n.t("order.ordersTitle")}</span>
          </div>
          <div className="box-container">
            <h4>{this.i18n.t("order.loadingOrderEmpty")}</h4>
          </div>
        </div>
      );
    }
  }

  _renderListOrdersPagination() {
    let data = this.state.data;
    let orders = data ? data.orders : null;
    if (orders && orders.data && orders.data.length > 0) {
      return (
        <div className="list-pagination">
          <Pagination
            total={orders.total}
            current={this.state.currentPage}
            showSizeChanger={true}
            pageSizeOptions={["10", "20", "30"]}
            pageSize={this.state.currentPageSize}
            onChange={this.onPaginationPageChange}
            onShowSizeChange={this.onPaginationPageSizeChange}
            showQuickJumper
          />
        </div>
      );
    } else {
      return null;
    }
  }

  _renderListToadysOrder() {
    let data = this.state.data;
    if (data) {
      let statistics = data.statistics;
      let orderCount = statistics ? statistics.count : 0;
      let totalPrice = statistics ? statistics.total_price : 0;

      return (
        <div className="box-container">
          <h4>
            {this.i18n.t("order.todaysCount", {
              orderCount: orderCount,
            })}
          </h4>
          <h4>
            {this.i18n.t("order.todaysTotal", {
              totalPrice: this.util.getFormattedPrice(totalPrice),
            })}
          </h4>
        </div>
      );
    } else {
      if (this.state.loadingData) {
        return (
          <div className="box-container">
            <h4>{this.i18n.t("order.todaysLoading")}</h4>
          </div>
        );
      }

      return (
        <div className="box-container">
          <h4>{this.i18n.t("order.todaysEmpty")}</h4>
        </div>
      );
    }
  }

  _renderModals() {
    let items = [];

    items.push(this._renderOrderSendModal());
    items.push(this._renderOrder100Modal());
    items.push(this.renderOrderRefundModal());
    items.push(this.renderOrderSendModalForLbsShops());

    return items;
  }

  _renderOrderSendModal() {
    if (!this.state.showSendModal) return null;

    let confirmLoading = this.state.sendingOrder;

    return (
      <OrderSendModal
        key="order-send-modal"
        order={this.state.sendModalOrder}
        onCancel={this.handleSendModalCancel}
        onConfirm={this.handleSendModalConfirm}
        confirmLoading={confirmLoading}
      />
    );
  }

  renderOrderSendModalForLbsShops() {
    if (!this.state.showSendModalForLbsShops) return null;
    let confirmLoading = this.state.sendingOrder;
    return (
      <DeliverymanSelectModal
        key="order-send-modal-lbs"
        selectedDeliverymanId={this.state.sendModalOrder.deliveryman_id}
        orderId={this.state.sendModalOrder.id}
        visible={this.state.showSendModalForLbsShops}
        onConfirm={this.handleSendLbsModalConfirm}
        confirmLoading={confirmLoading}
        onCancel={() => {
          this.setState({
            showSendModalForLbsShops: false,
          });
        }}
      />
    );
  }

  _renderOrder100Modal() {
    if (!this.state.showKuaidi100Modal) return null;

    return (
      <Order100Modal
        key="order-100-modal"
        order={this.state.kuaidi100ModalOrder}
        onCancel={this.handle100ModalCancel}
      />
    );
  }

  renderOrderRefundModal() {
    const { refundOrderDetails } = this.state;
    return (
      <OrderRefundModal
        key="order-refund-modal"
        details={refundOrderDetails}
        onCancel={this.cancelOrderRefund}
        onFinish={this.onRefundOrder}
      />
    );
  }

  /* user methods */

  handleFilterPayTypeChange = (e) => {
    let type = RefundList.PayTypes.find((type) => type.key === e.target.value);
    this.setState({ payType: type, currentPage: 1 });
  };

  handleFilterRefundTypeChange = (e) => {
    let type = RefundList.RefundTypes.find(
      (type) => type.key === e.target.value
    );
    this.setState({ refundType: type, currentPage: 1 });
  };

  handleFilterOrderSortChange = (e) => {
    let sort = RefundList.OrderSorts.find(
      (sort) => sort.key === e.target.value
    );
    this.setState({ orderSort: sort, currentPage: 1 });
  };

  handleFiltersTimeChange = (dates) => {
    this.setState({ rangeTime: dates, currentPage: 1 });
  };

  onOrderAction = (order, action, data) => {
    switch (action) {
      case RefundListItem.REFUND_ACCEPT:
        this.acceptRefund(order);
        break;

      case RefundListItem.REFUND_ACCEPT_AFTER_REJECT:
        this.acceptRefund(order, RefundListItem.REFUND_ACCEPT_AFTER_REJECT);
        break;

      case RefundListItem.REFUND_ACCEPT_AFTER_RETURN:
        this.acceptRefund(order, RefundListItem.REFUND_ACCEPT_AFTER_RETURN);
        break;

      case RefundListItem.REFUND_REJECT:
        this.rejectRefund(order);
        break;

      default:
        break;

    }
  };

  onPaginationPageChange = (page, pageSize) => {
    this.setState({ currentPage: page });
  };

  onPaginationPageSizeChange = (page, pageSize) => {
    this.setState({ currentPageSize: pageSize, currentPage: 1 });
  };

  handleSendModalCancel = () => {
    this.setState({
      showSendModal: false,
      sendModalOrder: null,
    });
  };

  handleSendModalConfirm = (values) => {
    if (!values) return;

    if (values.isEdit) {
      this.showMessage(this.i18n.t("order.sendChanging"), Base.Message.loading);
      this.sendChangeBBShopOrder(values);
    } else {
      this.showMessage(this.i18n.t("order.sending"), Base.Message.loading);
      this.sendBBShopOrder(values);
    }
  };

  handleSendLbsModalConfirm = (values) => {
    if (!values) return;
    if (
      this.state.sendModalOrder.deliveryman_id &&
      values.deliveryman_id !== this.state.sendModalOrder.deliveryman_id
    ) {
      this.showMessage(this.i18n.t("order.sendChanging"), Base.Message.loading);
      this.sendChangeBBShopOrder(values);
    } else {
      this.showMessage(this.i18n.t("order.sending"), Base.Message.loading);
      this.sendBBShopOrder(values);
    }
    this.setState({
      showSendModalForLbsShops: false,
    });
  };
  // deliveryman_id

  handle100ModalCancel = () => {
    this.setState({
      showKuaidi100Modal: false,
      kuaidi100ModalOrder: null,
    });
  };

  /* server methods */

  async loadOrderList() {
    if (this.state.loadingData) return;

    let token = this.getUserToken();
    if (!token) return;

    let api = this.api.orderv6.refundList;

    let apiConfig = {
      token: token,
      query: {
        "filter[search]": this.state.search,
        "filter[paytype]": this.state.payType.key,
        "filter[refundType]": this.state.refundType.key,
        "filter[start_date]": this.state.rangeTime[0].format("YYYY-MM-DD"),
        "filter[end_date]": this.state.rangeTime[1].format("YYYY-MM-DD"),
        page: this.state.currentPage,
        sort: this.state.orderSort.key,
        count: this.state.currentPageSize,
      },
    };

    this.setState({ loadingData: true });
    try {
      let res = await api.run(apiConfig);

      if (!this.mounted) return;

      if (res.result) {
        this.setState({ data: res.data });
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;

      this.setState({ loadingData: false });
    }
  }

  acceptRefund = async (order, type = RefundListItem.REFUND_ACCEPT) => {
    if (!order) return;

    let token = this.getUserToken();
    if (!token) return;

    let api = this.api.refundv6.accept;

    let apiConfig = {
      token: token,
      path: {
        refundId: order.refund_id,
      },
    };

    this.setState({ acceptingOrder: true });
    try {
      let res = await api.run(apiConfig);

      if (!this.mounted) return;

      if (res.result) {
        this.showMessage(res.message, Base.Message.success);

        this.showMessage(
          this.i18n.t("order.loadingData"),
          Base.Message.loading
        );
        this.loadOrderList();
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;

      this.setState({ acceptingOrder: false });
    }
  };

  rejectRefund = async (order) => {
    if (!order) return;

    let token = this.getUserToken();
    if (!token) return;

    let api = this.api.refundv6.reject;

    let apiConfig = {
      token: token,
      path: {
        refundId: order.refund_id,
      },
    };

    this.setState({ acceptingOrder: true });
    try {
      let res = await api.run(apiConfig);

      if (!this.mounted) return;

      if (res.result) {
        this.showMessage(res.message, Base.Message.success);

        this.showMessage(
          this.i18n.t("order.loadingData"),
          Base.Message.loading
        );
        this.loadOrderList();
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;

      this.setState({ acceptingOrder: false });
    }
  };

  cancelBBShopOrder = async (order) => {
    if (!order) return;

    let token = this.getUserToken();
    if (!token) return;

    let api = this.api.orderv6.cancel;
    if (this.isBBShopMerchant()) {
      api = this.api.order.cancel;
    }
    let apiConfig = {
      token: token,
      path: {
        orderId: order.id,
      },
    };

    this.setState({ cancelingOrder: true });
    try {
      let res = await api.run(apiConfig);

      if (!this.mounted) return;

      if (res.result) {
        this.showMessage(res.message, Base.Message.success);

        this.showMessage(
          this.i18n.t("order.loadingData"),
          Base.Message.loading
        );
        this.loadOrderList();
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;

      this.setState({ cancelingOrder: false });
    }
  };

  sendBBShopOrder = async (values) => {
    if (!values) return;

    let token = this.getUserToken();
    if (!token) return;

    let api = this.api.orderv6.send;
    if (this.isBBShopMerchant()) {
      api = this.api.order.send;
    }
    let data = {};
    if (values.trackingData) {
      data = {
        tracking_data: values.trackingData,
      };
    }
    if (values.deliveryman_id) {
      data = {
        deliveryman_id: values.deliveryman_id,
      };
    }
    let apiConfig = {
      token: token,
      path: {
        orderId: values.orderId,
      },
      data,
    };

    this.setState({ sendingOrder: true });
    try {
      let res = await api.run(apiConfig);

      if (!this.mounted) return;

      if (res.result) {
        this.showMessage(res.message, Base.Message.success);

        this.setState({
          showSendModal: false,
          sendModalOrder: null,
        });

        this.showMessage(
          this.i18n.t("order.loadingData"),
          Base.Message.loading
        );
        this.loadOrderList();
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;

      this.setState({ sendingOrder: false });
    }
  };

  sendChangeBBShopOrder = async (values) => {
    if (!values) return;

    let token = this.getUserToken();
    if (!token) return;

    let api = this.api.orderv6.sendChange;
    if (this.isBBShopMerchant()) {
      api = this.api.order.sendChange;
    }

    let data = {};
    if (values.trackingData) {
      data = {
        tracking_data: values.trackingData,
      };
    }
    if (values.deliveryman_id) {
      data = {
        deliveryman_id: values.deliveryman_id,
      };
    }

    let apiConfig = {
      token: token,
      path: {
        orderId: values.orderId,
      },
      data,
    };

    this.setState({ sendingOrder: true });
    try {
      let res = await api.run(apiConfig);

      if (!this.mounted) return;

      if (res.result) {
        this.showMessage(res.message, Base.Message.success);

        this.setState({
          showSendModal: false,
          sendModalOrder: null,
        });

        this.showMessage(
          this.i18n.t("order.loadingData"),
          Base.Message.loading
        );
        this.loadOrderList();
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;

      this.setState({ sendingOrder: false });
    }
  };

  orderRefund(order) {
    this.setState({ refundOrderDetails: order.details });
  }

  orderRefundCheck = async (order) => {
    if (!order) return;

    let token = this.getUserToken();
    if (!token) return;

    let api = this.api.orderv6.refundCheck;
    if (this.isBBShopMerchant()) {
      api = this.api.order.refundCheck;
    }
    let apiConfig = {
      token: token,
      path: {
        orderId: order.id,
      },
    };

    this.setState({ loadingData: true });
    try {
      let res = await api.run(apiConfig);

      if (!this.mounted) return;

      if (res.result) {
        this.setState({ loadingData: false });
        this.showMessage(res.message, Base.Message.success);

        this.showMessage(
          this.i18n.t("order.loadingData"),
          Base.Message.loading
        );
        this.loadOrderList();
      } else {
        this.setState({ loadingData: false });
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      this.setState({ loadingData: false });
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;

      this.setState({ loadingData: false });
    }
  };

  cancelOrderRefund = () => {
    this.setState({ refundOrderDetails: [] });
  };

  onRefundOrder = (values) => {
    const productsKey = Object.keys(values).filter((k) => k !== "reason");
    const atLeastOneRefund = productsKey.some((key) => values[key] > 0);
    if (atLeastOneRefund) {
      this.showModal(
        this.i18n.t("order.refundConfirm"),
        Base.Modal.confirm,
        async () => {
          const token = this.getUserToken();
          const { refundOrderDetails } = this.state;
          if (token) {
            let api = this.api.orderv6.refund;
            if (this.isBBShopMerchant()) {
              api = this.api.order.refund;
            }
            const apiConfig = {
              token,
              path: {
                orderId: refundOrderDetails[0].order_id,
              },
              data: {
                refund_list: productsKey.map((key) => ({
                  product_id: key,
                  refund_count: values[key],
                })),
                reason: values.reason,
              },
            };
            try {
              const res = await api.run(apiConfig);
              if (res.result) {
                this.showMessage(res.message);
                this.setState({ refundOrderDetails: [] });
              } else {
                this.showModal(res.message, Base.Modal.error);
              }
            } catch (error) {
              this.handleApiError(api, error);
            }
          }
        }
      );
    } else {
      this.showModal(this.i18n.t("order.atLeastRefund"), Base.Modal.warning);
    }
  };

  printOrderByLocalPrinter = async (orderId) => {
    if (!orderId) return;
    printJS({
      printable: "orderItem" + orderId,
      type: "html",
      header:
        '<div class="print-header">' +
        this.i18n.t("order.reviewItem.detailLabel") +
        "</div>",
      headerStyle: "font-weight: bold; text-align: center; font-size: 30px;",
      css: "https://bulutbazar.com/css/print.css?" + new Date().getTime(),
    });
  };

  printOrder = async (orderId) => {
    if (!orderId) return;

    let token = this.getUserToken();
    if (!token) return;

    let api = this.api.orderv6.print;
    if (this.isBBShopMerchant()) {
      api = this.api.order.print;
    }
    let apiConfig = {
      token: token,
      path: {
        orderId: orderId,
      },
    };

    this.setState({ printingOrder: true });
    try {
      let res = await api.run(apiConfig);

      if (!this.mounted) return;

      if (res.result) {
        this.showMessage(res.message, Base.Message.success);

        this.setState({
          printingOrder: false,
        });

        this.showMessage(
          this.i18n.t("order.loadingData"),
          Base.Message.loading
        );
        this.loadOrderList();
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;

      this.setState({ sendingOrder: false });
    }
  };

  /* custom methods */

  getDefaultTimeRange() {
    let timeRange = [this.moment().subtract(7, "days"), this.moment()];

    if (Boolean(this.state.search)) {
      timeRange = [this.moment().subtract(30, "days"), this.moment()];
    }

    let queryTime = this.getRouterQueryTimeRange();
    if (queryTime) {
      timeRange = queryTime;
    }

    return timeRange;
  }

  copyBBShopOrderShipNum(number) {
    if (!number) return;

    let target = document.createElement("div");
    target.id = "shipNumberCopy";
    target.style.opacity = "0";
    target.innerText = number;
    document.body.appendChild(target);

    try {
      let range = document.createRange();
      range.selectNode(target);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand("copy");
      window.getSelection().removeAllRanges();

      this.showMessage(
        this.i18n.t("order.copyShipNoSuccess"),
        Base.Message.success
      );
    } catch (error) {
      this.showMessage(
        this.i18n.t("order.copyShipNoFailed"),
        Base.Message.error
      );
    } finally {
      target.parentElement.removeChild(target);
    }
  }

  onCalculateCommission = async (orderId) => {
    if (!orderId) return;

    let token = this.getUserToken();
    if (!token) return;

    let api = this.api.orderv6.calculate;
    if (this.isBBShopMerchant()) {
      api = this.api.order.calculate;
    }
    let apiConfig = {
      token,
      path: {
        orderId,
      },
    };

    this.setState({ loadingData: true });
    try {
      let res = await api.run(apiConfig);

      if (!this.mounted) return;

      if (res.result) {
        this.showMessage(res.message, Base.Message.success);

        this.setState({
          loadingData: false,
        });

        this.showMessage(
          this.i18n.t("order.loadingData"),
          Base.Message.loading
        );
        this.loadOrderList();
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      this.showMessage(JSON.stringify(error), Base.Message.error);
    }
  };
}

export default connect((state) => {
  return {
    user: getUserInfo(state),
  };
})(RefundList);
