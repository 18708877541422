import {
  CheckOutlined,
  CloseOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Form,
  message,
  Button,
  Space,
  Upload,
  Input,
  Switch,
  InputNumber,
  Radio,
  Card,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { ApiManager, Locale, Request, Util } from "../../utils";
import { getUserInfo } from "../../utils/redux/reducers/User";
import { getMerchantSettings } from "../../utils/redux/reducers/Merchant";
import TranslateBtn from "../../components/product/TranslateBtn";
import SlateRichTextEditor from "../../components/editor/SlateRichTextEditor";
import TextArea from "antd/lib/input/TextArea";

import "./editCard.scss";
import { BreadCrumb } from "../../components/main/LayoutComponents";
import { useHistory, useLocation } from "react-router-dom";

const i18n = Locale.getInstance();
const util = Util.getInstance();
const api = ApiManager.getInstance();

function EditCard() {
  const user = useSelector((state) => getUserInfo(state));
  const settings = useSelector((state) => getMerchantSettings(state));
  const editCard = useRef();
  const [form] = useForm();
  const history = useHistory();
  const location = useLocation();
  const { state } = location;
  editCard.current = async (values) => {
    if (user.token) {
      try {
        let url = "/member/api/v6/merchant/member-card";
        let method = Request.post;
        const {
          status,
          valid_days,
          should_all_course_free,
          ug_description,
          zh_description,
          price,
          discount_percent,
          ...otherValues
        } = values;
        let payload = {
          ...otherValues,
          status: status ? 1 : 0,
          price: price * 100,
          should_all_course_free,
          valid_days,
          discount_percent,
          zh_description,
          ug_description,
        };

        if (state && state.cardData) {
          const { id } = state.cardData;
          url += `/${id}`;
          method = Request.put;
        }

        const res = await method(url, payload, user.token);
        if (res.result) {
          history.goBack();
        } else {
          message.warning(res.message);
        }
      } catch (error) {
        message.error(error.message);
      }
    }
  };
  const onUploadImg = async ({ file, onError, onSuccess }) => {
    if (!file) onError("invalid images");
    const token = user.token;
    if (!token) onError("no user token");
    const uploadFiles = [{ file }];
    const apiConfig = {
      token,
      uploadFiles,
      uploadType: "member-card-img",
    };
    try {
      const res = await api.common.uploadImages.run(apiConfig);
      if (res.result) {
        onSuccess(res.data[0], file);
      } else {
        onError(res.message);
      }
    } catch (error) {
      onError(error);
    }
  };

  useEffect(() => {
    if (state && state.cardData) {
      const {
        zh_description,
        ug_description,
        discount_percent,
        points,
        points_multiple_by,
        status,
        order,
        valid_days,
        should_all_course_free,
        zh_name,
        ug_name,
        commission_percent,
        parent_commission_percent,
        price,
      } = state.cardData;
      form.setFieldsValue({
        zh_description,
        ug_description,
        status: status === 1,
        discount_percent,
        order,
        should_all_course_free,
        valid_days,
        price: price / 100,
        points,
        points_multiple_by,
        zh_name,
        ug_name,
        commission_percent,
        parent_commission_percent,
        no_commission: !!parent_commission_percent,
      });
    }
  }, [history, form, state]);
  const breadCrumbData = [
    {
      href: "/main/members/card",
      title: i18n.t("main.sideMenus.membersCard"),
    },
    {
      title:
        state && state.cardData
          ? i18n.t("member.editCard")
          : i18n.t("member.addCard"),
    },
  ];
  return (
    <>
      <BreadCrumb data={breadCrumbData} />
      <Form
        style={{ backgroundColor: "#fff", paddingTop: 40, paddingBottom: 20 }}
        wrapperCol={{ span: 20 }}
        className="member-card-form"
        labelCol={{ span: 4 }}
        onFinish={editCard.current}
        form={form}
      >
        <Card
          title={i18n.t("course.basicInfo")}
          className="info commission-info"
        >
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                key={"zh_name"}
                name={"zh_name"}
                rules={[
                  { required: true, message: i18n.t("sales.zhTitleWarning") },
                ]}
                label={i18n.t("agentLevel.zh")}
              >
                <Input
                  addonAfter={
                    <TranslateBtn
                      context={getFieldValue("zh_name")}
                      onFinish={(value) =>
                        setFieldsValue({
                          ug_name: value,
                        })
                      }
                      from="zh"
                      to="ug"
                    />
                  }
                />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                key={"ug_name"}
                name={"ug_name"}
                rules={[
                  { required: true, message: i18n.t("sales.ugTitleWarning") },
                ]}
                label={i18n.t("agentLevel.ug")}
              >
                <Input
                  addonAfter={
                    <TranslateBtn
                      context={getFieldValue("ug_name")}
                      onFinish={(value) =>
                        setFieldsValue({
                          zh_name: value,
                        })
                      }
                      from="ug"
                      to="zh"
                    />
                  }
                />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item
            label={i18n.t("course.validDays")}
            name="valid_days"
            rules={[{ required: true }]}
          >
            <InputNumber style={{ width: 150 }} />
          </Form.Item>
          <Form.Item
            name="price"
            label={i18n.t("member.price")}
            initialValue={1}
          >
            <InputNumber
              style={{ width: 150 }}
              min={0}
              formatter={(p) => `¥${p}`}
            />
          </Form.Item>
          <Form.Item
            name="status"
            label={i18n.t("sales.status")}
            valuePropName="checked"
            initialValue={true}
          >
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </Form.Item>
          <Form.Item
            label={i18n.t("community.order")}
            name="order"
            rules={[{ required: true }]}
            extra={i18n.t("common.orderDescription")}
          >
            <InputNumber style={{ width: 150 }} />
          </Form.Item>
        </Card>

        <Card
          title={i18n.t("member.memberAdventages")}
          className="info commission-info"
        >
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                name="should_all_course_free"
                label={i18n.t("member.shouldAllCourseFree")}
                valuePropName="checked"
                initialValue={getFieldValue("should_all_course_free")}
                extra={i18n.t("member.shouldAllCourseFreeDescription")}
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Form.Item>
            )}
          </Form.Item>

          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                name="discount_percent"
                label={i18n.t("member.discount")}
                rules={[
                  {
                    required: true,
                    message: i18n.t("member.discountWarning"),
                  },
                ]}
                initialValue={0}
                extra={i18n.t("member.discountDescription")}
              >
                <InputNumber
                  style={{ width: 150 }}
                  formatter={(v) => `${v}%`}
                  min={0}
                  max={99}
                />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                label={i18n.t("member.multiple")}
                name="points_multiple_by"
                rules={[{ required: true }]}
                initialValue={1}
                extra={i18n.t("member.multiplePointsDescription")}
              >
                <InputNumber style={{ width: 150 }} />
              </Form.Item>
            )}
          </Form.Item>
        </Card>
        <Card
          title={i18n.t("course.commissionInfo")}
          className="commission-info info"
        >
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                name="no_commission"
                label={i18n.t("member.independentCommission")}
                valuePropName="checked"
                initialValue={getFieldValue("no_commission")}
                extra={i18n.t("common.independentCommissionDescription")}
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) =>
              !!getFieldValue("no_commission") && (
                <>
                  <Form.Item
                    name="commission_percent"
                    label={i18n.t("course.commissionPercent")}
                  >
                    <Input style={{ width: 120 }} addonAfter={<span>%</span>} />
                  </Form.Item>
                  <Form.Item
                    name="parent_commission_percent"
                    label={i18n.t("course.parentCommissionPercent")}
                  >
                    <Input style={{ width: 120 }} addonAfter={<span>%</span>} />
                  </Form.Item>
                </>
              )
            }
          </Form.Item>
        </Card>
        <Card
          title={i18n.t("course.saleInfo")}
          className="info commission-info"
        >
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                name="zh_description"
                label={i18n.t("member.zhDesc")}
                initialValue={state?.cardData?.zh_description}
              >
                <SlateRichTextEditor
                  setFieldsValue={(value) =>
                    setFieldsValue({
                      zh_description: value,
                    })
                  }
                  initialValue={
                    state?.cardData ? state?.cardData?.zh_description : null
                  }
                />
              </Form.Item>
            )}
          </Form.Item>

          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                name="ug_description"
                label={i18n.t("member.ugDesc")}
                initialValue={state?.cardData?.ug_description}
              >
                <SlateRichTextEditor
                  setFieldsValue={(value) =>
                    setFieldsValue({
                      ug_description: value,
                    })
                  }
                  initialValue={
                    state?.cardData ? state?.cardData?.ug_description : null
                  }
                />
              </Form.Item>
            )}
          </Form.Item>
        </Card>
        <Form.Item wrapperCol={{ offset: 4 }}>
          <Space>
            <Button htmlType="submit" type="primary">
              {i18n.t("property.submit")}
            </Button>
            <Button
              onClick={() => {
                history.goBack();
              }}
            >
              {i18n.t("common.cancelText")}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  );
}

export default EditCard;
