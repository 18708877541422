import { Button, Image, Space, Tag } from "antd";
import { Locale } from "../../../utils";
import {
  CheckCircleOutlined,
  InfoCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
const i18n = Locale.getInstance();

export const storeListColumns = (history, handleApprove, handleReject) => {
  return [
    {
      title: i18n.t("qrcode.name"),
      render: (item) => {
        return <span>{i18n.getPropNameByLocale(item, "name")}</span>;
      },
    },
    {
      title: i18n.t("common.mobile"),
      render: (item) => {
        return <span>{item.mobile}</span>;
      },
    },
    {
      title: i18n.t("qrcode.signboard"),
      render: (item) => {
        return <Image minwidth={100} maxwidth={200} src={item.signboard} />;
      },
    },
    {
      title: i18n.t("qrcode.license"),
      render: (item) => {
        return (
          <Image minwidth={100} maxwidth={200} src={item.business_licence} />
        );
      },
    },
    {
      title: i18n.t("qrcode.idCard"),
      render: (item) => {
        return <Image minwidth={100} maxwidth={200} src={item.id_card} />;
      },
    },
    {
      title: i18n.t("qrcode.regionName"),
      render: (item) => {
        return (
          <div>
            {item?.province ? item.province?.name.zh + " > " : ""}
            {item?.city ? item.city?.name.zh + " > " : ""}
            {item?.region ? item.region?.name.zh : ""}
          </div>
        );
      },
    },
    {
      title: i18n.t("commissionList.address"),
      dataIndex: "address",
    },
    {
      title: i18n.t("qrcode.applyDate"),
      dataIndex: "applied_at",
    },

    {
      title: i18n.t("qrcode.approveDate"),
      dataIndex: "approved_at",
    },
    {
      title: i18n.t("common.statusLabel"),
      render: (item) => {
        let color;
        let icon;
        if (item.status === 1) {
          color = "purple";
          icon = <InfoCircleOutlined />;
        } else if (item.status === 3) {
          color = "red";
          icon = <QuestionCircleOutlined />;
        } else {
          color = "green";
          icon = <CheckCircleOutlined />;
        }
        return (
          <>
            <Tag icon={icon} color={color}>
              {item.status_label}
            </Tag>
            <span>
              {item.zh_refuse_reason ? i18n.getPropNameByLocale(item, 'refuse_reason') : ''}
            </span>
          </>
        );
      },
    },

    {
      title: i18n.t("users.actions"),
      fixed: "right",
      width: 100,
      render: (item) => {
        return (
          <Space direction="vertical">
            <Button
              onClick={() => {
                history.push({
                  pathname: `/main/lottery-qrcode/store/${item.id}`,
                });
              }}
              type="link"
              size="small"
            >
              {i18n.t("product.productItem.actionEdit")}
            </Button>
            {item.status == 1 && (
              <Button
                onClick={() => handleApprove(item.id)}
                type="primary"
                size="small"
              >
                {i18n.t("commissionList.confirm")}
              </Button>
            )}
            {item.status != 3 && (
              <Button
                onClick={() => handleReject(item.id)}
                type="primary"
                size="small"
                danger
              >
                {i18n.t("commissionList.reject")}
              </Button>
            )}
          </Space>
        );
      },
    },
  ];
};
