import { DeleteOutlined } from "@ant-design/icons";
import { notification, Button, Popconfirm } from "antd";
import { useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { Locale } from "../../utils";
import { handleMutationError } from "../../utils/helpers/helpers";
import { getUserInfo } from "../../utils/redux/reducers/User";
import { batchDeleteFiles } from "./mediaApi";

const i18n = Locale.getInstance();

const MediaListFooter = ({ selectedIds, setMediaLoading }) => {
  const queryClient = useQueryClient();
  const user = useSelector((state) => getUserInfo(state));

  const batchDeleteFilesMutation = useMutation(batchDeleteFiles, {
    onSuccess: () => {
      setMediaLoading(false);
      queryClient.invalidateQueries("media-list");
    },
    onError: (error) => {
      setMediaLoading(false);
      handleMutationError(error);
    },
  });

  const hasSelected = selectedIds.length > 0;
  return (
    <>
      <Popconfirm
        title={i18n.t("sales.deleteWarning")}
        onConfirm={() => {
          setMediaLoading(true);
          batchDeleteFilesMutation.mutate({
            ids: selectedIds,
            token: user.token,
          });
        }}
        okText={i18n.t("base.modal.okText")}
        cancelText={i18n.t("base.modal.cancelText")}
      >
        <Button disabled={!hasSelected} icon={<DeleteOutlined />}>
          {i18n.t("common.delete")}
        </Button>
      </Popconfirm>
      <span
        style={{
          marginLeft: 8,
        }}
      >
        {hasSelected
          ? i18n.t("media.selectedRows", { count: selectedIds.length })
          : ""}
      </span>
    </>
  );
};

export default MediaListFooter;
