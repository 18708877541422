import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Button, Card, Col, Form, Image, Input, Row, Spin, Switch } from "antd";
import { useCallback, useEffect, useRef } from "react";
import AMapLoader from "@amap/amap-jsapi-loader";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { getOne, update } from "../../../utils/http/dataProvider";
import { getUserInfo } from "../../../utils/redux/reducers/User";
import { ApiManager, Locale, Util } from "../../../utils";
import TranslateBtn from "../../../components/product/TranslateBtn";
import { BreadCrumb } from "../../../components/main/LayoutComponents";
import { AMAP_KEY, AMAP_VERSION, AMAP_ZOOM } from "../../../constants";
import { showNotificationWithIcon } from "../../../utils/helpers/helpers";

const i18n = Locale.getInstance();
const api = ApiManager.getInstance();
const util = Util.getInstance();
const { Meta } = Card;

function LotteryRecommenderList() {
  const { recommenderId } = useParams();
  const user = useSelector((state) => getUserInfo(state));
  const [recommenderForm] = Form.useForm();
  let map = useRef(null);
  const queryClient = useQueryClient();
  const history = useHistory();

  const { data, isLoading } = useQuery(
    ["lottery-recommender", user.token, recommenderId],
    () =>
      getOne("lottery-recommender", {
        id: recommenderId,
        token: user.token,
      })
  );
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  useEffect(() => {
    if (data) {
      console.log("🚀 ~ useEffect ~ data:", data);

      recommenderForm.setFieldsValue({
        name: data.name,
        mobile: data.mobile,
        commission_rate: data.commission_rate,
        status: data.status,
      });
    }
  }, [data]);

  const updateStoreMutation = useMutation(update, {
    onSuccess: () => {
      queryClient.invalidateQueries("lottery-recommender");
      showNotificationWithIcon(i18n.t("media.saveFinished"), "success");
      history.goBack();
    },
    onError: (error) => {
      handleMutationError(error);
    },
  });

  const handleSubmit = async (values) => {
    try {
      const { name, mobile, commission_rate, status } = values;
      let data = {
        name,
        commission_rate,
        mobile,
        status: status ? 1 : 0,
      };

      updateStoreMutation.mutate({
        resource: "lottery-recommender",
        params: { data, token: user.token, id: recommenderId },
      });
    } catch (error) {
      console.log("🚀 ~ file: StoreEdit.jsx:84 ~ handleSubmit ~ error:", error);
    }
  };

  const cardStyle = {
    display: "flex",
    flexDirection: "column",
  };

  return (
    <>
      <BreadCrumb
        data={[
          {
            href: "/main/lottery/recommender",
            title: i18n.t("recommender.title"),
          },
        ]}
      />
      <div className="qrcode">
        <Spin spinning={isLoading}>
          <Form
            form={recommenderForm}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 10 }}
            onFinish={handleSubmit}
          >
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldsValue }) => (
                <Form.Item
                  shouldUpdate
                  name="name"
                  label={i18n.t("users.name")}
                  rules={[
                    {
                      required: true,
                      message: i18n.t("productEdit.standardRequired"),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              )}
            </Form.Item>

            <Form.Item
              name="mobile"
              label={i18n.t("common.mobile")}
              rules={[
                {
                  required: true,
                  message: i18n.t("productEdit.standardRequired"),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="commission_rate"
              label={i18n.t("productEdit.commission")}
              rules={[
                {
                  required: true,
                  message: i18n.t("productEdit.standardRequired"),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldsValue }) => (
                <Form.Item
                  name="status"
                  label={i18n.t("commissionList.status")}
                  valuePropName="checked"
                  initialValue={getFieldValue("status")}
                >
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {i18n.t("productEdit.saveAndBack")}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </div>
    </>
  );
}

export default LotteryRecommenderList;
