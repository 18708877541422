import { DeleteOutlined } from "@ant-design/icons";

const ImageUploadPreview = ({ selectedImages, selectImages }) => {
  if (!selectedImages || selectedImages.length < 1) {
    return null;
  }
  return (
    <>
      <div className="preview">
        {selectedImages.map((image, index) => {
          if (!image) {
            return;
          }
          return (
            <div className="preview-item" key={index}>
              <div className="image">
                <img src={image.url} />
              </div>
              <div className="remove">
                <DeleteOutlined
                  className="icon"
                  onClick={() => {
                    const newItems = selectedImages.filter(
                      (v) => v.url !== image.url
                    );
                    selectImages(newItems);
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ImageUploadPreview;
