import { Button, Spin, Table } from "antd";

import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Locale } from "../../utils";
import { showNotificationWithIcon } from "../../utils/helpers/helpers";
import { getMany } from "../../utils/http/dataProvider";
import { getUserInfo } from "../../utils/redux/reducers/User";

import "./ProductCategorySelector.scss";

const i18n = Locale.getInstance();

const ProductCategorySelector = ({ setSelectedLink, selectedLink }) => {
  const user = useSelector((state) => getUserInfo(state));

  const { data, error, isLoading } = useQuery(
    ["product-categories", user.token],
    () =>
      getMany("categories", {
        token: user.token,
      })
  );

  if (error) {
    console.log(
      "🚀 ~ file: ProductCategorySelector.jsx ~ line 38 ~ ProductCategorySelector ~ error",
      error
    );
    showNotificationWithIcon(error.data.message);
  }

  const columns = [
    {
      title: i18n.t("product.cats.name"),
      render: (item) => {
        return <span>{i18n.getPropNameByLocale(item, "name")}</span>;
      },
    },
    {
      title: i18n.t("users.date"),
      dataIndex: "created_at",
      width: 200,
    },
    {
      title: i18n.t("product.cats.actions"),
      width: 100,
      render: (item) => {
        return (
          <>
            <Button
              type={item.id === selectedLink.key ? "primary" : "default"}
              onClick={() => {
                setSelectedLink({
                  key: item.id,
                  ug_name: `مەھسۇلات تۈرى~${item.ug_name}`,
                  zh_name: `产品分类~${item.zh_name}`,
                  url: `/pages/main-page/index?tabIndex=1&catId=${item.id}`,
                });
              }}
              size="small"
            >
              {item.id === selectedLink.key
                ? i18n.t("link.selected")
                : i18n.t("link.select")}
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Spin spinning={isLoading}>
        <div className="product-categories">
          <Table
            className="list"
            rowKey="id"
            columns={columns}
            dataSource={data?.data}
            scroll={{
              y: 320,
            }}
          />
        </div>
      </Spin>
    </>
  );
};

export default ProductCategorySelector;
