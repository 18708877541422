import moment from "dayjs";
import { Logger } from "../";

export default class Util {
  static util;
  static getInstance() {
    if (!Util.util) Util.util = new Util();
    return Util.util;
  }

  constructor() {
    Logger.getInstance().log(`util ${this.constructor.name} initializing ...`);
    Logger.getInstance().log(`util ${this.constructor.name} initialized`);
  }

  isValidText(text) {
    return text && text.length > 0;
  }

  isValidUrl(url) {
    if (!url) return false;

    if (!url.startsWith("http://") && !url.startsWith("https://")) return false;

    return true;
  }

  setWindowTitle(title = "") {
    let titleTag = document.getElementsByTagName("title")[0];
    if (titleTag) {
      if (title !== titleTag.innerHTML) titleTag.innerHTML = title;
    }
  }

  getPrice(price = 0, forYuan = false) {
    if (forYuan) {
      return price;
    }
    return price / 100;
  }
  getFormattedPriceForYuan(number = 0,
    symbol = "",
    places = 2,
    thousand = ",",
    decimal = ".",) {
    return this.getFormattedPrice(number, symbol, places, thousand, decimal, true);
  }

  getFormattedPrice(
    number = 0,
    symbol = "",
    places = 2,
    thousand = ",",
    decimal = ".",
    forYuan = false
  ) {
    number = this.getPrice(number, forYuan);
    let negative = number < 0 ? "-" : "";
    let i =
      parseInt((number = Math.abs(+number || 0).toFixed(places)), 10) + "";
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      symbol +
      negative +
      (j ? i.substr(0, j) + thousand : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) +
      (places
        ? decimal +
        Math.abs(number - i)
          .toFixed(places)
          .slice(2)
        : "")
    );
  }

  getWeekDayText(day) {
    const days = [
      "common.day.1",
      "common.day.2",
      "common.day.3",
      "common.day.4",
      "common.day.5",
      "common.day.6",
      "common.day.7",
    ];
    return days[day];
  }

  mathMobileNumber(str = "") {
    return str.match(/^\d{0,11}$/g) ? true : false;
  }

  normFile4Img(e) {
    let arr = null;
    if (Array.isArray(e)) {
      arr = e;
    }
    arr = e && e.fileList;
    if (
      Array.isArray(arr) &&
      arr.length > 0 &&
      arr.every((file) => file.size / 1024 / 1024 < 1)
    ) {
      return arr;
    }
    return [];
  }

  normFile(e) {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  }

  getDefaultImages(imgUrl = "") {
    if (imgUrl) {
      imgUrl = {
        uid: -1,
        name: `image-${new Date().getTime()}`,
        status: "done",
        url: imgUrl,
      };

      return [imgUrl];
    }
    return [];
  }

  getDefaultFile (fileUrl = ""){
    if (fileUrl) {
      let file = {
        uid: -1,
        name: `file-${new Date().getTime()}`,
        status: "done",
        url: typeof fileUrl === "boolean" ? "" : fileUrl,
      };

      return [file];
    }
    return [];
  };
  uuid(len, radix) {
    var chars =
      "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split(
        ""
      );
    var uuid = [],
      i;
    radix = radix || chars.length;
    if (len) {
      for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
    } else {
      var r;
      uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-";
      uuid[14] = "4";
      for (i = 0; i < 36; i++) {
        if (!uuid[i]) {
          r = 0 | (Math.random() * 16);
          uuid[i] = chars[i === 19 ? (r & 0x3) | 0x8 : r];
        }
      }
    }
    return uuid.join("");
  }

  getDefaultImagesForCopy(imgUrl = "") {
    let path = "";
    //"https://bulutbazar.oss-cn-qingdao.aliyuncs.com/upload/merchant/263/2205/29/629362275930e.png"

    const cdnUrl = "https://bulutbazar.oss-cn-qingdao.aliyuncs.com";
    const themeUrl = "https://bulutbazar-dev.oss-cn-qingdao.aliyuncs.com";
    if (imgUrl) {
      if (imgUrl.indexOf(cdnUrl) !== -1 || imgUrl.indexOf(themeUrl) !== -1) {
        path = imgUrl.replace(cdnUrl, "");
        path = path.replace(themeUrl, "");
      }
      imgUrl = {
        uid: Util.getInstance().uuid(8, 2),
        name: `image-${new Date().getTime()}`,
        status: "done",
        url: imgUrl,
        response: {
          path,
        },
      };
      return [imgUrl];
    }
    return [];
  }

  omit(obj, arr) {
    return Object.keys(obj)
      .filter((k) => !arr.includes(k))
      .reduce((acc, key) => ((acc[key] = obj[key]), acc), {}); //eslint-disable-line
  }

  getColor4Net(net) {
    if (net <= 0) return "#cf1322";
    if (net <= 6) return "#ff6600";
    if (net >= 30) return "#357a38";
    if (net >= 10) return "#71a173";
    return "#333";
  }
  getFreeShppingPurchase(price = 0, jd = false) {
    if (jd) {
      if (price <= 4900) {
        return price + 800;
      } else if (price <= 9900) {
        return price + 600;
      } else {
        return price;
      }
    }
    return price;
  }
  getFormattedDateString(date) {
    if (moment.isDayjs(date)) {
      return date.format("YYYY-MM-DD");
    }
    if (typeof date === "string") {
      return moment(date).format("YYYY-MM-DD");
    }
    return null;
  }
}
