import { Form, Spin, DatePicker, Radio } from "antd";
import React, { useState } from "react";
import { useForm } from "antd/lib/form/Form";
import { Locale } from "../../../utils";

import "./AddStudent.scss";
import UserSelector from "../../../components/main/UserSelector";

const i18n = Locale.getInstance();

const AddStudent = React.forwardRef(({ loading }, ref) => {
  const [studentForm] = useForm();
  const [showDate, setShowDate] = useState(false);

  return (
    <>
      <Spin spinning={loading}>
        <Form
          ref={ref}
          form={studentForm}
          className="student-form"
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          autoComplete="off"
        >
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, setFieldsValue }) => (
              <Form.Item
                shouldUpdate
                name="user_id"
                label={i18n.t("member.selectUser")}
                rules={[
                  {
                    required: true,
                    message: i18n.t("productEdit.standardRequired"),
                  },
                ]}
              >
                <UserSelector setFieldsValue={setFieldsValue} />
              </Form.Item>
            )}
          </Form.Item>

          <Form.Item
            name="valid_time"
            label={i18n.t("course.student.validTime")}
          >
            <Radio.Group
              onChange={(v) => {
                if (v.target.value === 0) {
                  setShowDate(false);
                } else {
                  setShowDate(true);
                }
              }}
            >
              <Radio defaultChecked={true} value={0}>
                {i18n.t("course.student.validForever")}
              </Radio>
              <Radio value={1}>{i18n.t("course.student.customDate")}</Radio>
            </Radio.Group>
          </Form.Item>
          {showDate ? (
            <Form.Item
              name="expired_at"
              label={i18n.t("course.student.customDateLabel")}
              shouldUpdate
            >
              <DatePicker />
            </Form.Item>
          ) : null}
        </Form>
      </Spin>
    </>
  );
});

export default AddStudent;
