import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Button, Card, Col, Form, Image, Input, Row, Spin, Upload } from "antd";
import { useCallback, useEffect, useRef } from "react";
import AMapLoader from "@amap/amap-jsapi-loader";
import { LeftOutlined, UploadOutlined } from "@ant-design/icons";
import { getOne, update } from "../../../utils/http/dataProvider";
import { getUserInfo } from "../../../utils/redux/reducers/User";
import { ApiManager, Locale, Util } from "../../../utils";
import TranslateBtn from "../../../components/product/TranslateBtn";
import { BreadCrumb } from "../../../components/main/LayoutComponents";
import { AMAP_KEY, AMAP_VERSION, AMAP_ZOOM } from "../../../constants";
import { showNotificationWithIcon } from "../../../utils/helpers/helpers";

const i18n = Locale.getInstance();
const api = ApiManager.getInstance();
const util = Util.getInstance();
const { Meta } = Card;

function StoreEdit() {
  const { storeId } = useParams();
  const user = useSelector((state) => getUserInfo(state));
  const [storeForm] = Form.useForm();
  let map = useRef(null);
  const queryClient = useQueryClient();
  const history = useHistory();

  const { data, isLoading } = useQuery(
    ["lottery-store", user.token, storeId],
    () =>
      getOne("lottery-store", {
        id: storeId,
        token: user.token,
      })
  );
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  useEffect(() => {
    if (data) {
      console.log("🚀 ~ useEffect ~ data:", data);

      storeForm.setFieldsValue({
        ug_name: data.ug_name,
        zh_name: data.zh_name,
        business_licence: util.getDefaultImages(data.business_licence),
        signboard: util.getDefaultImages(data.signboard),
        mobile: data.mobile,
        address: data.address,
      });

      AMapLoader.load({
        key: AMAP_KEY,
        version: AMAP_VERSION,
        plugins: ["AMap.Marker"],
      })
        .then((AMap) => {
          const { longitude, latitude } = data;
          map.current = new AMap.Map("map-container", {
            center: [longitude, latitude],
            zoom: AMAP_ZOOM,
          });
          const shopMarker = new AMap.Marker({
            position: new AMap.LngLat(longitude, latitude),
            label: { content: "您的店铺的位置", direction: "top" },
          });
          map.current.add(shopMarker);
        })
        .catch((e) => {
          console.log("ServiceAreaSetting -> e", e);
        });
    }
  }, [data]);

  const updateStoreMutation = useMutation(update, {
    onSuccess: () => {
      queryClient.invalidateQueries("lottery-store");
      showNotificationWithIcon(i18n.t("media.saveFinished"), "success");
      history.goBack();
    },
    onError: (error) => {
      handleMutationError(error);
    },
  });
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };
  const onBeforeUpload = (file) => {
    const isLt2000KB = file.size / 1024 < 2000;
    if (!isLt2000KB) {
      message.warning(i18n.t("productEdit.uploadNavImageSizeWarning"));
    }
    return isLt2000KB;
  };

  const uploadImages = useCallback(
    async ({ file, onError, onSuccess, uploadType }) => {
      if (!file) onError("invalid images");

      const { token } = user;
      if (!token) onError("no user token");

      const uploadFiles = [{ file }];
      const apiUrl = api.common.uploadImages;
      const apiConfig = {
        token,
        uploadFiles,
        uploadType: "merchant-info",
      };

      try {
        const res = await apiUrl.run(apiConfig);
        if (res.result) {
          onSuccess(res.data[0], file);
        } else {
          onError(res.message);
        }
      } catch (error) {
        onError(error);
      }
    },
    [user]
  );
  const handleSubmit = async (values) => {
    try {
      const { ug_name, zh_name, mobile, address, signboard, business_licence } =
        values;
      let data = {
        ug_name,
        zh_name,
        mobile,
        address,
      };
      if (
        signboard &&
        signboard[0] &&
        signboard[0].uid !== -1 &&
        signboard[0].response
      ) {
        data["signboard"] = signboard[0].response.path;
      }
      if (
        business_licence &&
        business_licence[0] &&
        business_licence[0].uid !== -1 &&
        business_licence[0].response
      ) {
        data["business_licence"] = business_licence[0].response.path;
      }
      updateStoreMutation.mutate({
        resource: "lottery-store",
        params: { data, token: user.token, id: storeId },
      });
    } catch (error) {
      console.log("🚀 ~ file: StoreEdit.jsx:84 ~ handleSubmit ~ error:", error);
    }
  };

  const cardStyle = {
    display: "flex",
    flexDirection: "column",
  };

  return (
    <>
      <BreadCrumb
        data={[
          {
            href: "/main/lottery-qrcode/store",
            title: i18n.t("qrcode.store"),
          },
        ]}
      />
      <div className="qrcode">
        <Spin spinning={isLoading}>
          <Form
            form={storeForm}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 10 }}
            onFinish={handleSubmit}
          >
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldsValue }) => (
                <Form.Item
                  shouldUpdate
                  name="ug_name"
                  label={i18n.t("qrcode.ugName")}
                  rules={[
                    {
                      required: true,
                      message: i18n.t("productEdit.standardRequired"),
                    },
                  ]}
                >
                  <Input
                    addonAfter={
                      <TranslateBtn
                        context={getFieldValue("ug_name")}
                        onFinish={(value) =>
                          setFieldsValue({
                            zh_name: value,
                          })
                        }
                        from="ug"
                        to="zh"
                      />
                    }
                  />
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldsValue }) => (
                <Form.Item
                  shouldUpdate
                  name="zh_name"
                  label={i18n.t("qrcode.zhName")}
                  rules={[
                    {
                      required: true,
                      message: i18n.t("productEdit.standardRequired"),
                    },
                  ]}
                >
                  <Input
                    addonAfter={
                      <TranslateBtn
                        context={getFieldValue("zh_name")}
                        onFinish={(value) =>
                          setFieldsValue({
                            ug_name: value,
                          })
                        }
                        from="zh"
                        to="ug"
                      />
                    }
                  />
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item
              name="mobile"
              label={i18n.t("common.mobile")}
              rules={[
                {
                  required: true,
                  message: i18n.t("productEdit.standardRequired"),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="address"
              label={i18n.t("commissionList.address")}
              rules={[
                {
                  required: true,
                  message: i18n.t("productEdit.standardRequired"),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {i18n.t("productEdit.saveAndBack")}
              </Button>
            </Form.Item>

            <Row justify="space-around" gutter={8}>
              <Col>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    return (
                      <Form.Item
                        name="business_licence"
                        valuePropName="fileList"
                        getValueFromEvent={(e) => util.normFile4Img(e)}
                      >
                        <Upload
                          accept="image/*"
                          action=""
                          listType="picture-card"
                          beforeUpload={onBeforeUpload}
                          onPreview={onPreview}
                          customRequest={uploadImages}
                          openFileDialogOnClick
                          maxCount={1}
                        >
                          {(!getFieldValue("business_licence") ||
                            !getFieldValue("business_licence").length) && (
                            <span>
                              <UploadOutlined /> {i18n.t("sales.upload")}
                            </span>
                          )}
                        </Upload>
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    return (
                      <Form.Item
                        name="signboard"
                        valuePropName="fileList"
                        getValueFromEvent={(e) => util.normFile4Img(e)}
                      >
                        <Upload
                          accept="image/*"
                          action=""
                          listType="picture-card"
                          beforeUpload={onBeforeUpload}
                          onPreview={onPreview}
                          customRequest={uploadImages}
                          openFileDialogOnClick
                          maxCount={1}
                        >
                          {(!getFieldValue("signboard") ||
                            !getFieldValue("signboard").length) && (
                            <span>
                              <UploadOutlined /> {i18n.t("sales.upload")}
                            </span>
                          )}
                        </Upload>
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
            <div
              id="map-container"
              style={{ height: 300, width: "100%", marginTop: "1rem" }}
            />
          </Form>
        </Spin>
      </div>
    </>
  );
}

export default StoreEdit;
