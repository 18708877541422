import { Locale } from "../../../utils";

const i18n = Locale.getInstance();
export default [
  {
    href: "/main/red-packet",
    title: i18n.t("redPacketActivity.title"),
  },
  {
    title: i18n.t("redPacketActivity.redPacket"),
  },
];
