import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from "antd";

import { Locale } from "../../../utils";
import { QRCODE_STATUS } from "./qrcodeApi";
const i18n = Locale.getInstance();

export const qrcodeListColumns = (
  deleteMutation,
  generateQrcodeMutation,
  generateExcelMutation,
  token,
  setLoading,
  history
) => {
  return [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: i18n.t("qrcode.name"),
      render: (item) => {
        return <span>{i18n.getPropNameByLocale(item, "name")}</span>;
      },
    },
    {
      title: i18n.t("qrcode.batch"),
      dataIndex: "batch_number",
    },
    {
      title: i18n.t("qrcode.quantity"),
      dataIndex: "quantity",
    },
    {
      title: i18n.t("qrcode.palletCapacity"),
      dataIndex: "pallet_capacity",
    },
    {
      title: i18n.t("qrcode.cartoonCapacity"),
      dataIndex: "cartoon_capacity",
    },
    {
      title: i18n.t("qrcode.boxCapacity"),
      dataIndex: "box_capacity",
    },
    {
      title: i18n.t("users.actions"),
      render: (item) => {
        return (
          <>
            {item.status === QRCODE_STATUS.STATUS_CREATED && (
              <>
                <Popconfirm
                  title={i18n.t("sales.deleteWarning")}
                  onConfirm={() => {
                    setLoading(true);
                    deleteMutation.mutate({
                      resource: "lottery-qrcode",
                      params: {
                        id: item.id,
                        token,
                      },
                    });
                  }}
                  okText={i18n.t("base.modal.okText")}
                  cancelText={i18n.t("base.modal.cancelText")}
                >
                  <Button size="small" icon={<DeleteOutlined />}>
                    {i18n.t("common.delete")}
                  </Button>
                </Popconfirm>
                <Button
                  onClick={() => {
                    history.push({
                      pathname: "/main/lottery-qrcode/edit",
                      state: {
                        id: item.id,
                      },
                    });
                  }}
                  size="small"
                  icon={<EditOutlined />}
                >
                  {i18n.t("product.productItem.actionEdit")}
                </Button>
              </>
            )}
            {item.status === QRCODE_STATUS.STATUS_CREATED ? (
              <Popconfirm
                title={i18n.t("qrcode.qrcodeGenerateWarning")}
                onConfirm={() => {
                  setLoading(true);
                  generateQrcodeMutation.mutate({

                    id: item.id,
                    token,

                  });
                }}
                okText={i18n.t("base.modal.okText")}
                cancelText={i18n.t("base.modal.cancelText")}
              >
                <Button size="small" icon={<DeleteOutlined />}>
                  {i18n.t("qrcode.generateQRcode")}
                </Button>
              </Popconfirm>
            ) : (
              <>
                {
                  item.status === QRCODE_STATUS.STATUS_GENERATING_QRCODE ? (
                    <Button
                      type="link"
                      size="small"
                    >
                      {i18n.t("qrcode.generatingQrcode")}
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        history.push({
                          pathname: `/main/lottery-qrcode/detail-list`,
                          query: {
                            id: item.id,
                          },
                        });
                      }}
                      type="link"
                      size="small"
                    >
                      {i18n.t("qrcode.qrcodeDetailList")}
                    </Button>
                  )
                }
              </>
            )}
            {item.status === QRCODE_STATUS.STATUS_GENERATED_QRCODE && (
              <Popconfirm
                title={i18n.t("qrcode.excelGenerateWarning")}
                onConfirm={() => {
                  setLoading(true);
                  generateExcelMutation.mutate({
                    id: item.id,
                    token,

                  });
                }}
                okText={i18n.t("base.modal.okText")}
                cancelText={i18n.t("base.modal.cancelText")}
              >
                <Button size="small" icon={<DeleteOutlined />}>
                  {i18n.t("qrcode.generateExcel")}
                </Button>
              </Popconfirm>
            )}
            {item.status === QRCODE_STATUS.STATUS_GENERATING_EXCEL && (

              <Button
                type="link"
                size="small"
              >
                {i18n.t("qrcode.generatingExcel")}
              </Button>
            )}
            {item.status === QRCODE_STATUS.STATUS_GENERATED_EXCEL && (

              <Button
                onClick={() => {
                  //Download the file
                }}
                type="link"
                size="small"
              >
                <a
                  key="action-download"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={item.excel_path}
                >
                  {i18n.t("qrcode.downloadExcel")}
                </a>
              </Button>
            )}


          </>
        );
      },
    },
  ];
};
