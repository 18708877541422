import { Button, Space, Tag } from "antd";
import { Locale } from "../../../utils";
import {
  CheckCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
const i18n = Locale.getInstance();

export const agreementListColumns = (history, handleApprove, handleReject) => {
  return [
    {
      title: i18n.t("financeBillDetail.billType"),
      render: (item) => {
        return (
          <Tag color="blue">
            {item.type_label}
          </Tag>
        )
      }
    },
    {
      title: i18n.t("common.statusLabel"),
      render: (item) => {
        let color;
        let icon;
        if (item.status === 1) {
          color = "gray";
          icon = <InfoCircleOutlined />;
        } else {
          color = "green";
          icon = <CheckCircleOutlined />;
        }
        return (
          <>
            <Tag icon={icon} color={color}>
              {item.status_label}
            </Tag>

          </>
        );
      },
    },
    {
      title: i18n.t("applyment.createdDate"),
      dataIndex: "created_at",
    },
    {
      title: i18n.t("applyment.updatedDate"),
      dataIndex: "updated_at",
    },



    {
      title: i18n.t("users.actions"),
      fixed: "right",
      width: 100,
      render: (item) => {
        return (
          <Space direction="vertical">
            <Button
              onClick={() => {
                history.push({
                  pathname: `/main/lottery-qrcode/agreement/${item.id}`,
                });
              }}
              type="link"
              size="small"
            >
              {i18n.t("product.productItem.actionEdit")}
            </Button>
            {item.status == 0 && (
              <Button
                onClick={() => handleApprove(item.id)}
                type="primary"
                size="small"
              >
                {i18n.t("course.statusActive")}
              </Button>
            )}
            {item.status == 1 && (
              <Button
                onClick={() => handleReject(item.id)}
                type="primary"
                size="small"
                danger
              >
                {i18n.t("course.statusInActive")}
              </Button>
            )}
          </Space>
        );
      },
    },
  ];
};
