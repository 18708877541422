import {  Table } from "antd";
import { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  handleMutationError,
  showNotificationWithIcon,
} from "../../../utils/helpers/helpers";
import { create, getList, remove } from "../../../utils/http/dataProvider";
import { getUserInfo } from "../../../utils/redux/reducers/User";
import { BreadCrumb } from "../../../components/main/LayoutComponents";

import { redPacketDetailListColumns } from "./redPacketDetailListColumns";
import RedPacketDetailListHeader from "./RedPacketDetailListHeader";
import breadCrumbDefault from "./breadCrumb";
import { Locale } from "../../../utils";
import Base from "../../Base";
import { useParams } from "react-router-dom";

const i18n = Locale.getInstance();
const base = Base.getInstance();

const RedPacketDetailList = () => {
  const user = useSelector((state) => getUserInfo(state));
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const {redPacketId} = useParams();
  const { data, error, isLoading } = useQuery(
    ["red-packet-detail", [user.token,redPacketId ]],
    () =>
      getList("red-packet-detail", {
        query: {
          red_packet_id: redPacketId
        },
        pagination: {
          perPage: 10,
          page,
        },
        sort: {
          field: "id",
          order: "desc",
        },
        
        token: user.token,
      })
  );
  const deleteMutation = useMutation(remove, {
    onSuccess: () => {
      queryClient.invalidateQueries("red-packet-detail");
      setLoading(false);
    },
    onError: (error) => {
      setLoading(false);
      handleMutationError(error);
    },
  });
  const updateDetailMutation = useMutation(create, {
    onSuccess: () => {
      queryClient.invalidateQueries("red-packet-detail");
      showNotificationWithIcon(i18n.t("media.saveFinished"), "success");
    },
    onError: (error) => {
      handleMutationError(error);
    },
    onSettled: () => {
      setLoading(false);
    },
  });

  const handleChangePage = (newPage) => {
    console.log(
      "🚀 ~ file: RedPacketDetailList.jsx:162 ~ handleChangePage ~ newPage:",
      newPage
    );
    setPage(newPage);
  };

  if (error) {
    showNotificationWithIcon(error.data.message);
  }

  const handleGenerate = (id) => {
    base.showModal("", Base.Modal.confirm, () => {
      try {
        setLoading(true);
        updateDetailMutation.mutate({
          resource: `red-packet-detail/${id}/generate`,
          params: { token: user.token },
        });
      } catch (error) {
        console.log(
          "🚀 ~ file: RedPacketDetailList.jsx:78 ~ handleGenerate ~ error:",
          error
        );
      }
    });
  };

  
  return (
    <>
      <BreadCrumb data={breadCrumbDefault} />
      <div className="qrcode">
        <RedPacketDetailListHeader
          redPacketId={redPacketId}
        />
        <Table
          className="list"
          loading={isLoading || loading}
          rowKey="id"
          dataSource={data?.data}
          columns={redPacketDetailListColumns(history, redPacketId, handleGenerate, deleteMutation, setLoading)}
          scroll={{
            x: 1200,
          }}
          pagination={{
            onChange: handleChangePage,
            current: data?.current_page,
            total: data?.total,
            showSizeChanger: false,
            showQuickJumper: false,
          }}
        />
      </div>
      
    </>
  );
};

export default RedPacketDetailList;
