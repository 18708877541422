import { Button, Image, Popconfirm, Tag } from "antd";
import { Locale } from "../../../utils";
import { CheckCircleOutlined } from "@ant-design/icons";
import { useQuery } from "react-query";
import { getOne } from "../../../utils/http/dataProvider";
import { useSelector } from "react-redux";
import { getUserInfo } from "../../../utils/redux/reducers/User";
import { useHistory } from "react-router-dom";
const i18n = Locale.getInstance();

const QrcodeItem = ({ id }) => {
  const user = useSelector((state) => getUserInfo(state));
  const history = useHistory();

  const { data } = useQuery(
    ["lottery-qrcode", user.token],
    () => getOne("lottery-qrcode", { id, token: user.token }),
    {
      enabled: !!id,
    }
  );

  return (
    <Button
      type="link"
      onClick={() => {
        history.push({
          pathname: `/main/lottery-qrcode/detail-list`,
          query: {
            id,
          },
        });
      }}
    >
      {i18n.getPropNameByLocale(data, "name")}
    </Button>
  );
};

export const qrcodeListColumns = (
  deleteMutation,
  token,
  setLoading,
  history
) => {
  return [
    {
      title: i18n.t("qrcode.name"),
      render: (item) => {
        return <span>{i18n.getPropNameByLocale(item, "name")}</span>;
      },
    },
    {
      title: i18n.t("live.ug_poster_image"),
      render: (item) => {
        return <Image minwidth={100} maxwidth={200} src={item.ug_image} />;
      },
    },
    {
      title: i18n.t("live.zh_poster_image"),
      render: (item) => {
        return <Image minwidth={100} maxwidth={200} src={item.zh_image} />;
      },
    },
    {
      title: i18n.t("sales.activity.start_date"),
      dataIndex: "start_time",
    },
    {
      title: i18n.t("sales.activity.end_date"),
      dataIndex: "end_time",
    },
    {
      title: i18n.t("common.statusLabel"),
      render: (item) => {
        return (
          <Tag
            icon={<CheckCircleOutlined />}
            color={item.status === 1 ? "success" : "default"}
          >
            {item.status === 1
              ? i18n.t("article.opened")
              : i18n.t("article.closed")}
          </Tag>
        );
      },
    },

    {
      title: i18n.t("member.points"),
      dataIndex: "points",
    },
    {
      title: i18n.t("qrcode.customer"),
      render: (item) => {
        let obj;
        if (item.customer_type === 0) {
          obj = {
            color: "purple",
            title: i18n.t("qrcode.client"),
          };
        } else {
          obj = {
            color: "blue",
            title: i18n.t("qrcode.shop"),
          };
        }
        return (
          <Tag icon={<CheckCircleOutlined />} color={obj.color}>
            {obj.title}
          </Tag>
        );
      },
    },

    {
      title: i18n.t("qrcode.selectQrcode"),
      render: (item) => {
        return <QrcodeItem id={item.qrcode_id} />;
      },
    },
    {
      title: i18n.t("users.actions"),
      fixed: "right",
      width: 100,
      render: (item) => {
        return (
          <>
            <Button
              onClick={() => {
                history.push({
                  pathname: `/main/lottery-qrcode/activity/${item.id}`,
                });
              }}
              type="link"
              size="small"
            >
              {i18n.t("product.productItem.actionEdit")}
            </Button>
            <Popconfirm
              title={i18n.t("sales.deleteWarning")}
              onConfirm={() => {
                setLoading(true);
                deleteMutation.mutate({
                  resource: "lottery-qrcode-activity",
                  params: {
                    id: item.id,
                    token,
                  },
                });
              }}
              okText={i18n.t("base.modal.okText")}
              cancelText={i18n.t("base.modal.cancelText")}
            >
              <Button danger type="link" size="small">
                {i18n.t("common.delete")}
              </Button>
            </Popconfirm>
          </>
        );
      },
    },
  ];
};
