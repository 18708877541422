import React from "react";
import {
  List,
  Card,
  Button,
  Spin,
  Switch,
  Avatar,
  Typography,
  Tag,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";

import Base from "../Base";
import { getUserInfo } from "../../utils/redux/reducers/User";
import EditDeliverymanForm from "./EditDeliverymanForm";
import Modal from "antd/lib/modal/Modal";
import CommunityManagement from "../delivery/CommunityManagement";

const { Meta } = Card;
const { Text } = Typography;

class DeliverymanManagement extends Base {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      data: { data: [], current_page: 1 },
      deliverymanData: null,
      loading: false,
      selectedDliman: null,
      selectedCommunities: [],
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.onGetDeliverymanList();
  }

  async onGetDeliverymanList(page = 1) {
    const token = this.getUserToken();
    if (!token) return;

    const api = this.api.deliveryman.list;
    const apiConfig = {
      token,
      query: {
        page,
      },
    };

    try {
      this.setState({ loading: true });
      const res = await api.run(apiConfig);
      if (!this.mounted) return;

      if (res.result) {
        this.setState({ data: res.data });
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;
      this.setState({ loading: false, deliverymanData: null });
    }
  }

  onHandleCancel = () => {
    this.setState({ deliverymanData: null });
  };

  onAddNewDeliveryman = () => {
    this.setState({ deliverymanData: {} });
  };

  onCloseBindModal = () => {
    this.setState({ selectedDliman: null, selectedCommunities: [] });
  };

  getDeliverymanStatus(status) {
    if (typeof status === "number") return status;
    return status ? 1 : 0;
  }

  onFinishForm = async (values) => {
    const token = this.getUserToken();
    if (!token) return;
    const { zh_name, ug_name, mobile, status, password } = values;

    let api = this.api.deliveryman.add;
    let apiConfig = {
      token,
      data: {
        zh_name,
        ug_name,
        mobile,
        status: this.getDeliverymanStatus(status),
        password,
      },
    };

    const { deliverymanData } = this.state;
    if (deliverymanData && deliverymanData.id) {
      api = this.api.deliveryman.update;
      apiConfig.path = { id: deliverymanData.id };
    }

    this.setState({ loading: true });
    try {
      const res = await api.run(apiConfig);
      if (!this.mounted) return;
      if (res.result) {
        this.onGetDeliverymanList();
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;
      this.setState({ loading: false });
    }
  };

  onDeleteDeliveryman(id) {
    this.showModal(
      this.i18n.t("deliveryman.deleteWarning"),
      Base.Modal.confirm,
      async () => {
        const token = this.getUserToken();
        if (!token) return;

        const api = this.api.deliveryman.delete;
        const apiConfig = {
          token,
          path: { id },
        };
        try {
          const res = await api.run(apiConfig);
          if (res.result) {
            this.onGetDeliverymanList();
          } else {
            this.showMessage(res.message);
          }
        } catch (error) {
          if (!this.mounted) return;
          this.handleApiError(api, error);
        }
      }
    );
  }

  onBindDeliveryman = async () => {
    const token = this.getUserToken();
    if (token) {
      const { selectedDliman, selectedCommunities } = this.state;
      if (selectedCommunities.length > 0) {
        const api = this.api.deliveryman.bind;
        const apiConfig = {
          token,
          path: { id: selectedDliman.id },
          data: {
            community_ids: selectedCommunities,
          },
        };
        try {
          this.setState({ loading: true });
          const res = await api.run(apiConfig);
          if (res.result) {
            this.showMessage(res.message);
            this.onCloseBindModal();
            this.onGetDeliverymanList();
          } else {
            this.showMessage(res.message);
          }
        } catch (error) {
          if (!this.mounted) return;
          this.handleApiError(api, error);
        } finally {
          if (this.mounted) {
            this.setState({ loading: false });
          }
        }
      } else {
        this.showModal(this.i18n.t("deliveryman.atleast"), Base.Modal.warning);
      }
    }
  };

  onEditDeliveryman(deliverymanData) {
    this.setState({ deliverymanData });
  }

  async onChangeDeliverymanStatus(item) {
    const token = this.getUserToken();
    if (!token) return;

    let api = this.api.deliveryman.update;
    let apiConfig = {
      token,
      path: { id: item.id },
      data: {
        ...item,
        status: item.status === 0 ? 1 : 0,
      },
    };

    this.setState({ loading: true });
    try {
      const res = await api.run(apiConfig);
      if (!this.mounted) return;
      if (res.result) {
        this.onGetDeliverymanList();
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;
      this.setState({ loading: false });
    }
  }

  render() {
    const {
      data,
      deliverymanData,
      loading,
      selectedDliman,
      selectedCommunities,
    } = this.state;

    return (
      <Spin spinning={loading}>
        <List
          pagination={{
            current: data.current_page,
            total: data.total,
            showQuickJumper: true,
            onChange: (value) => this.onGetDeliverymanList(value),
            style: {
              paddingBottom: 20,
            },
          }}
          header={
            <Button
              size="large"
              type="primary"
              onClick={this.onAddNewDeliveryman}
            >
              {this.i18n.t("deliveryman.add")}
            </Button>
          }
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 3,
            lg: 3,
            xl: 3,
            xxl: 6,
          }}
          dataSource={data ? data.data : []}
          renderItem={(item) => (
            <List.Item>
              <Card
                hoverable
                style={{ flexDirection: "column" }}
                actions={[
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={item.status !== 0}
                    onClick={() => this.onChangeDeliverymanStatus(item)}
                  />,
                  <EditOutlined
                    key="edit"
                    onClick={() => this.onEditDeliveryman(item)}
                  />,
                  <DeleteOutlined
                    key="delete"
                    onClick={() => this.onDeleteDeliveryman(item.id)}
                  />,
                  <SwapOutlined
                    key="bind"
                    onClick={() =>
                      this.setState({
                        selectedDliman: item,
                        selectedCommunities: item.communities
                          ? item.communities.map((i) => i.id)
                          : [],
                      })
                    }
                  />,
                ]}
              >
                <Meta
                  avatar={<Avatar src={item.image} size="large"></Avatar>}
                  title={`${item.zh_name}/${item.ug_name}`}
                  description={item.mobile}
                />
                {Array.isArray(item.communities) &&
                  item.communities.length > 0 &&
                  item.communities.map((i) => (
                    <Tag key={i.id} color="blue" style={{ marginTop: 10 }}>
                      {this.i18n.getPropNameByLocale(i, "name")}
                    </Tag>
                  ))}
              </Card>
            </List.Item>
          )}
        />
        <EditDeliverymanForm
          deliverymanData={deliverymanData}
          onCancel={this.onHandleCancel}
          onFinish={this.onFinishForm}
          loading={loading}
        />
        <Modal
          open={selectedDliman !== null}
          width={1200}
          style={{ maxHeight: 900 }}
          onCancel={this.onCloseBindModal}
          title={
            <Text mark>
              {this.i18n.getPropNameByLocale(selectedDliman, "name")}
            </Text>
          }
          okText={this.i18n.t("base.modal.okText")}
          cancelText={this.i18n.t("base.modal.cancelText")}
          onOk={this.onBindDeliveryman}
          okButtonProps={{ loading }}
          forceRender
        >
          <CommunityManagement
            selectedCommunities={selectedCommunities}
            onSelectCommunity={(list) =>
              this.setState({ selectedCommunities: list })
            }
          />
        </Modal>
      </Spin>
    );
  }
}

export default connect((state) => ({
  user: getUserInfo(state),
}))(DeliverymanManagement);
