import { Button } from "antd";
import Input from "antd/lib/input/Input";
import { useHistory } from "react-router-dom";
import { Locale } from "../../../utils";
import {
  typeLabels,
  TYPE_ARTICLE,
  TYPE_AUDIO,
  TYPE_VIDEO,
} from "./courseTypes";

const i18n = Locale.getInstance();
const CourseListHeader = ({ keyword, setKeyword, type }) => {
  const router = useHistory();
  let addLabel = i18n.t("course.add");
  if (type === TYPE_ARTICLE) {
    addLabel = i18n.t("course.addArticle");
  }
  if (type === TYPE_AUDIO) {
    addLabel = i18n.t("course.addAudio");
  }
  if (type === TYPE_VIDEO) {
    addLabel = i18n.t("course.addVideo");
  }

  return (
    <div className="header">
      <div className="actions">
        <Button
          onClick={() => {
            router.push({
              pathname: `/main/courses/${typeLabels[type]}-courses/edit`,
              state: {
                type,
              },
            });
          }}
          type="primary"
        >
          {addLabel}
        </Button>
      </div>
      <div className="search">
        <Input
          defaultValue={keyword}
          onChange={(e) => {
            setKeyword(e.target.value);
          }}
          placeholder={i18n.t("course.name")}
        />
        <Button type="primary">{i18n.t("common.search")}</Button>
      </div>
    </div>
  );
};
export default CourseListHeader;
