import { Request, Locale } from "../";

import commonApi from "./CommonApi";
import userApi from "./UserApi";
import orderApi from "./OrderApi";
import orderApiV6 from "./OrderApiV6";
import refundApiV6 from "./refundApiV6";
import reviewApi from "./ReviewApi";
import productApi from "./ProductApi";
import checkoutApi from "./CheckoutApi";
import proxyApi from "./ProxyApi";
import showsApi from "./ShowsApi";
import adsApi from "./AdsApi";
import navsApi from "./NavigatorApi";
import weappApi from "./MiniApi";
import flashApi from "./FlashApi";
import couponsApi from "./CouponsApi";
import propertyApi from "./PropertyApi";
import agentApi from "./AgentApi";
import balanceApi from "./BalanceApi";
import settingApi from "./SettingApi";
import liveApi from "./LiveApi";
import giftApi from "./GiftApi";
import commApi from "./CommissionApi";
import deliverymanApi from "./DliverymanApi";
import communityApi from "./CommunityApi";
import lotteryApi from "./LotteryApi";
import articleApi from "./ArticleApi";
import adminApi from "./AdminApi";
import grouponApi from "./GrouponApi";
import managerApi from "./ManagerApi";
import applymentApi from "./ApplymentApi";
import merchantApi from "./MerchantApi";
import douyinApi from "./DouyinApi";
import memberApi from "./MemberApi";

class ApiManager {
  static api = null;
  static getInstance() {
    if (ApiManager.api == null) ApiManager.api = new ApiManager();
    return ApiManager.api;
  }

  constructor() {
    this.common = this._reformatApi(commonApi);
    this.user = this._reformatApi(userApi);
    this.order = this._reformatApi(orderApi);
    this.orderv6 = this._reformatApi(orderApiV6);
    this.refundv6 = this._reformatApi(refundApiV6);
    this.review = this._reformatApi(reviewApi);
    this.product = this._reformatApi(productApi);
    this.checkout = this._reformatApi(checkoutApi);
    this.proxy = this._reformatApi(proxyApi);
    this.shows = this._reformatApi(showsApi);
    this.ads = this._reformatApi(adsApi);
    this.navs = this._reformatApi(navsApi);
    this.weapp = this._reformatApi(weappApi);
    this.flash = this._reformatApi(flashApi);
    this.coupons = this._reformatApi(couponsApi);
    this.productProperty = this._reformatApi(propertyApi);
    this.agent = this._reformatApi(agentApi);
    this.balance = this._reformatApi(balanceApi);
    this.setting = this._reformatApi(settingApi);
    this.live = this._reformatApi(liveApi);
    this.commission = this._reformatApi(commApi);
    this.gift = this._reformatApi(giftApi);
    this.deliveryman = this._reformatApi(deliverymanApi);
    this.community = this._reformatApi(communityApi);
    this.lottery = this._reformatApi(lotteryApi);
    this.article = this._reformatApi(articleApi);
    this.admin = this._reformatApi(adminApi);
    this.groupon = this._reformatApi(grouponApi);
    this.managers = this._reformatApi(managerApi);
    this.applyment = this._reformatApi(applymentApi);
    this.merchant = this._reformatApi(merchantApi);
    this.douyin = this._reformatApi(douyinApi);
    this.member = this._reformatApi(memberApi);
  }

  _reformatApi(apiConfig) {
    for (let key in apiConfig) {
      let api = apiConfig[key];
      this._formatApiEqual(api);
      this._formatApiRun(api);
    }
    return apiConfig;
  }

  _formatApiEqual(api) {
    api.equal = (anApi) => {
      if (!anApi) return false;
      return (
        api.name === anApi.name &&
        api.url === anApi.url &&
        api.method === anApi.method
      );
    };
  }

  _formatApiRun(api) {
    switch (api.method.toUpperCase()) {
      case "GET": {
        api.run = (config = {}) => {
          let url = this._getApiUrl(api, config);
          let token = config ? config.token : "";

          return Request.get(url, token);
        };
        break;
      }
      case "POST": {
        api.run = (config = {}) => {
          let url = this._getApiUrl(api, config);
          let data = config ? config.data : "";
          let token = config ? config.token : "";

          return Request.post(url, data, token);
        };
        break;
      }
      case "PUT": {
        api.run = (config = {}) => {
          let url = this._getApiUrl(api, config);
          let data = config ? config.data : "";
          let token = config ? config.token : "";

          return Request.put(url, data, token);
        };
        break;
      }
      case "DELETE": {
        api.run = (config = {}) => {
          let url = this._getApiUrl(api, config);
          let token = config ? config.token : "";

          return Request.delete(url, token);
        };
        break;
      }
      case "UPLOAD": {
        api.run = (config = {}) => {
          let url = this._getApiUrl(api, config);
          let uploadFiles = config ? config.uploadFiles : "";
          let token = config ? config.token : "";
          let uploadType = config ? config.uploadType : "";

          return Request.upload(url, uploadFiles, token, uploadType);
        };
        break;
      }
      default: {
        api.run = (config = {}) => {
          let message =
            api.method +
            " is an unsupported api method, please check for api decleration and try again !";
          throw new Error(message);
        };
        break;
      }
    }
  }

  _getApiUrl(api, config) {
    let url = api.url;

    let path = config ? config.path : null;
    if (path) {
      for (let key in path) {
        url = url.replace("%{" + key + "}", path[key]);
      }
    }

    let query = config ? config.query : null;
    if (query) {
      for (let key in query) {
        if (url.indexOf("?") === -1) {
          url += "?";
        } else {
          url += "&";
        }
        url += key + "=" + query[key];
      }
    }

    let locale = Locale.getLocale();
    if (locale === Locale.Locales.ug.locale) {
      locale = ``;
    } else {
      locale = `/${locale}`;
    }


    if (url.indexOf("oauth") === -1) {
      if (url.indexOf("bbshop") !== -1) {
        url = `${locale}/api/v${api.version}/${url}`;
      } else if (url.indexOf("jd") !== -1 || url.indexOf("admin") !== -1) {
        url = `${locale}/api/v${api.version}/${url}`;
      } else if (url.indexOf("translate") !== -1) {
        url = `${locale}/${url}`;
      } else if (url.indexOf("theme") !== -1) {
        url = `${locale}/lottery/api/v${api.version}/merchant/${url}`;
      } else if (url.indexOf("llottery") !== -1) {
        const newurl = url.slice(1);
        url = `${locale}/lottery/api/v${api.version}/merchant/${newurl}`;
      } else if (url.indexOf("takeout@") !== -1) {
        const newurl = url.split('@');
        url = `${locale}/takeout/api/v${api.version}/merchant/${newurl[1]}`;
      }else if (url.indexOf("member@") !== -1) {
        const newurl = url.split('@');
        url = `${locale}/member/api/v${api.version}/merchant/${newurl[1]}`;
      } else {
        url = `${locale}/api/v${api.version}/merchant/${url}`;
      }
    } else {
      url = `${locale}/${url}`;
    }

    return url;
  }
}

export default ApiManager;
