import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from "antd";

import { Locale } from "../../../utils";
const i18n = Locale.getInstance();

export const teacherListColumns = (
  deleteMutation,
  token,
  setLoading,
  history
) => {
  return [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: i18n.t("users.avatar"),
      render: (item) => {
        return (
          <>
            <img
              src={`${item.avatar}`}
              alt={i18n.getPropNameByLocale(item, "name")}
              width={60}
              height={60}
            />
          </>
        );
      },
    },
    {
      title: i18n.t("course.teacher.name"),
      render: (item) => {
        return <span>{i18n.getPropNameByLocale(item, "name")}</span>;
      },
    },
    {
      title: i18n.t("users.date"),
      dataIndex: "created_at",
    },
    {
      title: i18n.t("users.actions"),
      render: (item) => {
        return (
          <>
            <Popconfirm
              title={i18n.t("sales.deleteWarning")}
              onConfirm={() => {
                setLoading(true);
                deleteMutation.mutate({
                  resource: "course/teachers",
                  params: {
                    id: item.id,
                    token,
                  },
                });
              }}
              okText={i18n.t("base.modal.okText")}
              cancelText={i18n.t("base.modal.cancelText")}
            >
              <Button size="small" icon={<DeleteOutlined />}>
                {i18n.t("common.delete")}
              </Button>
            </Popconfirm>
            <Button
              onClick={() => {
                history.push({
                  pathname: "/main/course/teacher/edit",
                  state: {
                    id: item.id,
                  },
                });
              }}
              size="small"
              icon={<EditOutlined />}
            >
              {i18n.t("product.productItem.actionEdit")}
            </Button>
          </>
        );
      },
    },
  ];
};
