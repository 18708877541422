import { useHistory } from "react-router-dom";
import { Button } from "antd";
import { Locale } from "../../../utils";

const i18n = Locale.getInstance();
const AgreementListHeader = ({ keyword, setKeyword }) => {
  const history = useHistory();
  return (
    <div className="header">
      <div className="actions">
        <Button
          onClick={() => {
            history.push({
              pathname: "/main/lottery-qrcode/agreement/add",
            });
          }}
          type="primary"
        >
          {i18n.t("qrcode.addAgreement")}
        </Button>
      </div>
      <div className="search"></div>
    </div>
  );
};
export default AgreementListHeader;
