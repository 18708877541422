import newRequest, { buildBaseURL } from "../../../utils/http/newRuequest";

export const QRCODE_STATUS = {
  STATUS_CREATED: 0,
  STATUS_GENERATING_QRCODE: 1,
  STATUS_GENERATED_QRCODE: 2,
  STATUS_GENERATING_EXCEL: 3,
  STATUS_GENERATED_EXCEL: 4,
};

export const generateQrcode = async ({ id, token }) => {
  const url =
    buildBaseURL("v1", "lottery") +
    `merchant/lottery-qrcode/${id}/generate-qrcode`;
  const result = await newRequest.post(url, [], token);

  return {
    data: result.data ?? [],
    total: result.total ? result.total : 10,
  };
};
export const generateExcel = async ({ id, token }) => {
  const url =
    buildBaseURL("v1", "lottery") +
    `merchant/lottery-qrcode/${id}/generate-excel`;
  const result = await newRequest.post(url, [], token);

  return {
    data: result.data ?? [],
    total: result.total ? result.total : 10,
  };
};
