import { Form, Input } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Locale } from "../../utils";
import { getUserInfo } from "../../utils/redux/reducers/User";

import "./weappInput.scss";

const i18n = Locale.getInstance();

const WeappInput = ({ setSelectedLink, selectedLink }) => {
  const user = useSelector((state) => getUserInfo(state));
  const [weappInputForm] = useForm();
  const [wechatAppId, setWechatAppId] = useState();
  const [wechatAppPath, setWechatAppPath] = useState();

  useEffect(() => {
    if (selectedLink) {
      const linkArray = selectedLink.url.split("@");
      if (linkArray[0]) {
        weappInputForm.setFieldsValue({ wechat_path: linkArray[0] });
        setWechatAppPath(linkArray[0]);
      }
      if (linkArray[1]) {
        weappInputForm.setFieldsValue({ wechat_app_id: linkArray[1] });
        setWechatAppId(linkArray[1]);
      }
    }
  }, []);

  useEffect(() => {
    const url = wechatAppPath + "@" + wechatAppId;
    setSelectedLink({
      key: 0,
      url,
      zh_name: "小程序跳转",
      ug_name: "ئەپچاق ئېلان",
    });
  }, [wechatAppId, wechatAppPath]);

  return (
    <>
      <Form
        name="basic"
        form={weappInputForm}
        className="weapp-input"
        autoComplete="off"
        onValuesChange={(v) => {
          if (v.wechat_path) {
            setWechatAppPath(v.wechat_path);
          }
          if (v.wechat_app_id) {
            setWechatAppId(v.wechat_app_id);
          }
        }}
      >
        <Form.Item
          name="wechat_app_id"
          label={i18n.t("link.wechatAppId")}
          shouldUpdate
        >
          <Input style={{ width: 180 }} />
        </Form.Item>
        <Form.Item
          name="wechat_path"
          label={i18n.t("link.wechatPath")}
          shouldUpdate
        >
          <Input style={{ width: 180 }} />
        </Form.Item>
      </Form>
    </>
  );
};

export default WeappInput;
