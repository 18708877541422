import { useState } from "react";
import { Button, Radio } from "antd";
import Input from "antd/lib/input/Input";
import { Locale } from "../../../utils";
import { FILTER_STATUS } from "../../../constants";

const i18n = Locale.getInstance();

const RecommenderListHeader = ({ keyword, setKeyword, status, setStatus }) => {
  const [q, setQ] = useState();
  return (
    <div className="header">
      <div className="search">
        <Input
          defaultValue={keyword}
          onChange={(e) => {
            setQ(e.target.value);
          }}
          placeholder={i18n.t("qrcode.name")}
        />
        <Button
          onClick={() => {
            setKeyword(q);
          }}
          type="primary"
        >
          {i18n.t("common.search")}
        </Button>
      </div>
      <div className="filter">
        <Radio.Group
          style={{ marginRight: 20, marginLeft: 20 }}
          onChange={(e) => {
            setStatus(e.target.value);
          }}
          value={status}
        >
          <Radio value={FILTER_STATUS.ALL.value}>
            {FILTER_STATUS.ALL.name}
          </Radio>
          <Radio value={FILTER_STATUS.OPENED.value}>
            {FILTER_STATUS.OPENED.name}
          </Radio>
          <Radio value={FILTER_STATUS.CLOSED.value}>
            {FILTER_STATUS.CLOSED.name}
          </Radio>
          <Radio value={FILTER_STATUS.OPENED.value + 1}>
            {i18n.t("shows.status.rejected")}
          </Radio>
        </Radio.Group>
      </div>
    </div>
  );
};
export default RecommenderListHeader;
