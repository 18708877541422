import { Button, Drawer, Form, Input, Space, Table } from "antd";
import { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  handleMutationError,
  showNotificationWithIcon,
} from "../../../utils/helpers/helpers";
import { create, getList } from "../../../utils/http/dataProvider";
import { getUserInfo } from "../../../utils/redux/reducers/User";
import { BreadCrumb } from "../../../components/main/LayoutComponents";

import { recommenderListColumns } from "./recommenderListColumns";
import StoreListHeader from "./RecommenderListHeader";
import breadCrumbDefault from "./breadCrumb";
import { Locale } from "../../../utils";
import Base from "../../Base";
import TranslateBtn from "../../../components/product/TranslateBtn";

const i18n = Locale.getInstance();
const base = Base.getInstance();
const { TextArea } = Input;

function RefuseReason({ rejectId, onClose, onReject }) {
  const [form] = Form.useForm();

  const handleReject = () => {
    form.validateFields().then((values) => {
      onReject(values);
    });
  };

  return (
    <Drawer
      title={i18n.t("qrcode.rejectReason")}
      placement="bottom"
      open={rejectId !== undefined}
      onClose={onClose}
      extra={
        <Space>
          <Button onClick={onClose}>{i18n.t("base.modal.cancelText")}</Button>
          <Button type="primary" onClick={handleReject}>
            {i18n.t("base.modal.okText")}
          </Button>
        </Space>
      }
    >
      <Form form={form}>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldsValue }) => (
            <Form.Item
              shouldUpdate
              name="ug_refuse_reason"
              label={
                <Space>
                  <span>{i18n.t("sales.mini.ug")}</span>
                  <TranslateBtn
                    context={getFieldValue("ug_refuse_reason")}
                    onFinish={(value) =>
                      setFieldsValue({
                        zh_refuse_reason: value,
                      })
                    }
                    from="ug"
                    to="zh"
                    btnType="link"
                  />
                </Space>
              }
              rules={[
                {
                  required: true,
                  message: i18n.t("productEdit.standardRequired"),
                },
              ]}
            >
              <TextArea dir="rtl" rows={3} />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldsValue }) => (
            <Form.Item
              shouldUpdate
              name="zh_refuse_reason"
              label={
                <Space>
                  <span>{i18n.t("sales.mini.zh")}</span>
                  <TranslateBtn
                    context={getFieldValue("zh_refuse_reason")}
                    onFinish={(value) =>
                      setFieldsValue({
                        ug_refuse_reason: value,
                      })
                    }
                    from="zh"
                    to="ug"
                    btnType="link"
                  />
                </Space>
              }
              rules={[
                {
                  required: true,
                  message: i18n.t("productEdit.standardRequired"),
                },
              ]}
            >
              <TextArea rows={3} />
            </Form.Item>
          )}
        </Form.Item>
      </Form>
    </Drawer>
  );
}

const RecommenderList = () => {
  const user = useSelector((state) => getUserInfo(state));
  const queryClient = useQueryClient();
  const [keyword, setKeyword] = useState();
  const [status, setStatus] = useState();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [rejectId, setRejectId] = useState();

  const { data, error, isLoading } = useQuery(
    ["lottery-recommender", [user.token, keyword, status, page]],
    () =>
      getList("lottery-recommender", {
        pagination: {
          perPage: 10,
          page,
        },
        sort: {
          field: "id",
          order: "desc",
        },
        filter: {
          keyword,
          status,
        },
        token: user.token,
      })
  );

  const updateStoreMutation = useMutation(create, {
    onSuccess: () => {
      queryClient.invalidateQueries("lottery-recommender");
      showNotificationWithIcon(i18n.t("media.saveFinished"), "success");
    },
    onError: (error) => {
      handleMutationError(error);
    },
    onSettled: () => {
      setRejectId(undefined);
      setLoading(false);
    },
  });

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  if (error) {
    showNotificationWithIcon(error.data.message);
  }

  const handleApprove = (id) => {
    base.showModal("", Base.Modal.confirm, () => {
      try {
        setLoading(true);
        updateStoreMutation.mutate({
          resource: `lottery-recommender/${id}/approve`,
          params: { token: user.token },
        });
      } catch (error) {
        console.log(
          "🚀 ~ file: StoreList.jsx:78 ~ handleApprove ~ error:",
          error
        );
      }
    });
  };

  const handleReject = (id) => {
    setRejectId(id);
  };

  const onReject = (data) => {
    try {
      setLoading(true);
      updateStoreMutation.mutate({
        resource: `lottery-recommender/${rejectId}/reject`,
        params: { token: user.token, data },
      });
    } catch (error) {
      console.log(
        "🚀 ~ file: StoreList.jsx:78 ~ handleApprove ~ error:",
        error
      );
    }
  };

  return (
    <>
      <BreadCrumb data={breadCrumbDefault} />
      <div className="qrcode">
        <StoreListHeader
          keyword={keyword}
          setKeyword={setKeyword}
          status={status}
          setStatus={setStatus}
        />
        <Table
          className="list"
          loading={isLoading || loading}
          rowKey="id"
          dataSource={data?.data}
          columns={recommenderListColumns(history, handleApprove, handleReject)}
          scroll={{
            x: 1200,
          }}
          pagination={{
            onChange: handleChangePage,
            current: data?.current_page,
            total: data?.total,
            showSizeChanger: false,
            showQuickJumper: false,
          }}
        />
      </div>
      <RefuseReason
        rejectId={rejectId}
        onClose={() => setRejectId(undefined)}
        onReject={onReject}
      />
    </>
  );
};

export default RecommenderList;
