import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Badge, Button, Popconfirm, Tag } from "antd";
import { Locale } from "../../../utils";
const i18n = Locale.getInstance();
export const categoryListColumns = (
  deleteMutation,
  token,
  setLoading,
  selectCategory
) => {
  return [
    {
      title: i18n.t("course.category.name"),
      render: (item) => {
        return <span>{i18n.getPropNameByLocale(item, "name")}</span>;
      },
    },
    {
      title: i18n.t("common.statusLabel"),
      render: (item) => {
        return (
          <Badge
            status={item.status ? "success" : "default"}
            text={item.status_label}
          />
        );
      },
    },
    {
      title: i18n.t("live.rec"),
      render: (item) => {
        if (item?.recommend === 1) {
          return <Tag color="green">{i18n.t("productEdit.suggest.on")}</Tag>;
        }
        return null;
      },
    },
    {
      title: i18n.t("shows.sortTypesTitle"),
      dataIndex: "order",
    },
    {
      title: i18n.t("users.date"),
      dataIndex: "created_at",
    },
    {
      title: i18n.t("users.actions"),
      render: (item) => {
        return (
          <>
            <Popconfirm
              title={i18n.t("sales.deleteWarning")}
              onConfirm={() => {
                setLoading(true);
                deleteMutation.mutate({
                  resource: "course/categories",
                  params: {
                    id: item.id,
                    token,
                  },
                });
              }}
              okText={i18n.t("base.modal.okText")}
              cancelText={i18n.t("base.modal.cancelText")}
            >
              <Button size="small" icon={<DeleteOutlined />}>
                {i18n.t("common.delete")}
              </Button>
            </Popconfirm>
            <Button
              onClick={() => {
                selectCategory(item);
              }}
              size="small"
              icon={<EditOutlined />}
            >
              {i18n.t("product.productItem.actionEdit")}
            </Button>
          </>
        );
      },
    },
  ];
};
