import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Row,
  List,
  Divider,
  Input,
  message,
  Avatar,
  Button,
} from "antd";
import { useSelector } from "react-redux";

import { Locale, ApiManager } from "../../utils";
import { getUserInfo } from "../../utils/redux/reducers/User";
import Base from "../Base";

const i18n = Locale.getInstance();
const base = Base.getInstance();
const api = ApiManager.getInstance();

function omit(obj, arr) {
  return Object.keys(obj)
    .filter((k) => !arr.includes(k))
    .reduce((acc, key) => ((acc[key] = obj[key]), acc), {}); //eslint-disable-line
}

function SelectManagerList({ visible, onCancel, lotteryId, getListData }) {
  const user = useSelector((state) => getUserInfo(state));
  const [keyword, onChangeKeyword] = useState("");
  const [loading, setLoading] = useState(false);
  const [usersList, setUsersList] = useState({ data: [] });
  const [selectedUserList, setSelectedUserList] = useState({});
  const onSearchUsers = useRef(() => {});

  onSearchUsers.current = async (page = 1) => {
    const { token } = user;
    const apiUrl = api.coupons.users;
    if (token) {
      try {
        setLoading(true);
        const apiConfig = {
          token,
          query: {
            "filter[name]": keyword,
            page,
            count: 8,
          },
        };
        const res = await apiUrl.run(apiConfig);
        if (res.result) {
          setUsersList(res.data);
        }
      } catch (error) {
        message.error(error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  function onDeleteUser(userId) {
    const others = omit(selectedUserList, [`${userId}`]);
    setSelectedUserList(others);
  }

  function onSelectUser(item) {
    setSelectedUserList({
      ...selectedUserList,
      [item.id]: { ...item, count: 1 },
    });
  }

  function checkIfItemSelected(itemId) {
    const currentKeys = Object.keys(selectedUserList);
    return currentKeys.findIndex((key) => key === `${itemId}`) !== -1;
  }

  async function onFinish(data) {
    const apiUrl = api.managers.add;
    try {
      setLoading(true);
      const res = await Promise.all(
        data.map((d) => {
          const apiConfig = {
            data: d,
            token: user.token,
            query: {
              lottery_id: lotteryId,
            },
          };
          return apiUrl.run(apiConfig);
        })
      );
      if (Array.isArray(res) && res.every((i) => i.result)) {
        setSelectedUserList({});
        onCancel();
        getListData();
      } else {
        base.showMessage(res[0].message, Base.Message.error);
      }
    } catch (error) {
      base.handleApiError(apiUrl, error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    onSearchUsers.current();
  }, [onSearchUsers]);

  return (
    <Modal
      open={visible}
      onCancel={() => {
        setSelectedUserList({});
        onCancel();
      }}
      title={i18n.t("lottery.selectManager")}
      width={1020}
      okText={i18n.t("base.modal.okText")}
      cancelText={i18n.t("base.modal.cancelText")}
      onOk={() => {
        const data = [];
        Object.keys(selectedUserList).forEach((key) => {
          data.push({
            user_id: key,
            lottery_id: lotteryId,
            status: 1,
          });
        });
        onFinish(data);
      }}
    >
      <Row align="top">
        <List
          loading={loading}
          style={{ width: "49%", height: "100%" }}
          header={
            <Input.Search
              enterButton
              value={keyword}
              onChange={(e) => onChangeKeyword(e.target.value)}
              onSearch={() => onSearchUsers.current()}
            />
          }
          pagination={{
            current: usersList.current_page,
            onChange: (page) => onSearchUsers.current(page),
            total: usersList.total,
            pageSize: 8,
            showSizeChanger: false,
            showQuickJumper: true,
          }}
          dataSource={usersList.data}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button
                  disabled={checkIfItemSelected(item.id)}
                  onClick={() => onSelectUser(item)}
                >
                  {checkIfItemSelected(item.id)
                    ? i18n.t("sales.coupons.selected")
                    : i18n.t("sales.coupons.select")}
                </Button>,
              ]}
            >
              <List.Item.Meta
                avatar={
                  <Avatar shape="circle" size="large" src={item.avatar} />
                }
                title={item.nickname}
                description={item.mobile}
              />
            </List.Item>
          )}
        />
        <Divider type="vertical" style={{ height: 500 }} />
        <List
          style={{ width: "49%" }}
          dataSource={Object.keys(selectedUserList)}
          renderItem={(key) => {
            const item = selectedUserList[key];
            return (
              <List.Item
                actions={[
                  <Button
                    key="delete"
                    danger
                    onClick={() => onDeleteUser(item.id)}
                  >
                    {i18n.t("sales.activity.delete")}
                  </Button>,
                ]}
              >
                <List.Item.Meta
                  avatar={
                    <Avatar shape="circle" size="large" src={item.avatar} />
                  }
                  title={item.nickname}
                  description={item.mobile}
                />
              </List.Item>
            );
          }}
        />
      </Row>
    </Modal>
  );
}

export default SelectManagerList;
