export default {
  generate: {
    name: "generate weapp api",
    version: 6,
    url: "generate-weapp/%{id}",
    method: "POST",
  },
  add: {
    name: "add weapp api",
    version: 6,
    url: "weapp",
    method: "POST",
  },
  update: {
    name: "update weapp api",
    version: 6,
    url: "weapp/%{id}",
    method: "PUT",
  },
  delete: {
    name: "delete weapp api",
    version: 6,
    url: "weapp/%{id}",
    method: "DELETE",
  },
  show: {
    name: "show weapp api",
    version: 6,
    url: "weapp/show",
    method: "GET",
  },
  getPayment: {
    name: "get weapp payment config api",
    version: 6,
    url: "wechat/payment/config",
    method: "GET",
  },
  addPayment: {
    name: "add weapp payment config api",
    version: 6,
    url: "wechat/payment/config",
    method: "POST",
  },
  updatePayment: {
    name: "update weapp payment config api",
    version: 6,
    url: "wechat/payment/config/%{id}",
    method: "PUT",
  },
  getMerchantInfo: {
    name: "get merchant info api",
    version: 6,
    url: "merchant/info",
    method: "GET",
  },
  addMerchantInfo: {
    name: "add merchant info api",
    version: 6,
    url: "merchant/info",
    method: "POST",
  },
  updateMerchantInfo: {
    name: "update merchant info api",
    version: 6,
    url: "merchant/info/%{id}",
    method: "PUT",
  },
  getMerchantAccountInfo: {
    name: "get merchant account info api",
    version: 6,
    url: "merchant/account/info",
    method: "GET",
  },
  updateMerchantAccountInfo: {
    name: "update merchant account info api",
    version: 6,
    url: "merchant/account/info/%{id}",
    method: "PUT",
  },

  getMerchantBasicInfo: {
    name: "get merchant basic info api",
    version: 6,
    url: "merchant/basic",
    method: "GET",
  },
  updateMerchantBasicInfo: {
    name: "update merchant basic info api",
    version: 6,
    url: "merchant/basic/%{id}",
    method: "PUT",
  },
  upload: {
    name: "upload weapp payment cert api",
    version: 6,
    url: "weapp/upload-cert",
    method: "UPLOAD",
  },
};
