import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import Modal from "antd/lib/modal/Modal";
import { useEffect, useState } from "react";
import { Locale } from "../../utils";
import Media from "./Media";

import "./VideoUpload.scss";
const i18n = Locale.getInstance();
const VideoUpload = ({ video, setSelectedVideo, setDeleted }) => {
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [selectedFiles, selectFiles] = useState(false);
  const [selectedVideo, selectVideo] = useState(false);

  useEffect(() => {
    if (video && video.length > 0) {
      selectVideo(video);
    }
  }, [video]);

  const handleFinishSelection = () => {
    selectVideo(selectedFiles);
    setSelectedVideo(selectedFiles);
    setOpenUploadModal(false);
  };
  const hideModal = () => {
    setOpenUploadModal(false);
  };

  const showConfirm = () => {
    setOpenUploadModal(true);
  };

  if (selectedVideo && selectedVideo.length > 0) {
    video = selectedVideo[0];
    return (
      <>
        <div className="video-upload">
          <div className="preview">
            <video controls src={video.url} />
          </div>
          <div className="remove">
            <Button
              onClick={() => {
                selectVideo([]);
                setDeleted();
              }}
            >
              {i18n.t("common.delete")}
            </Button>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="video-upload">
        <div className="upload-button" onClick={showConfirm}>
          <PlusOutlined />
          <div className="ant-upload-text">
            {i18n.t("productEdit.imagesUpload")}
          </div>
        </div>
      </div>

      <Modal
        title={i18n.t("media.selectVideo")}
        open={openUploadModal}
        onOk={handleFinishSelection}
        onCancel={hideModal}
        okText={i18n.t("base.modal.okText")}
        cancelText={i18n.t("base.modal.cancelText")}
        width={880}
      >
        <Media selectFiles={selectFiles} collectionName="videos" />
      </Modal>
    </>
  );
};

export default VideoUpload;
