import React, { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import {
  Table,
  message,
  Button,
  Tag,
  Avatar,
  Modal,
  Space,
  Divider,
  Layout,
  Form,
  InputNumber,
} from "antd";
import Search from "antd/lib/input/Search";
import { getUserInfo } from "../../utils/redux/reducers/User";
import { ApiManager, Locale, Util } from "../../utils";
import TextArea from "antd/lib/input/TextArea";

const api = ApiManager.getInstance();
const i18n = Locale.getInstance();
const util = Util.getInstance();

function BalanceList({ user }) {
  const [list, setList] = useState({ data: [], current_page: 1 });
  const [balanceSum, setBalanceSum] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentClient, setCurrentClient] = useState(null);
  const [topupAmount, setTopupAmount] = useState(0);
  const [form] = Form.useForm();
  const amount = Form.useWatch("amount", form);
  const comment = Form.useWatch("comment", form);

  const fetchList = useCallback(
    async (page = 1, keyword = "", sorter = null) => {
      const { token } = user;
      if (token) {
        setLoading(true);
        try {
          const apiUrl = api.user.list;
          const apiConfig = {
            token,
            query: {
              keyword,
              page,
            },
          };
          if (sorter && sorter.field) {
            apiConfig.query[`filter[${sorter.field}]`] = sorter.order;
          }
          apiConfig.query[`filter[only-has-balance}]`] = true;
          const res = await apiUrl.run(apiConfig);
          if (res.result) {
            setList(res.data);
          } else {
            message.warning(res.message);
          }
        } catch (error) {
          message.error(error.message);
        } finally {
          setLoading(false);
        }
      }
    },
    [user]
  );

  const fetchBalanceSum = useCallback(async () => {
    const { token } = user;
    if (token) {
      setLoading(true);
      try {
        const apiUrl = api.user.balanceSum;
        const apiConfig = {
          token,
        };

        const res = await apiUrl.run(apiConfig);
        if (res.result) {
          setBalanceSum(res.data.balance_sum);
        } else {
          message.warning(res.message);
        }
      } catch (error) {
        message.error(error.message);
      } finally {
        setLoading(false);
      }
    }
  }, [user]);

  const reduceBalance = useCallback(
    async (record) => {
      const onFinish = async (values) => {
        console.log(
          "🚀 ~ file: BalanceList.jsx ~ line 96 ~ onFinish ~ values",
          values
        );

        const { token } = user;
        if (token) {
          try {
            const apiUrl = api.balance.reduce;
            const apiConfig = {
              token,
              data: {
                user_id: record.user_id,
                amount: values.amount * 100,
                comment: values.comment,
              },
            };
            const res = await apiUrl.run(apiConfig);
            if (res.result) {
              Modal.info(res.message);

              fetchList();
            } else {
              Modal.warning(res.message);
              message.warning(res.message);
            }
            form.resetFields();
            Modal.destroyAll();
          } catch (error) {
            Modal.error(error.message);
            Modal.destroyAll();
          }
        }
      };

      const onFinishFailed = (errorInfo) => {
        console.log(
          "🚀 ~ file: BalanceList.jsx ~ line 103 ~ onFinishFailed ~ errorInfo",
          errorInfo
        );
      };

      const validateMessages = {
        required: i18n.t("users.columnRequired"),
      };
      Modal.info({
        title: i18n.t("users.confirmReducing"),

        content: (
          <>
            <Layout className="reduce_balance_form">
              <Space
                align="center"
                direction="vertical"
                style={{ width: "100%" }}
              >
                <Avatar
                  size="large"
                  shape="circle"
                  src={record.avatar}
                  alt={record.nickname}
                />
                {record.nickname}
                <Space direction="horizontal" style={{ width: "100%" }}>
                  {i18n.t("users.currentBalance")} ￥
                  {util.getFormattedPrice(record.user.balance)}
                </Space>
              </Space>
              <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                className="form"
                form={form}
                layout="vertical"
                validateMessages={validateMessages}
              >
                <Form.Item
                  required
                  rules={[{ required: true }]}
                  label={i18n.t("users.reduceAmount")}
                  name="amount"
                >
                  <InputNumber min={1} max={record.user.balance / 100} />
                </Form.Item>
                <Form.Item
                  required
                  rules={[{ required: true }]}
                  label={i18n.t("users.reduceComment")}
                  name="comment"
                >
                  <TextArea rows={4} />
                </Form.Item>

                <Form.Item
                  wrapperCol={{
                    offset: 8,
                    span: 16,
                  }}
                >
                  <Button type="primary" htmlType="submit">
                    {i18n.t("base.modal.okText")}
                  </Button>
                  <Button
                    type=""
                    onClick={() => {
                      Modal.destroyAll();
                    }}
                  >
                    {i18n.t("base.modal.cancelText")}
                  </Button>
                </Form.Item>
              </Form>
            </Layout>
          </>
        ),
      });
    },
    [user, fetchList, currentClient, topupAmount]
  );

  useEffect(() => {
    fetchList();
    fetchBalanceSum();
  }, [fetchList, fetchBalanceSum]);

  return (
    <>
      <Search
        onSearch={(keyword) => fetchList(1, keyword)}
        placeholder={i18n.t("users.searchPlace")}
        style={{ width: 300, marginBottom: 16 }}
        enterButton
      />
      <div>
        <span>{i18n.t("users.balanceSum")}</span>
        <span> ¥{util.getPrice(balanceSum)}</span>
      </div>
      <Table
        loading={loading}
        dataSource={list.data}
        rowKey={(record) => `${record.open_id}`}
        onChange={(pagination, f, sorter) =>
          fetchList(pagination.current, "", sorter)
        }
        pagination={{
          current: list.current_page,
          total: list.total,
          showSizeChanger: false,
          showQuickJumper: true,
        }}
        columns={[
          {
            title: i18n.t("users.avatar"),
            key: "avatar",
            render: (text, record) => (
              <Avatar
                size="large"
                shape="circle"
                src={record.avatar}
                alt={record.nickname}
              />
            ),
          },
          {
            title: i18n.t("users.name"),
            dataIndex: "nickname",
            key: "nickname",
          },
          {
            title: i18n.t("commissionList.mobile"),
            key: "mobile",
            align: "center",
            render: (text, record) => {
              if (record.user) {
                return record.user.mobile;
              }
              return null;
            },
          },
          {
            title: i18n.t("users.order"),
            key: "orderCount",
            dataIndex: "orders_count",
            align: "center",
            sorter: true,
          },
          {
            title: i18n.t("users.balance"),
            key: "balance",
            dataIndex: "balance",
            sorter: true,
            render: (text, record) => {
              if (record.user) {
                return util.getFormattedPrice(record.user.balance, "¥");
              }
              return null;
            },
          },
          {
            title: i18n.t("users.tags"),
            key: "tags",
            render: (text, record) => {
              if (record.user_id) {
                return <Tag color="green">{i18n.t("users.registered")}</Tag>;
              }
              return null;
            },
          },
          {
            title: "Open Id",
            key: "open_id",
            dataIndex: "open_id",
            align: "center",
            responsive: ["xxl"],
          },
          {
            title: i18n.t("users.date"),
            key: "date",
            dataIndex: "created_at",
            align: "center",
            sorter: true,
          },
          {
            title: i18n.t("users.actions"),
            key: "actions",
            align: "center",
            render: (text, record) => {
              if (record.user_id) {
                const isEnabledSpecifiedUsers =
                  user.merchant && user.merchant.enabled_specified_users;
                return (
                  <Space>
                    <Button type="link" onClick={() => reduceBalance(record)}>
                      {i18n.t("users.reduceAmount")}
                    </Button>
                  </Space>
                );
              }
              return null;
            },
          },
        ]}
      />
    </>
  );
}

export default connect((state) => ({
  user: getUserInfo(state),
}))(BalanceList);
