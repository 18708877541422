import { Spin } from "antd";
import React, { useState } from "react";

import "./Media.scss";
import MediaFolder from "./MediaFolder";
import MediaList from "./MediaList";

const Media = ({ collectionName, selectFiles }) => {
  const [folderId, setFolderId] = useState();
  const [loading, setLoading] = useState(false);
  return (
    <div className="media">
      <div className="gutter-row folder" span={6}>
        <MediaFolder
          setFolderId={setFolderId}
          collectionName={collectionName}
        />
      </div>
      <div className="gutter-row files" span={6}>
        <Spin spinning={loading}>
          <MediaList
            folderId={folderId}
            setMediaLoading={setLoading}
            collectionName={collectionName}
            selectFiles={selectFiles}
          />
        </Spin>
      </div>
    </div>
  );
};

export default Media;
