import React from "react";
import debounce from "lodash/debounce";
import { LeftOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Layout,
  Divider,
  Input,
  Select,
  Upload,
  Radio,
  Button,
  Spin,
  InputNumber,
  Tag,
  Avatar,
  Tooltip,
  Switch,
  Form,
} from "antd";

import Base from "../Base";

//redux
import { connect } from "react-redux";
import { addMedia, removeMedia } from "../../utils/redux/actions/Medias";
import { getMedias } from "../../utils/redux/reducers/Medias";
import { getUserInfo } from "../../utils/redux/reducers/User";
import { JD_ID } from "../../constants";
import VideoUpload from "../../components/media/VideoUpload";
import ImageUpload from "../../components/media/ImageUpload";
import {
  getUploadedImagePaths,
  getUploadedVideoPaths,
} from "../../utils/helpers/mediaHelpers";

class ShowsEdit extends Base {
  static MediaTypes = [
    { value: "text", name: "showsEdit.status.text" },
    { value: "image/*", name: "showsEdit.status.image" },
    { value: "video/*", name: "showsEdit.status.video" },
  ];

  static Suggestion = [
    { value: 0, name: "productEdit.suggest.off" },
    { value: 1, name: "productEdit.suggest.on" },
  ];

  editShowForm = React.createRef();

  /* lifecycle methods */

  constructor(props) {
    super(props);
    this.onSearchProduct = debounce(this.onSearchProduct, 800);
    this.state = {
      ...this.state,
      editShow: this.getEditShow(),
      categories: null,
      showTagInput: false,
      tags: this.getTags(),
      tagInput: "",
      uploadingImages: false,
      updatingShow: false,
      fetching: false,
      productList: { data: [] },
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.getCategoriesFromServer();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  /* render methods */

  render() {
    return (
      <Layout id="route-product-edit">
        {this._renderHeader()}
        {this._renderContent()}
        {this._renderFooter()}
        {this._renderModals()}
      </Layout>
    );
  }

  _renderHeader() {
    if (this.showEditIs4Mobile()) return null;

    let title = this.i18n.t("showsEdit.headerTitle");
    if (this.state.editProduct) {
      title = this.i18n.t("showsEdit.headerTitleEdit");
    }

    return (
      <Layout.Header className="edit-header">
        <Button
          style={{
            backgroundColor: "transparent",
            borderWidth: 0,
            boxShadow: "none",
          }}
          onClick={this.onHeaderBackClick}
        >
          <LeftOutlined />
          {this.i18n.t("productEdit.headerBack")}
        </Button>
        <Divider type="vertical" />
        <span className="title">{title}</span>
      </Layout.Header>
    );
  }

  _renderContent() {
    return (
      <Layout.Content className="edit-content">
        {this._renderContentForm()}
      </Layout.Content>
    );
  }

  _renderContentForm() {
    return (
      <Spin spinning={this.state.uploadingImages || this.state.updatingShow}>
        <Form ref={this.editShowForm}>
          {this._renderContentFormCategory()}
          {this._renderContentFormName()}
          {this._renderContentFormTag()}
          {this._renderContentFormStatusSwitch()}
          {this._renderContentFormStatus()}
          {this._renderContentFormLanguage()}
          {this._renderContentFormVideoCover()}
          {this._renderContentFormVideo()}
          {this._renderContentFormImage()}
          {this._renderContentFormOrder()}
          {this._renderContentFormOrderId()}
          {this._renderContentFormProduct()}
        </Form>
      </Spin>
    );
  }

  _renderContentFormCategory() {
    const item = {
      colon: false,
      label: this.i18n.t("showsEdit.catLabel"),
      labelCol: { span: 4 },
      wrapperCol: { span: 8 },
      required: true,
    };

    let categories = this.getCategories();

    return (
      <Form.Item
        {...item}
        name="category_id"
        rules={[
          {
            required: true,
            message: this.i18n.t("productEdit.catRequired"),
          },
        ]}
      >
        <Select>
          {categories.map((option, index) => {
            return (
              <Select.Option key={`option-${index}`} value={option.id}>
                {this.i18n.getPropNameByLocale(option, "name")}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    );
  }

  _renderContentFormName() {
    const item = {
      colon: false,
      label: this.i18n.t("showsEdit.nameLabel"),
      labelCol: { span: 4 },
      wrapperCol: { span: 16 },
      required: true,
    };

    return (
      <Form.Item
        {...item}
        name="content"
        initialValue={this.getDefaultName()}
        rules={[
          {
            required: true,
            message: this.i18n.t("showsEdit.nameRequired"),
          },
        ]}
      >
        <Input.TextArea placeholder={this.i18n.t("showsEdit.nameHint")} />
      </Form.Item>
    );
  }

  _renderContentFormTag() {
    const item = {
      colon: false,
      label: this.i18n.t("showsEdit.productLabel"),
      labelCol: { span: 4 },
      wrapperCol: { span: 16 },
      required: false,
    };

    const { showTagInput, tags, tagInput } = this.state;

    const tagsInput = (
      <div>
        {tags.map((tag, index) => {
          const isLongTag = tag.length > 20;
          const tagElem = (
            <Tag key={index} closable>
              {isLongTag ? `${tag.slice(0, 20)}...` : tag}
            </Tag>
          );
          return isLongTag ? (
            <Tooltip title={tag} key={tag}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          );
        })}
        {showTagInput && (
          <Input
            type="text"
            size="small"
            style={{ width: 78 }}
            value={tagInput}
            onChange={this.handleTagInputChange}
            onBlur={this.handleTagInputConfirm}
            onPressEnter={this.handleTagInputConfirm}
          />
        )}
        {!showTagInput && (
          <Tag
            onClick={this.showTagInput}
            style={{ background: "#fff", borderStyle: "dashed" }}
          >
            <PlusOutlined /> {this.i18n.t("showsEdit.addTag")}
          </Tag>
        )}
      </div>
    );

    return <Form.Item {...item}>{tagsInput}</Form.Item>;
  }

  _renderContentFormImage() {
    return (
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => {
          if (this.getCurrentMediaType().value !== "image/*") return null;
          const mediaType = this.getCurrentMediaType();
          const images = getFieldValue("images");
          const item = {
            colon: false,
            label: this.i18n.t(mediaType.name),
            labelCol: { span: 4 },
            wrapperCol: { span: 16 },
            required: false,
          };
          return (
            <Form.Item
              {...item}
              name="images"
              valuePropName="fileList"
              initialValue={this.getDefaultImages()}
              getValueFromEvent={this.onUploadImageChange}
            >
              <ImageUpload
                multiple
                images={getFieldValue("images")}
                setSelectedImages={(images) => {
                  this.editShowForm.current.setFieldsValue({
                    images: images,
                  });
                }}
              />
            </Form.Item>
          );
        }}
      </Form.Item>
    );
  }

  _renderContentFormVideoCover() {
    const item = {
      colon: false,
      label: this.i18n.t("showsEdit.videoCover"),
      labelCol: { span: 4 },
      wrapperCol: { span: 16 },
      required: false,
    };

    return (
      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) => {
          if (!this.currentMediaTypeIsVideo()) return null;

          return (
            <Form.Item
              {...item}
              name="video_cover"
              valuePropName="fileList"
              initialValue={this.getDefaultVideoCover()}
              getValueFromEvent={this.onUploadImageChange}
            >
              <ImageUpload
                multiple
                images={getFieldValue("video_cover")}
                setSelectedImages={(cover) => {
                  this.editShowForm.current.setFieldsValue({
                    video_cover: cover,
                  });
                }}
              />
            </Form.Item>
          );
        }}
      </Form.Item>
    );
  }

  _renderContentFormVideo() {
    return (
      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) => {
          if (!this.currentMediaTypeIsVideo()) return null;
          const mediaType = this.getCurrentMediaType();
          const video = getFieldValue("video");
          const item = {
            colon: false,
            label: this.i18n.t(mediaType.name),
            labelCol: { span: 4 },
            wrapperCol: { span: 16 },
            required: false,
          };
          return (
            <Form.Item
              {...item}
              name="video"
              valuePropName="fileList"
              initialValue={this.getDefaultVideo()}
            >
              <VideoUpload
                video={this.getDefaultVideo()}
                setSelectedVideo={(video) => {
                  this.editShowForm.current.setFieldsValue({ video });
                }}
              />
            </Form.Item>
          );
        }}
      </Form.Item>
    );
  }

  _renderContentFormStatusSwitch() {
    const item = {
      colon: false,
      label: this.i18n.t("proxy.editProxyStatusSwitch"),
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      required: false,
    };

    return (
      <Form.Item
        {...item}
        name="switch"
        valuePropName="checked"
        initialValue={this.getDefaultStatusSwithValue()}
        getValueFromEvent={this.onChangeStatusSwitch}
      >
        <Switch defaultChecked={this.getDefaultStatusSwithValue() === 1} />
      </Form.Item>
    );
  }
  _renderContentFormLanguage() {
    const item = {
      colon: false,
      label: this.i18n.t("showsEdit.languageLabel"),
      labelCol: { span: 4 },
      wrapperCol: { span: 16 },
    };

    return (
      <Form.Item {...item} name="language" initialValue={this.getDefaultLang()}>
        <Radio.Group
          onChange={this.onMediaTypeChange}
          style={{ fontFamily: "Alp Ekran" }}
        >
          <Radio.Button key={`lang-ug`} value="ug">
            ئ‍ۇيغۇرچە
          </Radio.Button>
          <Radio.Button key={`lang-zh`} value="zh">
            中文
          </Radio.Button>
        </Radio.Group>
      </Form.Item>
    );
  }
  _renderContentFormStatus() {
    const item = {
      colon: false,
      label: this.i18n.t("showsEdit.statusLabel"),
      labelCol: { span: 4 },
      wrapperCol: { span: 16 },
    };

    return (
      <Form.Item {...item} name="status" initialValue={this.getDefaultStatus()}>
        <Radio.Group onChange={this.onMediaTypeChange}>
          {ShowsEdit.MediaTypes.map((status, index) => {
            return (
              <Radio.Button key={`status-${index}`} value={status.value}>
                {this.i18n.t(status.name)}
              </Radio.Button>
            );
          })}
        </Radio.Group>
      </Form.Item>
    );
  }

  _renderContentFormOrder() {
    const item = {
      colon: false,
      label: this.i18n.t("showsEdit.orderLabel"),
      labelCol: { span: 4 },
      wrapperCol: { span: 2 },
    };
    return (
      <Form.Item {...item} name="order" initialValue={this.getDefaultOrder()}>
        <InputNumber min={0} step={1} />
      </Form.Item>
    );
  }

  _renderContentFormOrderId() {
    const item = {
      colon: false,
      label: this.i18n.t("showsEdit.orderIdLabel"),
      labelCol: { span: 4 },
      wrapperCol: { span: 8 },
    };

    return (
      <Form.Item
        {...item}
        name="order_id"
        initialValue={this.getDefaultOrderId()}
      >
        <Input />
      </Form.Item>
    );
  }

  _renderContentFormProduct() {
    const { productList, fetching } = this.state;

    const item = {
      colon: false,
      label: this.i18n.t("showsEdit.bindProduct"),
      labelCol: { span: 4 },
      wrapperCol: { span: 8 },
    };

    return (
      <Form.Item
        {...item}
        name="product_id"
        initialValue={this.getDefaultProductId()}
      >
        <Select
          mode="multiple"
          placeholder={this.i18n.t("showsEdit.selectProduct")}
          notFoundContent={fetching ? <Spin size="small" /> : null}
          filterOption={false}
          onSearch={this.onSearchProduct}
          tagRender={({ label, closable, onClose }) => {
            return (
              <Tag
                closable={closable}
                onClose={onClose}
                style={{ borderWidth: 0 }}
              >
                {label}
              </Tag>
            );
          }}
        >
          {productList.data.map((d) => (
            <Select.Option key={d.id}>
              <Avatar src={d.images[0].url} shape="square" size="large" />
              {this.i18n.getPropNameByLocale(d, "name")}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    );
  }

  _renderFooter() {
    if (this.showEditIs4Mobile()) {
      return (
        <Layout.Footer className="edit-footer">
          <Button onClick={() => this.onFooterConfirmClick(true)}>
            {this.i18n.t("productEdit.saveAndContinue")}
          </Button>
        </Layout.Footer>
      );
    }

    return (
      <Layout.Footer className="edit-footer">
        <Button type="primary" onClick={() => this.onFooterConfirmClick(false)}>
          {this.i18n.t("productEdit.saveAndBack")}
        </Button>
        {!this.isEditMode() ? (
          <Button onClick={() => this.onFooterConfirmClick(true)}>
            {this.i18n.t("productEdit.saveAndContinue")}
          </Button>
        ) : null}
        <Button onClick={this.onFooterCancelClick}>
          {this.i18n.t("productEdit.cancel")}
        </Button>
      </Layout.Footer>
    );
  }

  _renderModals() {
    let items = [];

    items.push(this.renderImagePreviewModal());

    return items;
  }

  /* user methods */

  onHeaderBackClick = () => {
    this.goToShowsList();
  };

  onUploadImageBefore = (file) => {
    if (typeof file.uid === "string") {
      if (!this.isImageTypeValid(file)) {
        this.showMessage(
          this.i18n.t("productEdit.uploadImageTypeWarning"),
          Base.Message.warning
        );
      }

      if (!this.isImageLessThan6MB(file)) {
        this.showMessage(
          this.i18n.t("productEdit.uploadImageSizeWarning"),
          Base.Message.warning
        );
      }
    }

    return false;
  };

  onUploadVideoBefore = (file) => {
    if (typeof file.uid === "string") {
      if (!this.isVideoLessThan10MB(file)) {
        this.showMessage(
          this.i18n.t("productEdit.uploadVideoSizeWarning"),
          Base.Message.warning
        );
      }
    }

    return false;
  };

  onUploadImageChange = (e) => {
    let arr = null;
    if (Array.isArray(e)) {
      arr = e;
    } else {
      arr = e && e.fileList;
    }

    let fileList = [];

    for (let i = 0; i < arr.length; i++) {
      let file = arr[i];
      if (typeof file.uid === "string") {
        if (!this.isImageTypeValid(file.originFileObj)) continue;
        if (!this.isImageLessThan6MB(file.originFileObj)) continue;
      }

      fileList.push(file);
    }

    return fileList;
  };

  onUploadVideoChange = (e) => {
    let arr = null;
    if (Array.isArray(e)) {
      arr = e;
    } else {
      arr = e && e.fileList;
    }

    if (arr && arr.length && this.isVideoLessThan10MB(arr[0].originFileObj)) {
      return arr;
    }

    return [];
  };

  onUploadImagePreview = (file) => {
    let fileList = this.editShowForm.current.getFieldValue("images");
    let fileIndex = 0;
    for (let i = 0; i < fileList.length; i++) {
      if (file.uid === fileList[i].uid) {
        fileIndex = i;
        break;
      }
    }
    this.setState({
      previewImages: fileList,
      previewImageIndex: fileIndex,
      isImg: true,
    });
  };

  onUploadVideoCoverPreview = () => {
    let fileList = this.editShowForm.current.getFieldValue("video_cover");
    this.setState({
      previewImages: fileList,
      previewImageIndex: 0,
      isImg: true,
    });
  };

  onUploadVideoPreview = () => {
    let fileList = this.editShowForm.current.getFieldValue("video");
    this.setState({
      previewImages: fileList,
      previewImageIndex: 0,
      isImg: false,
    });
  };

  onChangeStatusSwitch = (value) => {
    return value ? 1 : 0;
  };

  onMediaTypeChange = () => {
    this.editShowForm.current.resetFields(["images"]);
  };

  onFooterConfirmClick = (continueCreate = false) => {
    this.editShowForm.current.validateFields().then((values) => {
      if (!this.isEditMode()) {
        this.addShow(values, continueCreate);
      } else {
        this.updateShow(values);
      }
    });
  };

  onFooterCancelClick = () => {
    this.goToShowsList();
  };

  handleTagInputConfirm = () => {
    let { tagInput, tags } = this.state;
    if (tagInput && tags.indexOf(tagInput) === -1) {
      tags = [...tags, tagInput];
    }
    this.setState({
      tags,
      showTagInput: false,
      tagInput: "",
    });
  };

  handleTagInputChange = (e) => {
    this.setState({ tagInput: e.target.value });
  };

  showTagInput = () => {
    this.setState({ showTagInput: true });
  };

  handleCloseTag = (removedTag) => {
    const tags = this.state.tags.filter((tag) => tag !== removedTag);
    this.setState({ tags });
  };

  /* server methods */

  async addShow(values, continueCreate = false) {
    if (this.state.updatingShow) return;

    let token = this.getUserToken();
    if (!token) return;

    const { tags } = this.state;

    let api = this.api.shows.add;
    let apiConfig = {
      token: token,
      data: {
        category_id: values.category_id,
        content: values.content,
        order_id: values.order_id,
        order: values.order,
        language: values.language,
        status: values.switch,
        product_tags: tags,
        product_id: values.product_id,
        type: this.getMediaType(values.status),
      },
    };

    try {
      if (values.video) {
        const videoPaths = getUploadedVideoPaths(values.video);
        if (videoPaths) {
          apiConfig.data.media = videoPaths;
        }
      }
      if (values.images) {
        const imagePaths = getUploadedImagePaths(values.images);
        if (imagePaths) {
          apiConfig.data.media = imagePaths;
        }
      }

      if (
        this.currentMediaTypeIsVideo() &&
        values.video_cover &&
        values.video_cover.length > 0
      ) {
        const coverImagePaths = getUploadedImagePaths(values.video_cover);
        if (coverImagePaths) {
          apiConfig.data.video_cover = coverImagePaths[0];
        }
      }

      this.showMessage(
        this.i18n.t("productEdit.creatingProduct"),
        Base.Message.loading
      );
      this.setState({ updatingShow: true });

      let res = await api.run(apiConfig);

      if (!this.mounted) return;

      if (res.result) {
        this.showMessage(
          this.i18n.t("showsEdit.creatingShowSuccess"),
          Base.Message.success
        );
        if (continueCreate) {
          this.editShowForm.current.resetFields();
        } else {
          this.goToShowsList();
        }
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;
      this.setState({ updatingShow: false });
    }
  }

  async updateShow(values) {
    if (this.state.updatingShow) return;

    let show = this.getEditShow();
    if (!show) return;

    let token = this.getUserToken();
    if (!token) return;

    const { tags } = this.state;

    let api = this.api.shows.update;
    let apiConfig = {
      token: token,
      path: {
        showsId: show.id,
      },
      data: {
        category_id: values.category_id,
        content: values.content,
        order_id: values.order_id,
        status: values.switch,
        order: values.order,
        language: values.language,
        product_tags: tags,
        product_id: values.product_id,
        type: this.getMediaType(values.status),
      },
    };

    try {
      if (values.images) {
        const imagePaths = getUploadedImagePaths(values.images);
        if (imagePaths) {
          apiConfig.data.media = imagePaths;
        }
      }
      if (values.video) {
        const videoPaths = getUploadedVideoPaths(values.video);
        if (videoPaths) {
          apiConfig.data.media = videoPaths;
        }
      }
      if (
        this.currentMediaTypeIsVideo() &&
        values.video_cover &&
        values.video_cover.length > 0
      ) {
        const coverImagePaths = getUploadedImagePaths(values.video_cover);
        if (coverImagePaths) {
          apiConfig.data.video_cover = coverImagePaths[0];
        }
      }

      this.showMessage(
        this.i18n.t("showsEdit.updatingProductSuccess"),
        Base.Message.loading
      );
      this.setState({ updatingShow: true });

      let res = await api.run(apiConfig);

      if (!this.mounted) return;

      if (res.result) {
        this.showMessage(
          this.i18n.t("showsEdit.updatingProductSuccess"),
          Base.Message.success
        );
        this.goToShowsList();
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;
      this.setState({ updatingShow: false });
    }
  }

  async uploadImages(images) {
    if (this.state.uploadingImages) throw new Error("uploading");

    if (!images && images.length <= 0) throw new Error("invalid images");

    let token = this.getUserToken();
    if (!token) throw new Error("no user token");

    let uploadType = "images";
    if (this.currentMediaTypeIsVideo()) {
      uploadType = "video";
    }
    let apiConfig = {
      token: token,
      uploadFiles: images,
      uploadType,
    };
    this.showMessage(
      this.i18n.t("productEdit.uploadingImages"),
      Base.Message.loading
    );
    this.setState({ uploadingImages: true });
    try {
      let res = await this.api.common.uploadImages.run(apiConfig);
      if (res.result) {
        let data = res.data;
        return data.map((item) => item.path);
      } else {
        throw new Error(res.message);
      }
    } catch (error) {
      throw error;
    } finally {
      if (!this.mounted) return;
      this.setState({ uploadingImages: false });
    }
  }

  async getCategoriesFromServer() {
    let token = this.getUserToken();
    if (!token) {
      this.goToLogin();
      return;
    }

    let api = this.api.shows.category;
    let apiConfig = {
      token,
    };

    try {
      let res = await api.run(apiConfig);
      this.setState({
        categories: res.data,
      });
      this.editShowForm &&
        this.editShowForm.current.setFieldsValue({
          category_id: this.getDefaultCategory(),
        });
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    }
  }

  onSearchProduct = async (keyword, search_type = "keyword") => {
    const token = this.getUserToken();
    const user = this.getUser();
    let customApi = this.api.product.products;
    if (user && user.merchant.id === JD_ID) {
      customApi = this.api.product.jdProducts;
    }
    if (token) {
      const query = {
        category_id: -1,
        page: 1,
        keyword,
        search_type,
        count: 5,
      };
      const apiConfig = {
        token,
        query,
      };
      try {
        this.setState({ fetching: true });
        const res = await customApi.run(apiConfig);
        if (res.result) {
          this.setState({ productList: res.data });
        } else {
          this.showMessage(res.message);
        }
      } catch (error) {
        console.log("loadProducts -> error", error);
      } finally {
        if (this.mounted) {
          this.setState({ fetching: false });
        }
      }
    }
  };

  /* custom methods */

  goToShowsList() {
    this.props.history.goBack();
  }

  getCategories() {
    return this.state.categories || [];
  }

  getMediaType() {
    const { getFieldValue } = this.editShowForm.current;
    return ShowsEdit.MediaTypes.findIndex(
      (item) => item.value === getFieldValue("status")
    );
  }

  getTags() {
    const show = this.getEditShow();
    if (
      show &&
      Array.isArray(show.product_tags) &&
      show.product_tags.length > 0
    ) {
      return show.product_tags;
    }
    return [];
  }

  isEditMode() {
    return this.getFromRouterState("edit");
  }

  getEditShow() {
    return this.getFromRouterState("show");
  }

  hasEditProductSku() {
    let product = this.getEditShow();
    return product ? product.sku_id > 0 : false;
  }

  getDefaultStatusSwithValue() {
    let status = 1;

    if (this.isEditMode()) {
      status = this.getEditShow().status;
    }

    return status;
  }

  getDefaultCategory() {
    let cats = this.getCategories();

    let cat = null;
    if (cats && cats.length > 0) {
      let stateCatId = this.getFromRouterState("currentCat");
      if (stateCatId) {
        cat = cats.find((cat) => cat.id === stateCatId);
      }

      if (!cat && this.isEditMode() && this.getEditShow()) {
        cat = cats.find((cat) => cat.id === this.getEditShow().category_id);
      }

      if (!cat) {
        cat = cats[0];
      }
    }

    return cat ? cat.id : -1;
  }

  getDefaultName() {
    let name = "";

    if (this.isEditMode() && this.getEditShow()) {
      name = this.getEditShow().content;
    }

    return name;
  }

  getDefaultUgName() {
    let name = "";

    if (this.isEditMode() && this.getEditShow()) {
      name = this.getEditShow().ug_name;
    }

    return name;
  }

  getDefaultImages() {
    let images = [];

    if (this.isEditMode() && this.getEditShow()) {
      let productImages = this.getEditShow().media;
      if (productImages && productImages.length > 0) {
        for (let i = 0; i < productImages.length; i++) {
          images.push({
            uid: -1 * (i + 1),
            name: `image${i + 1}`,
            status: "done",
            url: productImages[i] ? productImages[i].url : "",
            path: productImages[i] ? productImages[i].path : "",
          });
        }
      }
    }

    return images;
  }

  getDefaultVideoCover() {
    let cover = [];

    if (this.isEditMode() && this.getEditShow()) {
      let videoCover = this.getEditShow().video_cover;

      if (videoCover) {
        cover.push({
          uid: -1,
          name: `cover`,
          status: "done",
          url: videoCover.url,
          path: videoCover.path,
        });
      }
    }

    return cover;
  }

  getDefaultVideo() {
    let video = [];

    if (this.isEditMode() && this.getEditShow()) {
      let showVideo = this.getEditShow().video;
      if (showVideo && showVideo.length > 0) {
        for (let i = 0; i < showVideo.length; i++) {
          video.push({
            uid: -1 * (i + 1),
            name: `video${i + 1}`,
            status: "done",
            url: showVideo && showVideo[i].url,
            path: showVideo && showVideo[i].path,
          });
        }
      }
    }
    if (video.length < 1) return null;
    console.log(
      "🚀 ~ file: ShowsEdit.jsx ~ line 1170 ~ ShowsEdit ~ getDefaultVideo ~ video",
      video
    );
    return video;
  }

  getDefaultInPrice() {
    let price = 0;

    if (this.isEditMode() && this.getEditShow()) {
      price = this.util.getPrice(this.getEditShow().purchase_price || 0);
    }

    return price;
  }

  getDefaultOutPrice() {
    let price = 0;

    if (this.isEditMode() && this.getEditShow()) {
      price = this.util.getPrice(this.getEditShow().price);
    }

    return price;
  }

  getDefaultCommission() {
    let commission = 0;

    if (this.isEditMode() && this.getEditShow()) {
      commission = this.getEditShow().commission_percent;
    }

    return commission;
  }

  getDefaultParentCommission() {
    let commission = 0;

    if (this.isEditMode() && this.getEditShow()) {
      commission = this.getEditShow().parent_commission_percent;
    }

    return commission;
  }

  getDefaultQuantity() {
    let quantity = 0;

    if (this.isEditMode() && this.getEditShow()) {
      quantity = this.getEditShow().quantity;
    }

    return quantity;
  }

  getDefaultPackPrice() {
    let packPrice = 0;

    if (this.isEditMode() && this.getEditShow()) {
      packPrice = this.util.getPrice(this.getEditShow().pack_price);
    }

    return packPrice;
  }

  getDefaultPackCount() {
    let count = 0;

    if (this.isEditMode() && this.getEditShow()) {
      count = this.getEditShow().pack_count;
    }

    return count;
  }

  getDefaultLang() {
    let lang = "ug";

    const show = this.getEditShow();
    if (this.isEditMode() && show && show.language) {
      return show.language;
    }

    return lang;
  }

  getDefaultStatus() {
    let status = ShowsEdit.MediaTypes[0].value;

    const show = this.getEditShow();
    if (this.isEditMode() && show && show.type) {
      return ShowsEdit.MediaTypes[show.type].value;
    }

    return status;
  }

  getDefaultOrder() {
    let count = 0;

    if (this.isEditMode() && this.getEditShow()) {
      count = this.getEditShow().order;
    }

    return count;
  }

  getDefaultOrderId() {
    let count = 0;

    if (this.isEditMode() && this.getEditShow()) {
      count = this.getEditShow().order_id;
    }

    return count;
  }

  getDefaultProductId() {
    let product_id = null;

    if (this.isEditMode() && this.getEditShow()) {
      if (
        this.getEditShow().products &&
        this.getEditShow().products.length > 0
      ) {
        product_id = this.getEditShow().product_ids;
      }
    }

    return product_id;
  }

  isImageTypeValid(image) {
    const types = ["image/jpeg", "image/png"];
    return types.indexOf(image.type) !== -1;
  }

  isImageLessThan6MB(image) {
    return image.size / 1024 / 1024 < 16;
  }

  isVideoLessThan10MB(image) {
    return image.size / 1024 / 1024 < 20;
  }

  getCurrentMediaType() {
    if (this.editShowForm.current) {
      const { getFieldValue } = this.editShowForm.current;
      return ShowsEdit.MediaTypes.find(
        (item) => item.value === getFieldValue("status")
      );
    }
    return {};
  }

  currentMediaTypeIsVideo() {
    return this.getCurrentMediaType().value === "video/*";
  }

  currentMediaTypeIsImg() {
    return this.getCurrentMediaType().value === "image/*";
  }

  currentMediaTypeIsText() {
    return this.getCurrentMediaType().value === "text";
  }

  showEditIs4Mobile() {
    return this.props.location.pathname === "/show-edit";
  }
}

export default connect(
  (state) => {
    return {
      user: getUserInfo(state),
      images: getMedias(state),
    };
  },
  { addMedia, removeMedia }
)(ShowsEdit);
