import { FolderOutlined } from "@ant-design/icons";
import { Button, Modal, Tree, Input, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";

import { getUserInfo } from "../../utils/redux/reducers/User";
import { addFolder, getMediaFolderList, renameFolder } from "./mediaFolderApi";
import MediaFolderItem from "./MediaFolderItem";
import { Locale } from "../../utils";
import { showNotificationWithIcon } from "../../utils/helpers/helpers";

const { DirectoryTree } = Tree;
const i18n = Locale.getInstance();

const MediaFolder = ({ collectionName, setFolderId }) => {
  const user = useSelector((state) => getUserInfo(state));
  const [openAddMedia, setOpenAddMedia] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [folderName, setFolderName] = useState();
  const [selectedFolder, setSelectedFolder] = useState();
  const [action, setAction] = useState("add");
  const queryClient = useQueryClient();
  let history = useHistory();

  useEffect(() => {
    if (selectedFolder && selectedFolder.id) {
      setFolderId(selectedFolder.id);
    } else {
      setFolderId();
    }
  }, [selectedFolder, setFolderId]);

  const { error, data, isLoading, status } = useQuery(
    ["folder-list", collectionName, user.token],
    () =>
      getMediaFolderList({
        filter: {
          collection_name: collectionName,
        },
        token: user.token,
      }),
    {
      onError: (error) => {
        console.log(
          "🚀 ~ file: MediaFolder.jsx ~ line 43 ~ MediaFolder ~ error",
          error
        );
        showNotificationWithIcon(error.messages);
      },
    }
  );

  if (error) {
    showNotificationWithIcon(error.data.message);
    if (error.status === 400) {
      history.push("/main/media/storage-setting");
    }
  }

  const treeData = [
    {
      title: i18n.t("product.filter.all"),
      key: "0-0",
      item: "all",
    },
  ];

  data?.data.map((item, parentIndex) => {
    treeData.push({
      title: () => (
        <MediaFolderItem
          setAction={setAction}
          setOpenAddMedia={setOpenAddMedia}
          setSelectedFolder={setSelectedFolder}
          setFolderName={setFolderName}
          item={item}
          isParentItem
        />
      ),
      key: item.id,
      item,
      children: item.children.map((childItem, index) => {
        return {
          title: (
            <MediaFolderItem
              setAction={setAction}
              setOpenAddMedia={setOpenAddMedia}
              setSelectedFolder={setSelectedFolder}
              setFolderName={setFolderName}
              item={childItem}
            />
          ),
          key: childItem.id,
          isLeaf: true,
          parentIndex,
          item: childItem,
          childIndex: index,
          icon: <FolderOutlined />,
        };
      }),
    });
  });

  const onSelect = (keys, info) => {
    setTimeout(() => {
      setSelectedFolder(info.node.item);
    }, 500);
  };

  const onExpand = (keys, info) => {
    // console.log("Trigger Expand", keys, info);
  };
  const addFoldermutation = useMutation(addFolder, {
    onSuccess: () => {
      queryClient.invalidateQueries("folder-list");
      setConfirmLoading(false);
      setOpenAddMedia(false);
      setFolderName("");
    },
    onError: (error) => {
      showNotificationWithIcon(error.messages);
      console.log(
        "🚀 ~ file: MediaFolder.jsx ~ line 76 ~ MediaFolder ~ error",
        error
      );
      setConfirmLoading(false);
    },
  });

  const renameFoldermutation = useMutation(renameFolder, {
    onSuccess: () => {
      queryClient.invalidateQueries("folder-list");
      setConfirmLoading(false);
      setOpenAddMedia(false);
      setFolderName();
      setSelectedFolder();
    },
    onError: (error) => {
      showNotificationWithIcon(error.message);
      setConfirmLoading(false);
    },
  });

  const addOrUpdateMedia = () => {
    setConfirmLoading(true);
    let data = {
      name: folderName,
      collection_name: collectionName,
    };

    if (selectedFolder) {
      data["folder_id"] = selectedFolder.id;
    }
    if (action === "add") {
      addFoldermutation.mutate({
        data,
        token: user.token,
      });
    } else {
      renameFoldermutation.mutate({
        id: selectedFolder.id,
        data: {
          name: folderName,
          collection_name: selectedFolder.collection_name,
          folder_id: selectedFolder.folder_id,
        },
        token: user.token,
      });
    }
  };

  if (isLoading) {
    return <Spin spinning={isLoading} />;
  }
  return (
    <>
      <div className="list">
        <DirectoryTree
          multiple
          defaultExpandParent={false}
          onSelect={onSelect}
          onExpand={onExpand}
          treeData={treeData}
        />
      </div>
      <div className="action-bar">
        <Button
          onClick={() => {
            setOpenAddMedia(true);
          }}
        >
          {i18n.t("media.newFolder")}
        </Button>
      </div>

      <Modal
        title={i18n.t("media.newFolder")}
        open={openAddMedia}
        onOk={addOrUpdateMedia}
        confirmLoading={confirmLoading}
        onCancel={() => {
          setOpenAddMedia(false);
          setFolderName("");
          setConfirmLoading(false);
        }}
      >
        <p>
          <Input
            defaultValue={folderName}
            value={folderName}
            onChange={(e) => {
              setFolderName(e.target.value);
            }}
            placeholder={i18n.t("media.folderName")}
          />
        </p>
      </Modal>
    </>
  );
};

export default MediaFolder;
