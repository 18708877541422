import React, { useCallback, useState, useEffect } from "react";
import { message, Spin, Tabs } from "antd";
import { connect } from "react-redux";
import { getUserInfo } from "../../utils/redux/reducers/User";
import { ApiManager, Locale } from "../../utils";
import SubSettings from "./SubSettings";

const api = ApiManager.getInstance();
const i18n = Locale.getInstance();
const { TabPane } = Tabs;

function ShopSettingGroup({ user }) {
  const [groupData, setGroupData] = useState(null);

  const fetchList = useCallback(async () => {
    const { token } = user;
    if (token) {
      const apiUrl = api.setting.list;
      const apiConfig = {
        token,
      };

      try {
        const res = await apiUrl.run(apiConfig);
        if (res.result) {
          setGroupData(res.data);
        } else {
          message.warning(res.message);
        }
      } catch (error) {
        message.error(error.message);
      }
    }
  }, [user]);

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  return (
    <Spin spinning={!groupData}>
      {groupData && Object.keys(groupData).length > 0 && (
        <Tabs
          type="card"
          style={{ backgroundColor: "#fff" }}
          tabBarStyle={{ backgroundColor: "#f1f1f1" }}
          dir={i18n.isLocaleRTL() ? "rtl" : "ltr"}
          items={Object.keys(groupData).map((key) => ({
            label: i18n.getPropNameByLocale(groupData[key], "name"),
            key: `${key}`,
            children: (
              <SubSettings data={groupData[key].settings} groupKey={key} />
            ),
          }))}
        />
      )}
    </Spin>
  );
}

export default connect((state) => ({ user: getUserInfo(state) }))(
  ShopSettingGroup
);
