import { Spin, Table } from "antd";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Route, useHistory } from "react-router-dom";
import { BreadCrumb } from "../../../components/main/LayoutComponents";
import { Locale } from "../../../utils";
import { showNotificationWithIcon } from "../../../utils/helpers/helpers";
import { getList } from "../../../utils/http/dataProvider";
import { getUserInfo } from "../../../utils/redux/reducers/User";

import "./courseOrderList.scss";
import { courseOrderListColumns } from "./courseOrderListColumns";
import CourseOrderListHeader from "./CourseOrderListHeader";
import CourseOrderDetail from "./CourseOrderDetail";

const i18n = Locale.getInstance();

const CourseOrderList = ({ type }) => {
  const user = useSelector((state) => getUserInfo(state));
  const history = useHistory();
  const [keyword, setKeyword] = useState();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const { data, error, isLoading } = useQuery(
    ["courses-orders", user.token],
    () =>
      getList("course/orders", {
        pagination: {
          perPage: 10,
          page,
        },
        sort: {
          field: "id",
          order: "desc",
        },
        filter: {
          keyword,
          type: type,
        },
        token: user.token,
      })
  );
  if (error) {
    showNotificationWithIcon(error.data.message);
  }

  const breadCrumbData = [
    {
      href: "/main/courses",
      title: i18n.t("course.title"),
    },
    {
      href: "/main/course/orders",
      title: i18n.t("course.order.title"),
    },
  ];
  return (
    <>
      <BreadCrumb data={breadCrumbData} />
      <Spin spinning={loading || isLoading}>
        <CourseOrderListHeader
          keyword={keyword}
          setKeyword={setKeyword}
          type={type}
        />
        <Table
          className="list"
          rowKey="id"
          columns={courseOrderListColumns(history)}
          dataSource={data?.data}
          scroll={{
            y: 520,
          }}
          pagination={{ onChange: handleChangePage }}
        />
      </Spin>
    </>
  );
};

const CourseOrderListCompact = () => {
  return (
    <>
      <Route exact path="/main/course/orders" component={CourseOrderList} />
      <Route path="/main/course/orders/detail" component={CourseOrderDetail} />
    </>
  );
};

export default CourseOrderListCompact;
