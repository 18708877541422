import React from "react";

import {
  Layout,
  DatePicker,
  Checkbox,
  Divider,
  Progress,
  Pagination,
  Spin,
  Button,
  Space,
} from "antd";

import Base from "../Base";

//redux
import { connect } from "react-redux";
import { getUserInfo } from "../../utils/redux/reducers/User";

class FinanceCheck extends Base {
  static Tabs = [{ key: "settled", name: "financeHome.listTitle" }];

  /* lifcyle methods */

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,

      currentTab: this.getRouterQueryItemByKey(FinanceCheck.Tabs, "tab"),

      rangeTime: this.getDefaultTimeRange(),
      isAllTimeChecked:
        this.getRouterQueryValueByKey("allTime", Base.QueryValueType.Boolean) ||
        false,

      checkedData: null,
      loadingCheckedData: false,
      currentPage: this.getRouterQueryPage(),
      currentPageSize: this.getRouterQueryPageSize(1),

      noneCheckData: null,
      loadingNoneCheck: false,
    };
  }

  componentDidMount() {
    super.componentDidMount();

    if (this.getUser()) {
      this.loadData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);

    if (
      prevState.currentTab.key !== this.state.currentTab.key ||
      prevState.rangeTime[0].diff(this.state.rangeTime[0]) !== 0 ||
      prevState.rangeTime[1].diff(this.state.rangeTime[1]) !== 0 ||
      prevState.currentPage !== this.state.currentPage ||
      prevState.currentPageSize !== this.state.currentPageSize ||
      prevState.isAllTimeChecked !== this.state.isAllTimeChecked
    ) {
      this.loadData();

      this.setRouterQuery({
        tab: this.state.currentTab.key,
        timeRange: `${this.state.rangeTime[0].format(
          "YYYY-MM-DD"
        )}~${this.state.rangeTime[1].format("YYYY-MM-DD")}`,
        allTime: `${this.state.isAllTimeChecked}`,
        page: this.state.currentPage,
        pageSize: this.state.currentPageSize,
      });
    }
  }

  /* render methods */

  render() {
    return (
      <Layout id="route-finance-check">
        {this._renderHeaderTabs()}
        {this._renderHeaderFilter()}
        {this._renderListHeader()}
        {this._renderListGroups()}
        {this._renderListPagination()}
      </Layout>
    );
  }

  _renderHeaderTabs() {
    return (
      <div className="header-tabs">
        {FinanceCheck.Tabs.map((tab, index) => {
          let className =
            tab.key === this.state.currentTab.key ? "primary" : "default";
          return (
            <Button
              key={`tab-${index}`}
              type={className}
              onClick={() => this.onHeaderTabClick(tab)}
            >
              {this.i18n.t(tab.name)}
            </Button>
          );
        })}
      </div>
    );
  }

  _renderHeaderFilter() {
    if (this.isCurretnTabUnsettled()) {
      return null;
    }

    return (
      <div className="header-filters">
        <div className="filter-row">
          <span className="label">
            {this.i18n.t("financeCheck.selectTime")}
          </span>
          <DatePicker.RangePicker
            defaultValue={this.state.rangeTime}
            format="YYYY-MM-DD"
            allowClear={false}
            disabled={this.state.isAllTimeChecked}
            onChange={this.handleFiltersTimeChange}
          />
          <Checkbox
            className="date-all"
            checked={this.state.isAllTimeChecked}
            onChange={this.onAllTimeCheckChange}
          >
            {this.i18n.t("financeCheck.allTime")}
          </Checkbox>
        </div>
      </div>
    );
  }

  _renderListHeader() {
    return (
      <div className="finance-bill-list-header margin-top">
        <div className="date">{this.i18n.t("financeHome.listHeaderDate")}</div>
        <div className="sum">{this.i18n.t("financeHome.listHeaderSum")}</div>
        <div className="progress"></div>
        <div className="actions">
          {this.i18n.t("financeHome.listHeaderActions")}
        </div>
        <div className="details">
          {this.i18n.t("financeHome.listHeaderDetails")}
        </div>
      </div>
    );
  }

  _renderListGroups() {
    let data = null;
    if (this.isCurretnTabUnsettled()) {
      data = this.state.noneCheckData;
    } else {
      data = this.state.checkedData;
    }

    if (data) {
      let groups = null;
      if (this.isCurretnTabUnsettled()) {
        groups = data.record ? [data.record] : null;
      } else {
        let records = data.records;
        groups = records ? records.data : null;
      }

      if (groups && groups.length > 0) {
        return (
          <Spin
            spinning={
              this.state.loadingCheckedData || this.state.loadingNoneCheck
            }
          >
            <div className="list-items">
              {groups.map((group, index) => {
                let className = "finance-bill-list-group";
                if (this.isCurretnTabUnsettled()) {
                  className += " unsettled";
                }

                return (
                  <div key={`list-group-${index}`} className={className}>
                    {this._renderListGroupItems(group, index)}
                    {this._renderListGroupDetails(group, index)}
                  </div>
                );
              })}
            </div>
          </Spin>
        );
      } else {
        return (
          <div className="list-items">
            <div className="list-message">
              {this.i18n.t("financeHome.loadingEmpty")}
            </div>
          </div>
        );
      }
    } else {
      let isLoading =
        (this.isCurretnTabUnsettled() && this.state.loadingNoneCheck) ||
        (!this.isCurretnTabUnsettled() && this.state.loadingCheckedData);
      if (isLoading) {
        return (
          <div className="list-items">
            <div className="list-message">
              {this.i18n.t("financeHome.loadingData")}
            </div>
          </div>
        );
      }

      return (
        <div className="list-items">
          <div className="list-message">
            {this.i18n.t("financeHome.loadingEmpty")}
          </div>
        </div>
      );
    }
  }

  _renderListGroupItems(group, index, unsettled) {
    return (
      <div className="group-items">
        <div key={`group-${index}-item-${index}`} className="group-item">
          <div className="date">
            {this.moment(group.checkout_date).format("YYYY-MM-DD")}
          </div>
          <div className="sum">
            <span className="unit">￥</span>
            <span className="total">
              {this.util.getFormattedPrice(group.total_merchant_income)}
              {group.succeeded_amount && (
                <span className="succeeded">
                  ({this.i18n.t("financeOrderDetail.detailTotal")}:
                  {this.util.getFormattedPrice(group.succeeded_amount)})
                </span>
              )}
            </span>
          </div>
          <div className="progress">
            <Progress
              percent={this.getPricePercent(group.total_merchant_income)}
              showInfo={false}
            />
          </div>
          <Space direction="horizontal" className="actions">
            <Button
              style={{
                borderWidth: 0,
                backgroundColor: "transparent",
                boxShadow: "none",
              }}
              onClick={() => this.onDailyBillView(group, group, unsettled)}
            >
              {this.i18n.t("financeHome.listItemActionDetails")}
            </Button>
            <Divider type="vertical" />
            <Button
              style={{
                borderWidth: 0,
                backgroundColor: "transparent",
                boxShadow: "none",
              }}
              onClick={() => this.onDailyBillDownload(group, group, unsettled)}
            >
              {this.i18n.t("financeHome.listItemActionDownload")}
            </Button>
          </Space>
        </div>
      </div>
    );
  }

  _renderListGroupDetails(group, index) {
    let detailInfo = this.i18n.t("financeHome.listGroupInfo");
    if (this.isCurretnTabUnsettled()) {
      detailInfo = this.i18n.t("financeHome.listGroupInfoUnsettled", {
        sign_date: group.sign_date,
      });
    }

    return (
      <div className="group-details">
        <span className="sum">
          <span className="unit">￥</span>
          <span className="total">
            {this.util.getFormattedPrice(group.total_merchant_income)}
          </span>
        </span>
        <Button
          className="info"
          onClick={() => this.onGroupBillInfoClick(group)}
          style={{ borderWidth: 0, boxShadow: "none" }}
        >
          {detailInfo}
        </Button>
      </div>
    );
  }

  _renderListPagination() {
    if (this.isCurretnTabUnsettled()) {
      return null;
    }

    let data = this.state.checkedData;
    let records = data ? data.records : null;
    if (records && records.data && records.data.length > 0) {
      return (
        <div className="list-pagination">
          <Pagination
            total={records.total}
            current={this.state.currentPage}
            showSizeChanger={true}
            pageSizeOptions={["1", "2", "3", "4", "5"]}
            pageSize={this.state.currentPageSize}
            onChange={this.onPaginationPageChange}
            onShowSizeChange={this.onPaginationPageSizeChange}
            showQuickJumper
          />
        </div>
      );
    } else {
      return null;
    }
  }

  /* user methods */

  onHeaderTabClick(tab) {
    this.setState({ currentTab: tab });
  }

  handleFiltersTimeChange = (dates) => {
    this.setState({ rangeTime: dates, currentPage: 1 });
  };

  onAllTimeCheckChange = (e) => {
    this.setState({ isAllTimeChecked: e.target.checked, currentPage: 1 });
  };

  onDailyBillView = (group, item) => {
    this.props.history.push({
      pathname: "/main/finance/check-daily",
      state: {
        data: {
          ...item,
          sign_date: group.sign_date,
          is_signed: !this.isCurretnTabUnsettled(),
        },
      },
    });
  };

  onDailyBillDownload = (group, item) => {
    this.props.history.push({
      pathname: "/main/finance/download",
      query: {
        timeType: "custom",
        timeRange: `${this.moment(group.start_date).format(
          "YYYY-MM-DD"
        )}~${this.moment(group.end_date).format("YYYY-MM-DD")}`,
      },
    });
  };

  onGroupBillInfoClick = (group) => {
    if (this.isCurretnTabUnsettled()) return;

    this.props.history.push({
      pathname: "/main/finance/account",
    });
  };

  onPaginationPageChange = (page, pageSize) => {
    this.setState({ currentPage: page });
  };

  onPaginationPageSizeChange = (page, pageSize) => {
    this.setState({ currentPageSize: pageSize, currentPage: 1 });
  };

  /* server methods */

  async loadNoneCheckData() {
    if (this.state.loadingNoneCheck) return;

    let token = this.getUserToken();
    if (!token) return;

    let api = this.api.checkout.nonCheckout;
    let apiConfig = {
      token: token,
    };

    this.setState({ loadingNoneCheck: true });
    try {
      let res = await api.run(apiConfig);

      if (!this.mounted) return;

      if (res.result) {
        this.setState({ noneCheckData: res.data });
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;
      this.setState({ loadingNoneCheck: false });
    }
  }

  async loadCheckedData() {
    if (this.state.loadingCheckedData) return;

    let token = this.getUserToken();
    if (!token) return;

    let api = this.api.checkout.checkout;
    let apiConfig = {
      token: token,
      query: {
        with_record_details: 1,
        page: this.state.currentPage,
        count: 10,
      },
    };

    if (!this.state.isAllTimeChecked) {
      apiConfig.query["filter[start_date]"] =
        this.state.rangeTime[0].format("YYYY-MM-DD");
      apiConfig.query["filter[end_date]"] =
        this.state.rangeTime[1].format("YYYY-MM-DD");
    }

    this.setState({ loadingCheckedData: true });
    try {
      let res = await api.run(apiConfig);

      if (!this.mounted) return;

      if (res.result) {
        this.setState({ checkedData: res.data });
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;
      this.setState({ loadingCheckedData: false });
    }
  }

  /* custom methods */

  getDefaultTimeRange() {
    let rangeTime = [this.moment().subtract(6, "month"), this.moment()];

    let queryTime = this.getRouterQueryTimeRange();
    if (queryTime) {
      rangeTime = queryTime;
    }

    return rangeTime;
  }

  isCurretnTabUnsettled() {
    return false;
  }

  loadData() {
    if (this.isCurretnTabUnsettled()) {
      this.showMessage(
        this.i18n.t("financeCheck.loadingUnsettledData"),
        Base.Message.loading
      );
      this.loadNoneCheckData();
    } else {
      this.showMessage(
        this.i18n.t("financeCheck.loadingSettledData"),
        Base.Message.loading
      );
      this.loadCheckedData();
    }
  }

  getPricePercent(price = 0) {
    let max = 6000;

    let data = null;
    if (this.isCurretnTabUnsettled()) {
      data = this.state.noneCheckData;
    } else {
      data = this.state.checkedData;
    }
    if (data && data.max_income_amount) {
      max = data.max_income_amount / 100;
    }

    price = this.util.getPrice(price);
    let ratio = (price / max) * 100;

    if (ratio >= 100) {
      ratio = 100;
    }

    if (ratio < 1) {
      ratio = 1;
    }

    return ratio;
  }
}

export default connect((state) => {
  return {
    user: getUserInfo(state),
  };
}, {})(FinanceCheck);
