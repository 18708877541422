import { Route } from "react-router-dom";
import CourseList from "./CourseList";
import { TYPE_VIDEO } from "./courseTypes";
import { BreadCrumb } from "../../../components/main/LayoutComponents";
import { Locale } from "../../../utils";
import CourseStudentList from "../students/StudentList";
import LessonList from "../lessons/LessonList";
import AddOrEditCourse from "./AddOrEditCourse";
const i18n = Locale.getInstance();

const VideoCourseList = () => {
  const breadCrumbData = [
    {
      href: "/main/courses/video-courses",
      title: i18n.t("course.titleVideo"),
    },
  ];
  return (
    <div className="course">
      <BreadCrumb data={breadCrumbData} />
      <CourseList type={TYPE_VIDEO} />
    </div>
  );
};

const CourseListCompact = ({ match }) => {
  return (
    <>
      <Route exact path={`${match.path}`} component={VideoCourseList} />
      <Route path={`${match.path}/students`} component={CourseStudentList} />
      <Route path={`${match.path}/edit`} component={AddOrEditCourse} />
      <Route path={`${match.path}/lessons`} component={LessonList} />
    </>
  );
};

export default CourseListCompact;
