import React, { useEffect } from "react";

import { Modal, Input, Form, Button, InputNumber, Switch } from "antd";

import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Locale, Util } from "../../../utils";
import TranslateBtn from "../../../components/product/TranslateBtn";
import LinkSelector from "../../../components/linkSelector/LinkSelector";
import ImageUpload from "../../../components/media/ImageUpload";

const i18n = Locale.getInstance();
const util = Util.getInstance();

const EditNavForm = ({ navData, onCancel, onFinish, loading }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (navData && Object.keys(navData).length > 0) {
      const { zh_name, ug_name, order, image, status, link_data } = navData;
      const fields = {
        zh_name,
        ug_name,
        order: !Number.isNaN(Number(order)) ? Number(order) : 0,
        status: status === 1,
        image: util.getDefaultImages(image),
        data: link_data,
      };
      form.setFieldsValue(fields);
    }
    return () => {
      form.resetFields();
    };
  }, [navData, form]);

  return (
    <Modal
      title={i18n.t("sales.nav.title")}
      open={!!navData}
      onCancel={onCancel}
      footer={null}
      forceRender
    >
      <Form labelCol={{ span: 6 }} form={form} onFinish={onFinish}>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldsValue }) => (
            <Form.Item
              label={i18n.t("sales.zhTitle")}
              name="zh_name"
              rules={[
                { required: true, message: i18n.t("sales.zhTitleWarning") },
              ]}
            >
              <Input
                addonAfter={
                  <TranslateBtn
                    context={getFieldValue("zh_name")}
                    onFinish={(value) =>
                      setFieldsValue({
                        ug_name: value,
                      })
                    }
                    from="zh"
                    to="ug"
                  />
                }
              />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldsValue }) => (
            <Form.Item
              label={i18n.t("sales.ugTitle")}
              name="ug_name"
              rules={[
                { required: true, message: i18n.t("sales.ugTitleWarning") },
              ]}
            >
              <Input
                addonAfter={
                  <TranslateBtn
                    context={getFieldValue("ug_name")}
                    onFinish={(value) =>
                      setFieldsValue({
                        zh_name: value,
                      })
                    }
                    from="ug"
                    to="zh"
                  />
                }
              />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item label={i18n.t("sales.nav.order")} name="order">
          <InputNumber
            style={{ width: "100%" }}
            max={99999}
            placeholder={i18n.t("sales.nav.orderPlace")}
          />
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldsValue }) => (
            <Form.Item name="data" shouldUpdate label={i18n.t("sales.ad.type")}>
              <LinkSelector
                selectedLink={navData?.link_data ?? false}
                setSelectedLink={(link) => {
                  setFieldsValue({
                    data: link,
                  });
                }}
              />
            </Form.Item>
          )}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue, setFieldsValue }) => (
            <Form.Item
              name="image"
              label={i18n.t("sales.nav.img")}
              rules={[
                { required: true, message: i18n.t("sales.nav.imgWarning") },
              ]}
              extra={i18n.t("sales.nav.imgTip")}
            >
              <ImageUpload
                images={getFieldValue("image")}
                setSelectedImages={(image) => {
                  setFieldsValue({
                    image,
                  });
                }}
              />
            </Form.Item>
          )}
        </Form.Item>

        <Form.Item
          name="status"
          valuePropName="checked"
          label={i18n.t("sales.status")}
        >
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 10 }}>
          <Button
            disabled={loading}
            loading={loading}
            type="primary"
            htmlType="submit"
          >
            {i18n.t("sales.submit")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditNavForm;
