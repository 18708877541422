import { UserSwitchOutlined } from "@ant-design/icons";
import { Avatar, Button } from "antd";
import { Locale } from "../../utils";

const i18n = Locale.getInstance();
const SelectedUser = ({ user, setSelectedUser }) => {
  return (
    <div className="selcted-user">
      <div className="user-info">
        <Avatar src={user.avatar} shape="square" size="large" />
        {user.mobile}
        <Button
          icon={<UserSwitchOutlined />}
          size="small"
          onClick={() => {
            setSelectedUser();
          }}
        >
          {i18n.t("member.reselect")}
        </Button>
      </div>
    </div>
  );
};
export default SelectedUser;
