import {
  Modal,
  Table,
  message,
  Button,
  Layout,
  Divider,
  Breadcrumb,
  Space,
  Form,
  DatePicker,
  Avatar,
  InputNumber,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import moment from "dayjs";
import { useSelector } from "react-redux";
import { ApiManager, Locale } from "../../utils";
import { getUserInfo } from "../../utils/redux/reducers/User";
import Base from "../Base";
import { DeleteOutlined, LeftOutlined } from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom";

const i18n = Locale.getInstance();
const api = ApiManager.getInstance();
const base = Base.getInstance();

const LotteryPrePrize = () => {
  const router = useHistory();
  const location = useLocation();
  const { state } = location;
  const onGetPreList = useRef();
  const [preList, setPreList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => getUserInfo(state));

  const onDeleteItem = (id) => {
    base.showModal(
      i18n.t("sales.deleteWarning"),
      Base.Modal.confirm,
      async () => {
        const apiUrl = api.lottery.preDelete;
        try {
          const token = user.token;
          if (token) {
            const apiConfig = {
              token,
              path: {
                id,
              },
            };
            const res = await apiUrl.run(apiConfig);
            if (res.result) {
              onGetPreList.current();
            } else {
              base.showMessage(res.message, Base.Message.warning);
            }
          }
        } catch (error) {
          base.handleApiError(api, error);
        } finally {
          setLoading(false);
        }
      }
    );
  };

  const columns = [
    {
      title: i18n.t("qrcode.onHowManyTimes"),
      dataIndex: "on_how_many_times",
      key: "on_how_many_times",
    },
    {
      title: i18n.t("lottery.drew_at"),
      dataIndex: "draw_date",
      key: "draw_date",
    },
    {
      title: i18n.t("users.date"),
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: i18n.t("financeAccount.contactTypePersonInCharge"),
      dataIndex: "creator",
      key: "creator",
      render: (text, record) => (
        <Space>
          <Avatar src={record?.creator?.avatar} />
          <a>{record?.creator?.nickname}</a>
        </Space>
      ),
    },
    {
      title: i18n.t("redPacket.user"),
      dataIndex: "winner",
      key: "winner",
      render: (text, record) => (
        <div>
          <Space>
            <Avatar src={record?.winner?.avatar} />
            <a>{record?.winner?.nickname}</a>
          </Space>
          <div>{record.drew_at}</div>
        </div>
      ),
    },
    {
      title: "",
      key: "action",
      render: (record) => (
        <Space>
          <Button
            onClick={() => onDeleteItem(record.id)}
            size="small"
            type="danger"
            icon={<DeleteOutlined />}
          >
            {i18n.t("proxy.listItemDelete")}
          </Button>
        </Space>
      ),
    },
  ];

  onGetPreList.current = debounce(async (page = 1) => {
    let prizeApi = api.lottery.preList;
    if (user && user.token) {
      const query = {
        page,
        count: 10,
      };
      const apiConfig = {
        token: user.token,
        query,
      };
      if (state.prizeId) {
        apiConfig.path = { id: state.prizeId };
      }
      try {
        const res = await prizeApi.run(apiConfig);
        if (res.result) {
          setPreList(res.data);
        } else {
          message.warning(res.message);
        }
      } catch (error) {
        console.log("loadProducts -> error", error);
      } finally {
      }
    }
  }, 500);

  const onAddPrePrize = () => {
    setVisible(true);
  };

  const getFormattedDateString = (date) => {
    if (moment.isDayjs(date)) {
      return date.format("YYYY-MM-DD HH:mm:ss");
    }
    if (typeof date === "string") {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    }
    return null;
  };

  const onFinish = async (values) => {
    if (user.token) {
      const date = getFormattedDateString(values.drew_at);
      const onHowManyTimes = values.on_how_many_times;
      const preUrl = api.lottery.preAdd;
      const apiConfig = {
        token: user.token,
        data: {
          draw_date: date,
          on_how_many_times: onHowManyTimes,
          prize_id: state.prizeId,
        },
        path: { id: state.prizeId },
      };

      try {
        setLoading(true);
        const res = await preUrl.run(apiConfig);
        if (res.result) {
          message.success(res.message);
          onGetPreList.current();
        } else {
          message.warning(res.message);
        }
      } catch (error) {
        base.handleApiError(preUrl, error);
      } finally {
        setLoading(false);
        setVisible(false);
      }
    } else {
      base.showModal(i18n.t("lottery.empty"), Base.Modal.warning);
    }
  };

  useEffect(() => {
    onGetPreList.current();
  }, []);

  return (
    <>
      <Layout id="route-product-edit">
        <Layout.Header className="edit-header">
          <Button
            type="link"
            className="back"
            onClick={() => {
              router.goBack();
            }}
          >
            <LeftOutlined />
            {i18n.t("productEdit.headerBack")}
          </Button>
          <Divider type="vertical" />

          <Breadcrumb className="back">
            <Breadcrumb.Item href="/main/lottery/list">
              {i18n.t("main.sideMenus.lotteryList")}
            </Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => router.goBack()}>
              {i18n.getThemeNameByLocale(state, "prename")}
            </Breadcrumb.Item>
            <Breadcrumb.Item href="">
              {i18n.getThemeNameByLocale(state, "name")}{" "}
              {"（" + state.prizeId + "）"}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Layout.Header>
        <Space style={{ direction: "rtl" }} className="edit-header">
          <Button type="primary" onClick={() => onAddPrePrize()}>
            {i18n.t("lottery.addPrePrize")}
          </Button>
        </Space>
      </Layout>
      <Table columns={columns} dataSource={preList} />
      <Modal
        title={i18n.t("lottery.addPrePrize")}
        centered
        open={visible}
        footer={null}
        onCancel={() => setVisible(false)}
        width={800}
      >
        <Form onFinish={onFinish}>
          <Form.Item label={i18n.t("lottery.drew_at")} name="drew_at">
            <DatePicker format="YYYY-MM-DD" />
          </Form.Item>
          <Form.Item
            label={i18n.t("qrcode.onHowManyTimes")}
            name="on_how_many_times"
          >
            <InputNumber />
          </Form.Item>
          <Form.Item>
            <Button loading={loading} type="primary" htmlType="submit">
              {i18n.t("sales.submit")}
            </Button>

            <Button
              style={{ marginLeft: 10, marginRight: 10 }}
              onClick={() => setVisible(false)}
            >
              {i18n.t("common.cancelText")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default LotteryPrePrize;
