import React from "react";

import {
  LeftOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";

import {
  Layout,
  Divider,
  Row,
  Col,
  DatePicker,
  Input,
  Button,
  Checkbox,
  TimePicker,
} from "antd";

import Base from "../Base";

class PromotionDemandReduce extends Base {
  static Days = [
    { label: "周一", value: "1" },
    { label: "周二", value: "2" },
    { label: "周三", value: "3" },
    { label: "周四", value: "4" },
    { label: "周五", value: "5" },
    { label: "周六", value: "6" },
    { label: "周日", value: "7" },
  ];

  /* lifecycle methods */

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,

      rangeTime: [this.moment().subtract(7, "days"), this.moment()],
    };
  }

  /* render methods */

  render() {
    return (
      <Layout id="route-promotion-demand-reduce">
        {this._renderHeader()}
        {this._renderContent()}
        {this._renderFooter()}
      </Layout>
    );
  }

  _renderHeader() {
    return (
      <div className="header-wrap">
        <Button
          style={{ backgroundColor: "transparent", borderWidth: 0 }}
          className="back"
          onClick={this.onHeaderBackClick}
        >
          <LeftOutlined />
          返回
        </Button>
        <Divider type="vertical" />
        <span className="title">满减活动设置</span>
      </div>
    );
  }

  _renderContent() {
    return (
      <div className="content-wrap">
        {this._renderContentTime()}
        {this._renderContentDays()}
        {this._renderContentDayTimes()}
        {this._renderContentDiscount()}
      </div>
    );
  }

  _renderContentTime() {
    return (
      <Row gutter={16}>
        <Col span={6}>活动时间：</Col>
        <Col span={18}>
          <DatePicker.RangePicker
            defaultValue={this.state.rangeTime}
            format="YYYY-MM-DD"
            allowClear={false}
            onChange={this.handleTimeChange}
          />
        </Col>
      </Row>
    );
  }

  _renderContentDays() {
    return (
      <Row gutter={16}>
        <Col span={6}>循环周期：</Col>
        <Col span={18}>
          <Checkbox.Group
            options={PromotionDemandReduce.Days}
            defaultValue={["1", "2", "3", "4", "5", "6", "7"]}
          />
        </Col>
      </Row>
    );
  }

  _renderContentDayTimes() {
    return (
      <Row gutter={16}>
        <Col span={6}>周期时间段：</Col>
        <Col span={18}>
          {[1, 2, 3].map((item, index) => {
            return (
              <div key={`day-times-item-${index}`} className="day-times-item">
                <TimePicker format="HH:mm" placeholder="00:00" />
                <span className="text-divider">至</span>
                <TimePicker format="HH:mm" placeholder="23:59" />
                <PlusCircleOutlined className="disabled" />
                <MinusCircleOutlined />
              </div>
            );
          })}
        </Col>
      </Row>
    );
  }

  _renderContentDiscount() {
    return (
      <Row gutter={16} className="discount">
        <Col span={6}>满减优惠：</Col>
        <Col span={18}>
          {[1, 2, 3, 4, 5].map((item, index) => {
            return (
              <div key={`discount-item-${index}`} className="discount-item">
                <span className="input-label">满</span>
                <Input placeholder="0" />
                <span className="money-unit">元</span>
                <span className="input-label">减</span>
                <Input placeholder="0" />
                <span className="money-unit">元</span>
                <PlusCircleOutlined className="disabled" />
                <MinusCircleOutlined />
              </div>
            );
          })}
        </Col>
      </Row>
    );
  }

  _renderFooter() {
    return (
      <div className="footer-wrap">
        <Button type="primary" onClick={this.onFooterConfirmClick}>
          确定
        </Button>
        <Button onClick={this.onFooterCancelClick}>取消</Button>
      </div>
    );
  }

  /* user methods */

  onHeaderBackClick = () => {
    this.props.history.goBack();
  };

  handleFiltersTimeChange = (dates) => {
    this.setState({ rangeTime: dates });
  };

  onFooterConfirmClick = () => {};

  onFooterCancelClick = () => {
    this.props.history.goBack();
  };
}

export default PromotionDemandReduce;
