import React, { useState, useCallback, useEffect } from "react";
import {
  List,
  Card,
  Button,
  Row,
  Col,
  Slider,
  InputNumber,
  Space,
  Typography,
  Divider,
  Input,
  Modal,
  message,
  Form,
} from "antd";
import { Locale, ApiManager } from "../../utils";
import {
  EditOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import { getUserInfo } from "../../utils/redux/reducers/User";
import TranslateBtn from "../../components/product/TranslateBtn";
import { getMerchantSettings } from "../../utils/redux/reducers/Merchant";
import LinkSelector from "../../components/linkSelector/LinkSelector";

const { Text } = Typography;
const i18n = Locale.getInstance();
const api = ApiManager.getInstance();

function formatter(value) {
  return `${value}%`;
}

function AgentLevel({ user, setting }) {
  const [isEditing, setIsEditing] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [listData, setListData] = useState([]);
  const [addForm] = Form.useForm();
  const [commissionForm] = Form.useForm();

  const fetchList = useCallback(async () => {
    const { token } = user;
    if (token) {
      const apiUrl = api.agent.list;
      const apiConfig = {
        token,
      };
      try {
        const res = await apiUrl.run(apiConfig);
        if (res.result) {
          setListData(res.data);
        } else {
          message.warning(res.message);
        }
      } catch (error) {
        console.log("AgentLevel -> error", error);
        message.error(error.message);
      }
    }
  }, [user]);

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  useEffect(() => {
    commissionForm && commissionForm.resetFields();
    if (!isAdding) {
      addForm.resetFields();
    }
    return () => {
      addForm.resetFields();
    };
  }, [isAdding, addForm, isEditing, commissionForm]);

  const onAddNewLevel = useCallback(() => {
    addForm.validateFields().then(async (values) => {
      const { token } = user;
      if (token) {
        const apiUrl = api.agent.add;
        const apiConfig = {
          token,
          data: values,
        };
        try {
          const res = await apiUrl.run(apiConfig);
          if (res.result) {
            setIsAdding(false);
            fetchList();
          } else {
            message.warning(res.message);
          }
        } catch (error) {
          console.log("AgentLevel -> error", error);
          message.error(error.message);
        }
      }
    });
  }, [user, fetchList, addForm]);

  const onDeleteLevel = useCallback(
    async (id) => {
      const { token } = user;
      if (token) {
        Modal.confirm({
          title: i18n.t("sales.deleteWarning"),
          okText: i18n.t("base.modal.okText"),
          cancelText: i18n.t("base.modal.cancelText"),
          onOk: async () => {
            try {
              const apiUrl = api.agent.delete;
              const apiConfig = {
                token,
                path: { levelId: id },
              };
              const res = await apiUrl.run(apiConfig);
              if (res.message) {
                fetchList();
              } else {
                message.warning(res.message);
              }
            } catch (error) {
              message.error(error.message);
            }
          },
        });
      }
    },
    [fetchList, user]
  );

  const onEditLevel = useCallback(
    async (values) => {
      const { token } = user;
      if (token) {
        try {
          const apiUrl = api.agent.update;
          const apiConfig = {
            path: { levelId: isEditing },
            token,
            data: {
              zh_name: values.zh_name,
              ug_name: values.ug_name,
              product_id: values.product_id,
              commission_percent: values.commission_percent,
              levels: Object.keys(values)
                .filter(
                  (key) =>
                    typeof key === "string" &&
                    key.indexOf(`${isEditing}-level-`) !== -1
                )
                .map((levelKey) => ({
                  level_id: levelKey.split(`${isEditing}-level-`)[1],
                  commission_percent: values[levelKey],
                })),
            },
          };
          const res = await apiUrl.run(apiConfig);
          if (res.result) {
            message.success(res.message);
            setIsEditing(null);
            fetchList();
          } else {
            message.warning(res.message);
          }
        } catch (error) {
          message.error(error.message);
        }
      }
    },
    [fetchList, user, isEditing]
  );

  function getDataSource4Levels(item) {
    if (
      Array.isArray(item.level_commissions) &&
      Array.isArray(listData) &&
      listData.length > 0
    ) {
      const parentCommissionIds = item.level_commissions.map((i) => i.level_id);
      const checkIfThereAnyLeft = listData.filter((itm) => {
        return (
          !parentCommissionIds.includes(itm.id) &&
          itm.commission_percent <= item.commission_percent
        );
      });
      const mainList = item.level_commissions
        .concat(checkIfThereAnyLeft)
        .map((i) => ({
          ug_name: i.ug_name,
          zh_name: i.zh_name,
          commission_percent: i.parent_commission_percent || 0,
          product_id: i.product_id,
          level_id: i.level_id || i.id,
        }));
      return mainList;
    }
    return [];
  }

  return (
    <div>
      <List
        grid={{
          gutter: 16,
        }}
        dataSource={listData}
        header={
          <Button type="primary" size="large" onClick={() => setIsAdding(true)}>
            {i18n.t("agentLevel.add")}
          </Button>
        }
        renderItem={(item) => {
          const { link_data } = item;
          return (
            <List.Item key={item.id}>
              <Form onFinish={onEditLevel} form={commissionForm}>
                <Card
                  style={{ width: 400 }}
                  title={
                    <Space direction="horizontal" size={4}>
                      {isEditing === item.id ? (
                        <Form.Item noStyle shouldUpdate>
                          {({ getFieldValue, setFieldsValue }) => (
                            <Form.Item
                              noStyle
                              name="zh_name"
                              initialValue={item.zh_name}
                            >
                              <Input
                                addonAfter={
                                  <TranslateBtn
                                    context={getFieldValue("ug_name")}
                                    onFinish={(value) =>
                                      setFieldsValue({
                                        zh_name: value,
                                      })
                                    }
                                    from="ug"
                                    to="zh"
                                  />
                                }
                              />
                            </Form.Item>
                          )}
                        </Form.Item>
                      ) : (
                        <Text>{item.zh_name}</Text>
                      )}
                      <Divider type="vertical" />
                      {isEditing === item.id ? (
                        <Form.Item noStyle shouldUpdate>
                          {({ getFieldValue, setFieldsValue }) => (
                            <Form.Item
                              noStyle
                              initialValue={item.ug_name}
                              name="ug_name"
                            >
                              <Input
                                addonAfter={
                                  <TranslateBtn
                                    context={getFieldValue("zh_name")}
                                    onFinish={(value) =>
                                      setFieldsValue({
                                        ug_name: value,
                                      })
                                    }
                                    from="zh"
                                    to="ug"
                                  />
                                }
                              />
                            </Form.Item>
                          )}
                        </Form.Item>
                      ) : (
                        <Text style={{ fontFamily: "Alp Ekran" }}>
                          {item.ug_name}
                        </Text>
                      )}
                    </Space>
                  }
                  bordered={false}
                  extra={
                    isEditing === item.id ? (
                      <Form.Item
                        initialValue={item.commission_percent}
                        name="commission_percent"
                        noStyle
                      >
                        <InputNumber min={1} formatter={formatter} />
                      </Form.Item>
                    ) : (
                      `${item.commission_percent}%`
                    )
                  }
                  hoverable
                  actions={
                    isEditing === item.id
                      ? [
                          <Button
                            htmlType="submit"
                            icon={<CheckOutlined />}
                            type="link"
                          />,
                          <Button
                            type="link"
                            onClick={() => {
                              setIsEditing(null);
                              commissionForm.resetFields();
                            }}
                            icon={<CloseOutlined />}
                          />,
                        ]
                      : [
                          <EditOutlined
                            onClick={() => setIsEditing(item.id)}
                          />,
                          <DeleteOutlined
                            onClick={() => onDeleteLevel(item.id)}
                          />,
                        ]
                  }
                >
                  <List
                    dataSource={getDataSource4Levels(item)}
                    itemLayout="vertical"
                    renderItem={(itm, idx) => (
                      <Row
                        style={{ marginBottom: "12px" }}
                        align="center"
                        key={`sub-level-${idx}`}
                      >
                        <Col span={3}>
                          <Text>{i18n.getPropNameByLocale(itm, "name")}</Text>
                        </Col>
                        <Col span={12}>
                          <Slider
                            min={0}
                            max={item.commission_percent}
                            tooltip={{ formatter }}
                            onChange={(value) =>
                              commissionForm.setFieldsValue({
                                [`${item.id}-level-${itm.level_id}`]: value,
                              })
                            }
                            defaultValue={itm.commission_percent}
                            disabled={item.id !== isEditing}
                          />
                        </Col>
                        <Col span={4}>
                          <Form.Item
                            noStyle
                            name={`${item.id}-level-${itm.level_id}`}
                            initialValue={itm.commission_percent}
                          >
                            <InputNumber
                              min={0}
                              max={item.commission_percent}
                              style={{ margin: "0 16px" }}
                              formatter={formatter}
                              disabled={item.id !== isEditing}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                  />
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        label={i18n.t("agentLevel.product")}
                        name="zh_name"
                      >
                        <Form.Item noStyle shouldUpdate>
                          {({ getFieldValue, setFieldsValue }) => (
                            <Form.Item name="product_id" shouldUpdate>
                              <LinkSelector
                                items={["agentLevelProduct"]}
                                selectedLink={item.link_data}
                                setSelectedLink={(link) => {
                                  setFieldsValue({
                                    product_id: link.key,
                                  });
                                }}
                              />
                            </Form.Item>
                          )}
                        </Form.Item>
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Form>
            </List.Item>
          );
        }}
      />
      <Modal
        open={isAdding}
        title={i18n.t("agentLevel.add")}
        onCancel={() => setIsAdding(false)}
        onOk={onAddNewLevel}
        okText={i18n.t("base.modal.okText")}
        cancelText={i18n.t("base.modal.cancelText")}
        forceRender
      >
        <Form form={addForm} labelCol={{ span: 4 }} wrapperCol={{ span: 10 }}>
          <Form.Item label={i18n.t("agentLevel.product")} name="zh_name">
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFieldsValue }) => (
                <Form.Item name="product_id" shouldUpdate>
                  <LinkSelector
                    items={["agentLevelProduct"]}
                    selectedLink={false}
                    setSelectedLink={(link) => {
                      console.log("🚀 ~ AgentLevel ~ link:", link);
                      setFieldsValue({
                        product_id: link.key,
                      });
                    }}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </Form.Item>
          <Form.Item label={i18n.t("agentLevel.zh")} name="zh_name">
            <Input />
          </Form.Item>
          <Form.Item label={i18n.t("agentLevel.ug")} name="ug_name">
            <Input />
          </Form.Item>
          <Form.Item
            label={i18n.t("agentLevel.percent")}
            name="commission_percent"
          >
            <InputNumber formatter={formatter} min={0} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default connect((state) => ({
  user: getUserInfo(state),
  setting: getMerchantSettings(state),
}))(AgentLevel);
