import {  Table } from "antd";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  handleMutationError,
  showNotificationWithIcon,
} from "../../utils/helpers/helpers";
import {  getList, remove } from "../../utils/http/dataProvider";
import { getUserInfo } from "../../utils/redux/reducers/User";
import { BreadCrumb } from "../../components/main/LayoutComponents";

import { redPacketListColumns } from "./redPacketListColumns";
import RedPacketListHeader from "./RedPacketListHeader";
import breadCrumbDefault from "./breadCrumb";


const RedPacketList = () => {
  const user = useSelector((state) => getUserInfo(state));
  const queryClient = useQueryClient();
  const [keyword, setKeyword] = useState();
  const [status, setStatus] = useState();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const { data, error, isLoading } = useQuery(
    ["red-packet", [user.token, keyword, status]],
    () =>
      getList("red-packet", {
        pagination: {
          perPage: 10,
          page,
        },
        sort: {
          field: "id",
          order: "desc",
        },
        filter: {
          keyword,
          status,
        },
        token: user.token,
      })
  );

  const deleteMutation = useMutation(remove, {
    onSuccess: () => {
      queryClient.invalidateQueries("red-packet-detail");
      setLoading(false);
    },
    onError: (error) => {
      setLoading(false);
      handleMutationError(error);
    },
  });

  const handleChangePage = (newPage) => {
    console.log(
      "🚀 ~ file: RedPacketList.jsx:162 ~ handleChangePage ~ newPage:",
      newPage
    );
    setPage(newPage);
  };

  if (error) {
    showNotificationWithIcon(error.data.message);
  }
  return (
    <>
      <BreadCrumb data={breadCrumbDefault} />
      <div className="qrcode">
        <RedPacketListHeader
          keyword={keyword}
          setKeyword={setKeyword}
          status={status}
          setStatus={setStatus}
        />
        <Table
          className="list"
          loading={isLoading || loading}
          rowKey="id"
          dataSource={data?.data}
          columns={redPacketListColumns(history, deleteMutation, setLoading)}
          scroll={{
            x: 1200,
          }}
          pagination={{
            onChange: handleChangePage,
            current: data?.current_page,
            total: data?.total,
            showSizeChanger: false,
            showQuickJumper: false,
          }}
        />
      </div>
      
    </>
  );
};

export default RedPacketList;
