import { Avatar, Select, Spin, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";

import { Locale } from "../../utils";
import { getManyReference } from "../../utils/http/dataProvider";
import { getUserInfo } from "../../utils/redux/reducers/User";
import SelectedUser from "./SelectedUser";
const i18n = Locale.getInstance();

const UserSelector = ({ setFieldsValue, propUser }) => {
  const user = useSelector((state) => getUserInfo(state));
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(false);
  const [fetchingUsers, setFetchingUsers] = useState("");
  const queryClient = useQueryClient();

  useEffect(() => {
    if (propUser && selectedUser.id !== propUser.id) {
      setSelectedUser(propUser);
    }
  }, [propUser]);
  const fetchUsers = async (keyword) => {
    try {
      setFetchingUsers(true);
      const data = await queryClient.fetchQuery("users", () =>
        getManyReference("users", {
          pagination: {
            perPage: 10,
            page: 1,
          },
          sort: {
            field: "id",
            order: "desc",
          },
          filter: {
            name: keyword,
          },
          token: user.token,
        })
      );
      setUsers(data);

      setFetchingUsers(false);
    } catch (error) {
      setFetchingUsers(false);
      console.log(
        "🚀 ~ file: AddOrEditTeacher.jsx ~ line 64 ~ fetchUsers ~ error",
        error
      );
    }
  };

  const onSelectUser = (value) => {
    const selectedUsers = users.data.filter((v) => value === v.id);
    setSelectedUser(selectedUsers[0]);
  };
  if (selectedUser) {
    return (
      <SelectedUser user={selectedUser} setSelectedUser={setSelectedUser} />
    );
  }

  return (
    <>
      <Select
        mode="tags"
        style={{ width: 260 }}
        placeholder={i18n.t("users.select")}
        notFoundContent={fetchingUsers ? <Spin size="small" /> : null}
        filterOption={false}
        showSearch={true}
        onSearch={fetchUsers}
        onSelect={(value) => {
          setFieldsValue({ user_id: `${value}` });
          onSelectUser(value);
        }}
        tagRender={({ closable, onClose, value }) => {
          return (
            <Tag
              closable={closable}
              onClose={onClose}
              style={{ borderWidth: 0 }}
            >
              <Avatar src={value.avatar} shape="square" size="large" />
              {value.mobile}
            </Tag>
          );
        }}
      >
        {users.data && users.data.length > 0
          ? users.data.map((d) => (
              <Select.Option key={d.id} value={d.id}>
                <Avatar src={d.avatar} shape="square" size="large" />
                {d.mobile}
              </Select.Option>
            ))
          : null}
      </Select>
    </>
  );
};

export default UserSelector;
