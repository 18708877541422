import { Button, Space, Spin, Table } from "antd";
import { useState } from "react";

import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Locale } from "../../utils";
import { showNotificationWithIcon } from "../../utils/helpers/helpers";
import { getList } from "../../utils/http/dataProvider";
import { getUserInfo } from "../../utils/redux/reducers/User";

import "./ProductSelector.scss";

const i18n = Locale.getInstance();

const ProductSelector = ({
  setSelectedLink,
  selectedLink,
  agentLevelProduct,
}) => {
  const user = useSelector((state) => getUserInfo(state));
  const [page, setPage] = useState(1);
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };
  const { data, error, isLoading } = useQuery(
    ["products", user.token, agentLevelProduct],
    () =>
      getList("product", {
        pagination: {
          perPage: 10,
          page,
        },
        sort: {
          field: "id",
          order: "desc",
        },
        query: {
          status: 1,
          agentLevelProduct,
        },

        token: user.token,
      })
  );

  if (error) {
    showNotificationWithIcon(error.data.message);
  }

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: i18n.t("product.cats.name"),
      render: (item) => {
        return (
          <>
            <Space align="center" direction="vertical">
              <img
                src={`${item?.images[0]?.url}`}
                alt={i18n.getPropNameByLocale(item, "name")}
                width={60}
                height={60}
              />
              <span>{i18n.getPropNameByLocale(item, "name")}</span>
            </Space>
          </>
        );
      },
    },
    {
      title: i18n.t("users.date"),
      dataIndex: "created_at",
      width: 200,
    },
    {
      title: i18n.t("product.cats.actions"),
      width: 100,
      render: (item) => {
        return (
          <>
            <Button
              type={item.id === selectedLink.key ? "primary" : "default"}
              onClick={() => {
                setSelectedLink({
                  key: item.id,
                  image: item.images[0].url,
                  ug_name: `مەھسۇلات ~${item.ug_name}`,
                  zh_name: `产品~${item.zh_name}`,
                  url: `/pages/product-detail/index?productId=${item.id}`,
                });
              }}
              size="small"
            >
              {item.id === selectedLink.key
                ? i18n.t("link.selected")
                : i18n.t("link.select")}
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Spin spinning={isLoading}>
        <div className="products">
          <Table
            className="list"
            rowKey="id"
            columns={columns}
            dataSource={data?.data}
            pagination={{ onChange: handleChangePage }}
            scroll={{
              y: 320,
            }}
          />
        </div>
      </Spin>
    </>
  );
};

export default ProductSelector;
