import React from "react";

import {
  CaretDownOutlined,
  EnvironmentOutlined,
  QuestionCircleOutlined,
  UpOutlined,
  WechatFilled,
  MoneyCollectFilled,
  DownOutlined,
} from "@ant-design/icons";

import {
  Divider,
  Badge,
  Button,
  Popover,
  Steps,
  Collapse,
  Typography,
  Image,
  Space,
} from "antd";
import Base from "../Base";

const { Text } = Typography;

export default class RefundListItem extends Base {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true,
    };
  }

  render() {
    if (!this.props.order) return null;

    return (
      <div className="col-box orders" id={`orderItem${this.props.order.id}`}>
        {this._renderHeader()}
        {this._renderContainer()}
        {this._renderFooter()}
      </div>
    );
  }

  _renderHeader() {
    let order = this.props.order;
    let orderUser = order.user;

    return (
      <div className="box-header">
        <span className="num">
          <span>#</span>
          {order.sequence}
        </span>
        <div className="info">
          {!this.isBBShopMerchant() ? null : (
            <span className="text warning">
              {this.i18n.t("order.user", {
                user: `${orderUser.nickname} ${orderUser.mobile}`,
              })}
            </span>
          )}
          {order.is_from_bulutbazar === 1 && <Divider type="vertical" />}
          {order.is_from_bulutbazar === 1 && (
            <span className="text">{this.i18n.t("order.isFromBB")}</span>
          )}
          {order.is_douyin_order === 1 && <Divider type="vertical" />}
          {order.is_douyin_order === 1 && (
            <span className="text">{this.i18n.t("order.isDouyin")}</span>
          )}
        </div>
        <span className="status">{this.props.order.refund_status_label}</span>
      </div>
    );
  }

  _renderContainer() {
    return (
      <div className="box-container">
        {this._renderReceiver()}
        {this._renderDeliverman()}
        {this._renderProducts()}
      </div>
    );
  }

  _renderReceiver() {
    const { order } = this.props;
    let text = "";
    let color;
    if (order?.self_order === 1) {
      text = this.i18n.t("order.eat");
      color = "red";
    }
    if (order?.self_order === 2) {
      text = this.i18n.t("order.take");
      color = "green";
    }
    let insideCom = (
      <div className="info-row receiver">
        <div className="info">
          <span className="name">
            {this.i18n.getPropNameByLocale(order, "receiver")}
          </span>
        </div>
        <div className="contact">
          <span className="phone">{order.tel}</span>
        </div>
        <div className="address">
          <span className="text">
            {this.isBBShopMerchant()
              ? this.i18n.getPropNameByLocale(order, "address")
              : `
          ${this.i18n.getPropNameByLocale(order.community, "name")}
          (
            ${this.i18n.getPropNameByLocale(order.city, "name")}
            ${this.i18n.getPropNameByLocale(order.region, "name")}
            ${this.i18n.getPropNameByLocale(order.street, "name")}
          )
        `}
          </span>
          <span className="location">
            <EnvironmentOutlined />
            <span>{` > ${order.distance}km`}</span>
          </span>
        </div>
      </div>
    );
    if (Number(order?.self_order) > 0) {
      return (
        <Badge.Ribbon text={text} color={color}>
          {insideCom}
        </Badge.Ribbon>
      );
    }
    return insideCom;
  }

  _renderDeliverman() {
    return (
      <div>
        <div className="info-row deliver noprint">
          <div className="info">
            {this._renderDelivermanInfo()}
            {this._renderDelivermanOrderHistory()}
          </div>
          {this._renderDelivermanActions()}
        </div>
      </div>
    );
  }

  _renderDelivermanInfo() {
    let devliverman = this.props.order.deliveryman;
    if (devliverman) {
      return (
        <div className="inner-row">
          <span className="name">
            {this.i18n.getPropNameByLocale(devliverman, "name")}
          </span>
          <span className="phone">{devliverman.mobile}</span>
        </div>
      );
    }

    return null;
  }

  _renderDelivermanOrderHistory() {
    let placement = this.i18n.isLocaleRTL() ? "bottomRight" : "bottomLeft";
    let statusHistory = this.props.order.status_history;
    if (statusHistory && statusHistory.length > 0) {
      return (
        <div className="inner-row">
          <span className="time">
            {statusHistory[statusHistory.length - 1].changed_at}
          </span>
          <Popover
            content={statusPopoverContent(statusHistory, this.moment)}
            placement={placement}
          >
            <span className="status">
              {statusHistory[statusHistory.length - 1].status_label}
              <CaretDownOutlined />
            </span>
          </Popover>
        </div>
      );
    }

    return null;
  }

  _renderDelivermanActions() {
    let actions = this.getOrderActions();
    return <div className="actions">{actions}</div>;
  }

  _renderProducts() {
    return (
      <div className="info-row products">
        {this._renderProductsHeader()}
        {this._renderProductsDetail()}
      </div>
    );
  }

  _renderProductsHeader() {
    let actionText = this.i18n.t("order.productInfoExpand");
    let actionIcon = <DownOutlined />;
    if (this.state.expanded) {
      actionText = this.i18n.t("order.productInfoCollapse");
      actionIcon = <UpOutlined />;
    }

    return (
      <div className="header">
        <span className="title">{this.i18n.t("order.productInfo")}</span>
        <Button
          className="noprint"
          type="link"
          onClick={() => this.setState({ expanded: !this.state.expanded })}
        >
          <span>{actionText}</span>
          {actionIcon}
        </Button>
      </div>
    );
  }

  _renderProductsDetail() {
    let activeKeys = [];
    if (this.state.expanded) {
      activeKeys = ["1"];
    }

    return (
      <Collapse bordered={false} activeKey={activeKeys}>
        <Collapse.Panel key="1" showArrow={false} header={null}>
          {this._renderProductsDetailItems()}
          
          {this._renderProductsDetailTotal()}
        </Collapse.Panel>
      </Collapse>
    );
  }

  

  _renderProductsDetailItems() {
    let details = this.props.order.details || [];

    return (
      <div className="details">
        {details.map((item, index) => {
          return (
            <div key={`order-detail-${index}`}>
              <div className="item">
                <Image src={item.product.images[0]} width={80} />
                <span className="name">
                  {this.i18n.getPropNameByLocale(item.product, "name")}
                </span>
                {item.selected_sku && (
                  <span className="price">
                    {this.i18n.getPropNameByLocale(item.selected_sku, "name")}
                  </span>
                )}
                <span className="price">
                  {this.util.getFormattedPrice(item.price, "￥")}
                </span>
                <span className="quantity">x{item.amount}</span>
                <span className="item_total">
                  {this.util.getFormattedPrice(item.amount * item.price, "￥")}
                </span>
              </div>
              {item.gifts &&
                item.gifts.length > 0 &&
                this._renderGifts(item.gifts)}
            </div>
          );
        })}
      </div>
    );
  }
  _renderGifts(gifts) {
    return (
      <div className="gifts">
        {gifts.map((item, index) => {
          return (
            <div key={`gift-${index}`} className="gift-item">
              <span>{this.i18n.t("order.giftLabel")}</span>
              <Image width={40} src={item.images && item.images[0]} />
              <span className="name">
                {this.i18n.getPropNameByLocale(item, "name")}
              </span>
              <span className="quantity">x{item.amount}</span>
            </div>
          );
        })}
      </div>
    );
  }
  _renderProductsDetailPackPrice() {
    let items = [];

    items.push(
      <div key={`${this.props.order.id}-pack-price`}>
        <span className="title">{this.i18n.t("order.packingFee")}</span>
        <span className="price">
          {this.util.getFormattedPrice(this.props.order.total_pack_price, "￥")}
        </span>
      </div>
    );

    if (this.props.order.deliver_by_us !== 1 || this.isBBShopMerchant()) {
      items.push(
        <div key={`${this.props.order.id}-ship-price`}>
          <span className="title">{this.i18n.t("order.shippingFee")}</span>
          <span className="price">
            {this.util.getFormattedPrice(this.props.order.ship_price, "￥")}
          </span>
        </div>
      );
    }

    return <div className="dishware-fee">{items}</div>;
  }

  _renderProductsDetailSubtotal() {
    let subTotal = this.props.order.total;

    let merchantPlacement = this.i18n.isLocaleRTL()
      ? "bottomRight"
      : "bottomLeft";
    let platformPlacement = this.i18n.isLocaleRTL()
      ? "bottomRight"
      : "bottomLeft";

    const expence = this.isBBShopMerchant()
      ? this.props.order.commission
      : this.props.order.merchant_expenses;

    return (
      <div className="subtotal">
        <div className="subtotal-header">
          <span className="title">{this.i18n.t("order.subTotal")}</span>
          <span className="price">
            {this.util.getFormattedPrice(subTotal, "￥")}
          </span>
        </div>
        <div>
          <span>
            <span className="title">
              {this.i18n.t("order.merchantExpence")}
            </span>
            <Popover
              content={subTotalMerchantPopoverContent(
                this.i18n,
                this.util,
                expence,
                this.isBBShopMerchant()
              )}
              placement={merchantPlacement}
            >
              <QuestionCircleOutlined />
            </Popover>
          </span>
          <span className="price">
            -
            {this.util.getFormattedPrice(
              this.props.order.merchant_expenses.amount,
              "￥"
            )}
          </span>
        </div>
        {!this.isBBShopMerchant() && (
          <div>
            <span>
              <span className="title">
                {this.i18n.t("order.platformIncome")}
              </span>
              <Popover
                content={subTotalPlatformPopoverContent(
                  this.i18n,
                  this.props.order.platform_rate
                )}
                placement={platformPlacement}
              >
                <QuestionCircleOutlined />
              </Popover>
            </span>
            <span className="price">
              -
              {this.util.getFormattedPrice(this.props.order.platform_fee, "￥")}
            </span>
          </div>
        )}
      </div>
    );
  }

  _renderProductsDetailTotal() {
    return (
      <div className="total">
        <div className="merchant-total">
          <span className="title">{this.i18n.t("order.refundAmount")}</span>
          <span className="price">
            {this.util.getFormattedPrice(
              this.props.order.refund_price,
              "￥"
            )}
          </span>
          <span className="status">{this.props.order.refund_status_label}</span>
          <span className="paytype">
            {this.props.order.paytype === 10 ? (
              <MoneyCollectFilled />
            ) : (
              <WechatFilled />
            )}
          </span>
        </div>
       
      </div>
    );
  }

  _renderFooter() {
    return (
      <div className="box-footer">
        <span className="time">
          {this.i18n.t("order.orderTime")}
          <span className="time-value">
            {this.moment(this.props.order.created_at).format(
              "YYYY-MM-DD HH:mm"
            )}
          </span>
        </span>
        <Divider type="vertical" />
        <span className="track-number">
          {this.i18n.t("order.orderNo")}
          <Text copyable>{this.props.order.order_no}</Text>
        </span>
        {this.isJdShop() && <Divider type="vertical" />}
        {this.isJdShop() && (
          <span className="track-number">
            {this.i18n.t("order.jdOrderNo")}
            <Text copyable>{this.props.order.jd_order_no}</Text>
          </span>
        )}
        <Divider type="vertical" />
        <span className="track-number">
          {this.i18n.t("order.bbOrderNo")}
          <Text copyable>{this.props.order.id}</Text>
        </span>
        {this.isBBShopMerchant() && this._renderBBShopShipNumber()}
      </div>
    );
  }

  _renderBBShopShipNumber() {
    let trackData = this.props.order.tracking_data;

    if (!trackData || !trackData.length) {
      return null;
    }

    let items = [];

    trackData.forEach((item) => {
      items.push(<Divider key={`track-divider-${item.id}`} type="vertical" />);
      items.push(
        <span key={`track-number-${item.id}`} className="track-number">
          {this.i18n.t("order.orderShipNo")}
          <Text
            copyable={{
              text: item.tracking_number,
              tooltips: false,
            }}
          >
            <Text
              style={{ color: "#419cb4" }}
              onClick={() =>
                this.fireAction(RefundListItem.BB_SHOP_ACTION_KUAIDI_100, {
                  ...item,
                })
              }
            >
              {item.tracking_name}
            </Text>
            ({item.tracking_number}
            {this.util.getFormattedPrice(item.shipping_price, "￥")})
          </Text>
        </span>
      );
    });

    return items;
  }

  isBBShopMerchant() {
    return this.props.bbshop;
  }

  isJdShop() {
    return this.props.merchant.id === 380;
  }

  static REFUND_REJECT = "refund-reject";
  static REFUND_ACCEPT = "refund-accept";
  static REFUND_ACCEPT_AFTER_RETURN = "refund-after-return";
  static REFUND_ACCEPT_AFTER_REJECT = "refund-after-reject";
  

  getOrderActions() {
    let items = [];
    const { refund_status } = this.props.order;
    if ([0].includes(refund_status)) {
      items.push(
        <Button
          key={RefundListItem.REFUND_ACCEPT}
          onClick={() =>
            this.fireAction(RefundListItem.REFUND_ACCEPT)
          }
        >
          {this.i18n.t("order.refundAccept")}
        </Button>
      );
      
      // items.push(
      //   <Button
      //     key={RefundListItem.REFUND_ACCEPT_AFTER_REJECT}
      //     onClick={() =>
      //       this.fireAction(RefundListItem.REFUND_ACCEPT_AFTER_REJECT)
      //     }
      //   >
      //     {this.i18n.t("order.refundAcceptAfterReject")}
      //   </Button>
      // );
      // items.push(
      //   <Button
      //     key={RefundListItem.REFUND_ACCEPT_AFTER_RETURN}
      //     onClick={() =>
      //       this.fireAction(RefundListItem.REFUND_ACCEPT_AFTER_RETURN)
      //     }
      //   >
      //     {this.i18n.t("order.refundAcceptAfterReturn")}
      //   </Button>
      // );

      items.push(
        <Button
          key={RefundListItem.REFUND_REJECT}
          onClick={() =>
            this.fireAction(RefundListItem.REFUND_REJECT)
          }
        >
          {this.i18n.t("order.refundReject")}
        </Button>
      );
    }

    return items;
  }

  fireAction = (action, data) => {
    if (this.props.onAction) {
      this.props.onAction(action, data);
    }
  };
}

const statusPopoverContent = (statusHistory, moment) => {
  let steps = [];
  for (let i = statusHistory.length - 1; i >= 0; i--) {
    let status = statusHistory[i];
    steps.push({
      key: `status-step-${i}`,
      icon: "profile",
      title: `[${status.changed_at}] ${status.status_label}`,
    });
  }

  return (
    <div id="route-order-list-item-status-popover">
      <Steps direction="vertical" size="small" current={0} items={steps} />
    </div>
  );
};

const subTotalMerchantPopoverContent = (
  i18n,
  util,
  expence,
  isBBShopMerchant
) => {
  if (!expence) return null;

  if (isBBShopMerchant && expence.length > 0) {
    return (
      <div id="route-order-list-item-subtotal-merchant-popover">
        <div>
          {i18n.t("order.parentCommission", {
            price: util.getFormattedPrice(
              expence[0] ? expence[0].commission : 0
            ),
          })}
        </div>
        <div>
          {i18n.t("order.childCommission", {
            price: util.getFormattedPrice(
              expence[1] ? expence[1].commission : 0
            ),
          })}
        </div>
      </div>
    );
  }

  return (
    <div id="route-order-list-item-subtotal-merchant-popover">
      <div>
        {i18n.t("order.merchantExpenceDisocunt", {
          price: util.getFormattedPrice(expence.discount),
        })}
      </div>
      <div>
        {i18n.t("order.merchantExpenceCoupon", {
          price: util.getFormattedPrice(expence.coupon),
        })}
      </div>
    </div>
  );
};

const subTotalPlatformPopoverContent = (i18n, rate) => {
  return (
    <div id="route-order-list-item-subtotal-platform-popover">
      <span>{i18n.t("order.platformRate", { rate: rate })}</span>
    </div>
  );
};
