import React from "react";

import { LeftOutlined } from "@ant-design/icons";

import { Layout, Divider, Row, Col, DatePicker, Input, Button } from "antd";

import Base from "../Base";

class PromotionNewUser extends Base {
  /* lifecycle methods */

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,

      rangeTime: [this.moment().subtract(7, "days"), this.moment()],
    };
  }

  /* render methods */

  render() {
    return (
      <Layout id="route-promotion-new-user">
        {this._renderHeader()}
        {this._renderContent()}
        {this._renderFooter()}
      </Layout>
    );
  }

  _renderHeader() {
    return (
      <div className="header-wrap">
        <Button
          style={{ backgroundColor: "transparent", borderWidth: 0 }}
          className="back"
          onClick={this.onHeaderBackClick}
        >
          <LeftOutlined />
          返回
        </Button>
        <Divider type="vertical" />
        <span className="title">新客立减活动设置</span>
      </div>
    );
  }

  _renderContent() {
    return (
      <div className="content-wrap">
        {this._renderContentTime()}
        {this._renderContentDiscount()}
      </div>
    );
  }

  _renderContentTime() {
    return (
      <Row gutter={16}>
        <Col span={6}>活动时间：</Col>
        <Col span={18}>
          <DatePicker.RangePicker
            defaultValue={this.state.rangeTime}
            format="YYYY-MM-DD"
            allowClear={false}
            onChange={this.handleTimeChange}
          />
        </Col>
      </Row>
    );
  }

  _renderContentDiscount() {
    return (
      <Row gutter={16} className="discount">
        <Col span={6}>新客优惠：</Col>
        <Col span={18}>
          <Input placeholder="0" />
          <span className="money-unit">元</span>
        </Col>
      </Row>
    );
  }

  _renderFooter() {
    return (
      <div className="footer-wrap">
        <Button type="primary" onClick={this.onFooterConfirmClick}>
          确定
        </Button>
        <Button onClick={this.onFooterCancelClick}>取消</Button>
      </div>
    );
  }

  /* user methods */

  onHeaderBackClick = () => {
    this.props.history.goBack();
  };

  handleFiltersTimeChange = (dates) => {
    this.setState({ rangeTime: dates });
  };

  onFooterConfirmClick = () => {};

  onFooterCancelClick = () => {
    this.props.history.goBack();
  };
}

export default PromotionNewUser;
