export default {
  list: {
    name: "order list api",
    version: 6,
    url: "order",
    method: "GET",
  },
  listExecl: {
    name: "order export list api",
    version: 6,
    url: "order/excels",
    method: "GET",
  },
  accept: {
    name: "order accept api",
    version: 6,
    url: "order/%{orderId}/accept",
    method: "POST",
  },
  cancel: {
    name: "order cancel api",
    version: 6,
    url: "order/%{orderId}/reject",
    method: "POST",
  },
  send: {
    name: "order send api",
    version: 6,
    url: "order/%{orderId}/take",
    method: "POST",
  },
  sendChange: {
    name: "order send change api",
    version: 6,
    url: "order/%{orderId}/update-tracking",
    method: "POST",
  },
  import: {
    name: "import excel api",
    version: 6,
    url: "order/import-excel",
    method: "POST",
  },
  export: {
    name: "order list export api",
    version: 6,
    url: "order/export-excel",
    method: "POST",
  },
  importDetail: {
    name: "get single xls detail api",
    version: 6,
    url: "order/import-excel/%{id}",
    method: "GET",
  },
  print: {
    name: "print order",
    version: 6,
    url: "order/%{orderId}/print",
    method: "POST",
  },
  calculate: {
    name: "calculate order",
    version: 6,
    url: "bbshop/merchant/calculate-commission/%{orderId}",
    method: "POST",
  },
  refund: {
    name: "refund order",
    version: 6,
    url: "order-refund/%{orderId}",
    method: "POST",
  },
  refundCheck: {
    name: "refund check",
    version: 6,
    url: "order-refund-check/%{orderId}",
    method: "POST",
  },
  refundList: {
    name: "order refund list api",
    version: 6,
    url: "order/refund-list",
    method: "GET",
  },
  finishProfitSharing: {
    name: "finish profit sharing",
    version: 6,
    url: "order/%{orderId}/finish-profit-sharing",
    method: "POST",
  },
};
