import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Badge, Button, Popconfirm } from "antd";
import { Locale } from "../../../utils";
const i18n = Locale.getInstance();
export const lessonListColumns = (
    deleteMutation,
    token,
    setLoading,
    selectLesson
) => {

    return [

        {
            title: i18n.t('course.lesson.name'),
            render: (item) => {
                return (
                    <span>{i18n.getPropNameByLocale(item, 'name')}</span>
                )
            },
        },
        {
            title: i18n.t("shows.sortTypesTitle"),
            dataIndex: "order",
        },
        {
            title: i18n.t("course.lesson.duration"),
            dataIndex: "duration",
        },
        {
            title: i18n.t("course.lesson.trial"),
            render: (item) => {
                return (
                    <Badge status={item.trial ? "success" : "default"} text={item.trial_label} />
                )
            }
        },
        {
            title: i18n.t("course.lesson.trialDuration"),
            dataIndex: "trial_duration",
        },
        {
            title: i18n.t("users.actions"),
            render: (item) => {
                return (
                    <>
                        <Popconfirm
                            title={i18n.t("sales.deleteWarning")}
                            onConfirm={() => {
                                setLoading(true);
                                deleteMutation.mutate({
                                    resource: 'course/lessons',
                                    params: {
                                        id: item.id,
                                        token,
                                    }
                                });
                            }}
                            okText={i18n.t("base.modal.okText")}
                            cancelText={i18n.t("base.modal.cancelText")}
                        >
                            <Button size="small" icon={<DeleteOutlined />}>
                                {i18n.t("common.delete")}
                            </Button>
                        </Popconfirm>
                        <Button onClick={() => {
                            selectLesson(item);
                        }} size="small" icon={<EditOutlined />}>
                            {i18n.t("product.productItem.actionEdit")}
                        </Button>
                    </>
                );
            },
        },
    ];
};
