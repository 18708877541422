import { Button, Form, Input, Radio, Spin } from "antd";
import React from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { Locale } from "../../utils";
import { showNotificationWithIcon } from "../../utils/helpers/helpers";
import { getUserInfo } from "../../utils/redux/reducers/User";
import { BreadCrumb } from "../main/LayoutComponents";
import { getStorageSettings, saveStorageSettings } from "./mediaApi";
import "./MediaStorageSetting.scss";
const i18n = Locale.getInstance();

const MediaStorageSetting = () => {
  const user = useSelector((state) => getUserInfo(state));
  const queryClient = useQueryClient();

  const onFinish = (values) => {
    saveStorageSettingMutation.mutate({ data: values, token: user.token });
  };

  const onFinishFailed = (errorInfo) => {
    showNotificationWithIcon(errorInfo);
  };

  const { data, isLoading } = useQuery(["storage-setting", user.token], () =>
    getStorageSettings({ token: user.token })
  );

  const breadCrumbData = [
    {
      href: "/main/media/files",
      title: i18n.t("main.sideMenus.media"),
    },
    {
      title: i18n.t("main.sideMenus.storageSetting"),
    },
  ];

  const saveStorageSettingMutation = useMutation(saveStorageSettings, {
    onSuccess: () => {
      queryClient.invalidateQueries("storage-setting");
      showNotificationWithIcon(i18n.t("media.saveFinished"), "success");
    },
    onError: (error) => {
      showNotificationWithIcon(error.message);
      setConfirmLoading(false);
    },
  });
  if (isLoading) {
    return <Spin spinning={isLoading} />;
  }
  return (
    <>
      <BreadCrumb data={breadCrumbData} />
      <Form
        className="storage-settings"
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item name="type" label="存储类型" initialValue={data?.data?.type}>
          <Radio.Group>
            <Radio value={1}>七牛存储</Radio>
            <Radio defaultChecked={true} value={0}>
              阿里云存储
            </Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label="App access Id"
          name="access_key"
          initialValue={data?.data?.access_key}
          rules={[{ required: true, message: "Please input your accesKey!" }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="App access key"
          name="secret_key"
          initialValue={data?.data?.secret_key}
          rules={[{ required: true, message: "Please input your secret key!" }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="Bucket"
          name="bucket"
          initialValue={data.data.bucket}
          rules={[{ required: true, message: "Please input your secret key!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Url"
          name="url"
          initialValue={data.data.url}
          rules={[{ required: true, message: "Please input your secret key!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            {i18n.t("sales.submit")}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default MediaStorageSetting;
