import React from "react";

import { Modal, Layout, Card, InputNumber, Form } from "antd";

import Base from "../Base";
const { Meta } = Card;

class DiscountProductModal extends Base {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      price: null,
    };
  }

  render() {
    let price = this.util.getPrice(this.props.product.price);

    return (
      <Modal
        open
        className="route-discount-product-modal"
        title={this.getTitle()}
        onCancel={this.onCancel}
        onOk={this.onConfirm}
        confirmLoading={this.getPropConfirmLoading()}
      >
        <Layout className="discount-product-wrap">
          <Card
            style={{ borderWidth: 0 }}
            cover={
              <img
                alt={this.props.product.zh_name}
                src={this.props.product.images[0].url}
              />
            }
          >
            <Meta title={this.props.product.zh_detail_description} />
            <p>
              {this.i18n.t("productEdit.outPriceLabel")} ￥ {price}
            </p>
            <Form ref={this.formRef} key={this.props.product.zh_name}>
              {this._renderDiscountedPrice(this.props.product)}
            </Form>
          </Card>
        </Layout>
      </Modal>
    );
  }

  _renderDiscountedPrice() {
    const item = {
      colon: false,
      label: this.i18n.t("product.discount.priceLabel"),
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      required: true,
    };

    return (
      <Form.Item
        {...item}
        rules={[
          {
            required: true,
            message: this.i18n.t("product.discount.discountedPriceHint"),
          },
        ]}
      >
        <InputNumber onChange={this.onChange} min={0} step={0.01} />
        <span className="unit">{this.i18n.t("common.yuan")}</span>
      </Form.Item>
    );
  }

  onChange = (value) => {
    this.setState({ price: value });
  };

  onCancel = () => {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  onConfirm = () => {
    this.formRef.current.validateFields().then((values) => {
      if (this.props.onConfirm) {
        this.props.onConfirm(this.props.product.id, this.state.price);
      }
    });
  };

  getPropConfirmLoading() {
    return this.props.confirmLoading;
  }

  getTitle() {
    let title = this.props.product.zh_name;
    return title;
  }
}

export default DiscountProductModal;
