import React from "react";

import {
  Layout,
  Row,
  Col,
  DatePicker,
  Radio,
  Pagination,
  Spin,
  Button,
} from "antd";

import Base from "../Base";

//redux
import { connect } from "react-redux";
import { getUserInfo } from "../../utils/redux/reducers/User";

class FinanceAccount extends Base {
  static Times = [
    { key: "month", name: "financeAccount.times.month" },
    { key: "season", name: "financeAccount.times.season" },
    { key: "custom", name: "financeAccount.times.custom" },
  ];

  static SettlementTypes = ["financeAccount.settlementTypes.cycle"];

  /* lifecycle mehotds */

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,

      timeType: this.getRouterQueryItemByKey(FinanceAccount.Times, "timeType"),
      rangeTime: this.getDefaultTimeRange(),

      data: null,
      loadingData: false,
      currentPage: this.getRouterQueryPage(),
      currentPageSize: this.getRouterQueryPageSize(5),
    };
  }

  componentDidMount() {
    super.componentDidMount();

    if (this.getUser()) {
      this.showMessage(
        this.i18n.t("financeAccount.loadingData"),
        Base.Message.loading
      );
      this.loadData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);

    if (
      prevState.timeType.key !== this.state.timeType.key ||
      prevState.rangeTime[0].diff(this.state.rangeTime[0]) !== 0 ||
      prevState.rangeTime[1].diff(this.state.rangeTime[1]) !== 0 ||
      prevState.currentPage !== this.state.currentPage ||
      prevState.currentPageSize !== this.state.currentPageSize
    ) {
      this.showMessage(
        this.i18n.t("financeAccount.loadingData"),
        Base.Message.loading
      );
      this.loadData();

      this.setRouterQuery({
        timeType: this.state.timeType.key,
        timeRange: `${this.state.rangeTime[0].format(
          "YYYY-MM-DD"
        )}~${this.state.rangeTime[1].format("YYYY-MM-DD")}`,
        page: this.state.currentPage,
        pageSize: this.state.currentPageSize,
      });
    }
  }

  /* render methods */

  render() {
    return (
      <Layout id="route-finance-account">
        {this._renderAccountInfo()}
        {this._renderFlowList()}
      </Layout>
    );
  }

  _renderAccountInfo() {
    return (
      <Row gutter={16} className="account-info">
        {this._renderAccountBallanceInfo()}
        {this._renderAccountBankInfo()}
      </Row>
    );
  }

  _renderAccountBallanceInfo() {
    let data = this.state.data;
    if (data) {
      let accountInfo = data ? data.account_info : null;
      if (accountInfo) {
        return (
          <Col span={12}>
            <div className="box-header">
              {this.i18n.t("financeAccount.ballanceInfoTitle")}
            </div>
            <div className="box-content">
              <div className="box-row">
                <span className="label">
                  {this.i18n.t("financeAccount.ballanceInfoCycle")}
                </span>
                <span className="value">
                  {this.i18n.t("financeAccount.ballanceInfoCycleValue", {
                    day: accountInfo.settlement_cycle,
                  })}
                </span>
              </div>
              <div className="box-row">
                <span className="label">
                  {this.i18n.t("financeAccount.ballanceInfoCycleType")}
                </span>
                <span className="value">
                  {this.i18n.t(
                    FinanceAccount.SettlementTypes[accountInfo.settlement_type]
                  )}
                </span>
              </div>
              <div className="box-row">
                <span className="label">
                  {this.i18n.t("financeAccount.ballanceInfoMin")}
                </span>
                <span className="value">
                  {this.i18n.t("financeAccount.ballanceInfoValue", {
                    amount: this.util.getFormattedPrice(
                      accountInfo.minimum_settlement_amount
                    ),
                  })}
                </span>
              </div>
              <div className="box-row">
                <span className="label">
                  {this.i18n.t("financeAccount.ballanceInfoContact")}
                </span>
                <span className="value">
                  {`${accountInfo.contact_name} ${accountInfo.contact_phone}`}
                </span>
              </div>
            </div>
          </Col>
        );
      } else {
        return (
          <Col span={12}>
            <div className="box-header">
              {this.i18n.t("financeAccount.ballanceInfoTitle")}
            </div>
            <div className="box-content">
              {this.i18n.t("financeAccount.loadingEmpty")}
            </div>
          </Col>
        );
      }
    } else {
      if (this.state.loadingData) {
        return (
          <Col span={12}>
            <div className="box-header">
              {this.i18n.t("financeAccount.ballanceInfoTitle")}
            </div>
            <div className="box-content">
              {this.i18n.t("financeAccount.loadingData")}
            </div>
          </Col>
        );
      }

      return (
        <Col span={12}>
          <div className="box-header">
            {this.i18n.t("financeAccount.ballanceInfoTitle")}
          </div>
          <div className="box-content">
            {this.i18n.t("financeAccount.loadingEmpty")}
          </div>
        </Col>
      );
    }
  }

  _renderAccountBankInfo() {
    let data = this.state.data;
    if (data) {
      let accountInfo = data ? data.account_info : null;
      if (accountInfo) {
        return (
          <Col span={12}>
            <div className="box-header">
              {this.i18n.t("financeAccount.bankInfoTitle")}
            </div>
            <div className="box-content">
              <div className="box-row">
                <span className="label">
                  {this.i18n.t("financeAccount.bankInfoName")}
                </span>
                <span className="value">{accountInfo.bank_name}</span>
              </div>
              <div className="box-row">
                <span className="label">
                  {this.i18n.t("financeAccount.bankInfoAccount")}
                </span>
                <span className="value">{accountInfo.bank_account}</span>
              </div>
              <div className="box-row">
                <span className="label">
                  {this.i18n.t("financeAccount.bankInfoAccountName")}
                </span>
                <span className="value">{accountInfo.bank_account_name}</span>
              </div>
            </div>
          </Col>
        );
      } else {
        return (
          <Col span={12}>
            <div className="box-header">
              {this.i18n.t("financeAccount.bankInfoTitle")}
            </div>
            <div className="box-content">
              {this.i18n.t("financeAccount.loadingEmpty")}
            </div>
          </Col>
        );
      }
    } else {
      if (this.state.loadingData) {
        return (
          <Col span={12}>
            <div className="box-header">
              {this.i18n.t("financeAccount.bankInfoTitle")}
            </div>
            <div className="box-content">
              {this.i18n.t("financeAccount.loadingData")}
            </div>
          </Col>
        );
      }

      return (
        <Col span={12}>
          <div className="box-header">
            {this.i18n.t("financeAccount.bankInfoTitle")}
          </div>
          <div className="box-content">
            {this.i18n.t("financeAccount.loadingEmpty")}
          </div>
        </Col>
      );
    }
  }

  _renderFlowList() {
    return (
      <div className="bill-flow-list">
        {this._renderFlowListTitle()}
        {this._renderFlowListFilter()}
        {this._renderFlowListHeader()}
        {this._renderFlowListItems()}
        {this._renderFlowListPagination()}
      </div>
    );
  }

  _renderFlowListTitle() {
    return (
      <div className="flow-list-title">
        {this.i18n.t("financeAccount.listTitle")}
      </div>
    );
  }

  _renderFlowListFilter() {
    return (
      <div className="flow-list-filter">
        <div className="filter-row">
          <span className="label">
            {this.i18n.t("financeAccount.listFilterTime")}
          </span>
          <Radio.Group
            size="small"
            value={this.state.timeType.key}
            onChange={this.handleFilterTimeTypeChange}
          >
            {FinanceAccount.Times.map((time, index) => {
              return (
                <Radio.Button key={time.key} value={time.key}>
                  {this.i18n.t(time.name)}
                </Radio.Button>
              );
            })}
          </Radio.Group>
          <DatePicker.RangePicker
            size="small"
            defaultValue={this.state.rangeTime}
            format="YYYY-MM-DD"
            allowClear={false}
            disabled={
              this.state.timeType.key !==
              FinanceAccount.Times[FinanceAccount.Times.length - 1].key
            }
            onChange={this.handleFiltersTimeChange}
          />
        </div>
      </div>
    );
  }

  _renderFlowListHeader() {
    return (
      <div className="flow-list-header">
        <span className="date">
          {this.i18n.t("financeAccount.listHeaderDate")}
        </span>
        <span className="name">
          {this.i18n.t("financeAccount.listHeaderName")}
        </span>
        <span className="amount">
          {this.i18n.t("financeAccount.listHeaderAmount")}
        </span>
        <span className="status">
          {this.i18n.t("financeAccount.listHeaderStatus")}
        </span>
        <span className="action">
          {this.i18n.t("financeAccount.listHeaderAction")}
        </span>
      </div>
    );
  }

  _renderFlowListItems() {
    let data = this.state.data;
    if (data) {
      let records = data ? data.records : null;
      let recordsData = records ? records.data : null;

      if (recordsData && recordsData.length > 0) {
        return (
          <Spin spinning={this.state.loadingData}>
            <div className="flow-list-items">
              {recordsData.map((item, index) => {
                const checkoutLabel = this.i18n.t(
                  "financeAccount.checkoutDateLabel",
                  {
                    checkout_date: item.checkout_date,
                  }
                );
                console.log(
                  "🚀 ~ file: FinanceAccount.js ~ line 356 ~ FinanceAccount ~ _renderFlowListItems ~ checkoutLabel",
                  checkoutLabel
                );
                return (
                  <div
                    key={`list-item-${index}`}
                    className="flow-list-header item"
                  >
                    <span className="date">{item.checkout_date}</span>
                    <span className="name">{checkoutLabel}</span>
                    <span className="amount">
                      {this.i18n.t("financeAccount.listItemAmount", {
                        amount: this.util.getFormattedPrice(
                          item.total_merchant_income
                        ),
                      })}
                    </span>
                    <span className="status">
                      {this.i18n.t("financeAccount.listItemStatusSuccess")}
                    </span>
                    <span className="action">
                      <Button
                        style={{
                          borderWidth: 0,
                          backgroundColor: "transparent",
                        }}
                        onClick={() => this.onBillListItemDetailClick(item)}
                      >
                        {this.i18n.t("financeAccount.listItemActionDetails")}
                      </Button>
                    </span>
                  </div>
                );
              })}
            </div>
          </Spin>
        );
      } else {
        return (
          <div className="flow-list-items">
            <div className="items-message">
              {this.i18n.t("financeAccount.loadingEmpty")}
            </div>
          </div>
        );
      }
    } else {
      if (this.state.loadingData) {
        return (
          <div className="flow-list-items">
            <div className="items-message">
              {this.i18n.t("financeAccount.loadingData")}
            </div>
          </div>
        );
      }

      return (
        <div className="flow-list-items">
          <div className="items-message">
            {this.i18n.t("financeAccount.loadingEmpty")}
          </div>
        </div>
      );
    }
  }

  _renderFlowListPagination() {
    let data = this.state.data;
    let records = data ? data.records : null;
    if (records && records.data && records.data.length > 0) {
      return (
        <div className="flow-list-pagination">
          <Pagination
            total={records.total}
            current={this.state.currentPage}
            showSizeChanger={true}
            pageSizeOptions={["5", "10", "15", "20"]}
            pageSize={this.state.currentPageSize}
            onChange={this.onPaginationPageChange}
            onShowSizeChange={this.onPaginationPageSizeChange}
            showQuickJumper
          />
        </div>
      );
    } else {
      return null;
    }
  }

  /* user methods */

  handleFilterTimeTypeChange = (e) => {
    let timeType = FinanceAccount.Times.find(
      (time) => time.key === e.target.value
    );
    let rangeTime = this.state.rangeTime;

    if (timeType.key === FinanceAccount.Times[0].key) {
      rangeTime = [this.moment().subtract(30, "days"), this.moment()];
    } else if (timeType.key === FinanceAccount.Times[1].key) {
      rangeTime = [this.moment().subtract(90, "days"), this.moment()];
    }

    this.setState({ timeType: timeType, rangeTime: rangeTime, currentPage: 1 });
  };

  handleFiltersTimeChange = (dates) => {
    this.setState({ rangeTime: dates, currentPage: 1 });
  };

  onBillListItemDetailClick(bill) {
    this.props.history.push({
      pathname: "/main/finance/bill-detail",
      query: {
        billId: bill.id,
      },
    });
  }

  onPaginationPageChange = (page, pageSize) => {
    this.setState({ currentPage: page });
  };

  onPaginationPageSizeChange = (page, pageSize) => {
    this.setState({ currentPageSize: pageSize, currentPage: 1 });
  };

  /* server methods */

  async loadData() {
    if (this.state.loadingData) return;

    let token = this.getUserToken();
    if (!token) return;

    let api = this.api.checkout.checkout;
    let apiConfig = {
      token: token,
      query: {
        "filter[start_date]": this.state.rangeTime[0].format("YYYY-MM-DD"),
        "filter[end_date]": this.state.rangeTime[1].format("YYYY-MM-DD"),
        with_account: 1,
        with_record_details: 0,
        page: this.state.currentPage,
        count: this.state.currentPageSize,
      },
    };

    this.setState({ loadingData: true });
    try {
      let res = await api.run(apiConfig);

      if (!this.mounted) return;

      if (res.result) {
        this.setState({ data: res.data });
      } else {
        this.showMessage(res.message, Base.Message.error);
      }
    } catch (error) {
      if (!this.mounted) return;
      this.handleApiError(api, error);
    } finally {
      if (!this.mounted) return;
      this.setState({ loadingData: false });
    }
  }

  /* custom methods */

  getDefaultTimeRange() {
    let rangeTime = [this.moment().subtract(30, "days"), this.moment()];

    let type = this.getRouterQueryItemByKey(FinanceAccount.Times, "timeType");
    if (type.key === FinanceAccount.Times[1].key) {
      rangeTime = [this.moment().subtract(90, "days"), this.moment()];
    } else if (type.key === FinanceAccount.Times[2].key) {
      let queryTime = this.getRouterQueryTimeRange();
      if (queryTime) {
        rangeTime = queryTime;
      }
    }

    return rangeTime;
  }
}

export default connect((state) => {
  return {
    user: getUserInfo(state),
  };
})(FinanceAccount);
