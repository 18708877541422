import { stringify } from "qs";
import newRequest, { buildBaseURL } from "./newRuequest";

const resources = {
  courses: { prefix: "course", version: "v1" },
  course: { prefix: "course", version: "v1" },
  "red-packet": { prefix: "lottery", version: "v1" },
  "red-packet-detail": { prefix: "lottery", version: "v1" },
  "lottery-store": { prefix: "lottery", version: "v1" },
  "lottery-recommender": { prefix: "lottery", version: "v1" },
  "tuangou-shops": { prefix: "tuangou", version: "v6" },
  "lottery-agreements": { prefix: "lottery", version: "v1" },
  "lottery-redeem-orders": { prefix: "lottery", version: "v1" },
  "course/lessons": { prefix: "course", version: "v1" },
  "course/teachers": { prefix: "course", version: "v1" },
  "course/students": { prefix: "course", version: "v1" },
  "course/orders": { prefix: "course", version: "v1" },
  "course/categories": { prefix: "course", version: "v1" },
  product: { prefix: "", version: "v4" },
};
const getPrefix = (resource) => {
  if (resource.indexOf("red-packet") !== -1) {
    return "lottery";
  }
  if (resource.indexOf("red-packet-detail") !== -1) {
    return "lottery";
  }
  if (resource.indexOf("lottery-qrcode") !== -1) {
    return "lottery";
  }
  if (resource.indexOf("lottery-store") !== -1) {
    return "lottery";
  }
  if (resource.indexOf("lottery-recommender") !== -1) {
    return "lottery";
  }

  if (Object.keys(resources).includes(resource)) {
    return resources[resource].prefix;
  }
  return null;
};

const getVersion = (resource) => {
  if (resource.indexOf("red-packet") !== -1) {
    return "v1";
  }
  if (resource.indexOf("red-packet-detail") !== -1) {
    return "v1";
  }
  if (resource.indexOf("lottery-qrcode") !== -1) {
    return "v1";
  }
  if (resource.indexOf("lottery-store") !== -1) {
    return "v1";
  }
  if (resource.indexOf("lottery-recommender") !== -1) {
    return "v1";
  }

  if (Object.keys(resources).includes(resource)) {
    return resources[resource].version;
  }
  return "v6";
};
export const getList = async (resource, params) => {
  const { page, perPage } = params.pagination;
  const { field, order } = params.sort;
  let query = {
    orderBy: field,
    sortedBy: order,
    page,
    limit: perPage,
    count: perPage
  };

  if (params.filter && Object.keys(params.filter).length > 0) {
    query = { ...query, filter: { ...params.filter } };
  }

  if (params.query && Object.keys(params.query).length > 0) {
    query = { ...query, ...params.query };
  }

  let prefix = buildBaseURL(getVersion(resource), getPrefix(resource));

  const url = prefix + `merchant/${resource}?${stringify(query)}`;
  const result = await newRequest.get(url, params.token);
  return {
    data: result.data.data ?? [],
    total: result.data.total ? result.data.total : 10,
    current_page: result.data.current_page ? result.data.current_page : 1,
  };
};

export const getOne = async (resource, params) => {
  const url =
    buildBaseURL(getVersion(resource), getPrefix(resource)) +
    `merchant/${resource}/${params.id}`;
  const result = await newRequest.get(url, params.token);
  return result?.data;
};

export const getMany = async (resource, params) => {
  let query = {};
  if (params?.ids) {
    query["search"] = JSON.stringify({ id: params.ids });
  }
  if (params?.filter) {
    params.filter.map((filter) => {
      query[`filter[${filter?.label}]`] = filter.value;
    });
  }
  const url =
    buildBaseURL(getVersion(resource), getPrefix(resource)) +
    `merchant/${resource}?${stringify(query)}`;
  const result = await newRequest.get(url, params.token);

  return {
    data: result.data ?? [],
    total: result.total ? result.total : 10,
    current_page: result.current_page ? result.current_page : 1,
  };
};

export const getManyReference = async (resource, params) => {
  const { page, perPage } = params.pagination;
  const { field, order } = params.sort;
  let query = {
    orderBy: field,
    sortedBy: order,
    page,
    limit: perPage,
  };
  if (params.filter && Object.keys(params.filter).length > 0) {
    query = { ...query, filter: { ...params.filter } };
  }
  const url =
    buildBaseURL(getVersion(resource), getPrefix(resource)) +
    `merchant/${resource}?${stringify(query)}`;
  const result = await newRequest.get(url, params.token);
  return {
    data: result.data.data ?? [],
    total: result.data.total ? result.data.total : 10,
    current_page: result.data.current_page ? result.data.current_page : 1,
  };
};

export const update = async ({ resource, params }) => {
  const url =
    buildBaseURL(getVersion(resource), getPrefix(resource)) +
    `merchant/${resource}/${params.id}`;
  const result = await newRequest.put(url, params.data, params.token);
  return result?.data;
};

export const updateMany = async (resource, params) => {
  const query = {
    search: JSON.stringify({ id: params.ids }),
  };
  const url =
    buildBaseURL(getVersion(resource), getPrefix(resource)) +
    `merchant/${resource}?${stringify(query)}`;
  const result = await newRequest.put(url, data, params.token);
  return result?.data;
};

export const create = async ({ resource, params }) => {
  const url =
    buildBaseURL(getVersion(resource), getPrefix(resource)) +
    `merchant/${resource}`;
  const { data } = params;
  const result = await newRequest.post(url, data, params.token);
  return result?.data?.data;
};

export const remove = async ({ resource, params }) => {
  const url =
    buildBaseURL(getVersion(resource), getPrefix(resource)) +
    `merchant/${resource}/${params.id}`;
  const result = await newRequest.delete(url, params.token);
  if (!result.result) {
    throw new Error(result?.message);
  }
  return {
    data: result.data ?? [],
    total: result.total ? result.total : 10,
    current_page: result.data.current_page ? result.data.current_page : 1,
  };
};


export const wrappedCustomList = async (resource, params) => {
  const { page, perPage } = params.pagination;
  console.log("🚀 ~ wrappedCustomList ~ page:", params)
  const { field, order } = params.sort;
  let query = {
    orderBy: field,
    sortedBy: order,
    page,
    count: perPage,
  };

  if (params.filter && Object.keys(params.filter).length > 0) {
    query = { ...query, filter: { ...params.filter } };
  }

  if (params.query && Object.keys(params.query).length > 0) {
    query = { ...query, ...params.query };
  }

  let prefix = buildBaseURL(getVersion(resource), getPrefix(resource));

  const url = prefix + `merchant/${resource}/${params.url}?${stringify(query)}`;
  
  const result = await newRequest.get(url, params.token);
  return {
    data: result.data.data ?? [],
    total: result.data.total ? result.data.total : 10,
    current_page: result.data.current_page ? result.data.current_page : 1,
  };
};


